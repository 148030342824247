import { type Address, ContractFunctionZeroDataError, type PublicClient } from 'viem';

import { walkError } from '@/helpers/errors';
import { createLogger } from '@/logs/datadog';
import { LruMap } from '@/references/LruMap';
import { deriveChainId } from '@/references/onchain/adapters';

import { argentWalletDetectorABI } from '../abi/argentWalletDetector';
import { isContract } from './isContract';

const argentWalletDetectors: Record<number, Address> = {
  1: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8'
} as const;

const cache = new LruMap<string, boolean>(16);
const logger = createLogger('isArgentWallet');

export const isArgentWallet = async (
  publicClient: PublicClient,
  address: Address
): Promise<boolean> => {
  const chainId = await deriveChainId(publicClient);
  const cacheKey = `${chainId}:${address}`;
  const cached = cache.get(cacheKey);
  if (cached !== undefined) {
    return cached;
  }

  const detectorAddress = argentWalletDetectors[chainId];
  if (detectorAddress === undefined) {
    cache.set(cacheKey, false);
    return false;
  }

  logger.debug(
    `Argent detector in ${chainId} CA is ${detectorAddress}. Checking if it's an actual contract`
  );

  const isDetectorAddressCA = await isContract(publicClient, detectorAddress);
  if (!isDetectorAddressCA) {
    logger.warn(
      `A detector address is not a CA. Assume the AA wallet ${address} is not an Argent wallet`
    );
    cache.set(cacheKey, false);
    return false;
  }

  try {
    const res = await publicClient.readContract({
      address: detectorAddress,
      abi: argentWalletDetectorABI,
      functionName: 'isArgentWallet',
      args: [address]
    });

    cache.set(cacheKey, res);
    return res;
  } catch (error) {
    if (walkError(error, (e) => e instanceof ContractFunctionZeroDataError)) {
      logger.warn(
        `The function call "isArgentWallet" on ${detectorAddress} in chain ${chainId} reverted. Either such function is not implemented or the address is EOA`
      );

      cache.set(cacheKey, false);
      return false;
    }

    throw error;
  }
};
