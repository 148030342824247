const processEndpointUrl = (url: string | undefined) => {
  if (typeof url !== 'string') {
    return '';
  }

  return new URL(url).toString();
};

const endpoints = {
  apiBrrr: processEndpointUrl(import.meta.env.VITE_API_BRRR_SERVICE_URL),
  apiAssets: processEndpointUrl(import.meta.env.VITE_API_ASSETS_SERVICE_URL),
  apiDatabus: processEndpointUrl(import.meta.env.VITE_API_DATABUS_SERVICE_URL)
};

export const getEndpoint = <K extends keyof typeof endpoints>(key: K): string => {
  return endpoints[key];
};
