<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { useStepper } from '@vueuse/core';

import topUpLottieData from '@/assets/lottie/top-up.json';
import { useBrrrOnChainService } from '@/composables/services/useBrrrOnChainService';
import { useBrrr } from '@/composables/useBrrr';
import { type ClientEECode, useErrorModal } from '@/composables/useErrorModal';
import { useWagmi } from '@/composables/useWagmi';
import { intersect } from '@/helpers/arrays';
import { isZero } from '@/helpers/bigmath';
import { fade, type MotionVariants, slideLeft, slideRight } from '@/helpers/motion';
import { addSentryBreadcrumb } from '@/logs/sentry';
import { PROGRESS_VIEW_VISIBILITY_DURATION } from '@/references/constants';
import { ExpectedError } from '@/references/ExpectedError';
import { Network } from '@/references/network';
import type { TokenWithPriceAndBalance } from '@/references/tokens';
import { RouteNames } from '@/router';
import { BrrrBuyViewStep } from '@/views/BrrrBuyView.types';

import BrrrCrypto from '@/components/BrrrCrypto.vue';
import ConnectModal from '@/components/ConnectModal.vue';
import FlowNetworkSelector from '@/components/FlowNetworkSelector.vue';
import { InnerLayoutButtonType } from '@/components/layouts/InnerLayout.types';
import InnerLayout from '@/components/layouts/InnerLayout.vue';
import ProgressLayout from '@/components/layouts/ProgressLayout.vue';
import TokenSelector from '@/components/TokenSelector.vue';

const { t } = useI18n();
const { replace, push } = useRouter();
const { supportedNetworks: walletSupportedNetworks } = useWagmi();
const { isBrrrAvailable, available, termsAccepted, infoAccepted } = useBrrr();

const state = reactive<{
  isMounted: boolean;
  previousStep: BrrrBuyViewStep | null;
  network: Network;
  token: TokenWithPriceAndBalance | null;
  isProgressState: boolean;
  isConnectModalOpened: boolean;
  txHash: string;
}>({
  isMounted: false,
  previousStep: null,
  network: Network.unknown,
  token: null,
  isProgressState: false,
  isConnectModalOpened: false,
  txHash: ''
});

const stepper = useStepper([
  BrrrBuyViewStep.Chain,
  BrrrBuyViewStep.Token,
  BrrrBuyViewStep.Value,
  BrrrBuyViewStep.Review
]);

let progressTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

const tipDescription = computed((): string | undefined => {
  if (stepper.current.value === BrrrBuyViewStep.Review) {
    if (state.token === null) {
      return t('forms.bankTransfer.tips.youAreAboutToMakeATransaction');
    }
    return t('youAreAboutToMakeATransaction');
  }

  return undefined;
});

const internalMotion = computed((): MotionVariants<never> | undefined => {
  if (!state.isMounted) {
    return fade;
  }

  if (!state.previousStep || stepper.isAfter(state.previousStep)) {
    return slideRight;
  }

  return slideLeft;
});

const networks = computed(() => {
  const serviceAvailableNetworks = useBrrrOnChainService().getAvailableNetworks();
  return intersect(walletSupportedNetworks.value, serviceAvailableNetworks);
});

function goBack(): void {
  if (stepper.current.value === BrrrBuyViewStep.Chain) {
    replace({ name: RouteNames.Brrr });
    return;
  }

  state.previousStep = stepper.current.value;
  stepper.goToPrevious();
}

function goNext(): void {
  state.previousStep = stepper.current.value;
  stepper.goToNext();
}

function selectedNetwork(net: Network | 'solana'): void {
  if (net === 'solana') {
    replace({ name: RouteNames.BrrrBuySolanaToken });
    return;
  }

  state.isConnectModalOpened = true;
}

function closeConnectModal(): void {
  state.isConnectModalOpened = false;
}

function selectToken(token: TokenWithPriceAndBalance): void {
  state.token = token;
  goNext();
}

function onConnected(): void {
  state.isConnectModalOpened = false;
  goNext();
}

function onDone(txHash: string): void {
  state.txHash = txHash;
  state.isProgressState = true;

  progressTimeout = setTimeout(() => {
    push({ name: RouteNames.Home });
  }, PROGRESS_VIEW_VISIBILITY_DURATION);
}

onMounted(() => {
  state.isMounted = true;

  if (
    !isBrrrAvailable.value ||
    !termsAccepted.value ||
    (!infoAccepted.value && isZero(available.value))
  ) {
    addSentryBreadcrumb({
      level: 'debug',
      message: 'BRRR buy feature is not currently not available',
      data: {
        featureAvailable: isBrrrAvailable.value,
        termsAccepted: termsAccepted.value,
        infoAccepted: infoAccepted.value
      }
    });

    useErrorModal().auto(
      new ExpectedError<ClientEECode>('brrrUnavailable', {
        sentryHandle: false,
        payload: {
          featureAvailable: isBrrrAvailable.value,
          termsAccepted: termsAccepted.value,
          infoAccepted: infoAccepted.value
        }
      })
    );

    replace({ name: RouteNames.Home });
  }
});

onBeforeUnmount(() => {
  clearTimeout(progressTimeout);
});
</script>

<template>
  <section class="brrr-buy-view">
    <ProgressLayout
      v-if="state.isProgressState"
      v-motion="slideRight"
      :title="t('brrr')"
      :lottie="topUpLottieData"
    />
    <InnerLayout
      v-else
      :title="t('brrrBuy.title')"
      :button-type="InnerLayoutButtonType.Back"
      :tip-title="t('whatDoesItMean')"
      :tip-description="tipDescription"
      @back="goBack"
    >
      <FlowNetworkSelector
        v-if="stepper.current.value === BrrrBuyViewStep.Chain"
        @selected="selectedNetwork"
      />
      <TokenSelector
        v-else-if="stepper.current.value === BrrrBuyViewStep.Token"
        v-model:network="state.network"
        v-motion="internalMotion"
        :networks="networks"
        is-fiat-button-visible
        use-markup-for-price
        :searchable="false"
        @select="selectToken"
      />
      <BrrrCrypto
        v-else-if="state.token !== null"
        :step="stepper.current.value"
        :token="state.token"
        :motion="internalMotion"
        @next="goNext"
        @done="onDone"
      />
    </InnerLayout>
    <ConnectModal
      :is-opened="state.isConnectModalOpened"
      @close="closeConnectModal"
      @connected="onConnected"
    />
  </section>
</template>

<style scoped>
.brrr-buy-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
