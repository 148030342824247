import { useApprovalAPIService } from '@/composables/services/useApprovalAPIService';
import { usePermit2OnChainService } from '@/composables/services/usePermit2OnChainService';
import { usePermitOnChainService } from '@/composables/services/usePermitOnChainService';
import { createWalletInfoAdapter } from '@/helpers/walletInfoAdapter';
import { BRRROnChainService } from '@/references/onchain/holyheld/BRRROnChainService';

let service: BRRROnChainService | undefined;
export const useBrrrOnChainService = (): BRRROnChainService => {
  if (service === undefined) {
    service = new BRRROnChainService({
      permitService: usePermitOnChainService(),
      permit2Service: usePermit2OnChainService(),
      walletInfo: createWalletInfoAdapter(),
      approvalService: useApprovalAPIService()
    });
  }

  return service;
};
