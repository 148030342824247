<script setup lang="ts">
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import helpLottieData from '@/assets/lottie/help.json';
import { useBrrr } from '@/composables/useBrrr';
import { type ClientEECode, useErrorModal } from '@/composables/useErrorModal';
import { ExpectedError } from '@/references/ExpectedError';
import { RouteNames } from '@/router';

import { InnerLayoutButtonType } from '@/components/layouts/InnerLayout.types';
import InnerLayout from '@/components/layouts/InnerLayout.vue';
import UiButton from '@/components/ui/UiButton.vue';
import { type UiListItem } from '@/components/ui/UiList.types';
import UiList from '@/components/ui/UiList.vue';
import UiLottie from '@/components/ui/UiLottie.vue';
import UiText from '@/components/ui/UiText.vue';

const state = reactive<{
  isLoading: boolean;
}>({
  isLoading: false
});

const { t } = useI18n();
const { back, push } = useRouter();
const { acceptInfo } = useBrrr();
const { auto } = useErrorModal();

const items = computed((): UiListItem[] => {
  return [
    {
      title: t('brrrInfo.items.clearingHouse.title'),
      description: t('brrrInfo.items.clearingHouse.description'),
      icon: 'holytag'
    },
    {
      title: t('brrrInfo.items.layer.title'),
      description: t('brrrInfo.items.layer.description'),
      icon: 'box'
    },
    {
      title: t('brrrInfo.items.pointOfAccess.title'),
      description: t('brrrInfo.items.pointOfAccess.description'),
      icon: 'star'
    },
    {
      title: t('brrrInfo.items.futureProof.title'),
      description: t('brrrInfo.items.futureProof.description'),
      icon: 'currency'
    },
    {
      title: t('brrrInfo.items.global.title'),
      description: t('brrrInfo.items.global.description'),
      icon: 'auto-theme'
    },
    {
      title: t('brrrInfo.items.tested.title'),
      description: t('brrrInfo.items.tested.description'),
      icon: 'vip'
    }
  ];
});

async function onButtonClick(): Promise<void> {
  try {
    state.isLoading = true;
    await acceptInfo();
    await push({ name: RouteNames.BrrrTermsAndConditions });
  } catch (error) {
    auto(new ExpectedError<ClientEECode>('brrrInfoAccept', { cause: error, sentryHandle: true }));
  } finally {
    state.isLoading = false;
  }
}
</script>

<template>
  <InnerLayout class="brr-info-view" :button-type="InnerLayoutButtonType.Back" @back="back">
    <div class="brr-info-view__body">
      <UiLottie class="brr-info-view__icon" :data="helpLottieData" :size="120" loop autoplay />
      <UiText
        class="brr-info-view__description"
        weight="medium"
        size="lg"
        align="center"
        tag="p"
        muted
      >
        {{ t('brrrInfo.description') }}
      </UiText>
      <UiList class="brr-info-view__list" :items="items" />
      <div class="brr-info-view__button-wrapper">
        <UiButton
          class="brr-info-view__button"
          type="button"
          :loading="state.isLoading"
          @click="onButtonClick"
        >
          {{ t('continue') }}
        </UiButton>
      </div>
    </div>
  </InnerLayout>
</template>

<style scoped>
.brr-info-view {
  position: relative;
  flex-grow: 1;
}

.brr-info-view__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 8px 0 0;
}

.brr-info-view__icon {
  margin: 0 auto 24px;
}

.brr-info-view__description {
  margin: 0 0 40px;
}

.brr-info-view__list {
  margin: 0 0 80px;
}

.brr-info-view__button-wrapper {
  position: sticky;
  bottom: 40px;
  margin: auto 0 0;
  background-color: var(--color-background);
  box-shadow: var(--shadow-background-spread-lg);
}
</style>
