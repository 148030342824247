import { createWalletInfoAdapter } from '@/helpers/walletInfoAdapter';
import { Permit2OnChainService } from '@/references/onchain/holyheld/Permit2OnChainService';

let service: Permit2OnChainService | undefined;
export const usePermit2OnChainService = (): Permit2OnChainService => {
  if (service === undefined) {
    service = new Permit2OnChainService(createWalletInfoAdapter());
  }
  return service;
};
