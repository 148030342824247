import dayjs from 'dayjs';

import { useNonceAPIService } from '@/composables/services/useNonceAPIService';
import { useWagmi } from '@/composables/useWagmi';
import { captureSentryException } from '@/logs/sentry';
import { HHError } from '@/references/HHError';
import { isContract } from '@/references/onchain/account-abstraction-wallets/isContract';
import type { IsErc1271SignerParams, WalletInfoAdapter } from '@/references/onchain/adapters';

export function createWalletInfoAdapter(): WalletInfoAdapter {
  const {
    address,
    getPublicClient,
    supportsSignTypedDataV4: _supportsSignTypedDataV4
  } = useWagmi();

  return {
    async isErc1271Signer({ chainId }: IsErc1271SignerParams): Promise<boolean> {
      if (address.value === undefined) {
        return false;
      }

      if (await isContract(getPublicClient({ chainId }), address.value)) {
        return true;
      }

      return false; //TODO check return value
    },
    supportsSignTypedDataV4: () => _supportsSignTypedDataV4.value,
    supportsRawTransactionsSigning: () => false,
    async getOffchainPermit2Nonce({ address: senderAddress, network }) {
      try {
        return await useNonceAPIService().getNonce(senderAddress, network);
      } catch (error) {
        captureSentryException(
          new HHError('Failed to get offchain permit2 nonce', { cause: error })
        );
        return BigInt(dayjs().unix()); // default to at least something to not block the flow
      }
    },
    async incrementPermit2Nonce({ address: senderAddress, network }): Promise<void> {
      try {
        return await useNonceAPIService().incrementNonce(senderAddress, network);
      } catch (error) {
        captureSentryException(new HHError('Failed to increment nonce', { cause: error }));
      }
    }
  };
}
