<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRouter } from 'vue-router';

import { formatUnits } from 'viem';

import topUpLottieData from '@/assets/lottie/top-up.json';
import { type GetBrrrFlowReturn, useBrrrTransaction } from '@/composables/useBrrrTransaction';
import { useCurrency } from '@/composables/useCurrency';
import { type ClientEECode, useErrorModal } from '@/composables/useErrorModal';
import { useTokens } from '@/composables/useTokens';
import { useWagmi } from '@/composables/useWagmi';
import { assert } from '@/helpers/assert';
import { add, divide, isZero, lessThan } from '@/helpers/bigmath';
import { walkError } from '@/helpers/errors';
import { GasWatcher, type NetworkInfoEvent, WatchEvent } from '@/helpers/flow/gas/watchGas';
import { BadSwapPriceError, type PrepareReturn, Type } from '@/helpers/flow/types';
import {
  calcLocalInsufficientToken,
  isNeedApproveOrPermit,
  isNeedTransaction
} from '@/helpers/flow/utils';
import { formatAmount, formatTokenAmount, formatTokensRate } from '@/helpers/formatters';
import { addSentryBreadcrumb, captureSentryException } from '@/logs/sentry';
import { AmountMode } from '@/references/axios/transactions/types';
import { MAX_TOKEN_VISIBLE_DECIMALS } from '@/references/constants';
import { CurrencyCode } from '@/references/currency';
import { ExpectedError } from '@/references/ExpectedError';
import { HHError } from '@/references/HHError';
import { getNetwork, isBaseAssetByNetwork } from '@/references/network';
import { getMaxPermit2Allowance } from '@/references/onchain/erc20/approve';
import type { EstimateContractGasCompoundReturn } from '@/references/onchain/gas';
import { AllowanceMismatchError } from '@/references/onchain/holyheld/AllowanceMismatchError';
import { AllowanceFlow } from '@/references/onchain/holyheld/flowTypes';
import { getBrrrToken } from '@/references/tokens';
import { RouteNames } from '@/router';

import { ApproveOrPermitModalType } from '@/components/ApproveOrPermitModal.types';
import ApproveOrPermitModal from '@/components/ApproveOrPermitModal.vue';
import ConfirmationModal from '@/components/ConfirmationModal.vue';
import FeeInfo from '@/components/FeeInfo.vue';
import FeeModal from '@/components/FeeModal.vue';
import TipModal from '@/components/TipModal.vue';
import { TransactionPendingModalStatus } from '@/components/TransactionPendingModal.types';
import TransactionPendingModal from '@/components/TransactionPendingModal.vue';
import TransactionReview from '@/components/TransactionReview.vue';
import type { UiListPairGroup, UiListPairItem } from '@/components/ui/UiListPair.types';

interface Props {
  prepare: PrepareReturn<Type.Brrr>;
  flowData: GetBrrrFlowReturn;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'done', txHash: string): void;
}>();

const state = reactive<{
  prepare: PrepareReturn<Type.Brrr>;
  flowData: GetBrrrFlowReturn;
  approveNetworkInfo: NetworkInfoEvent | null;
  txHash: string;
  txApproveHash: string;
  pendingStatus: TransactionPendingModalStatus;
  isPendingModalOpened: boolean;
  isExecuting: boolean;
  isDataReloading: boolean;
  isApproveOrPermitModalOpened: boolean;
  isBadPriceDetected: boolean;
  isSwapInsufficientLiquidity: boolean;
  isAllowanceMismatchError: boolean;
  isExpiredApproveOrPermit: boolean;
  isFailToCheckAllowance: boolean;
  isCalculatingNetworkInfo: boolean;
  isCalculatingNewAmount: boolean;
  isPulsingNetworkInfo: boolean;
  nonTransferrableInfoOpened: boolean;
  updatingTokensReceiver: boolean;
  isFeeModalOpened: boolean;
  leaveModalCallback: ((isValid?: boolean) => void) | null;
}>({
  prepare: props.prepare,
  flowData: props.flowData,
  approveNetworkInfo: null,
  txHash: '',
  txApproveHash: '',
  pendingStatus: TransactionPendingModalStatus.Confirm,
  isPendingModalOpened: false,
  isExecuting: false,
  isDataReloading: false,
  isApproveOrPermitModalOpened: false,
  isBadPriceDetected: false,
  isSwapInsufficientLiquidity: false,
  isAllowanceMismatchError: false,
  isExpiredApproveOrPermit: false,
  isFailToCheckAllowance: false,
  isCalculatingNetworkInfo: false,
  isCalculatingNewAmount: false,
  isPulsingNetworkInfo: false,
  nonTransferrableInfoOpened: false,
  updatingTokensReceiver: false,
  isFeeModalOpened: false,
  leaveModalCallback: null
});

let gasWatcher: GasWatcher | undefined = new GasWatcher(state.flowData);
let sendTxAbortSignal: AbortController = new AbortController();
let approveActionAbortSignal: AbortController = new AbortController();

const nonTransferrableInfo = 'nonTransferrableInfo';

const { t } = useI18n();
const { assets: walletAssets } = useTokens();
const { fromUSD } = useCurrency();
const { replace } = useRouter();
const toast = useErrorModal();
const {
  applyFlowData,
  reloadBrrrWithNewAmount,
  prepareBrrr,
  makeGetFlowParamsFromPrepareReturn,
  subscribeOnReceiveHash
} = useBrrrTransaction();
const { address } = useWagmi();

const isInsufficientGas = computed((): boolean => {
  if (state.isPendingModalOpened) {
    return false;
  }
  if (state.approveNetworkInfo === null) {
    return true;
  }

  if (state.approveNetworkInfo.networkInfo.baseAsset === undefined) {
    return true;
  }

  const flowAmount = isBaseAssetByNetwork(
    state.flowData.inputToken.address,
    state.flowData.inputToken.network
  )
    ? formatUnits(state.flowData.inputAmountInWei, state.flowData.inputToken.decimals)
    : '0';

  const amount = add(flowAmount, state.approveNetworkInfo.networkInfo.baseAssetAmount);

  return calcLocalInsufficientToken(
    state.approveNetworkInfo.networkInfo.baseAsset,
    amount,
    walletAssets.value
  );
});

const isInsufficientToken = computed((): boolean => {
  if (state.isPendingModalOpened) {
    return false;
  }

  return calcLocalInsufficientToken(
    state.prepare.token,
    state.prepare.tokenAmount,
    walletAssets.value
  );
});

const formattedGasPrice = computed((): string => {
  if (state.approveNetworkInfo === null) {
    return '';
  }

  if (state.approveNetworkInfo.networkInfo.baseAsset === undefined) {
    return '';
  }

  const baseToken = state.approveNetworkInfo.networkInfo.baseAsset;

  return formatTokenAmount(
    state.approveNetworkInfo.networkInfo.baseAssetAmount,
    Math.min(MAX_TOKEN_VISIBLE_DECIMALS, baseToken.decimals),
    baseToken.symbol,
    AmountMode.ValueOnly
  );
});

const formattedGasPriceEur = computed((): string => {
  if (state.approveNetworkInfo === null) {
    return '';
  }

  if (state.approveNetworkInfo.networkInfo.baseAsset === undefined) {
    return '';
  }

  const eurGasPrice = fromUSD(state.approveNetworkInfo.networkInfo.txGasPriceUSD);

  if (lessThan(eurGasPrice, 0.01)) {
    const formatted = formatAmount(0.01, 2, CurrencyCode.EUR, AmountMode.SymbolFirst);
    return `< ${formatted}`;
  } else {
    return formatAmount(eurGasPrice, 2, CurrencyCode.EUR, AmountMode.SymbolFirst);
  }
});

const isGasPriceCalculating = computed((): boolean => {
  if (state.approveNetworkInfo === null) {
    return true;
  }
  if (state.approveNetworkInfo.networkInfo.baseAsset === undefined) {
    return true;
  }

  return state.isCalculatingNetworkInfo;
});

const blockTime = computed((): number => {
  if (state.approveNetworkInfo === null) {
    return 0;
  }

  return state.approveNetworkInfo.networkInfo.blockTime;
});

const totalFeeEur = computed((): string => {
  const value = 0;

  if (isZero(value)) {
    return formatAmount(0, 2, CurrencyCode.EUR, AmountMode.SymbolFirst);
  }

  if (lessThan(value, 0.01)) {
    const formatted = formatAmount(0.01, 2, CurrencyCode.EUR, AmountMode.SymbolFirst);
    return `< ${formatted}`;
  } else {
    return formatAmount(value, 2, CurrencyCode.EUR, AmountMode.SymbolFirst);
  }
});

const isSubmitButtonDisabled = computed(
  (): boolean =>
    state.isExecuting ||
    isInsufficientToken.value ||
    state.isDataReloading ||
    state.updatingTokensReceiver
);

const formattedError = computed((): string | undefined => {
  if (state.isBadPriceDetected) {
    return t('transactionFlow.titles.badPrice');
  }
  if (state.isSwapInsufficientLiquidity) {
    return t('forms.swapInsufficientLiquidity');
  }
  if (isInsufficientToken.value) {
    return t('forms.insufficientBalance');
  }
  return undefined;
});

const brrrToken = computed(() => getBrrrToken(state.flowData.inputToken.network));

const exchangeRateText = computed((): string => {
  return formatTokensRate(
    divide(props.prepare.totalSent, props.prepare.tokenAmount),
    6,
    props.prepare.token.symbol,
    brrrToken.value.symbol
  );
});

const totalAmountView = computed((): string => {
  return formatTokenAmount(
    state.prepare.tokenAmount,
    Math.min(MAX_TOKEN_VISIBLE_DECIMALS, state.prepare.token.decimals),
    state.prepare.token.symbol,
    AmountMode.ValueOnly
  );
});

const totalAmountText = computed((): string => {
  return formatTokenAmount(
    state.prepare.totalSent,
    brrrToken.value.decimals,
    brrrToken.value.symbol,
    AmountMode.ValueOnly
  );
});

const totalAmountTokenImageUrl = computed((): string | undefined => {
  return state.prepare.token.iconURL;
});

const mainDetails = computed((): UiListPairGroup[] => [
  {
    items: [
      {
        label: t('transactionFlow.titles.wallet'),
        coloredCircleIcon: 'wallet',
        value: address.value
      },
      {
        label: t('network'),
        value: getNetwork(state.flowData.inputToken.network)?.displayedName ?? '',
        image: getNetwork(state.flowData.inputToken.network)?.iconURL ?? ''
      },
      {
        label: t('transactionFlow.titles.amount'),
        value: totalAmountView.value,
        image: totalAmountTokenImageUrl.value,
        error: isInsufficientToken.value
      },
      {
        label: t('purchasing'),
        value: totalAmountText.value,
        image: brrrToken.value.iconURL,
        error: isInsufficientToken.value,
        blink: state.isCalculatingNewAmount || state.isPulsingNetworkInfo
      }
    ]
  }
]);

const additionalDetails = computed((): UiListPairGroup[] => [
  {
    items: [
      {
        label: t('rate'),
        value: exchangeRateText.value,
        blink: state.isCalculatingNewAmount || state.isPulsingNetworkInfo
      },
      {
        id: nonTransferrableInfo,
        label: t('status'),
        value: t('nonTransferrable'),
        clickable: true
      }
    ]
  }
]);

const feeModalDetails = computed((): UiListPairGroup[] => {
  return [
    {
      items: [
        {
          label: t('forms.fee'),
          value: '0'
        },
        {
          label: t('forms.networkFee'),
          value: '0'
        }
      ]
    }
  ];
});

const isLeaveModalOpened = computed((): boolean => !!state.leaveModalCallback);

const modalType = computed((): ApproveOrPermitModalType => {
  if (state.flowData.flow === AllowanceFlow.MakeApprove && state.flowData.allowanceAmount === 0n) {
    return ApproveOrPermitModalType.Reset;
  }
  if (
    state.flowData.flow === AllowanceFlow.MakeApprove &&
    state.flowData.allowanceAmount === getMaxPermit2Allowance(state.flowData.inputToken)
  ) {
    return ApproveOrPermitModalType.Universal;
  }
  if (state.flowData.flow === AllowanceFlow.MakeApprove) {
    return ApproveOrPermitModalType.Approve;
  }
  return ApproveOrPermitModalType.Permit;
});

function subscribeGasWatcher(gw: GasWatcher) {
  gw.addEventListener('reloadingNetworkInfo', onReloadingNetworkInfo);
  gw.addEventListener('networkInfo', onNewNetworkInfo);
  gw.addEventListener('error', onWatchGasError);
  gw.addEventListener('updateTick', updateTick);
}

function onWatchGasError(e: WatchEvent<'error'>) {
  captureSentryException(e.options.error);
}

function onReloadingNetworkInfo() {
  state.isCalculatingNetworkInfo = true;
}

function updateTick(e: WatchEvent<'updateTick'>) {
  if (e.options.total - e.options.now === 2) {
    state.isPulsingNetworkInfo = true;
  }
  if (e.options.total === e.options.now) {
    reloadFlowDataWithNewAmount();
  }
}

function onNewNetworkInfo(e: WatchEvent<'networkInfo'>) {
  addSentryBreadcrumb({
    level: 'info',
    message: 'received new network info',
    data: e.options
  });

  if (e.options.estimation === undefined && state.approveNetworkInfo !== null) {
    state.approveNetworkInfo = {
      estimation: state.approveNetworkInfo.estimation,
      networkInfo: {
        ...state.approveNetworkInfo.networkInfo,
        blockTime: e.options.networkInfo.blockTime,
        network: e.options.networkInfo.network
      }
    } as NetworkInfoEvent;
  } else {
    state.approveNetworkInfo = e.options;
  }
  state.isPulsingNetworkInfo = false;
  state.isCalculatingNetworkInfo = false;
}

async function onPressSentTxButton(useStateApproveNetworkInfo = true) {
  switch (state.flowData.flow) {
    case AllowanceFlow.MakePermit:
    case AllowanceFlow.MakePermit2:
    case AllowanceFlow.MakeApprove:
      state.isApproveOrPermitModalOpened = true;
      return;
  }

  if (state.isExecuting) {
    return;
  }

  const disp = subscribeOnReceiveHash((hash) => {
    addSentryBreadcrumb({
      level: 'warning',
      message: 'received hash from wallet',
      data: {
        hash: hash
      }
    });

    state.txHash = hash;
    state.pendingStatus = TransactionPendingModalStatus.Pending;
  });

  try {
    gasWatcher?.destroy();
    gasWatcher = undefined;

    sendTxAbortSignal = new AbortController();
    state.isExecuting = true;

    state.isPendingModalOpened = true;
    state.pendingStatus = TransactionPendingModalStatus.Confirm;

    let estimation: EstimateContractGasCompoundReturn | undefined = undefined;

    if (useStateApproveNetworkInfo) {
      if (state.approveNetworkInfo !== null && state.approveNetworkInfo.estimation !== undefined) {
        estimation = state.approveNetworkInfo.estimation;
      }
    }

    const result = await internalApplyFlowData(
      state.flowData,
      estimation,
      sendTxAbortSignal.signal
    );
    if (result === 'end') {
      emit('done', state.txHash);
    } else {
      state.pendingStatus = TransactionPendingModalStatus.Error;
    }
  } catch (e) {
    useErrorModal().auto(e);
  } finally {
    disp();
    state.isExecuting = false;
  }
}

async function reloadFlowDataWithNewAmount(): Promise<void> {
  if (state.isCalculatingNewAmount) {
    return;
  }

  state.isCalculatingNewAmount = true;
  try {
    addSentryBreadcrumb({
      level: 'info',
      message: 'reloading prepare data and flow data'
    });

    const prepare = await prepareBrrr(state.prepare.token, state.prepare.tokenAmount);

    const params = await makeGetFlowParamsFromPrepareReturn(prepare, (preResult) => {
      const newTokensReceiver = address.value;
      assert(
        newTokensReceiver !== undefined,
        'Tokens receiver is undefined. Unable to make get flow params from prepare return'
      );
      preResult.tokensReceiver = newTokensReceiver;
      return preResult;
    });
    const flowData = await reloadBrrrWithNewAmount(params, state.flowData, (hash) => {
      state.txApproveHash = hash;
    });

    state.flowData = flowData;
    state.prepare = prepare;

    addSentryBreadcrumb({
      level: 'info',
      message: 'reload end. generate new gas watcher',
      data: {
        flow: flowData.flow,
        prepare: prepare
      }
    });
    gasWatcher?.destroy();
    gasWatcher = new GasWatcher(flowData, state.approveNetworkInfo?.networkInfo.blockTime);
    subscribeGasWatcher(gasWatcher);
    gasWatcher.start();
  } catch (error) {
    if (
      walkError(
        error,
        (e) => e instanceof ExpectedError && e.getCode() === 'swapInsufficientLiquidity'
      )
    ) {
      addSentryBreadcrumb({
        level: 'warning',
        message: 'swap Insufficient Liquidity',
        data: {
          error: error
        }
      });
      captureSentryException(error);
      state.isSwapInsufficientLiquidity = true;
      return;
    }
    if (walkError(error, (e) => e instanceof BadSwapPriceError)) {
      addSentryBreadcrumb({
        level: 'warning',
        message: 'bad swap price',
        data: {
          error: error
        }
      });
      captureSentryException(error);
      state.isBadPriceDetected = true;
      useErrorModal().auto(t('toasts.badPrices'));
      return;
    }
  } finally {
    state.isCalculatingNewAmount = false;
  }
}

async function onApproveAction() {
  if (state.isExecuting) {
    return;
  }
  if (state.isCalculatingNetworkInfo) {
    return;
  }

  gasWatcher?.destroy();
  gasWatcher = undefined;

  const flowData = state.flowData;

  let estimation: EstimateContractGasCompoundReturn | undefined = undefined;

  if (state.approveNetworkInfo !== null && state.approveNetworkInfo.estimation !== undefined) {
    estimation = state.approveNetworkInfo.estimation;
  }

  approveActionAbortSignal.abort();
  approveActionAbortSignal = new AbortController();

  const result = await internalApplyFlowData(flowData, estimation, approveActionAbortSignal.signal);
  switch (result) {
    case 'readyTx':
      state.isApproveOrPermitModalOpened = false;
      onPressSentTxButton(false);
      break;
    case 'error':
      state.isApproveOrPermitModalOpened = false;
      gasWatcher = new GasWatcher(state.flowData, state.approveNetworkInfo?.networkInfo.blockTime);
      subscribeGasWatcher(gasWatcher);
      gasWatcher.start();
      break;
  }
}

async function internalApplyFlowData(
  flowData: GetBrrrFlowReturn,
  estimation: EstimateContractGasCompoundReturn | undefined,
  signal: AbortSignal
): Promise<'end' | 'needAction' | 'readyTx' | 'error'> {
  try {
    state.isAllowanceMismatchError = false;
    state.isFailToCheckAllowance = false;
    state.isExpiredApproveOrPermit = false;
    state.isSwapInsufficientLiquidity = false;

    const result = await applyFlowData(flowData, estimation, signal);
    if (result === true) {
      addSentryBreadcrumb({
        level: 'info',
        message: 'apply flow data success, end is here'
      });
      return 'end';
    }

    addSentryBreadcrumb({
      level: 'info',
      message: 'apply flow data success, result is',
      data: {
        flow: result.flow
      }
    });

    state.flowData = result;

    if (isNeedApproveOrPermit(result)) {
      state.isApproveOrPermitModalOpened = true;
      state.approveNetworkInfo = null;
      state.isCalculatingNetworkInfo = true;
      state.isPulsingNetworkInfo = false;

      if (isNeedTransaction(result)) {
        gasWatcher?.destroy();
        gasWatcher = new GasWatcher(result);
        subscribeGasWatcher(gasWatcher);
        gasWatcher.start();
      }
      state.isExecuting = false;
      return 'needAction';
    }

    state.isExecuting = false;
    return 'readyTx';
  } catch (error) {
    if (error === 'aborted') {
      //user close modal, that trigger signal, resolved as "aborted"
      toast.auto(new ExpectedError<ClientEECode>('executeAbortedByUser'));
      return 'error';
    }

    if (error instanceof ExpectedError && (error.getCode() as ClientEECode) === 'brrrUnavailable') {
      toast.auto(error);
      replace({ name: RouteNames.Brrr });
      return 'error';
    }

    if (
      walkError(
        error,
        (e) =>
          e instanceof ExpectedError &&
          (e.getCode() === 'brrrBackendCheckExpired' ||
            e.getCode() === 'brrrWithPermitPermitExpired')
      )
    ) {
      addSentryBreadcrumb({
        level: 'warning',
        message: 'permit (maybe v2, maybe trusted) expired error',
        data: {
          error: error
        }
      });

      await reloadFlowDataWithNewAmount();
      state.isExpiredApproveOrPermit = true;
      return 'error';
    }

    if (walkError(error, (e) => e instanceof AllowanceMismatchError)) {
      addSentryBreadcrumb({
        level: 'warning',
        message: 'allowance mismatch error',
        data: {
          error: error
        }
      });
      captureSentryException(error);

      await reloadFlowDataWithNewAmount();
      state.isAllowanceMismatchError = true;
      return 'error';
    }
    if (
      walkError(
        error,
        (e) =>
          e instanceof HHError &&
          e.message === 'Failed to check current allowance after successful approve'
      )
    ) {
      addSentryBreadcrumb({
        level: 'warning',
        message: 'fail to check allowance after successful approve',
        data: {
          error: error
        }
      });
      captureSentryException(error);

      await reloadFlowDataWithNewAmount();
      state.isFailToCheckAllowance = true;
      return 'error';
    }
    if (error instanceof ExpectedError) {
      toast.auto(error);
      return 'error';
    }

    toast.auto(
      new ExpectedError<ClientEECode>('swapDataFetchFailed', {
        cause: error,
        sentryHandle: true
      })
    );
    return 'error';
  } finally {
    state.isExecuting = false;
  }
}

function onDeclineApproveOrPermit(): void {
  addSentryBreadcrumb({
    level: 'info',
    message: 'user close modal manually'
  });
  approveActionAbortSignal.abort();
  gasWatcher?.destroy();
  gasWatcher = new GasWatcher(state.flowData, state.approveNetworkInfo?.networkInfo.blockTime);
  subscribeGasWatcher(gasWatcher);
  gasWatcher.start();

  state.isApproveOrPermitModalOpened = false;
  state.isPulsingNetworkInfo = false;
  state.isCalculatingNetworkInfo = false;
}

function handleListItemClicked(value: UiListPairItem): void {
  if (value.id === undefined) {
    return;
  }

  switch (value.id) {
    case nonTransferrableInfo:
      state.nonTransferrableInfoOpened = true;
      break;
  }
}

function onConfirm(): void {
  state.leaveModalCallback?.(true);
  state.leaveModalCallback = null;
}

function onCancel(): void {
  state.leaveModalCallback?.(false);
  state.leaveModalCallback = null;
}

onMounted(() => {
  switch (state.flowData.flow) {
    case AllowanceFlow.MakeApprove:
    case AllowanceFlow.MakePermit2:
    case AllowanceFlow.MakePermit:
      addSentryBreadcrumb({
        level: 'debug',
        message: 'start review with approve or permit flow',
        data: { flowData: state.flowData }
      });
      state.isApproveOrPermitModalOpened = true;
  }
});

onBeforeUnmount(() => {
  addSentryBreadcrumb({
    level: 'debug',
    message: 'unmount component, destroy gas watch'
  });
  gasWatcher?.destroy();
});

onBeforeRouteLeave((_to, _from, next) => {
  if (state.isExecuting) {
    next(true);
    return;
  }

  if (state.leaveModalCallback) {
    state.leaveModalCallback(true);
  }

  state.leaveModalCallback = next;
});

subscribeGasWatcher(gasWatcher);
gasWatcher.start();
</script>

<template>
  <section class="transaction-review-brrr">
    <TransactionReview
      :lottie="topUpLottieData"
      :title="t('transactionFlow.titles.confirmPurchase')"
      :details="mainDetails"
      :additional-details="additionalDetails"
      :error="formattedError"
      :button-text="t('transactionFlow.actions.brrr')"
      :disabled="isSubmitButtonDisabled"
      :loading="state.isExecuting"
      @submit="onPressSentTxButton"
      @click:item="handleListItemClicked"
    >
      <FeeInfo
        :value="totalFeeEur"
        :time="blockTime"
        :fee-blinking="state.isPulsingNetworkInfo || state.isCalculatingNewAmount"
        :time-blinking="state.isPulsingNetworkInfo"
        :is-tip-visible="!!feeModalDetails.length"
        @click:tip="state.isFeeModalOpened = true"
      />
    </TransactionReview>
    <ApproveOrPermitModal
      :model-value="state.isApproveOrPermitModalOpened"
      :is-need-transaction="isNeedTransaction(state.flowData)"
      :formatted-gas-price="formattedGasPrice"
      :formatted-gas-price-eur="formattedGasPriceEur"
      :calculating-gas-price="isGasPriceCalculating"
      :insufficient-gas="isInsufficientGas"
      :token="state.flowData.inputToken"
      :block-time="blockTime"
      :token-amount="state.prepare.tokenAmount"
      :executing="state.isExecuting"
      :has-expired-prefix="state.isExpiredApproveOrPermit"
      :modal-type="modalType"
      :is-user-modified-allowance="state.isAllowanceMismatchError"
      :is-pulsing="state.isPulsingNetworkInfo"
      :approve-hash="state.txApproveHash"
      @approve="onApproveAction"
      @decline="onDeclineApproveOrPermit"
    />
    <FeeModal
      :model-value="
        state.isFeeModalOpened &&
        !state.isApproveOrPermitModalOpened &&
        !state.isPendingModalOpened &&
        !isLeaveModalOpened
      "
      :title="t('newTxs.details.fees.swapFee')"
      :details="feeModalDetails"
      @update:model-value="state.isFeeModalOpened = false"
    />
    <TransactionPendingModal
      :model-value="state.isPendingModalOpened && !isLeaveModalOpened"
      :status="state.pendingStatus"
      :block-time="blockTime"
      @update:model-value="state.isPendingModalOpened = false"
    />
    <ConfirmationModal
      :model-value="!!isLeaveModalOpened"
      @update:model-value="onCancel"
      @confirm="onConfirm"
    >
      {{ t('ifYouCancelThisSwap') }}
    </ConfirmationModal>
    <TipModal
      :model-value="
        state.nonTransferrableInfoOpened &&
        !state.isApproveOrPermitModalOpened &&
        !state.isPendingModalOpened &&
        !isLeaveModalOpened
      "
      :title="t('whatDoesItMean')"
      @update:model-value="state.nonTransferrableInfoOpened = false"
    >
      {{ t('nonTransferrableDescription') }}
    </TipModal>
  </section>
</template>

<style scoped>
.transaction-review-brrr {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
