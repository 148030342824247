import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized,
  type RouteLocationRaw
} from 'vue-router';

import { useSettings } from '@/composables/useSettings';
import { logger } from '@/logs/datadog';
import { addSentryBreadcrumb } from '@/logs/sentry';
import type { ServerSettings } from '@/references/axios/auth/types';
import BrrrBuySolanaView from '@/views/BrrrBuySolanaView.vue';
import BrrrBuyView from '@/views/BrrrBuyView.vue';
import BrrrInfoView from '@/views/BrrrInfoView.vue';
import BrrrTermsView from '@/views/BrrrTermsView.vue';
import HomeRootView from '@/views/HomeRootView.vue';
import HomeView from '@/views/HomeView.vue';
import KYCView from '@/views/KYCView.vue';
import RootView from '@/views/RootView.vue';
import SettingsView from '@/views/SettingsView.vue';
import WalletsTestView from '@/views/WalletsTestView.vue';

import HomeInfoBrrr from '@/components/HomeInfoBrrr.vue';

export enum RouteNames {
  Root = 'root',
  Home = 'home',
  Activity = 'activity',
  Settings = 'settings',
  KYC = 'kyc',
  Brrr = 'brrr',
  BrrrInfo = 'brrr-info',
  BrrrTermsAndConditions = 'brrr-terms-and-conditions',
  BrrrBuyToken = 'brrr-buy-token',
  BrrrBuySolanaToken = 'brrr-buy-solana-token',
  Error404 = '404',
  WalletsTest = 'wallets'
}

type ServerSettingsKey = keyof ServerSettings;

function requireServerSettings<T extends ServerSettingsKey>(
  name: T,
  redirectLocation?: RouteLocationRaw
) {
  const { limits } = useSettings();
  return (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (!limits.value[name]) {
      addSentryBreadcrumb({
        level: 'debug',
        message: `Prevented from accessing the route as feature flag is disabled`,
        data: {
          route: to.fullPath,
          flag: name
        }
      });
      next(redirectLocation ?? { name: RouteNames.Home });
      return;
    }

    next();
  };
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: RootView,
      children: [
        /* Home */
        {
          path: '',
          component: HomeRootView,
          children: [
            {
              path: '',
              name: RouteNames.Home,
              component: HomeView,
              children: [
                {
                  name: RouteNames.Brrr,
                  path: '/brrr',
                  component: HomeInfoBrrr
                }
              ]
            },
            {
              path: 'settings',
              name: RouteNames.Settings,
              component: SettingsView
            }
          ]
        },
        {
          path: 'kyc',
          name: RouteNames.KYC,
          component: KYCView,
          props: true
        },

        /* BRRR */
        {
          name: RouteNames.BrrrInfo,
          path: '/brrr/info',
          component: BrrrInfoView,
          beforeEnter: [requireServerSettings('isBrrrEnabled')]
        },
        {
          name: RouteNames.BrrrTermsAndConditions,
          path: '/brrr/terms-and-conditions',
          component: BrrrTermsView,
          beforeEnter: [requireServerSettings('isBrrrEnabled')]
        },
        {
          name: RouteNames.BrrrBuyToken,
          path: '/brrr/buy',
          component: BrrrBuyView,
          beforeEnter: [requireServerSettings('isBrrrEnabled')]
        },
        {
          name: RouteNames.BrrrBuySolanaToken,
          path: '/brrr/solana-buy',
          component: BrrrBuySolanaView,
          beforeEnter: [requireServerSettings('isBrrrEnabled')]
        },

        {
          name: RouteNames.WalletsTest,
          path: '/wallets',
          component: WalletsTestView
        }
      ]
    },

    /* 404. */
    {
      name: RouteNames.Error404,
      path: '/:pathMatch(.*)*',
      component: () => import('@/views/Error404View.vue')
    }
  ]
});

router.afterEach((to, _, failure) => {
  if (!failure) {
    logger.info(`navigation changed to ${to.fullPath}`, {
      routeName: to.name
    });
  }
});

export default router;
