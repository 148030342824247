import { type Address, isAddress, type PublicClient } from 'viem';

import { assert } from '@/helpers/assert';

import { erc4337Abi, erc4337EntryPointAbi } from '../abi/erc4337';

export const getEIP4337Nonce = async (
  publicClient: PublicClient,
  address: Address
): Promise<bigint> => {
  const entryPoint = await publicClient.readContract({
    address,
    abi: erc4337Abi,
    functionName: 'entryPoint'
  });

  assert(
    isAddress(entryPoint),
    new Error(
      `Entrypoint ${entryPoint} is not a valid address. Sender ${address} is not an EIP-4337-compatible wallet`
    )
  );

  return await publicClient.readContract({
    address: entryPoint,
    abi: erc4337EntryPointAbi,
    functionName: 'getNonce',
    args: [address, 0n]
  });
};
