import { type Address } from 'viem';

import { useAssetsAPIService } from '@/composables/services/useAssetsAPIService';
import { sameAddress } from '@/helpers/addresses';
import type { GetTokenPricesInput, PricesReturn } from '@/references/axios/assets/types';
import type { Network } from '@/references/network';

const getTokenPrice = async (address: Address, network: Network): Promise<string> => {
  const assetsAPIService = useAssetsAPIService();
  const response = await assetsAPIService.getTokenPrices([{ address: address, network: network }]);

  return (
    response.find((t) => sameAddress(t.address, address) && t.network === network)?.price ?? '0'
  );
};

const getTokenPrices = async (items: GetTokenPricesInput): Promise<PricesReturn> => {
  const assetsAPIService = useAssetsAPIService();
  return await assetsAPIService.getTokenPrices(items);
};

export const useTokenPrice = () => ({
  getTokenPrice,
  getTokenPrices
});
