<script setup lang="ts">
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';

import { useIntervalFn } from '@vueuse/core';

import topUpLottieData from '@/assets/lottie/top-up.json';
import { useBrrrAPIService } from '@/composables/services/useBrrrAPIService';
import { useBrrrTransaction } from '@/composables/useBrrrTransaction';
import { useErrorModal } from '@/composables/useErrorModal';
import { useSolana } from '@/composables/useSolana';
import { isZero, lessThan } from '@/helpers/bigmath';
import { formatAmount, formatTokenAmount, formatTokensRate } from '@/helpers/formatters';
import { addSentryBreadcrumb, captureSentryException } from '@/logs/sentry';
import { AmountMode } from '@/references/axios/transactions/types';
import { MAX_TOKEN_VISIBLE_DECIMALS, SECOND } from '@/references/constants';
import { CurrencyCode } from '@/references/currency';

import ConfirmationModal from '@/components/ConfirmationModal.vue';
import FeeInfo from '@/components/FeeInfo.vue';
import FeeModal from '@/components/FeeModal.vue';
import type { PrepareBrrrSolanaReturn } from '@/components/PrepareFormBrrrSolana.types';
import TipModal from '@/components/TipModal.vue';
import { TransactionPendingModalStatus } from '@/components/TransactionPendingModal.types';
import TransactionPendingModal from '@/components/TransactionPendingModal.vue';
import TransactionReview from '@/components/TransactionReview.vue';
import type { UiListPairGroup, UiListPairItem } from '@/components/ui/UiListPair.types';

interface Props {
  prepare: PrepareBrrrSolanaReturn;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'done', txHash: string): void;
}>();

const state = reactive<{
  prepare: PrepareBrrrSolanaReturn;
  txHash: string;
  pendingStatus: TransactionPendingModalStatus;
  isSoonUpdate: boolean;
  isPendingModalOpened: boolean;
  isLoadingBlockTime: boolean;
  isExecuting: boolean;
  isDataReloading: boolean;
  nonTransferrableInfoOpened: boolean;
  isFeeModalOpened: boolean;
  leaveModalCallback: ((isValid?: boolean) => void) | null;
  secondsCounter: number;
  blockTime: number;
}>({
  prepare: props.prepare,
  txHash: '',
  pendingStatus: TransactionPendingModalStatus.Confirm,
  isSoonUpdate: false,
  isPendingModalOpened: false,
  isLoadingBlockTime: false,
  isExecuting: false,
  isDataReloading: false,
  nonTransferrableInfoOpened: false,
  isFeeModalOpened: false,
  leaveModalCallback: null,
  secondsCounter: 0,
  blockTime: 0
});

const nonTransferrableInfo = 'nonTransferrableInfo';

const { t } = useI18n();
const { sendUSDC, getBrrrToken, address, getUSDCToken, getBlockTime } = useSolana();
const { getBrrrConversionHelper } = useBrrrTransaction();

const token = getUSDCToken();

const blockTime = computed((): number => {
  return state.blockTime;
});

const totalFeeEur = computed((): string => {
  const value = 0;

  if (isZero(value)) {
    return formatAmount(0, 2, CurrencyCode.EUR, AmountMode.SymbolFirst);
  }

  if (lessThan(value, 0.01)) {
    const formatted = formatAmount(0.01, 2, CurrencyCode.EUR, AmountMode.SymbolFirst);
    return `< ${formatted}`;
  } else {
    return formatAmount(value, 2, CurrencyCode.EUR, AmountMode.SymbolFirst);
  }
});

const isSubmitButtonDisabled = computed((): boolean => state.isExecuting || state.isDataReloading);

const brrrToken = computed(() => getBrrrToken());

const exchangeRateText = computed((): string => {
  return formatTokensRate(state.prepare.convertRate, 6, token.symbol, brrrToken.value.symbol);
});

const totalAmountView = computed((): string => {
  return formatTokenAmount(
    state.prepare.tokenAmount,
    Math.min(MAX_TOKEN_VISIBLE_DECIMALS, token.decimals),
    token.symbol,
    AmountMode.ValueOnly
  );
});

const totalAmountText = computed((): string => {
  return formatTokenAmount(
    state.prepare.brrrAmount,
    brrrToken.value.decimals,
    brrrToken.value.symbol,
    AmountMode.ValueOnly
  );
});

const totalAmountTokenImageUrl = computed((): string | undefined => {
  return token.iconURL;
});

const mainDetails = computed((): UiListPairGroup[] => [
  {
    items: [
      {
        label: t('transactionFlow.titles.wallet'),
        coloredCircleIcon: 'wallet',
        value: address.value
      },
      {
        label: t('network'),
        value: 'solana key',
        image: ''
      },
      {
        label: t('transactionFlow.titles.amount'),
        value: totalAmountView.value,
        image: totalAmountTokenImageUrl.value
      },
      {
        label: t('purchasing'),
        value: totalAmountText.value,
        image: brrrToken.value.iconURL,
        blink: state.isSoonUpdate || state.isDataReloading
      }
    ]
  }
]);

const additionalDetails = computed((): UiListPairGroup[] => [
  {
    items: [
      {
        label: t('rate'),
        value: exchangeRateText.value,
        blink: state.isSoonUpdate || state.isDataReloading
      },
      {
        id: nonTransferrableInfo,
        label: t('status'),
        value: t('nonTransferrable'),
        clickable: true
      }
    ]
  }
]);

const feeModalDetails = computed((): UiListPairGroup[] => {
  return [
    {
      items: [
        {
          label: t('forms.fee'),
          value: '0'
        },
        {
          label: t('forms.networkFee'),
          value: '0'
        }
      ]
    }
  ];
});

const isLeaveModalOpened = computed((): boolean => !!state.leaveModalCallback);

async function onPressSentTxButton() {
  if (state.isExecuting) {
    return;
  }

  try {
    state.isExecuting = true;
    state.pendingStatus = TransactionPendingModalStatus.Confirm;
    state.isPendingModalOpened = true;

    const akaHash = await sendUSDC(state.prepare.tokenAmount);
    useBrrrAPIService()
      .registerSolanaPurchase({
        address: address.value,
        amountBRRR: state.prepare.brrrAmount,
        amountUSDC: state.prepare.tokenAmount,
        transactionSignature: akaHash,
        usdcToken: token,
        brrrToken: state.prepare.brrrToken
      })
      .catch((e) => {
        addSentryBreadcrumb({
          level: 'error',
          message: 'fail to notify backend about sended tx',
          data: {
            dataForSend: {
              address: address.value,
              amountBRRR: state.prepare.brrrAmount,
              amountUSDC: state.prepare.tokenAmount,
              transactionSignature: akaHash,
              usdcToken: token,
              brrrToken: state.prepare.brrrToken
            },
            error: e
          }
        });
        captureSentryException(e);
      });
    emit('done', akaHash);
  } catch (e) {
    state.pendingStatus = TransactionPendingModalStatus.Error;
    useErrorModal().auto(e);
  } finally {
    state.isExecuting = false;
  }
}

async function reloadBlockTime(): Promise<void> {
  if (state.isLoadingBlockTime) {
    return;
  }

  try {
    state.isLoadingBlockTime = true;
    state.blockTime = await getBlockTime();
  } catch (e) {
    addSentryBreadcrumb({
      level: 'error',
      message: 'fail to load solana block time'
    });
    captureSentryException(e);
  } finally {
    state.isLoadingBlockTime = false;
  }
}

async function reloadFlowDataWithNewAmount(): Promise<void> {
  if (state.isDataReloading) {
    return;
  }

  state.isDataReloading = true;
  try {
    addSentryBreadcrumb({
      level: 'info',
      message: 'reloading...'
    });

    const calcResult = await getBrrrConversionHelper().convertSolanaTokenToBRRR(
      token,
      state.prepare.tokenAmount
    );

    state.prepare = {
      brrrToken: calcResult.brrrToken,
      brrrAmount: calcResult.brrrAmount,
      tokenAmount: calcResult.inputTokenAmount,
      convertRate: calcResult.rate
    };
    addSentryBreadcrumb({
      level: 'info',
      message: 'reload end.',
      data: {
        prepare: calcResult
      }
    });
  } catch (error) {
    addSentryBreadcrumb({
      level: 'warning',
      message: 'fail to reload buy brrr solana data',
      data: {
        error: error
      }
    });
    captureSentryException(error);
  } finally {
    state.isDataReloading = false;
  }
}

function handleListItemClicked(value: UiListPairItem): void {
  if (value.id === undefined) {
    return;
  }

  switch (value.id) {
    case nonTransferrableInfo:
      state.nonTransferrableInfoOpened = true;
      break;
  }
}

function onConfirm(): void {
  state.leaveModalCallback?.(true);
  state.leaveModalCallback = null;
}

function onCancel(): void {
  state.leaveModalCallback?.(false);
  state.leaveModalCallback = null;
}

onBeforeRouteLeave((_to, _from, next) => {
  if (state.isExecuting) {
    next(true);
    return;
  }

  if (state.leaveModalCallback) {
    state.leaveModalCallback(true);
  }

  state.leaveModalCallback = next;
});

useIntervalFn(
  () => {
    if (state.isDataReloading) {
      return;
    }
    if (state.isExecuting) {
      return;
    }

    switch (state.secondsCounter) {
      case 13:
        state.secondsCounter += 1;
        state.isSoonUpdate = true;
        return;
      case 15:
        Promise.allSettled([reloadBlockTime(), reloadFlowDataWithNewAmount()]).finally(() => {
          state.secondsCounter = 0;
          state.isSoonUpdate = false;
        });
        return;
      default:
        state.secondsCounter += 1;
        return;
    }
  },
  SECOND,
  { immediate: true }
);
</script>

<template>
  <section class="transaction-review-brrr">
    <TransactionReview
      :lottie="topUpLottieData"
      :title="t('transactionFlow.titles.confirmPurchase')"
      :details="mainDetails"
      :additional-details="additionalDetails"
      :button-text="t('transactionFlow.actions.brrr')"
      :disabled="isSubmitButtonDisabled"
      :loading="state.isExecuting"
      @submit="onPressSentTxButton"
      @click:item="handleListItemClicked"
    >
      <FeeInfo
        :value="totalFeeEur"
        :time="blockTime"
        :time-blinking="state.isSoonUpdate || state.isLoadingBlockTime"
        :is-tip-visible="!!feeModalDetails.length"
        @click:tip="state.isFeeModalOpened = true"
      />
    </TransactionReview>
    <FeeModal
      :model-value="state.isFeeModalOpened && !state.isPendingModalOpened && !isLeaveModalOpened"
      :title="t('newTxs.details.fees.swapFee')"
      :details="feeModalDetails"
      @update:model-value="state.isFeeModalOpened = false"
    />
    <TransactionPendingModal
      :model-value="state.isPendingModalOpened && !isLeaveModalOpened"
      :status="state.pendingStatus"
      :block-time="blockTime"
      @update:model-value="state.isPendingModalOpened = false"
    />
    <ConfirmationModal
      :model-value="!!isLeaveModalOpened"
      @update:model-value="onCancel"
      @confirm="onConfirm"
    >
      {{ t('ifYouCancelThisSwap') }}
    </ConfirmationModal>
    <TipModal
      :model-value="
        state.nonTransferrableInfoOpened && !state.isPendingModalOpened && !isLeaveModalOpened
      "
      :title="t('whatDoesItMean')"
      @update:model-value="state.nonTransferrableInfoOpened = false"
    >
      {{ t('nonTransferrableDescription') }}
    </TipModal>
  </section>
</template>

<style scoped>
.transaction-review-brrr {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
