import type { Address, PublicClient } from 'viem';

import { assert } from '@/helpers/assert';
import { deriveChainId } from '@/references/onchain/adapters';

import { ambireQuickAccManagerABI } from '../abi/ambireQuickAccManager';

const quickAccManagerContracts: Record<number, Address> = {
  1: '0xfF3f6D14DF43c112aB98834Ee1F82083E07c26BF',
  25: '0xfF3f6D14DF43c112aB98834Ee1F82083E07c26BF',
  56: '0xfF3f6D14DF43c112aB98834Ee1F82083E07c26BF',
  100: '0xfF3f6D14DF43c112aB98834Ee1F82083E07c26BF',
  137: '0xfF3f6D14DF43c112aB98834Ee1F82083E07c26BF',
  250: '0xfF3f6D14DF43c112aB98834Ee1F82083E07c26BF',
  397: '0xfF3f6D14DF43c112aB98834Ee1F82083E07c26BF',
  1088: '0xfF3f6D14DF43c112aB98834Ee1F82083E07c26BF',
  8453: '0xfF3f6D14DF43c112aB98834Ee1F82083E07c26BF',
  43114: '0xfF3f6D14DF43c112aB98834Ee1F82083E07c26BF',
  42161: '0xfF3f6D14DF43c112aB98834Ee1F82083E07c26BF',
  1284: '0xfF3f6D14DF43c112aB98834Ee1F82083E07c26BF'
} as const;

export const getAmbireNonce = async (
  publicClient: PublicClient,
  address: Address
): Promise<bigint> => {
  const chainId = await deriveChainId(publicClient);

  const contractAddress = quickAccManagerContracts[chainId];
  assert(
    contractAddress !== undefined,
    new MissingReferenceError('Ambire QuickAccManager', chainId)
  );

  return await publicClient.readContract({
    address: contractAddress,
    abi: ambireQuickAccManagerABI,
    functionName: 'nonces',
    args: [address]
  });
};

export class MissingReferenceError extends Error {
  constructor(name: string, chainId: number) {
    super(`No "${name}" reference is defined for chainId ${chainId}`);
  }
}
