<script setup lang="ts">
import { computed, onBeforeUnmount, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import axios from 'axios';

import { useBrrr } from '@/composables/useBrrr';
import { useBrrrTransaction } from '@/composables/useBrrrTransaction';
import { useCurrency } from '@/composables/useCurrency';
import { type ClientEECode, useErrorModal } from '@/composables/useErrorModal';
import { useSolana } from '@/composables/useSolana';
import { greaterThan, isZero, lessThan, multiply, roundDown } from '@/helpers/bigmath';
import { formatAmount, formatTokenAmount } from '@/helpers/formatters';
import { roundToPrecision } from '@/helpers/utils';
import { addSentryBreadcrumb, captureSentryException } from '@/logs/sentry';
import { AmountMode } from '@/references/axios/transactions/types';
import {
  FORM_SET_AMOUNT_DEBOUNCE,
  MAX_TOKEN_VISIBLE_DECIMALS,
  SECOND
} from '@/references/constants';
import { CurrencyCode } from '@/references/currency';
import { ExpectedError } from '@/references/ExpectedError';
import { Network } from '@/references/network';
import { settings } from '@/references/settings/globals';

import type { PrepareFormAmountOption } from '@/components/PrepareForm.types';
import PrepareForm from '@/components/PrepareForm.vue';
import type { PrepareBrrrSolanaReturn } from '@/components/PrepareFormBrrrSolana.types';

const { t } = useI18n();
const toast = useErrorModal();
const { available, minBrrrAmount } = useBrrr();
const { getBrrrConversionHelper } = useBrrrTransaction();
const { fromUSD } = useCurrency();
const { usdcBalance, getUSDCToken, getBrrrToken } = useSolana();

interface Props {
  amount: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:amount', value: string): void;
  (event: 'update:convertRate', value: string): void;
  (event: 'clear'): void;
  (event: 'submit', value: PrepareBrrrSolanaReturn): void;
}>();

const token = getUSDCToken();

const state = reactive<{
  isLoading: boolean;
  isSoonReloadSwapData: boolean;
  outputData: PrepareBrrrSolanaReturn | null;
  reloadCounter: number;
  error: string;
}>({
  isLoading: false,
  isSoonReloadSwapData: false,
  outputData: null,
  reloadCounter: 0,
  error: ''
});

const prepareFormComponent = ref<typeof PrepareForm>();

let debounceAmountTimeout: ReturnType<typeof setTimeout> | undefined = undefined;
let reloadSwapDataInterval: ReturnType<typeof setTimeout> | undefined = undefined;
let calculateSecondAmountAbortController = new AbortController();

const minBrrrAmountText = computed((): string => {
  return formatAmount(minBrrrAmount.value, 2, CurrencyCode.EUR, AmountMode.CodeLast);
});

const maxBrrrAmountText = computed((): string => {
  return formatTokenAmount(
    available.value,
    brrrToken.value.decimals,
    brrrToken.value.symbol,
    AmountMode.CodeLast
  );
});

const maxTokenAmountValue = computed((): string => {
  return usdcBalance.value;
});

const maxAmountToken = computed((): string => {
  return roundToPrecision(maxTokenAmountValue.value, Math.min(token.decimals, 6));
});

const isUsingMax = computed((): boolean => {
  return props.amount === maxAmountToken.value;
});

const tokenAmountInEUR = computed((): string => {
  if (isZero(props.amount) || props.amount === '') {
    return '0';
  }

  return roundDown(fromUSD(multiply(props.amount, '1')), 2);
});

const brrrToken = computed(() => {
  return getBrrrToken();
});

const secondAmountText = computed((): string => {
  if (props.amount === '' || state.outputData === null) {
    return formatTokenAmount(
      0,
      Math.min(MAX_TOKEN_VISIBLE_DECIMALS, brrrToken.value.decimals),
      brrrToken.value.symbol,
      AmountMode.CodeLast
    );
  }

  return formatTokenAmount(
    state.outputData.brrrAmount,
    Math.min(MAX_TOKEN_VISIBLE_DECIMALS, brrrToken.value.decimals),
    brrrToken.value.symbol,
    AmountMode.CodeLast
  );
});

const isLoadingToAmount = computed((): boolean => {
  if (isZero(props.amount)) {
    return false;
  }

  if (state.isSoonReloadSwapData) {
    return true;
  }

  return props.amount !== '' && state.outputData === null;
});

const maxAmount = computed((): string => {
  if (isZero(maxTokenAmountValue.value)) {
    return '0';
  }

  return maxAmountToken.value;
});

const maxAmountText = computed((): string => {
  return formatTokenAmount(
    maxAmount.value,
    Math.min(MAX_TOKEN_VISIBLE_DECIMALS, token.decimals),
    token.symbol,
    AmountMode.CodeLast
  );
});

const options = computed((): PrepareFormAmountOption[] => {
  return [
    {
      label: isUsingMax.value ? t('forms.usingMax') : t('forms.useMax'),
      value: maxAmount.value,
      disabled: isUsingMax.value || isZero(maxAmount.value),
      handler() {
        if (isZero(maxTokenAmountValue.value)) {
          return;
        }

        emit('update:amount', maxAmount.value);
      }
    }
  ];
});

const computedError = computed((): string => {
  if (props.amount.includes('.')) {
    if (!settings.isLimitsDisabled && lessThan(tokenAmountInEUR.value, minBrrrAmount.value)) {
      return t('forms.minimumPurchase', {
        min: minBrrrAmountText.value
      });
    }
  }

  if (props.amount === '' || isZero(props.amount)) {
    return '';
  }

  if (greaterThan(props.amount, maxAmountToken.value)) {
    return t('forms.insufficientBalance');
  }

  if (state.outputData && greaterThan(state.outputData.brrrAmount, available.value)) {
    return t('forms.maximumPurchase', { max: maxBrrrAmountText.value });
  }

  return '';
});

const finalError = computed((): string => {
  return computedError.value || state.error;
});

async function loadBuyData(tokenAmount: string, signal: AbortSignal): Promise<void> {
  try {
    addSentryBreadcrumb({
      level: 'info',
      message: 'loading otc data'
    });
    const result = await getBrrrConversionHelper().convertSolanaTokenToBRRR(
      token,
      tokenAmount,
      signal
    );
    state.outputData = {
      brrrToken: result.brrrToken,
      brrrAmount: result.brrrAmount,
      tokenAmount: result.inputTokenAmount,
      convertRate: result.rate
    };
  } catch (error) {
    if (error === 'aborted') {
      return;
    }

    addSentryBreadcrumb({
      level: 'error',
      message: 'fail to get swap data',
      data: {
        error: error,
        forData: {
          token,
          tokenAmount
        }
      }
    });
    captureSentryException(error);
  }
}

function setAmount(amount: string): void {
  state.error = '';

  clearTimeout(debounceAmountTimeout);

  emit('update:amount', amount);
  state.outputData = null;

  if (isZero(amount) || amount === '') {
    return;
  }

  const tokenAmount = amount;

  calculateSecondAmountAbortController.abort();
  calculateSecondAmountAbortController = new AbortController();

  debounceAmountTimeout = setTimeout(() => {
    state.error = validate();
    loadBuyData(tokenAmount, calculateSecondAmountAbortController.signal);
  }, FORM_SET_AMOUNT_DEBOUNCE);

  clearInterval(reloadSwapDataInterval);

  reloadSwapDataInterval = setInterval(() => {
    switch (state.reloadCounter) {
      case 13: {
        state.isSoonReloadSwapData = true;
        state.reloadCounter = state.reloadCounter + 1;
        return;
      }
      case 15: {
        state.reloadCounter = 0;
        state.error = validate();
        loadBuyData(tokenAmount, calculateSecondAmountAbortController.signal).finally(() => {
          state.isSoonReloadSwapData = false;
        });
        break;
      }
      default: {
        state.reloadCounter = state.reloadCounter + 1;
      }
    }
  }, SECOND);
}

function validate(validEmptyString = true): string {
  if (props.amount === '' || isZero(props.amount) || props.amount.length === 0) {
    if (!validEmptyString) {
      return t('forms.minimumPurchase', {
        min: formatAmount(minBrrrAmount.value, 2, CurrencyCode.EUR, AmountMode.SymbolFirst)
      });
    }
  }

  if (state.outputData == null) {
    if (validEmptyString) {
      return '';
    }

    return t('forms.minimumPurchase', {
      min: formatAmount(minBrrrAmount.value, 2, CurrencyCode.EUR, AmountMode.SymbolFirst)
    });
  }

  if (!settings.isLimitsDisabled && lessThan(tokenAmountInEUR.value, minBrrrAmount.value)) {
    return t('forms.minimumPurchase', {
      min: formatAmount(minBrrrAmount.value, 2, CurrencyCode.EUR, AmountMode.SymbolFirst)
    });
  }

  return '';
}

async function continueClick(): Promise<void> {
  if (!props.amount) {
    prepareFormComponent.value?.shake();
    prepareFormComponent.value?.focus();
    return;
  }

  state.error = validate(false);

  if (state.error || isZero(props.amount)) {
    prepareFormComponent.value?.shake();
    prepareFormComponent.value?.focus();
    return;
  }

  if (state.isLoading) {
    return;
  }

  state.isLoading = true;
  let outputData = state.outputData;

  //maybe prev. calculating falled or stale
  if (outputData === null) {
    calculateSecondAmountAbortController.abort();
    clearTimeout(debounceAmountTimeout);
    clearTimeout(reloadSwapDataInterval);
    try {
      const amount = props.amount;
      const result = await getBrrrConversionHelper().convertSolanaTokenToBRRR(
        token,
        amount,
        new AbortController().signal
      );
      outputData = {
        brrrToken: result.brrrToken,
        brrrAmount: result.brrrAmount,
        tokenAmount: result.inputTokenAmount,
        convertRate: result.rate
      };

      state.outputData = outputData;
      state.error = validate();
      if (state.error !== undefined) {
        state.isLoading = false;
        prepareFormComponent.value?.shake();
        prepareFormComponent.value?.focus();
        return;
      }
    } catch (error) {
      state.isLoading = false;

      if (axios.isCancel(error)) {
        return;
      }

      if (error instanceof ExpectedError) {
        toast.auto(error);
        return;
      }

      toast.auto(
        new ExpectedError<ClientEECode>('otcDataFetchFailed', {
          cause: error,
          sentryHandle: true
        })
      );
      return;
    }
  }

  emit('submit', outputData);
  state.isLoading = false;
}

onBeforeUnmount(() => {
  clearTimeout(debounceAmountTimeout);
  clearTimeout(reloadSwapDataInterval);
});
</script>

<template>
  <section class="prepare-form-solana-brrr">
    <PrepareForm
      ref="prepareFormComponent"
      :token="{ ...token, network: Network.unknown }"
      :secondary-token="brrrToken"
      input-mode="TOKEN"
      :amount="props.amount"
      :options="options"
      :available-text="undefined"
      :max-amount-text="maxAmountText"
      :second-amount-text="secondAmountText"
      :is-second-amount-pulsing="isLoadingToAmount"
      :error="finalError"
      :is-submit-button-disabled="isLoadingToAmount"
      :is-submit-button-loading="state.isLoading"
      is-toggle-button-readonly
      @update:amount="setAmount"
      @submit="continueClick"
    />
  </section>
</template>

<style scoped>
.prepare-form-solana-brrr {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.prepare-form-solana-brrr__toggle-icon {
  margin: 0 4px 0 0;
}
</style>
