import type { Address } from 'viem';

export interface PermitParams extends EIP712Object {
  owner: string;
  spender: string;
  value: string;
  nonce: number;
  deadline: number;
}

export declare type EIP712ObjectValue = string | number | boolean | EIP712Object;

export interface EIP712Object {
  [key: string]: EIP712ObjectValue;
}

export interface EIP712TypedData {
  types: EIP712Types & { EIP712Domain: EIP712Parameter[] };
  domain: EIP712Object;
  message: EIP712Object;
  primaryType: string;
}

export interface EIP712Types {
  [key: string]: EIP712Parameter[];
}

export interface EIP712Parameter {
  name: string;
  type: string;
}

export interface MessageTypes {
  [additionalProperties: string]: MessageTypeProperty[];
  EIP712Domain: MessageTypeProperty[];
}

export interface MessageTypeProperty {
  name: string;
  type: string;
}

export interface PermitTypedDataParamsModel {
  chainId: number;
  tokenName: string;
  tokenAddress: Address;
  params: EIP712Object;
  isDomainWithoutVersion?: boolean;
  isSaltInsteadOfChainId?: boolean;
  version?: string;
  permitModelFields?: EIP712Parameter[];
}

export const eip2612PermitModelFields: EIP712Parameter[] = [
  { name: 'owner', type: 'address' },
  { name: 'spender', type: 'address' },
  { name: 'value', type: 'uint256' },
  { name: 'nonce', type: 'uint256' },
  { name: 'deadline', type: 'uint256' }
];
