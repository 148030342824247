import { reactive } from 'vue';

import BigNumber from 'bignumber.js';

import { useRatesAPIService } from '@/composables/services/useRatesAPIService';
import { addSentryBreadcrumb } from '@/logs/sentry';
import type { Rates } from '@/references/axios/rates/types';
import { CurrencyCode } from '@/references/currency';

const defaultRates: Rates = {
  [CurrencyCode.EUR]: 1
};

const state = reactive<{
  latest: Rates;
  inited: boolean;
}>({
  latest: defaultRates,
  inited: false
});

const ratesService = useRatesAPIService();

async function init(): Promise<boolean> {
  try {
    const rate = await ratesService.getLastEURRates();
    state.latest = rate;
    state.inited = true;
    return true;
  } catch (error) {
    addSentryBreadcrumb({
      level: 'warning',
      message: 'Failed to load currency rates',
      data: {
        error
      }
    });
    return false;
  }
}

function update() {
  return init();
}

function fromUSD(amount: BigNumber.Value): string {
  return new BigNumber(amount).multipliedBy(state.latest[CurrencyCode.EUR]).toFixed();
}

function toUSD(amount: BigNumber.Value): string {
  return new BigNumber(amount).dividedBy(state.latest[CurrencyCode.EUR]).toFixed();
}

export const useCurrency = () => {
  return {
    fromUSD,
    toUSD,
    update,
    init
  };
};
