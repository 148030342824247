import { formatUnits, InsufficientFundsError, parseUnits } from 'viem';

import { useBrrrTransaction } from '@/composables/useBrrrTransaction';
import { sameAddress } from '@/helpers/addresses';
import { getClonedGasEstimationByNetwork } from '@/helpers/gas';
import { addSentryBreadcrumb, captureSentryException } from '@/logs/sentry';
import { HHError } from '@/references/HHError';
import { isBaseAssetByNetwork } from '@/references/network';
import type { EstimateContractGasCompoundReturn } from '@/references/onchain/gas';
import type { Token, TokenWithPriceAndBalance } from '@/references/tokens';

export const sameToken = (tokenA: Token, tokenB: Token): boolean => {
  return sameAddress(tokenA.address, tokenB.address) && tokenA.network === tokenB.network;
};

type MaxUsableEstimation = {
  max: string;
  estimation: EstimateContractGasCompoundReturn | undefined;
};

export const getMaxUsableBalance = async (
  token: TokenWithPriceAndBalance
): Promise<MaxUsableEstimation> => {
  if (!isBaseAssetByNetwork(token.address, token.network)) {
    addSentryBreadcrumb({
      level: 'debug',
      message: `Provided token is not a base asset, use full balance (${token.balance})`,
      data: {
        token: token
      }
    });
    return { max: token.balance, estimation: undefined };
  }

  const tokenBalanceInWei = parseUnits(token.balance, token.decimals);
  let clearEstimation: EstimateContractGasCompoundReturn;
  let estimation: EstimateContractGasCompoundReturn;
  try {
    clearEstimation = await useBrrrTransaction().getBaseAssetEstimation(token);
    [estimation] = await getClonedGasEstimationByNetwork(token.network, clearEstimation, true);
  } catch (error) {
    if (error instanceof InsufficientFundsError) {
      addSentryBreadcrumb({
        level: 'info',
        message: `Failed to estimate card order tx: received Insufficient Funds error. Display as '0 available'`,
        data: {
          token: token,
          error
        }
      });
      return { max: '0', estimation: undefined };
    }

    captureSentryException(
      new HHError('Failed to compute max value', {
        cause: new HHError('Failed to base asset estimation', { cause: error })
      })
    );
    return { max: '0', estimation: undefined };
  }

  addSentryBreadcrumb({
    level: 'debug',
    message: `Estimated base asset tx: ${estimation.totalFee} wei is needed, got ${tokenBalanceInWei}`,
    data: {
      estimation: estimation
    }
  });

  if (estimation.totalFee > tokenBalanceInWei) {
    addSentryBreadcrumb({
      level: 'debug',
      message: `Token balance is less than required, truncate to 0`
    });
    return { max: '0', estimation: clearEstimation };
  }

  const max = formatUnits(tokenBalanceInWei - estimation.totalFee - 1n, token.decimals);
  addSentryBreadcrumb({
    level: 'debug',
    message: `Available balance (max value): ${max} (${
      tokenBalanceInWei - estimation.totalFee
    } wei)`
  });
  return { max: max, estimation: clearEstimation };
};
