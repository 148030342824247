import type { OnCalldataPayload } from '@/references/onchain/contractCallToPayload';

const sendEvent = (operationId: string, data: Record<string, unknown>) => {
  //TODO sand audit
  return;
};

const sendTxCallAuditEvent = async (operationId: string, data: OnCalldataPayload) => {
  //TODO sand audit
  return;
};

export const useAudit = () => {
  return { sendEvent, sendTxCallAuditEvent };
};
