import { currentClientType } from '@/helpers/utils';
import { HHAPIAssetsService } from '@/references/axios/assets/HHAPIAssetsService';
import { getEndpoint } from '@/references/endpoints';

let service: HHAPIAssetsService | undefined;
export const useAssetsAPIService = (): HHAPIAssetsService => {
  if (service === undefined) {
    service = new HHAPIAssetsService(
      getEndpoint('apiAssets'),
      getEndpoint('apiBrrr'),
      currentClientType()
    );
  }

  return service;
};
