import dayjs from 'dayjs';
import { type Address } from 'viem';

import { addSentryBreadcrumb, captureSentryException } from '@/logs/sentry';
import { createHHAxios } from '@/references/axios/axios';
import { HHAPIService } from '@/references/axios/base';
import type { ClientType } from '@/references/base';
import type { Network } from '@/references/network';
import type { PermitData } from '@/references/tokens';

import type {
  GetMultiChainWalletTokensResponse,
  GetPermitDataResponse,
  GetTokenPricesInput,
  GetTokenPricesRequest,
  PricesResponse,
  PricesReturn,
  TokenPriceResponse,
  WalletResponse,
  WalletToken
} from './types';

export class HHAPIAssetsService extends HHAPIService {
  protected pricesCache = new Map<string, { expiresAt: number; price: string }>();

  constructor(
    protected readonly publicBaseURL: string,
    protected readonly privateBaseURL: string,
    protected readonly clientType: ClientType,
    protected readonly cacheTTLSeconds = 5 * 60
  ) {
    super('assets.service');
  }

  public async getTokenPrices(tokensToCheck: GetTokenPricesInput): Promise<PricesReturn> {
    const now = dayjs().unix();

    const { hit, miss } = tokensToCheck.reduce(
      (acc, item) => {
        const cacheKey = this.getTokenPriceCacheKey(item.address, item.network);
        const cached = this.pricesCache.get(cacheKey);
        if (cached === undefined || cached.expiresAt < now) {
          acc.miss = acc.miss.concat({
            address: item.address,
            network: item.network
          });
          return acc;
        }

        acc.hit = acc.hit.concat({
          address: item.address,
          network: item.network,
          price: cached.price
        });

        return acc;
      },
      {
        hit: new Array<{ address: Address; network: Network; price: string }>(),
        miss: new Array<{ address: Address; network: Network }>()
      }
    );

    if (miss.length === 0) {
      return hit;
    }

    const instance = createHHAxios({
      baseURL: this.publicBaseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    const res = (
      await instance.request<PricesResponse, GetTokenPricesRequest>({
        method: 'POST',
        url: 'prices/tokens/with-stable',
        data: {
          tokens: miss
        }
      })
    ).data.payload.tokens;

    res.forEach((item) => {
      const cacheKey = this.getTokenPriceCacheKey(item.address, item.network);
      this.pricesCache.set(cacheKey, {
        expiresAt: now + this.cacheTTLSeconds,
        price: item.price
      });
    });

    return hit.concat(res);
  }

  public async getTokenPrice(
    tokenNetwork: Network,
    tokenAddress: Address,
    abortSignal?: AbortSignal
  ): Promise<TokenPriceResponse> {
    const instance = createHHAxios({
      baseURL: this.privateBaseURL,
      headers: this.getClientTypeHeaders(this.clientType),
      signal: abortSignal
    });

    return (
      await instance.request<
        TokenPriceResponse,
        {
          network: Network;
          address: Address;
        }
      >({
        method: 'POST',
        url: 'private/token/price',
        data: {
          network: tokenNetwork,
          address: tokenAddress
        },
        withCredentials: true
      })
    ).data.payload;
  }

  public async getMultiChainWalletTokens(
    address: Address
  ): Promise<GetMultiChainWalletTokensResponse> {
    const now = dayjs().unix();

    const instance = createHHAxios({
      baseURL: this.privateBaseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    const data = (
      await instance.request<WalletResponse, { address: Address }>({
        method: 'POST',
        url: '/v1/private/assets/multichain/balances',
        data: {
          address: address
        },
        withCredentials: true
      })
    ).data.payload;

    const tokens = data.tokens.map(
      (t) =>
        ({
          name: t.token.name,
          decimals: t.token.decimals,
          symbol: t.token.symbol,
          address: t.token.address,
          iconURL: t.token.logoUrl,
          network: t.token.network,
          hasPermit: t.token.hasPermit,
          permitType: t.token.permitType,
          permitVersion: t.token.permitVersion,
          priceUSD: t.price,
          balance: t.balance,
          groupId: t.groupId,
          priceChange: t.priceChange
        }) satisfies WalletToken
    );

    tokens.forEach((item) => {
      const cacheKey = this.getTokenPriceCacheKey(item.address, item.network);
      this.pricesCache.set(cacheKey, {
        expiresAt: now + this.cacheTTLSeconds,
        price: item.priceUSD
      });
    });

    return {
      tokens,
      totalBalance: data.totalBalance,
      totalPriceChange: data.totalPriceChange
    };
  }

  public async getPermitData(tokenAddress: Address, network: Network): Promise<PermitData> {
    try {
      const instance = createHHAxios({
        baseURL: this.publicBaseURL,
        headers: this.getClientTypeHeaders(this.clientType)
      });

      const respData = (
        await instance.request<GetPermitDataResponse>({
          method: 'GET',
          url: `/token/${network}/${tokenAddress}/permit`,
          withCredentials: true
        })
      ).data.payload;

      return {
        hasPermit: respData.hasPermit,
        permitType: respData.permitType,
        permitVersion: respData.permitVersion
      };
    } catch (err) {
      addSentryBreadcrumb({
        level: 'error',
        message: `failed get permit data from "/token/${network}/${tokenAddress}/permit"`,
        category: this.sentryCategoryPrefix,
        data: {
          error: err
        }
      });
      captureSentryException(err);
      return {
        hasPermit: false
      };
    }
  }

  private getTokenPriceCacheKey(address: Address, network: Network): string {
    return `${network}_${address}`;
  }

  // public async getTokenDetailedData(
  //   tokenNetwork: Network,
  //   tokenAddress: Address,
  //   address: Address,
  //   confirmationSignature: string
  // ): Promise<TokenDetailedDataResponse> {
  //   return (
  //     await this.client.post<HHAPISuccessfulResponse<TokenDetailedDataResponse>>(
  //       'private/token/data',
  //       {
  //         network: tokenNetwork,
  //         address: tokenAddress
  //       },
  //       { headers: this.getAuthHeaders(address, confirmationSignature) }
  //     )
  //   ).data.payload;
  // }
  //
  // public async getTokenChart(
  //   tokenNetwork: Network,
  //   tokenAddress: Address,
  //   type: TokenChartType,
  //   address: Address,
  //   confirmationSignature: string,
  //   abortSignal?: AbortSignal
  // ): Promise<TokenChartItem[]> {
  //   return (
  //     await this.client.post<HHAPISuccessfulResponse<TokenChartResponse>>(
  //       'private/token/data/chart',
  //       {
  //         type,
  //         network: tokenNetwork,
  //         address: tokenAddress
  //       },
  //       {
  //         headers: this.getAuthHeaders(address, confirmationSignature),
  //         signal: abortSignal
  //       }
  //     )
  //   ).data.payload.prices;
  // }
}
