<script setup lang="ts">
import UiImportant from '@/components/ui/UiImportant.vue';
import type { UiListPairGroup } from '@/components/ui/UiListPair.types';
import UiListPair from '@/components/ui/UiListPair.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

interface Props {
  modelValue: boolean;
  title: string;
  details: UiListPairGroup[];
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void;
}>();
</script>

<template>
  <UiModal
    class="fee-modal"
    :model-value="props.modelValue"
    @update:model-value="emit('update:modelValue', false)"
  >
    <UiImportant class="fee-modal__icon" mod="question" />
    <UiTitle class="fee-modal__title" :level="2" size="xl" align="center">
      {{ props.title }}
    </UiTitle>
    <UiListPair :details="props.details" />
  </UiModal>
</template>

<style scoped>
.fee-modal__icon {
  margin: 0 auto 24px;
}

.fee-modal__title {
  margin: 0 0 40px;
}
</style>
