<script setup lang="ts">
import { reactive, watch } from 'vue';

import { type MotionVariants } from '@vueuse/motion';

import { addSentryBreadcrumb } from '@/logs/sentry';
import { BrrrBuySolanaViewStep } from '@/views/BrrrBuySolanaView.types';

import type { PrepareBrrrSolanaReturn } from '@/components/PrepareFormBrrrSolana.types';
import PrepareFormBrrrSolana from '@/components/PrepareFormBrrrSolana.vue';
import TransactionReviewSolanaBrrr from '@/components/TransactionReviewSolanaBrrr.vue';

interface Props {
  step: BrrrBuySolanaViewStep.Value | BrrrBuySolanaViewStep.Review;
  motion: MotionVariants<never> | undefined;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'next'): void;
  (event: 'done', txHash: string): void;
}>();

const state = reactive<{
  amount: string;
  prepareObject: PrepareBrrrSolanaReturn | undefined;
}>({
  amount: '',
  prepareObject: undefined
});

async function onEnterValue(prepare: PrepareBrrrSolanaReturn): Promise<void> {
  addSentryBreadcrumb({
    level: 'debug',
    message: 'got prepare object',
    data: {
      prepare: prepare
    }
  });

  state.prepareObject = prepare;
  emit('next');
}

watch(
  () => props.step,
  () => {
    window.scrollTo(0, 0);
  }
);
</script>

<template>
  <PrepareFormBrrrSolana
    v-if="props.step === BrrrBuySolanaViewStep.Value"
    v-model:amount="state.amount"
    v-motion="props.motion"
    @submit="onEnterValue"
  />
  <TransactionReviewSolanaBrrr
    v-else-if="state.prepareObject !== undefined"
    v-motion="props.motion"
    :prepare="state.prepareObject"
    @done="emit('done', $event)"
  />
</template>
