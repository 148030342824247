<script setup lang="ts">
import { computed, onBeforeUnmount, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import checkmarkLottieData from '@/assets/lottie/checkmark.json';
import pendingLottieData from '@/assets/lottie/pending.json';
import unlockLottieData from '@/assets/lottie/unlock.json';
import { formatTokenAmount } from '@/helpers/formatters';
import { fade } from '@/helpers/motion';
import { AmountMode } from '@/references/axios/transactions/types';
import {
  MAX_TOKEN_VISIBLE_DECIMALS,
  SECOND,
  STILL_PENDING_STATUS_RESERVE_TIME
} from '@/references/constants';
import { getBaseAssetData } from '@/references/network';
import type { Token } from '@/references/tokens';

import { ApproveOrPermitModalType } from '@/components/ApproveOrPermitModal.types';
import FeeInfo from '@/components/FeeInfo.vue';
import FeeModal from '@/components/FeeModal.vue';
import TipModal from '@/components/TipModal.vue';
import TokenImage from '@/components/TokenImage.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiButtonIcon from '@/components/ui/UiButtonIcon.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import { type UiListPairGroup } from '@/components/ui/UiListPair.types';
import UiLottie from '@/components/ui/UiLottie.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

const TIME_TO_ACCEPT_TRANSACTION = 10 * SECOND;

interface Props {
  modelValue: boolean;
  executing: boolean;
  formattedGasPrice: string;
  formattedGasPriceEur: string;
  isNeedTransaction: boolean;
  insufficientGas: boolean;
  calculatingGasPrice: boolean;
  isPulsing: boolean;
  blockTime: number;
  modalType: ApproveOrPermitModalType;
  token?: Token;
  tokenAmount?: string;
  hasExpiredPrefix?: boolean;
  isUserModifiedAllowance: boolean;
  approveHash: string;
}

const props = withDefaults(defineProps<Props>(), {
  token: undefined,
  tokenAmount: undefined,
  hasExpiredPrefix: false
});

const emit = defineEmits<{
  (event: 'decline'): void;
  (event: 'approve'): void;
}>();

const state = reactive<{
  isLongPending: boolean;
  isTipModalOpened: boolean;
  isFeeModalOpened: boolean;
}>({
  isLongPending: false,
  isTipModalOpened: false,
  isFeeModalOpened: false
});

let longPendingTimeout: ReturnType<typeof setTimeout> | null = null;

const { t } = useI18n();

const tokenAmount = computed((): string => {
  if (!props.tokenAmount || !props.token) {
    return '';
  }

  return formatTokenAmount(
    props.tokenAmount,
    Math.min(MAX_TOKEN_VISIBLE_DECIMALS, props.token.decimals),
    props.token.symbol,
    AmountMode.CodeLast
  );
});

const isProcessingStateVisible = computed(
  (): boolean => props.executing && props.isNeedTransaction
);

const title = computed((): string => {
  if (isProcessingStateVisible.value) {
    return state.isLongPending ? t('stillPending') : t('approvalPending');
  }

  if (props.modalType === ApproveOrPermitModalType.Reset) {
    return t('transactionFlow.titles.resetPermission');
  }

  if (
    [ApproveOrPermitModalType.Universal, ApproveOrPermitModalType.Approve].includes(props.modalType)
  ) {
    return t('transactionFlow.titles.setPermission');
  }

  return t('transactionFlow.titles.approvalRequest');
});

const description = computed((): string => {
  if (isProcessingStateVisible.value) {
    return state.isLongPending
      ? t('approvalInTheWalletIsTakingLongerThanExpected')
      : t('yourTokenApprovalIsPending', { token: props.token?.symbol ?? '' });
  }

  if (props.isUserModifiedAllowance) {
    return t('transactionFlow.descriptions.modifiedAllowance', { symbol: props.token?.symbol });
  }

  let result = '';

  if (props.hasExpiredPrefix) {
    result = `${t('transactionFlow.descriptions.expirePrefix')} `;
  }

  switch (props.modalType) {
    case ApproveOrPermitModalType.Reset:
      result += t('transactionFlow.descriptions.resetPermission', {
        token: props.token?.symbol
      });
      break;
    case ApproveOrPermitModalType.Universal:
    case ApproveOrPermitModalType.Approve:
      result += t('transactionFlow.descriptions.setPermission', {
        token: props.token?.symbol
      });

      if (props.modalType === ApproveOrPermitModalType.Universal) {
        result += ` ${t('transactionFlow.descriptions.oneTimeAuthorizationTransaction')}`;
      }

      break;
    case ApproveOrPermitModalType.Permit:
      result += t('transactionFlow.descriptions.allowAccess', {
        token: props.token?.symbol
      });
      break;
  }

  return result;
});

const isButtonDisabled = computed((): boolean => {
  if (props.executing) {
    return true;
  }

  if (props.isNeedTransaction && !props.formattedGasPriceEur) {
    return true;
  }

  return props.calculatingGasPrice;
});

const feeModalDetails = computed((): UiListPairGroup[] => {
  if (!props.token) {
    return [];
  }

  const baseToken = getBaseAssetData(props.token.network);

  return [
    {
      items: [
        {
          label: t('newTxs.details.fees.networkFee'),
          value: props.formattedGasPrice || '0',
          token: baseToken
        }
      ]
    }
  ];
});

const tipDescription = computed((): string => {
  switch (props.modalType) {
    case ApproveOrPermitModalType.Reset:
    case ApproveOrPermitModalType.Universal:
    case ApproveOrPermitModalType.Approve:
      return t('transactionFlow.tips.setPermission');
    default:
      return '';
  }
});

function clear(): void {
  if (longPendingTimeout) {
    clearTimeout(longPendingTimeout);
  }
}

watch(
  () => props.executing,
  (value) => {
    clear();

    if (!value || !isProcessingStateVisible.value) {
      state.isLongPending = false;
      return;
    }

    const timeForLongPending =
      TIME_TO_ACCEPT_TRANSACTION + props.blockTime * SECOND + STILL_PENDING_STATUS_RESERVE_TIME;

    longPendingTimeout = setTimeout(() => {
      state.isLongPending = true;
    }, timeForLongPending);
  }
);

watch(
  () => props.approveHash,
  (value) => {
    clear();

    if (!value || !props.executing || !isProcessingStateVisible.value) {
      return;
    }

    const timeForLongPending = props.blockTime * SECOND + STILL_PENDING_STATUS_RESERVE_TIME;

    longPendingTimeout = setTimeout(() => {
      state.isLongPending = true;
    }, timeForLongPending);
  }
);

onBeforeUnmount(() => {
  clear();
});
</script>

<template>
  <UiModal
    class="approve-or-permit-modal"
    :model-value="props.modelValue && !state.isTipModalOpened && !state.isFeeModalOpened"
    @update:model-value="emit('decline')"
  >
    <div v-if="isProcessingStateVisible" v-motion="fade">
      <UiLottie
        v-if="state.isLongPending"
        class="approve-or-permit-modal__icon approve-or-permit-modal__icon_pending"
        :data="pendingLottieData"
        :size="40"
        loop
        autoplay
      />
      <UiSpinner v-else class="approve-or-permit-modal__icon" :size="40" />
      <UiTitle class="approve-or-permit-modal__title" :level="2" size="xl" align="center">
        {{ title }}
      </UiTitle>
      <UiText class="approve-or-permit-modal__description" size="md" muted tag="p" align="center">
        {{ description }}
      </UiText>
    </div>
    <div v-else v-motion="fade">
      <UiLottie
        class="approve-or-permit-modal__icon approve-or-permit-modal__icon_unlock"
        :data="unlockLottieData"
        :size="64"
        loop
        autoplay
      />
      <UiTitle class="approve-or-permit-modal__title" :level="2" size="xl" align="center">
        {{ title }}
      </UiTitle>
      <UiText class="approve-or-permit-modal__description" size="md" muted align="center" tag="p">
        {{ description }}
      </UiText>
      <ul class="approve-or-permit-modal__list">
        <li
          v-if="props.modalType === ApproveOrPermitModalType.Permit"
          class="approve-or-permit-modal__item"
        >
          <div class="approve-or-permit-modal__item-left">
            <UiLottie
              class="approve-or-permit-modal__item-icon"
              :data="checkmarkLottieData"
              :size="16"
              loop
              autoplay
            />
            <UiText size="sm" weight="medium">
              {{ t('approve') }}
            </UiText>
          </div>
          <div class="approve-or-permit-modal__item-right">
            <TokenImage
              class="approve-or-permit-modal__item-token"
              :token="props.token"
              size="sm"
            />
            <UiText class="approve-or-permit-modal__item-value" size="sm" weight="medium">
              {{ tokenAmount }}
            </UiText>
          </div>
        </li>
        <li v-else class="approve-or-permit-modal__item">
          <div class="approve-or-permit-modal__item-left">
            <UiIcon class="approve-or-permit-modal__item-icon" name="sign" :size="16" />
            <UiText size="sm" weight="medium">
              {{ t('permission') }}
            </UiText>
            <UiButtonIcon
              v-if="tipDescription"
              class="approve-or-permit-modal__item-tip"
              icon="tip"
              size="sm"
              :icon-size="16"
              mod="transparent-muted"
              @click="state.isTipModalOpened = true"
            />
          </div>
          <div class="approve-or-permit-modal__item-right">
            <TokenImage
              class="approve-or-permit-modal__item-token"
              :token="props.token"
              size="sm"
            />
            <UiText class="approve-or-permit-modal__item-value" size="sm" weight="medium">
              {{ props.token?.name }}
            </UiText>
          </div>
        </li>
        <li class="approve-or-permit-modal__item">
          <FeeInfo
            :value="isNeedTransaction ? props.formattedGasPriceEur : null"
            :time="isNeedTransaction ? props.blockTime : null"
            :blinking="isNeedTransaction ? props.isPulsing : false"
            :error="isNeedTransaction && props.insufficientGas"
            :is-tip-visible="!!feeModalDetails.length"
            @click:tip="state.isFeeModalOpened = true"
          />
        </li>
      </ul>
      <div class="approve-or-permit-modal__buttons">
        <UiButton class="approve-or-permit-modal__button" mod="secondary" @click="emit('decline')">
          {{ t('cancel') }}
        </UiButton>
        <UiButton
          class="approve-or-permit-modal__button"
          :disabled="isButtonDisabled"
          :loading="props.executing"
          @click="emit('approve')"
        >
          {{ t('confirm') }}
        </UiButton>
      </div>
    </div>
  </UiModal>

  <TipModal v-model="state.isTipModalOpened" :title="t('whatDoesItMean')" icon-mod="question">
    <slot name="tipDescription">{{ tipDescription }}</slot>
  </TipModal>

  <FeeModal
    v-model="state.isFeeModalOpened"
    :title="t('estimatedFee')"
    :details="feeModalDetails"
  />
</template>

<style scoped>
.approve-or-permit-modal__icon {
  margin: 0 auto 24px;
}

.approve-or-permit-modal__icon_unlock {
  margin: -12px auto 12px;
}

.approve-or-permit-modal__icon_pending :deep(path) {
  stroke: currentcolor;
}

.approve-or-permit-modal__icon_unlock :deep(path) {
  fill: currentcolor;
  stroke: currentcolor;
}

.approve-or-permit-modal__title {
  margin: 0 0 8px;
}

.approve-or-permit-modal__description:not(:last-child) {
  margin: 0 0 40px;
}

.approve-or-permit-modal__list {
  margin: 0 0 54px;
  padding: 0;
  list-style-type: none;
}

.approve-or-permit-modal__item {
  display: flex;
  align-items: center;
  width: 100%;
}

.approve-or-permit-modal__item:not(:first-child) {
  padding-top: 16px;
}

.approve-or-permit-modal__item:not(:last-child) {
  padding-bottom: 16px;
  border-bottom: 1px dashed var(--color-main-stroke);
}

.approve-or-permit-modal__item-left {
  display: flex;
  align-items: center;
  margin: 0 16px 0 0;
}

.approve-or-permit-modal__item-right {
  display: flex;
  align-items: center;
  min-width: 0;
  margin: 0 0 0 auto;
  text-align: right;
  overflow-wrap: break-word;
}

.approve-or-permit-modal__item-icon {
  margin: 0 8px 0 0;
}

.approve-or-permit-modal__item-token {
  margin: 0 4px 0 0;
}

.approve-or-permit-modal__item-value {
  max-width: calc(100% - 20px);
}

.approve-or-permit-modal__item-tip {
  margin: -4px 0;
}

.approve-or-permit-modal__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 413px) {
  .approve-or-permit-modal__buttons {
    flex-direction: column-reverse;
  }
}

.approve-or-permit-modal__button {
  width: calc(50% - 12px);
}

@media (max-width: 413px) {
  .approve-or-permit-modal__button {
    width: 100%;
  }
}

@media (max-width: 413px) {
  .approve-or-permit-modal__button:not(:first-child) {
    margin: 0 0 16px;
  }
}
</style>
