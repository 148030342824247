<script setup lang="ts">
import { computed, type PropType } from 'vue';

import arrowDownIcon from '@/assets/icons/arrow-down.svg';
import { useI18n } from '@/references/i18n';

import UiIcon from '@/components/ui/UiIcon.vue';
import UiText from '@/components/ui/UiText.vue';

const props = defineProps({
  opened: {
    type: Boolean as PropType<boolean>,
    default: false
  }
});

const { t } = useI18n();

const classList = computed(
  (): Record<string, boolean> => ({
    'ui-details-button_opened': props.opened
  })
);

const text = computed((): string => (props.opened ? t('lessDetails') : t('moreDetails')));
</script>

<template>
  <button class="ui-details-button" :class="classList" type="button">
    <span class="ui-details-button__body">
      <UiText class="ui-details-button__text" weight="medium" size="xs">
        <span class="ui-details-button__text-inner">
          {{ text }}
        </span>
      </UiText>
      <UiIcon class="ui-details-button__icon" :name="arrowDownIcon" :size="8" />
    </span>
  </button>
</template>

<style scoped>
.ui-details-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: 22px;
  margin: 0;
  color: var(--color-main-text-disabled);
  text-align: center;
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  appearance: none;
  user-select: none;
}

.ui-details-button::before {
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 100%;
  border-top: 1px dashed var(--color-main-stroke);
  content: '';
}

.ui-details-button__body {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 0 8px;
  background-color: var(--color-background);
}

.ui-details-button__text {
  max-width: calc(100% - 16px);
}

.ui-details-button__text-inner {
  display: block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ui-details-button__icon {
  flex-shrink: 0;
  margin: 0 4px;
  transition: transform var(--animation-medium) var(--animation-effect);
}

.ui-details-button_opened .ui-details-button__icon {
  transform: rotate(-180deg);
}
</style>
