import {
  BlockNotFoundError,
  CallExecutionError,
  type Hash,
  type PublicClient,
  TransactionNotFoundError,
  type TransactionReceipt
} from 'viem';

import type { ClientEECode } from '@/composables/useErrorModal';
import type { AwaitedNestedPromise } from '@/helpers/promises';
import { asyncSleep } from '@/helpers/promises';
import { urlJoin } from '@/helpers/url';
import { addSentryBreadcrumb } from '@/logs/sentry';
import { ExpectedError } from '@/references/ExpectedError';
import { HHError } from '@/references/HHError';
import type { Network } from '@/references/network';
import { getNetwork } from '@/references/network';
import type { WatchTxReceipt } from '@/references/onchain/hooks';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const callActionWithRetries = async <T extends (...args: any[]) => any, R = ReturnType<T>>(
  executor: T,
  maxRetries = 2,
  retryCount = 0,
  instantlyThrowPredicate?: (error: unknown, retryCount: number) => boolean,
  backoffFn?: (retryCount: number) => number
): Promise<AwaitedNestedPromise<R>> => {
  try {
    return await executor();
  } catch (error) {
    if (retryCount > maxRetries || instantlyThrowPredicate?.(error, retryCount)) {
      addSentryBreadcrumb({
        level: 'error',
        message: 'Executor failed. Cannot retry anymore',
        data: { error }
      });
      throw error;
    }

    addSentryBreadcrumb({
      level: 'warning',
      message: 'Executor failed. Schedule retry',
      data: { error }
    });

    if (backoffFn !== undefined) {
      const sleepFor = backoffFn(retryCount);
      addSentryBreadcrumb({
        level: 'debug',
        message: `Wait for ${sleepFor}ms before retrying`,
        data: { error }
      });
      await asyncSleep(sleepFor);
    }

    return callActionWithRetries(
      executor,
      maxRetries,
      retryCount + 1,
      instantlyThrowPredicate,
      backoffFn
    );
  }
};

let delayBeforeFalsePositiveReject = 20_000;
/**
 * Sets project-wide delay for viem rejection errors to wait in case of false positive
 * @param delayMillis - delay to wait before throwing captured scoped error
 */
export const setDelayBeforeFalsePositiveReject = (delayMillis: number): void => {
  if (delayMillis < 0) {
    addSentryBreadcrumb({
      level: 'warning',
      category: 'setDelayBeforeFalsePositiveReject',
      message: `Delay could not be lower than 0. Set is rejected, kept previous value of ${delayBeforeFalsePositiveReject}`,
      data: { received: delayMillis, current: delayBeforeFalsePositiveReject }
    });
    return;
  }

  delayBeforeFalsePositiveReject = delayMillis;
};

/**
 * A wrapper around viem's waitForTransactionReceipt utilizing optional external source of tx confirmation
 * @param publicClient - viem's public client instance
 * @param hash - tx hash
 * @param network - a network tx executes into
 * @param externalWatch - an external watch function that resolves if tx successfully made it into the block, otherwise rejects. Expected to never fail by timeout
 * @returns A promise that resolves if at least one source confirmed tx receipt, rejects using source precedence rules
 */
export const safeWatchTransactionReceipt = (
  publicClient: PublicClient,
  hash: Hash,
  network: Network,
  externalWatch?: WatchTxReceipt
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    let rejectTimeout: ReturnType<typeof setTimeout> | undefined;

    // set up external watch race handler
    externalWatch?.(network, hash)
      .then(() => {
        addSentryBreadcrumb({
          level: 'debug',
          message: 'Confirmed tx receipt by external watcher',
          data: { hash }
        });

        clearTimeout(rejectTimeout);
        resolve();
      })
      .catch((error) => {
        clearTimeout(rejectTimeout);
        reject(
          new TransactionRevertedError(
            {
              hash,
              network,
              source: 'external'
            },
            { cause: error }
          )
        );
      });

    // set up viem's native watch race handler
    callActionWithRetries(
      () =>
        publicClient.waitForTransactionReceipt({
          hash,
          onReplaced: (response) => {
            addSentryBreadcrumb({
              level: 'info',
              category: 'waitForTransactionReceipt.onReplaced',
              message: 'Transaction is replaced / cancelled',
              data: response
            });
            if (response.reason === 'cancelled') {
              throw new ExpectedError<ClientEECode>('userRejectTransaction', { payload: response });
            }
          }
        }),
      5,
      undefined,
      (error) => error instanceof ExpectedError,
      () => 1000
    )
      .then((receipt) => {
        if (receipt.status === 'success') {
          addSentryBreadcrumb({
            level: 'debug',
            message: 'Confirmed tx receipt by viem watcher',
            data: { hash }
          });
          resolve();
          return;
        }

        return clarifyRevertReason(publicClient, hash).then((cause) => {
          addSentryBreadcrumb({
            level: 'debug',
            message: 'Received tx receipt by viem watcher, yet status is "reverted"',
            data: { receipt }
          });

          if (externalWatch === undefined) {
            reject(
              new TransactionRevertedError(
                {
                  hash,
                  network,
                  receipt,
                  source: 'internal'
                },
                {
                  cause
                }
              )
            );
            return;
          }

          clearTimeout(rejectTimeout);
          setTimeout(() => {
            reject(
              new TransactionRevertedError(
                { hash, network, receipt, source: 'internal' },
                {
                  cause
                }
              )
            );
          }, delayBeforeFalsePositiveReject);
        });
      })
      .catch((error) => {
        if (error instanceof ExpectedError || error instanceof TransactionRevertedError) {
          reject(error);
          return;
        }

        const networkInfo = getNetwork(network);
        const wrappedError = new HHError(
          `Failed to get receipt for transaction with hash "${hash}"`,
          {
            payload: {
              hash,
              network,
              linkUrl:
                networkInfo !== undefined
                  ? urlJoin(networkInfo.explorerURL, '/tx/', hash)
                  : undefined
            },
            cause: error
          }
        );

        // don't trust these errors, wait for some time until possible external entry with trusted status appears
        if (error instanceof BlockNotFoundError || error instanceof TransactionNotFoundError) {
          if (externalWatch === undefined) {
            reject(wrappedError);
            return;
          }

          clearTimeout(rejectTimeout);
          rejectTimeout = setTimeout(() => {
            reject(wrappedError);
          }, delayBeforeFalsePositiveReject);
          return;
        }

        reject(wrappedError);
      });
  });
};

type WatcherType = 'internal' | 'external';

type TransactionRevertedErrorConstructorArgs = {
  hash: Hash;
  network: Network;
  source: WatcherType;
  receipt?: TransactionReceipt;
};
export class TransactionRevertedError extends HHError {
  readonly hash: Hash;
  readonly network: Network;
  readonly source: WatcherType;
  readonly receipt?: TransactionReceipt;

  override name = 'TransactionRevertedError';

  constructor(
    { hash, network, source, receipt }: TransactionRevertedErrorConstructorArgs,
    { cause, payload }: { cause?: unknown; payload?: Record<string, unknown> } = {}
  ) {
    const networkInfo = getNetwork(network);

    super(`Transaction with hash "${hash}" reverted (${source} watcher)`, {
      cause,
      payload: {
        ...payload,
        hash,
        network,
        source,
        receipt,
        linkUrl:
          networkInfo !== undefined ? urlJoin(networkInfo.explorerURL, '/tx/', hash) : undefined
      }
    });
    this.hash = hash;
    this.network = network;
    this.source = source;
    this.receipt = receipt;
  }
}

export async function clarifyRevertReason(publicClient: PublicClient, hash: Hash): Promise<Error> {
  try {
    const transaction = await publicClient.getTransaction({ hash });

    const { data } = await publicClient.call({
      account: transaction.from,
      to: transaction.to,
      gas: transaction.gas,
      nonce: transaction.nonce,
      value: transaction.value,
      data: transaction.input,
      accessList: transaction.accessList,
      blockNumber: transaction.blockNumber
    });

    if (data === undefined) {
      return new HHError('Reverted without a reason');
    }

    return new HHError(`Function reverted: "${data}"`, { payload: { data } });
  } catch (error) {
    if (error instanceof CallExecutionError) {
      return error;
    }

    return new HHError('Failed to get revert reason', { cause: error });
  }
}
