const imagesToPreload = new Set<string>();
const loadedImages = new Set<string>();
let idleCallbackHandle: number;

export function queueImagePreload(...imageURLs: string[]) {
  for (const url of imageURLs) {
    if (!loadedImages.has(url)) {
      imagesToPreload.add(url);
    }
  }

  if (imagesToPreload.size > 0) {
    requestCallback();
  }
}

function requestCallback() {
  cancelIdleCallback(idleCallbackHandle);
  idleCallbackHandle = requestIdleCallback((deadline) => {
    for (const url of imagesToPreload.values()) {
      if (deadline.timeRemaining() > 0) {
        preloadImage(url);
        continue;
      }

      break;
    }

    if (imagesToPreload.size > 0) {
      requestCallback();
    }
  });
}

function markImageLoaded(imageURL: string) {
  loadedImages.add(imageURL);
  imagesToPreload.delete(imageURL);
}

function preloadImage(imageURL: string) {
  const img = new Image();
  img.addEventListener(
    'error',
    () => {
      markImageLoaded(imageURL);
      img.remove();
    },
    { once: true }
  );
  img.addEventListener(
    'load',
    () => {
      markImageLoaded(imageURL);
      img.remove();
    },
    { once: true }
  );
  img.setAttribute('src', imageURL);
}
