<script setup lang="ts">
import { computed, onBeforeUnmount, reactive, watch } from 'vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import errorLottieData from '@/assets/lottie/error.json';
import pendingLottieData from '@/assets/lottie/pending.json';
import { fade } from '@/helpers/motion';
import { SECOND, STILL_PENDING_STATUS_RESERVE_TIME } from '@/references/constants';

import { TransactionPendingModalStatus } from '@/components/TransactionPendingModal.types';
import UiButton from '@/components/ui/UiButton.vue';
import UiDots from '@/components/ui/UiDots.vue';
import UiLottie from '@/components/ui/UiLottie.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

interface Props {
  modelValue: boolean;
  status: TransactionPendingModalStatus;
  blockTime: number;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void;
}>();

const state = reactive<{
  isStillPending: boolean;
}>({
  isStillPending: false
});

let stillPendingTimeout: ReturnType<typeof setTimeout> | null = null;

const { t } = useI18n();

const errorLottieElement = ref<typeof UiLottie>();

const walletImage = computed((): string | undefined => {
  return '@/assets/images/icons/other_wallets_dark.svg';
});

function clearStillPendingTimeout(): void {
  if (stillPendingTimeout) {
    clearTimeout(stillPendingTimeout);
    stillPendingTimeout = null;
  }
}

watch(
  () => props.status,
  (value) => {
    if (value === TransactionPendingModalStatus.Pending) {
      stillPendingTimeout = setTimeout(
        () => {
          stillPendingTimeout = null;
          state.isStillPending = true;
        },
        props.blockTime * SECOND + STILL_PENDING_STATUS_RESERVE_TIME
      );
      return;
    }

    state.isStillPending = false;
    clearStillPendingTimeout();

    if (value === TransactionPendingModalStatus.Error) {
      setTimeout(() => {
        errorLottieElement.value?.play();
      }, 250);
    }
  }
);

onBeforeUnmount(() => {
  clearStillPendingTimeout();
});
</script>

<template>
  <UiModal
    class="transaction-pending-modal"
    :model-value="props.modelValue"
    @update:model-value="emit('update:modelValue', false)"
  >
    <div v-if="props.status === TransactionPendingModalStatus.Confirm" v-motion="fade">
      <img
        :src="walletImage"
        class="transaction-pending-modal__image transaction-pending-modal__image_wallet"
        alt=""
      />
      <UiTitle class="transaction-pending-modal__title" :level="2" size="xl" align="center">
        {{ t('confirmInYourWallet') }}
      </UiTitle>
      <UiText class="transaction-pending-modal__description" tag="p" size="md" align="center" muted>
        {{ t('confirmTransactionInTheWallet') }}<UiDots />
      </UiText>
    </div>
    <template v-else-if="props.status === TransactionPendingModalStatus.Pending">
      <div v-if="state.isStillPending" v-motion="fade">
        <UiLottie
          class="transaction-pending-modal__image transaction-pending-modal__image_pending"
          :data="pendingLottieData"
          :size="40"
          autoplay
          loop
        />
        <UiTitle class="transaction-pending-modal__title" :level="2" size="xl" align="center">
          {{ t('stillPending') }}
        </UiTitle>
        <UiText
          class="transaction-pending-modal__description"
          tag="p"
          size="md"
          align="center"
          muted
        >
          {{ t('transactionInTheWalletIsTakingLongerThanExpected') }}
        </UiText>
      </div>
      <div v-else v-motion="fade">
        <UiSpinner class="transaction-pending-modal__image" :size="40" />
        <UiTitle class="transaction-pending-modal__title" :level="2" size="xl" align="center">
          {{ t('transactionPending') }}
        </UiTitle>
        <UiText
          class="transaction-pending-modal__description"
          tag="p"
          size="md"
          align="center"
          muted
        >
          {{ t('yourTransactionIsPending') }}
        </UiText>
      </div>
    </template>
    <div v-else v-motion="fade">
      <UiLottie
        :key="props.status"
        ref="errorLottieElement"
        class="transaction-pending-modal__image"
        :data="errorLottieData"
        :size="40"
      />
      <UiTitle class="transaction-pending-modal__title" :level="2" size="xl" align="center">
        {{ t('error') }}
      </UiTitle>
      <UiText class="transaction-pending-modal__description" tag="p" size="md" align="center" muted>
        <template v-if="props.status === TransactionPendingModalStatus.Error">
          {{ t('yourTransactionWasNotExecuted') }}
        </template>
        <slot v-else />
      </UiText>
      <UiButton mod="secondary" @click="emit('update:modelValue', false)">
        {{ t('done') }}
      </UiButton>
    </div>
  </UiModal>
</template>

<style scoped>
.transaction-pending-modal__image {
  margin: 0 auto 24px;
}

.transaction-pending-modal__image_wallet {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.transaction-pending-modal__image_pending:deep(path) {
  stroke: currentcolor;
}

.transaction-pending-modal__title:not(:last-child) {
  margin: 0 0 8px;
}

.transaction-pending-modal__description:not(:last-child) {
  margin: 0 0 40px;
}
</style>
