import { type Address, getAddress, type Hash, type Hex, hexToBigInt } from 'viem';

export type ParseBrrrPurchaseLogDataHexReturn = {
  token: Address;
  amountToken: bigint;
  amountBrrr: bigint;
  hash: Hash;
};

/**
 * 0x (2)
 * (0 * 64) + 2 000000000000000000000000912ce59144191c1204e64559fe8253a0e49e6548 (last 40 -- token address)
 * (1 * 64) + 2 000000000000000000000000000000000000000000000000002386f26fc10000 (all 64 -- amount token paid)
 * (2 * 64) + 2 0000000000000000000000000000000000000000000000000f0ceb010ae94000 (all 64 -- amount brrr minted)
 * (3 * 64) + 2 5344797da11b4256b59ab7bd476d240dc4fb890fe8e219fade6d59862beb7d41 (all 64 -- hash)
 */

const logBlockSize = 64;
const initialOffset = 2;
const addressOffset = 64 - 40;

const tokenFromStart = initialOffset + logBlockSize * 0 + addressOffset;
const tokenFromEnd = initialOffset + logBlockSize;

const amountFromStart = initialOffset + logBlockSize * 1;
const amountFromEnd = initialOffset + logBlockSize * 2;

const amountBrrrStart = initialOffset + logBlockSize * 2 + addressOffset;
const amountBrrrEnd = initialOffset + logBlockSize * 3;

const hashStart = initialOffset + logBlockSize * 3;
const hashEnd = initialOffset + logBlockSize * 4;

export function parseBrrrPurchaseLogDataHex(data: Hex): ParseBrrrPurchaseLogDataHexReturn {
  return {
    token: getAddress(`0x${data.slice(tokenFromStart, tokenFromEnd)}`),
    amountToken: hexToBigInt(`0x${data.slice(amountFromStart, amountFromEnd)}`, {
      signed: false,
      size: 256
    }),
    amountBrrr: hexToBigInt(`0x${data.slice(amountBrrrStart, amountBrrrEnd)}`, {
      signed: false,
      size: 256
    }),
    hash: `0x${data.slice(hashStart, hashEnd)}`
  };
}
