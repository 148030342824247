export const argentModuleABI = [
  {
    inputs: [
      { internalType: 'contract IModuleRegistry', name: '_registry', type: 'address' },
      { internalType: 'contract IGuardianStorage', name: '_guardianStorage', type: 'address' },
      { internalType: 'contract ITransferStorage', name: '_userWhitelist', type: 'address' },
      { internalType: 'contract IAuthoriser', name: '_authoriser', type: 'address' },
      { internalType: 'address', name: '_uniswapRouter', type: 'address' },
      { internalType: 'uint256', name: '_securityPeriod', type: 'uint256' },
      { internalType: 'uint256', name: '_securityWindow', type: 'uint256' },
      { internalType: 'uint256', name: '_recoveryPeriod', type: 'uint256' },
      { internalType: 'uint256', name: '_lockPeriod', type: 'uint256' }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: true, internalType: 'address', name: 'target', type: 'address' },
      { indexed: false, internalType: 'uint64', name: 'whitelistAfter', type: 'uint64' }
    ],
    name: 'AddedToWhitelist',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: true, internalType: 'address', name: 'guardian', type: 'address' }
    ],
    name: 'GuardianAdded',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: true, internalType: 'address', name: 'guardian', type: 'address' }
    ],
    name: 'GuardianAdditionCancelled',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: true, internalType: 'address', name: 'guardian', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'executeAfter', type: 'uint256' }
    ],
    name: 'GuardianAdditionRequested',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: true, internalType: 'address', name: 'guardian', type: 'address' }
    ],
    name: 'GuardianRevokationCancelled',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: true, internalType: 'address', name: 'guardian', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'executeAfter', type: 'uint256' }
    ],
    name: 'GuardianRevokationRequested',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: true, internalType: 'address', name: 'guardian', type: 'address' }
    ],
    name: 'GuardianRevoked',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: false, internalType: 'uint64', name: 'releaseAfter', type: 'uint64' }
    ],
    name: 'Locked',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'bytes32', name: 'name', type: 'bytes32' }],
    name: 'ModuleCreated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: true, internalType: 'address', name: '_newOwner', type: 'address' }
    ],
    name: 'OwnershipTransfered',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: true, internalType: 'address', name: '_recovery', type: 'address' }
    ],
    name: 'RecoveryCanceled',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: true, internalType: 'address', name: '_recovery', type: 'address' },
      { indexed: false, internalType: 'uint64', name: 'executeAfter', type: 'uint64' }
    ],
    name: 'RecoveryExecuted',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: true, internalType: 'address', name: '_recovery', type: 'address' }
    ],
    name: 'RecoveryFinalized',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: true, internalType: 'address', name: 'refundAddress', type: 'address' },
      { indexed: false, internalType: 'address', name: 'refundToken', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'refundAmount', type: 'uint256' }
    ],
    name: 'Refund',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: true, internalType: 'address', name: 'target', type: 'address' }
    ],
    name: 'RemovedFromWhitelist',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: false, internalType: 'address', name: 'sessionKey', type: 'address' }
    ],
    name: 'SessionCleared',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: false, internalType: 'address', name: 'sessionKey', type: 'address' },
      { indexed: false, internalType: 'uint64', name: 'expires', type: 'uint64' }
    ],
    name: 'SessionCreated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'wallet', type: 'address' },
      { indexed: true, internalType: 'bool', name: 'success', type: 'bool' },
      { indexed: false, internalType: 'bytes', name: 'returnData', type: 'bytes' },
      { indexed: false, internalType: 'bytes32', name: 'signedHash', type: 'bytes32' }
    ],
    name: 'TransactionExecuted',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'wallet', type: 'address' }],
    name: 'Unlocked',
    type: 'event'
  },
  { stateMutability: 'nonpayable', type: 'fallback' },
  {
    inputs: [],
    name: 'NAME',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'address', name: '_guardian', type: 'address' }
    ],
    name: 'addGuardian',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'address', name: '_module', type: 'address' }
    ],
    name: 'addModule',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'address', name: '_target', type: 'address' }
    ],
    name: 'addToWhitelist',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'address', name: '_guardian', type: 'address' }
    ],
    name: 'cancelGuardianAddition',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'address', name: '_guardian', type: 'address' }
    ],
    name: 'cancelGuardianRevokation',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_wallet', type: 'address' }],
    name: 'cancelRecovery',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_wallet', type: 'address' }],
    name: 'clearSession',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'address', name: '_guardian', type: 'address' }
    ],
    name: 'confirmGuardianAddition',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'address', name: '_guardian', type: 'address' }
    ],
    name: 'confirmGuardianRevokation',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_wallet', type: 'address' }],
    name: 'enableERC1155TokenReceiver',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
      { internalType: 'uint256', name: '_nonce', type: 'uint256' },
      { internalType: 'bytes', name: '_signatures', type: 'bytes' },
      { internalType: 'uint256', name: '_gasPrice', type: 'uint256' },
      { internalType: 'uint256', name: '_gasLimit', type: 'uint256' },
      { internalType: 'address', name: '_refundToken', type: 'address' },
      { internalType: 'address', name: '_refundAddress', type: 'address' }
    ],
    name: 'execute',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'address', name: '_recovery', type: 'address' }
    ],
    name: 'executeRecovery',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_wallet', type: 'address' }],
    name: 'finalizeRecovery',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_wallet', type: 'address' }],
    name: 'getGuardians',
    outputs: [{ internalType: 'address[]', name: '_guardians', type: 'address[]' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_wallet', type: 'address' }],
    name: 'getLock',
    outputs: [{ internalType: 'uint64', name: '_releaseAfter', type: 'uint64' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_wallet', type: 'address' }],
    name: 'getNonce',
    outputs: [{ internalType: 'uint256', name: 'nonce', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_wallet', type: 'address' }],
    name: 'getRecovery',
    outputs: [
      { internalType: 'address', name: '_address', type: 'address' },
      { internalType: 'uint64', name: '_executeAfter', type: 'uint64' },
      { internalType: 'uint32', name: '_guardianCount', type: 'uint32' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'bytes', name: '_data', type: 'bytes' }
    ],
    name: 'getRequiredSignatures',
    outputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'enum BaseModule.OwnerSignature', name: '', type: 'uint8' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_wallet', type: 'address' }],
    name: 'getSession',
    outputs: [
      { internalType: 'address', name: 'key', type: 'address' },
      { internalType: 'uint64', name: 'expires', type: 'uint64' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_wallet', type: 'address' }],
    name: 'guardianCount',
    outputs: [{ internalType: 'uint256', name: '_count', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_wallet', type: 'address' }],
    name: 'init',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'bytes32', name: '_signHash', type: 'bytes32' }
    ],
    name: 'isExecutedTx',
    outputs: [{ internalType: 'bool', name: 'executed', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'address', name: '_guardian', type: 'address' }
    ],
    name: 'isGuardian',
    outputs: [{ internalType: 'bool', name: '_isGuardian', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'address', name: '_guardian', type: 'address' }
    ],
    name: 'isGuardianOrGuardianSigner',
    outputs: [{ internalType: 'bool', name: '_isGuardian', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_wallet', type: 'address' }],
    name: 'isLocked',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: '_msgHash', type: 'bytes32' },
      { internalType: 'bytes', name: '_signature', type: 'bytes' }
    ],
    name: 'isValidSignature',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'address', name: '_target', type: 'address' }
    ],
    name: 'isWhitelisted',
    outputs: [{ internalType: 'bool', name: '_isWhitelisted', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_wallet', type: 'address' }],
    name: 'lock',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      {
        components: [
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'bytes', name: 'data', type: 'bytes' }
        ],
        internalType: 'struct TransactionManager.Call[]',
        name: '_transactions',
        type: 'tuple[]'
      }
    ],
    name: 'multiCall',
    outputs: [{ internalType: 'bytes[]', name: '', type: 'bytes[]' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      {
        components: [
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'bytes', name: 'data', type: 'bytes' }
        ],
        internalType: 'struct TransactionManager.Call[]',
        name: '_transactions',
        type: 'tuple[]'
      }
    ],
    name: 'multiCallWithGuardians',
    outputs: [{ internalType: 'bytes[]', name: '', type: 'bytes[]' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      {
        components: [
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'bytes', name: 'data', type: 'bytes' }
        ],
        internalType: 'struct TransactionManager.Call[]',
        name: '_transactions',
        type: 'tuple[]'
      },
      { internalType: 'address', name: '_sessionUser', type: 'address' },
      { internalType: 'uint64', name: '_duration', type: 'uint64' }
    ],
    name: 'multiCallWithGuardiansAndStartSession',
    outputs: [{ internalType: 'bytes[]', name: '', type: 'bytes[]' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      {
        components: [
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'bytes', name: 'data', type: 'bytes' }
        ],
        internalType: 'struct TransactionManager.Call[]',
        name: '_transactions',
        type: 'tuple[]'
      }
    ],
    name: 'multiCallWithSession',
    outputs: [{ internalType: 'bytes[]', name: '', type: 'bytes[]' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_token', type: 'address' }],
    name: 'recoverToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'address', name: '_target', type: 'address' }
    ],
    name: 'removeFromWhitelist',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'address', name: '_guardian', type: 'address' }
    ],
    name: 'revokeGuardian',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bytes4', name: '_interfaceID', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'pure',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bytes4', name: '_methodId', type: 'bytes4' }],
    name: 'supportsStaticCall',
    outputs: [{ internalType: 'bool', name: '_isSupported', type: 'bool' }],
    stateMutability: 'pure',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_wallet', type: 'address' },
      { internalType: 'address', name: '_newOwner', type: 'address' }
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_wallet', type: 'address' }],
    name: 'unlock',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
] as const;
