<script setup lang="ts">
import { nextTick, ref } from 'vue';

import { type WalletAdapter, WalletReadyState } from '@solana/wallet-adapter-base';

import { type ClientEECode, useErrorModal } from '@/composables/useErrorModal';
import { useSolana } from '@/composables/useSolana';
import { useWagmi } from '@/composables/useWagmi';
import { ExpectedError } from '@/references/ExpectedError';

import InnerLayout from '@/components/layouts/InnerLayout.vue';
import UiButton from '@/components/ui/UiButton.vue';

const {
  network,
  supportedNetworks,
  supportsSignTypedDataV4,
  address,
  isConnected,
  changeNetwork,
  connect,
  disconnect,
  init,
  connectors,
  walletConnectSession,
  tryConnectCached
} = useWagmi();

const {
  wallets,
  connect: connectSolana,
  connected,
  publicKey,
  select,
  cluster,
  sendUSDC,
  getUSDCBalance
} = useSolana();

init();
tryConnectCached();

async function handleConnectToWallet(wallet: WalletAdapter) {
  select(wallet.name);
  await nextTick();
  try {
    await connectSolana();
  } catch (error) {
    if (error instanceof Error && /user rejected the request/i.test(error.message)) {
      useErrorModal().auto(new ExpectedError<ClientEECode>('userRejectAuth', { cause: error }));
    }
  }
}

const sending = ref(false);
async function handleSendUSDC(): Promise<void> {
  if (sending.value) {
    return;
  }

  try {
    sending.value = true;
    await sendUSDC('0.01');
  } catch (error) {
    useErrorModal().auto(error);
  } finally {
    sending.value = false;
  }
}

const balance = ref('0');
const updatingBalance = ref(false);
async function updateBalance(): Promise<void> {
  try {
    updatingBalance.value = true;
    balance.value = await getUSDCBalance();
  } finally {
    updatingBalance.value = false;
  }
}
</script>

<template>
  <InnerLayout>
    <details>
      <summary>EVM</summary>

      <div>
        <UiButton v-if="isConnected" @click="disconnect">Disconnect</UiButton>
        <div v-else>
          <UiButton v-for="connector in connectors" :key="connector.id" @click="connect(connector)">
            <img width="16" height="16" :src="connector.icon" />{{ connector.name }}
          </UiButton>
        </div>
      </div>
      <div>
        <p>Ethereum address {{ address }}</p>
        <p>Connected {{ isConnected }}</p>
        <p>Network {{ network }}</p>
        <p>Supports sign typed data v4 {{ supportsSignTypedDataV4 }}</p>
      </div>
      <pre>{{ walletConnectSession }}</pre>
      <div>
        <h2>Networks</h2>
        <ul>
          <li v-for="n in supportedNetworks" :key="n">
            <UiButton @click="changeNetwork(n)">{{ n }}</UiButton>
          </li>
        </ul>
      </div>
    </details>
    <details>
      <summary>Solana</summary>

      <div>
        <p>Solana address {{ publicKey }}</p>
        <p>Connected {{ connected }}</p>
        <p>Cluster {{ cluster }}</p>
      </div>

      <ul>
        <li v-for="w in wallets" :key="w.adapter.name">
          <UiButton
            :loading="w.adapter.connecting"
            :disabled="
              w.readyState === WalletReadyState.NotDetected ||
              w.readyState === WalletReadyState.Unsupported
            "
            @click="handleConnectToWallet(w.adapter)"
          >
            <img width="16" height="16" :src="w.adapter.icon" :alt="w.adapter.name" />
            {{ w.adapter.name }}
          </UiButton>
        </li>
      </ul>
      <UiButton :loading="sending" @click="handleSendUSDC">Send 0.01 USDC to pon4</UiButton>
      <UiButton :loading="updatingBalance" @click="updateBalance"
        >Update USDC balance (current {{ balance }})</UiButton
      >
    </details>
  </InnerLayout>
</template>
