<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useLocalStorage } from '@vueuse/core';

import searchIcon from '@/assets/icons/search.svg';
import { isTouchDevice } from '@/helpers/device';
import type { Network } from '@/references/network';

import NetworkSelector from '@/components/NetworkSelector.vue';
import UiInput from '@/components/ui/UiInput.vue';
import UiTip from '@/components/ui/UiTip.vue';

interface Props {
  modelValue: string;
  placeholder: string;
  network: Network | null;
  networks: Network[];
  useUnset?: boolean;
  loading?: boolean;
  isNetworkSelectorReadonly?: boolean;
  localStorageTipKey?: string;
  localStorageNetworkKey?: string;
}

const props = withDefaults(defineProps<Props>(), {
  useUnset: true,
  loading: false,
  isNetworkSelectorReadonly: false,
  localStorageTipKey: undefined,
  localStorageNetworkKey: undefined
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
  (event: 'update:network', value: Network | null): void;
}>();

const inputComponent = ref<typeof UiInput>();

function focus(): void {
  inputComponent.value?.focus();
}

const tipComponent = ref<typeof UiTip>();

const { t } = useI18n();

const isTipClosed = useLocalStorage<boolean | null>(props.localStorageTipKey || '', null);

function onTipHide(): void {
  isTipClosed.value = true;
  tipComponent.value?.hide();
}

function onUpdate(value: string): void {
  onTipHide();
  emit('update:modelValue', value);
}

onMounted(() => {
  if (props.localStorageTipKey && !isTipClosed.value) {
    tipComponent.value?.show();
  }

  if (!isTouchDevice()) {
    focus();
  }
});
</script>

<template>
  <div class="token-selector-header">
    <UiInput
      ref="inputComponent"
      class="token-selector-header__input"
      :model-value="props.modelValue"
      :placeholder="props.placeholder"
      :icon="searchIcon"
      :loading="props.loading"
      @update:model-value="onUpdate"
    />
    <Transition>
      <NetworkSelector
        v-if="!props.modelValue"
        class="token-selector-header__network"
        :model-value="props.network"
        :networks="props.networks"
        :use-unset="props.useUnset"
        :readonly="props.isNetworkSelectorReadonly"
        :local-storage-network-key="props.localStorageNetworkKey"
        @update:model-value="emit('update:network', $event)"
        @click="onTipHide"
      />
    </Transition>
    <div class="token-selector-header__tip-wrapper">
      <UiTip
        ref="tipComponent"
        custom-class="token-selector-header__menu-tip"
        :title="t('tooltips.network.title')"
        :description="t('tooltips.network.description')"
        position="bottom-end"
        :distance="40"
        @hide="onTipHide"
      />
    </div>
  </div>
</template>

<style scoped>
.token-selector-header {
  background-color: var(--color-background);
  border-radius: 0 0 12px 12px;
  box-shadow: var(--shadow-background-spread-md);
}

@media (max-width: 919px) {
  .token-selector-header {
    top: 80px;
  }
}

.token-selector-header__network {
  position: absolute;
  top: 8px;
  right: 0;
}

.token-selector-header__tip-wrapper {
  position: absolute;
  top: calc(50% - 1px);
  right: 16px;
  width: 24px;
  height: 1px;
  pointer-events: none;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity var(--animation-micro) var(--animation-effect);
}
</style>

<style>
.token-selector-header__menu-tip.v-popper__popper {
  z-index: var(--z-index-header) !important;
}
</style>
