import {
  type Address,
  type ContractFunctionParameters,
  encodeFunctionData,
  maxUint160,
  type PrepareTransactionRequestReturnType,
  type PublicClient
} from 'viem';

import { assert } from '@/helpers/assert';
import { getConfig } from '@/references/config';
import type { Network } from '@/references/network';
import { getChainId } from '@/references/network';
import { erc20ABI } from '@/references/onchain/abi/erc20';
import type { WalletClientAdapter } from '@/references/onchain/adapters';
import { contractCallToPayload, Scope } from '@/references/onchain/contractCallToPayload';
import {
  extractEstimationError,
  rethrowIfChainMismatchError,
  rethrowIfUserRejectedRequest
} from '@/references/onchain/errors';
import type { EstimateContractGasCompoundReturn } from '@/references/onchain/gas';
import { estimateContractGasCompound } from '@/references/onchain/gas';
import type { EventConfig } from '@/references/onchain/hooks';
import { isRejectedRequestError } from '@/references/onchain/ProviderRPCError';
import { safeWatchTransactionReceipt } from '@/references/onchain/retries';
import { getAdditionalGasBufferPercent, type Token } from '@/references/tokens';

interface AllowanceParams {
  publicClient: PublicClient;
  tokenAddress: Address;
  network: Network;
  owner: Address;
  spender: Address;
  onCallData?: EventConfig['onCallData'];
}

export const allowance = async ({
  publicClient,
  tokenAddress,
  network,
  owner,
  spender,
  onCallData
}: AllowanceParams): Promise<bigint> => {
  const args = [owner, spender] as const;
  try {
    return await publicClient.readContract({
      address: tokenAddress,
      abi: erc20ABI,
      functionName: 'allowance',
      args
    });
  } catch (error) {
    onCallData?.(
      contractCallToPayload({
        abi: erc20ABI,
        network,
        scope: Scope.Call,
        args,
        address: tokenAddress,
        functionName: 'allowance',
        state: 'error'
      })
    );
    throw error;
  }
};

interface EstimateApproveParams {
  senderAddress: Address;
  publicClient: PublicClient;
  tokenAddress: Address;
  network: Network;
  amountWei: bigint;
  spenderAddress: Address;
  onCallData?: EventConfig['onCallData'];
}

export const estimateApprove = async ({
  senderAddress,
  publicClient,
  tokenAddress,
  network,
  amountWei,
  spenderAddress,
  onCallData
}: EstimateApproveParams): Promise<EstimateContractGasCompoundReturn> => {
  const approveParameters = formatApproveContractParameters({
    tokenAddress,
    spenderAddress,
    amountWei
  });

  onCallData?.(
    contractCallToPayload({
      abi: approveParameters.abi,
      address: approveParameters.address,
      args: approveParameters.args,
      functionName: approveParameters.functionName,
      network,
      scope: Scope.BeforeEstimation
    })
  );

  let res;
  try {
    res = await estimateContractGasCompound(
      publicClient,
      senderAddress,
      getAdditionalGasBufferPercent(tokenAddress, network)
    )({
      abi: approveParameters.abi,
      account: senderAddress,
      address: approveParameters.address,
      args: approveParameters.args,
      functionName: approveParameters.functionName
    });
    onCallData?.(
      contractCallToPayload({
        abi: approveParameters.abi,
        address: approveParameters.address,
        args: approveParameters.args,
        functionName: approveParameters.functionName,
        network,
        scope: Scope.Estimation,
        state: 'success'
      })
    );
  } catch (error) {
    onCallData?.(
      contractCallToPayload({
        abi: approveParameters.abi,
        address: approveParameters.address,
        args: approveParameters.args,
        functionName: approveParameters.functionName,
        network,
        scope: Scope.Estimation,
        state: 'error',
        meta: extractEstimationError(error)
      })
    );
    throw error;
  }

  return res;
};

interface ApproveParams {
  publicClient: PublicClient;
  walletClientAdapter: WalletClientAdapter;
  tokenAddress: Address;
  network: Network;
  amountWei: bigint;
  estimation: EstimateContractGasCompoundReturn;
  spenderAddress: Address;
  onTransactionHash?: EventConfig['onTransactionHash'];
  onTransactionExecuted?: EventConfig['onTransactionExecuted'];
  onBeforeApprove?: EventConfig['onBeforeApprove'];
  onCallData?: EventConfig['onCallData'];
}

export function formatApproveContractParameters({
  amountWei,
  tokenAddress,
  spenderAddress
}: {
  tokenAddress: Address;
  spenderAddress: Address;
  amountWei: bigint;
}): ContractFunctionParameters<typeof erc20ABI, 'nonpayable', 'approve', [Address, bigint]> {
  return {
    address: tokenAddress,
    abi: erc20ABI,
    functionName: 'approve',
    args: [spenderAddress, amountWei]
  };
}

export async function prepareApproveTransaction({
  publicClient,
  amountWei,
  tokenAddress,
  spenderAddress,
  estimation,
  senderAddress
}: {
  publicClient: PublicClient;
  amountWei: bigint;
  tokenAddress: Address;
  spenderAddress: Address;
  senderAddress: Address;
  estimation: EstimateContractGasCompoundReturn;
}): Promise<PrepareTransactionRequestReturnType> {
  const parameters = formatApproveContractParameters({
    amountWei,
    tokenAddress,
    spenderAddress
  });

  assert(publicClient.chain !== undefined, 'Public client must have "chain" defined');

  return await publicClient.prepareTransactionRequest({
    chain: publicClient.chain,
    data: encodeFunctionData({
      abi: parameters.abi,
      args: parameters.args,
      functionName: parameters.functionName
    }),
    from: senderAddress,
    to: tokenAddress,
    value: parameters.value,
    gas: estimation.gasLimit,
    ...estimation.feeValues
  });
}

export const approve = async ({
  publicClient,
  walletClientAdapter,
  tokenAddress,
  network,
  amountWei,
  spenderAddress,
  estimation,
  onTransactionHash,
  onBeforeApprove,
  onCallData,
  onTransactionExecuted
}: ApproveParams): Promise<void> => {
  const approveParameters = formatApproveContractParameters({
    tokenAddress,
    spenderAddress,
    amountWei
  });

  onBeforeApprove?.(amountWei.toString(10), tokenAddress);
  let hash;
  try {
    hash = await walletClientAdapter.useWalletClient({ chainId: getChainId(network) })((c) =>
      c.writeContract({
        address: approveParameters.address,
        abi: approveParameters.abi,
        functionName: approveParameters.functionName,
        args: approveParameters.args,
        gas: estimation.gasLimit,
        ...estimation.feeValues
      })
    );
    onCallData?.(
      contractCallToPayload({
        abi: approveParameters.abi,
        network,
        scope: Scope.ExecuteBroadcast,
        args: approveParameters.args,
        address: approveParameters.address,
        functionName: approveParameters.functionName,
        hash,
        estimation,
        state: 'success'
      })
    );
  } catch (error) {
    onCallData?.(
      contractCallToPayload({
        abi: approveParameters.abi,
        network,
        scope: Scope.ExecuteBroadcast,
        args: approveParameters.args,
        address: approveParameters.address,
        functionName: approveParameters.functionName,
        hash,
        estimation,
        state: isRejectedRequestError(error) ? 'rejected' : 'error',
        meta: extractEstimationError(error)
      })
    );
    rethrowIfChainMismatchError(error);
    rethrowIfUserRejectedRequest(error, 'userRejectTransaction');
    throw error;
  }

  onTransactionHash?.(hash, {});

  try {
    await safeWatchTransactionReceipt(publicClient, hash, network);
    onCallData?.(
      contractCallToPayload({
        abi: approveParameters.abi,
        network,
        scope: Scope.ExecuteReceipt,
        args: approveParameters.args,
        address: approveParameters.address,
        functionName: approveParameters.functionName,
        hash,
        estimation,
        state: 'success'
      })
    );
    onTransactionExecuted?.(hash, {});
  } catch (error) {
    onCallData?.(
      contractCallToPayload({
        abi: approveParameters.abi,
        network,
        scope: Scope.ExecuteReceipt,
        args: approveParameters.args,
        address: approveParameters.address,
        functionName: approveParameters.functionName,
        hash,
        estimation,
        state: isRejectedRequestError(error) ? 'rejected' : 'error',
        meta: extractEstimationError(error)
      })
    );
    rethrowIfUserRejectedRequest(error, 'userRejectTransaction');
    throw error;
  }
};

/** Returns max safe (by token implementation) allowance to be used as permit2 approval target */
export function getMaxPermit2Allowance(token: Token): bigint {
  const config = getConfig();

  if (
    config === undefined ||
    config[token.network] === undefined ||
    config[token.network].tokensWithSpecificMaxPermit2Allowance === undefined
  ) {
    return maxUint160;
  }

  const customMaxPermit2Allowance =
    config[token.network].tokensWithSpecificMaxPermit2Allowance?.[token.address];
  if (customMaxPermit2Allowance === undefined) {
    return maxUint160;
  }

  return BigInt(customMaxPermit2Allowance);
}

export function isSufficientAllowance(have: bigint, need: bigint): boolean {
  return have >= need;
}

export function isValidAllowanceWithBoundaries(
  have: bigint,
  need: bigint,
  maxDeltaPercent: bigint
): boolean {
  const maxValidAllowance = (need * (100n + maxDeltaPercent)) / 100n;
  return isSufficientAllowance(have, need) && have < maxValidAllowance;
}
