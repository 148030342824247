export const isTouchDevice = (): boolean =>
  'ontouchstart' in window || navigator.maxTouchPoints > 0;

export const isIos = (): boolean =>
  ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
    navigator.platform
  ) ||
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export const isSafari = (): boolean =>
  isIos() && !!navigator.userAgent.match(/WebKit/i) && !navigator.userAgent.match(/CriOS/i);
