import type { Address } from 'viem';

import { addSentryBreadcrumb } from '@/logs/sentry';
import { HHAPIService } from '@/references/axios/base';
import { Network } from '@/references/network';

import type { ISwapper } from './ISwapper';
import type { ParallelExecutionResult, QuoteData, TransferData } from './types';

export class ParallelExecutor extends HHAPIService implements ISwapper {
  private readonly executors: ISwapper[];
  private readonly network: Network;

  constructor(network: Network, executors: ISwapper[]) {
    super('parallel-swap.api.service');
    this.network = network;
    this.executors = executors;
  }

  public canHandle(network: Network): boolean {
    for (const executor of this.executors) {
      if (executor.canHandle(network)) {
        return true;
      }
    }
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canHandleToken(network: Network, _tokenAddress: Address): boolean {
    for (const executor of this.executors) {
      if (executor.canHandle(network)) {
        return true;
      }
    }
    return false;
  }

  public async getTransferData(
    buyTokenAddress: Address,
    sellTokenAddress: Address,
    rawAmount: string,
    fromAddress: Address
  ): Promise<TransferData> {
    const dataPromises: Promise<ParallelExecutionResult>[] = [];
    for (const executor of this.executors) {
      if (!executor.canHandleToken(this.network, sellTokenAddress)) {
        continue;
      }

      dataPromises.push(
        (async (ex: ISwapper): Promise<ParallelExecutionResult> => {
          const data = await ex.getTransferData(
            buyTokenAddress,
            sellTokenAddress,
            rawAmount,
            fromAddress
          );

          return {
            data,
            executorName: ex.getName()
          };
        })(executor)
      );
    }

    if (dataPromises.length === 0) {
      addSentryBreadcrumb({
        level: 'error',
        category: this.sentryCategoryPrefix,
        message: 'Failed to find suitable swap executor (fallback)',
        data: { network: this.network }
      });
      throw new Error(`No swap executor for network ${this.network}`);
    }

    const res = await Promise.any(dataPromises);

    addSentryBreadcrumb({
      level: 'info',
      message: 'Received transfer data from parallel',
      data: {
        swapExecutor: res.executorName,
        buyTokenAddress,
        sellTokenAddress,
        rawAmount,
        data: res.data
      }
    });

    return res.data;
  }

  getName(): string {
    return 'Parallel executor';
  }

  getQuoteData(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _buyTokenAddress: Address,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _sellTokenAddress: Address,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _rawAmount: string
  ): Promise<QuoteData> {
    throw new Error('not implemented');
  }
}
