import { type SessionTypes } from '@walletconnect/types';
import type { Account, Chain, WalletActions, WalletClient } from 'viem';
import { signMessage, signTypedData, writeContract } from 'viem/actions';
import { type WalletActionsL2, walletActionsL2 } from 'viem/op-stack';

import { deduplicate, filterDefined } from '@/helpers/arrays';
import { generateOperationId } from '@/helpers/operationId';
import { cloneObject } from '@/helpers/utils';
import { createLogger } from '@/logs/datadog';
import { ExpectedError } from '@/references/ExpectedError';
import { HHError } from '@/references/HHError';
import { getAvailableNetworks, getNetworkByChainId, Network } from '@/references/network';
import type {
  GetWalletClientArgs,
  WalletClientAdapter,
  WalletClientExecutor,
  WalletClientGetter,
  WalletClientWithHoistedAccount
} from '@/references/onchain/adapters';
import { fixSignatureV } from '@/references/onchain/signature';

const logger = createLogger('walletClientAdapter');

export const createAdapter = (getClient: WalletClientGetter): WalletClientAdapter => ({
  // Returns closure that is to be used by caller
  useWalletClient(args?: GetWalletClientArgs): WalletClientExecutor {
    // Injects decorated / overriden client to the provided executor
    return async (executor) => {
      let client: WalletClientWithHoistedAccount | null;
      try {
        client = await getClient(args);
      } catch (error) {
        if (error instanceof ExpectedError) {
          throw error;
        }

        throw new HHError('Failed to get wallet client', { cause: error, payload: args });
      }

      if (client === null) {
        throw new HHError('Empty wallet client received', { payload: args });
      }

      const extendedClient = client.extend(decorateActions(client.extend(walletActionsL2())));

      return executor(extendedClient);
    };
  }
});

type Actions = WalletActions<Chain, Account> & WalletActionsL2<Chain, Account>;

type Decorator = (client: WalletClient) => Partial<Actions>;

const decorateActions = (originalClient: WalletClientWithHoistedAccount & Actions): Decorator => {
  const proxyClient = (overrides: Partial<Actions>, keepOriginal: keyof Actions) => {
    // pick original methods, apply overrides, then omit overriden method to not cause recursive call
    return {
      ...originalClient,
      ...overrides,
      [keepOriginal]: originalClient[keepOriginal]
    };
  };

  return (): Partial<Actions> => ({
    signMessage(params) {
      return wrapOperation(
        generateOperationId('signMessage'),
        async (p) => {
          const signature = await signMessage(proxyClient(this, 'signMessage'), p);
          return fixSignatureV(signature);
        },
        params
      );
    },
    signTypedData(params) {
      return wrapOperation(
        generateOperationId('signTypedData'),
        async (p) => {
          const signature = await signTypedData(proxyClient(this, 'signTypedData'), p);
          return fixSignatureV(signature);
        },
        params
      );
    },
    writeContract(params) {
      return wrapOperation(
        generateOperationId('writeContract'),
        (p) => writeContract(proxyClient(this, 'writeContract'), p),
        params
      );
    }
  });
};

const wrapOperation = async <TArgs extends unknown[], TReturn>(
  requestId: string,
  fn: (...parameters: TArgs) => TReturn,
  ...args: TArgs
): Promise<Awaited<TReturn>> => {
  try {
    logger.debug(`Sending request ${requestId}`, cloneObject({ requestId, args }));
    const res = await fn(...args);
    logger.info(
      `Finished request ${requestId} (success=true)`,
      cloneObject({ requestId, args, res, success: true })
    );
    return res;
  } catch (error) {
    logger.warn(
      `Finished request ${requestId} (success=false)`,
      cloneObject({ requestId, args, success: false, errorRaw: error }),
      error instanceof Error ? error : new HHError('Request failed', { payload: { error } })
    );
    throw error;
  }
};

export const parseNamespaceSupportedChains = (
  namespace: SessionTypes.Namespace
): Array<Network> => {
  const source = namespace.chains ?? namespace.accounts;

  const networkInfos = source.map(parseCAIPChain).map((chainId) => getNetworkByChainId(chainId));
  // if the wallet supports multiple accounts in the same network (e.g. Rainbow Wallet), there will de duplicates
  const duplicated = filterDefined(networkInfos).map((item) => item.network);
  // prevent unsupported (unavailable) networks from being used during the session
  return deduplicate(duplicated).filter(
    (network) => getAvailableNetworks().includes(network) && network !== Network.unknown
  );
};

const parseCAIPChain = (caipChain: string): number => {
  return Number.parseInt(caipChain.split(':')[1], 10);
};
