import type { Address, PublicClient } from 'viem';

export const getEOANonce = async (
  senderAddress: Address,
  publicClient: PublicClient
): Promise<bigint> => {
  const txCount = await publicClient.getTransactionCount({
    address: senderAddress
  });

  return BigInt(txCount);
};
