import { fromWei, toWei } from '@/helpers/bigmath';
import { createHHAxios } from '@/references/axios/axios';
import { HHAPIService } from '@/references/axios/base';
import type { TransferData } from '@/references/axios/swap/types';
import type { ClientType } from '@/references/base';
import type { Network } from '@/references/network';
import type { Token } from '@/references/tokens';

import type {
  GetBrrrAmountBySolanaTokenAPIResponse,
  GetBrrrAmountBySolanaTokenParams,
  GetBrrrAmountBySolanaTokenReturn,
  GetBrrrAmountByTokenAPIResponse,
  GetBrrrAmountByTokenParams,
  GetBrrrAmountByTokenReturn,
  GetCachedResponseResponse,
  GlobalState,
  RegisterSolanaPurchaseParams
} from './types';

type ConstructorArgs = {
  baseURL: string;
  dataProviderBaseURL: string;
  clientType: ClientType;
};

export class HHAPIBrrrService extends HHAPIService {
  private readonly baseURL: string;
  private readonly clientType: ClientType;
  private readonly dataProviderBaseURL: string;

  constructor({ baseURL, dataProviderBaseURL, clientType }: ConstructorArgs) {
    super('brrr.api.service');
    this.baseURL = baseURL;
    this.clientType = clientType;
    this.dataProviderBaseURL = dataProviderBaseURL;
  }

  public async getBrrrAmountByToken(
    { inputToken, outputToken, tokenAmount, transferData }: GetBrrrAmountByTokenParams,
    signal?: AbortSignal
  ): Promise<GetBrrrAmountByTokenReturn> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });
    const response = await instance.request<
      GetBrrrAmountByTokenAPIResponse,
      {
        inputToken: Token;
        outputToken: Token;
        tokenAmountInWei: string;
        transferData?: TransferData;
        network: Network;
      }
    >({
      method: 'POST',
      url: '/v1/private/brrr/getBrrrByToken',
      data: {
        inputToken,
        outputToken,
        tokenAmountInWei: toWei(tokenAmount, inputToken.decimals),
        transferData,
        network: inputToken.network
      },
      withCredentials: true,
      opts: {
        signal
      }
    });

    return {
      brrrAmount: fromWei(response.data.payload.brrrAmountInWei, outputToken.decimals),
      inputToken,
      outputToken,
      inputTokenAmount: tokenAmount,
      transferData,
      rate: response.data.payload.rate
    };
  }

  public async getBrrrAmountBySolanaToken(
    { inputToken, brrrToken, tokenAmount }: GetBrrrAmountBySolanaTokenParams,
    signal?: AbortSignal
  ): Promise<GetBrrrAmountBySolanaTokenReturn> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });
    const response = await instance.request<
      GetBrrrAmountBySolanaTokenAPIResponse,
      {
        tokenAmountInLamports: string;
      }
    >({
      method: 'POST',
      url: '/v1/private/brrr/solana/getBrrrByToken',
      data: {
        tokenAmountInLamports: toWei(tokenAmount, inputToken.decimals)
      },
      withCredentials: true,
      opts: {
        signal
      }
    });

    return {
      inputToken,
      outputToken: brrrToken,
      brrrAmount: fromWei(response.data.payload.brrrAmountInLamports, brrrToken.decimals),
      inputTokenAmount: tokenAmount,
      rate: response.data.payload.rate
    };
  }

  public async getGlobalState(
    signal?: AbortSignal
  ): Promise<GetCachedResponseResponse<'/v2/global/brrr', GlobalState>> {
    const instance = createHHAxios({
      baseURL: this.dataProviderBaseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    const response = await instance.request<
      GetCachedResponseResponse<'/v2/global/brrr', GlobalState>
    >({
      method: 'GET',
      url: '/v1/public/brrr',
      opts: { signal }
    });

    return response.data.payload;
  }

  public async acceptInfo(signal?: AbortSignal): Promise<void> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    await instance.request<never>({
      url: '/v1/private/brrr/info/accept',
      method: 'POST',
      data: undefined,
      withCredentials: true,
      opts: { signal }
    });
  }

  public async acceptTermsAndConditions(signal?: AbortSignal): Promise<void> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    await instance.request<never>({
      url: '/v1/private/brrr/terms-and-conditions/accept',
      method: 'POST',
      data: undefined,
      withCredentials: true,
      opts: { signal }
    });
  }

  public async registerSolanaPurchase(
    {
      address,
      amountUSDC,
      amountBRRR,
      transactionSignature,
      usdcToken,
      brrrToken
    }: RegisterSolanaPurchaseParams,
    signal?: AbortSignal
  ): Promise<void> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    await instance.request<
      never,
      {
        address: string;
        amountUSDC: string;
        amountBRRR: string;
        transactionSignature: string;
      }
    >({
      url: '/v1/private/brrr/solana/notify',
      method: 'POST',
      data: {
        address,
        amountUSDC: toWei(amountUSDC, usdcToken.decimals),
        amountBRRR: toWei(amountBRRR, brrrToken.decimals),
        transactionSignature
      },
      withCredentials: true,
      opts: { signal }
    });
  }
}
