import { secp256k1 } from '@noble/curves/secp256k1';
import { type Hex, numberToHex, serializeSignature } from 'viem';
import { isErc6492Signature } from 'viem/experimental';

import { assertNonNullish } from '@/references/onchain/errors';

export const hexToSignature = (
  signatureHex: Hex
): {
  r: Hex;
  s: Hex;
  v: bigint;
} => {
  const { r, s } = secp256k1.Signature.fromCompact(signatureHex.slice(2, 130));
  const v = BigInt(`0x${signatureHex.slice(130)}`);
  return {
    r: numberToHex(r, { size: 32 }),
    s: numberToHex(s, { size: 32 }),
    v
  };
};

// Fixes invalid v component of the r, s, v signature
// @description Ensures v is either 27n or 28n
//
// If 0n or 1n is received -- it's most likely a hardware wallet with bug
export const fixSignatureV = (signature: Hex): Hex => {
  assertNonNullish(signature, 'userRejectSign');

  // do not modify ERC6492 signatures
  if (isErc6492Signature(signature)) {
    return signature;
  }

  // do not modify ERC1271 signatures
  // 132 chars: '0x' prefix + 128 symbols of rs + 2 symbols of v
  if (signature.length > 132) {
    return signature;
  }

  // split signature to r, s, v components
  const { r, s, v } = hexToSignature(signature);

  if (v === 0n || v === 1n) {
    // some wallets are introducing the invalid v component (expected 27n, 28n, got 0n, 1n)
    //  so append 27n to the value and merge the signature again
    return serializeSignature({ r, s, v: v + 27n });
  }

  return signature;
};
