export const ambireQuickAccManagerABI = [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'txnHash', type: 'bytes32' },
      { indexed: true, internalType: 'bytes32', name: 'accHash', type: 'bytes32' },
      { indexed: true, internalType: 'address', name: 'signer', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'time', type: 'uint256' }
    ],
    name: 'LogCancelled',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'txnHash', type: 'bytes32' },
      { indexed: true, internalType: 'bytes32', name: 'accHash', type: 'bytes32' },
      { indexed: false, internalType: 'uint256', name: 'time', type: 'uint256' }
    ],
    name: 'LogExecScheduled',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'txnHash', type: 'bytes32' },
      { indexed: true, internalType: 'bytes32', name: 'accHash', type: 'bytes32' },
      { indexed: true, internalType: 'address', name: 'signer', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'nonce', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'time', type: 'uint256' },
      {
        components: [
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'bytes', name: 'data', type: 'bytes' }
        ],
        indexed: false,
        internalType: 'struct Identity.Transaction[]',
        name: 'txns',
        type: 'tuple[]'
      }
    ],
    name: 'LogScheduled',
    type: 'event'
  },
  {
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'contract Identity', name: 'identity', type: 'address' },
      {
        components: [
          { internalType: 'uint256', name: 'timelock', type: 'uint256' },
          { internalType: 'address', name: 'one', type: 'address' },
          { internalType: 'address', name: 'two', type: 'address' }
        ],
        internalType: 'struct QuickAccManager.QuickAccount',
        name: 'acc',
        type: 'tuple'
      },
      { internalType: 'uint256', name: 'nonce', type: 'uint256' },
      { internalType: 'bytes', name: 'sig', type: 'bytes' },
      {
        components: [
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'bytes', name: 'data', type: 'bytes' }
        ],
        internalType: 'struct Identity.Transaction[]',
        name: 'txns',
        type: 'tuple[]'
      }
    ],
    name: 'cancel',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'contract Identity', name: 'identity', type: 'address' },
      { internalType: 'bytes32', name: 'accHash', type: 'bytes32' },
      { internalType: 'uint256', name: 'nonce', type: 'uint256' },
      {
        components: [
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'bytes', name: 'data', type: 'bytes' }
        ],
        internalType: 'struct Identity.Transaction[]',
        name: 'txns',
        type: 'tuple[]'
      }
    ],
    name: 'execScheduled',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      { internalType: 'bytes', name: 'signature', type: 'bytes' }
    ],
    name: 'isValidSignature',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'nonces',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    name: 'scheduled',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'contract Identity', name: 'identity', type: 'address' },
      {
        components: [
          { internalType: 'uint256', name: 'timelock', type: 'uint256' },
          { internalType: 'address', name: 'one', type: 'address' },
          { internalType: 'address', name: 'two', type: 'address' }
        ],
        internalType: 'struct QuickAccManager.QuickAccount',
        name: 'acc',
        type: 'tuple'
      },
      {
        components: [
          { internalType: 'bool', name: 'isBothSigned', type: 'bool' },
          { internalType: 'bytes', name: 'one', type: 'bytes' },
          { internalType: 'bytes', name: 'two', type: 'bytes' }
        ],
        internalType: 'struct QuickAccManager.DualSig',
        name: 'sigs',
        type: 'tuple'
      },
      {
        components: [
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'bytes', name: 'data', type: 'bytes' }
        ],
        internalType: 'struct Identity.Transaction[]',
        name: 'txns',
        type: 'tuple[]'
      }
    ],
    name: 'send',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'contract Identity', name: 'identity', type: 'address' },
      {
        components: [
          { internalType: 'uint256', name: 'timelock', type: 'uint256' },
          { internalType: 'address', name: 'one', type: 'address' },
          { internalType: 'address', name: 'two', type: 'address' }
        ],
        internalType: 'struct QuickAccManager.QuickAccount',
        name: 'acc',
        type: 'tuple'
      },
      {
        components: [
          { internalType: 'bytes', name: 'one', type: 'bytes' },
          { internalType: 'bytes', name: 'two', type: 'bytes' }
        ],
        internalType: 'struct QuickAccManager.DualSigAlwaysBoth',
        name: 'sigs',
        type: 'tuple'
      },
      {
        components: [
          { internalType: 'address', name: 'token', type: 'address' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'fee', type: 'uint256' }
        ],
        internalType: 'struct QuickAccManager.Transfer',
        name: 't',
        type: 'tuple'
      }
    ],
    name: 'sendTransfer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'contract Identity', name: 'identity', type: 'address' },
      {
        components: [
          { internalType: 'uint256', name: 'timelock', type: 'uint256' },
          { internalType: 'address', name: 'one', type: 'address' },
          { internalType: 'address', name: 'two', type: 'address' }
        ],
        internalType: 'struct QuickAccManager.QuickAccount',
        name: 'acc',
        type: 'tuple'
      },
      {
        components: [
          { internalType: 'bytes', name: 'one', type: 'bytes' },
          { internalType: 'bytes', name: 'two', type: 'bytes' }
        ],
        internalType: 'struct QuickAccManager.DualSigAlwaysBoth',
        name: 'sigs',
        type: 'tuple'
      },
      {
        components: [
          { internalType: 'string', name: 'description', type: 'string' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'bytes', name: 'data', type: 'bytes' }
        ],
        internalType: 'struct QuickAccManager.Txn[]',
        name: 'txns',
        type: 'tuple[]'
      }
    ],
    name: 'sendTxns',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
] as const;
