import { type Address, ContractFunctionZeroDataError, isAddress, type PublicClient } from 'viem';

import { assert } from '@/helpers/assert';
import { walkError } from '@/helpers/errors';
import { createLogger } from '@/logs/datadog';
import { deriveChainId } from '@/references/onchain/adapters';

import { argentBaseWalletABI } from '../abi/argentBaseWallet';
import { argentModuleABI } from '../abi/argentModule';
import { isContract } from './isContract';

const nonceMask = BigInt('0xffffffffffffffffffffffffffffffff00000000000000000000000000000000');
const nonceShift = 128n;

const logger = createLogger('getArgentNonce');

export const getArgentNonce = async (
  publicClient: PublicClient,
  address: Address
): Promise<bigint> => {
  const chainId = await deriveChainId(publicClient);

  let staticExecutorAddress;
  try {
    staticExecutorAddress = await publicClient.readContract({
      address,
      abi: argentBaseWalletABI,
      functionName: 'staticCallExecutor'
    });
  } catch (error) {
    if (walkError(error, (e) => e instanceof ContractFunctionZeroDataError)) {
      logger.debug(
        `The function call "staticCallExecutor" on ${address} in chain ${chainId} reverted. Either such function is not implemented or the CA is not an Argent wallet or EOA`
      );
    }

    throw error;
  }

  logger.debug(
    `Static call executor function call on ${address} in chain ${chainId} returned ${staticExecutorAddress}. Checking if it's the address`
  );

  assert(isAddress(staticExecutorAddress), `${staticExecutorAddress} is not a valid address`);
  const isStaticExecutorCA = await isContract(publicClient, staticExecutorAddress);
  assert(isStaticExecutorCA, `${staticExecutorAddress} is an EOA`);

  let nonceUint256;
  try {
    nonceUint256 = await publicClient.readContract({
      address: staticExecutorAddress,
      abi: argentModuleABI,
      functionName: 'getNonce',
      args: [address]
    });
  } catch (error) {
    if (walkError(error, (e) => e instanceof ContractFunctionZeroDataError)) {
      logger.debug(
        `The function call "getNonce" on ${staticExecutorAddress} in chain ${chainId} reverted. Either such function is not implemented or the CA is not an Argent wallet`
      );
    }

    throw error;
  }

  assert(nonceUint256 !== 0n, 'AA wallet nonce should not be 0');

  return (nonceUint256 & nonceMask) >> nonceShift;
};
