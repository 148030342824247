<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue';

import BigNumber from 'bignumber.js';

import { greaterThan } from '@/helpers/bigmath';
import { MAX_TOKEN_VISIBLE_DECIMALS } from '@/references/constants';
import type { Token } from '@/references/tokens';

import { type UiInputAlign } from '@/components/ui/UiInput.types';
import UiInput from '@/components/ui/UiInput.vue';
import type { InputTokenOrFiatMode } from '@/components/ui/UiTokenInput.types';

const PREFIX_AND_POSTFIX_MARGIN = 2;

interface Props {
  modelValue: string;
  token?: Token;
  readonly?: boolean;
  disabled?: boolean;
  error?: string;
  inputMode: InputTokenOrFiatMode;
  align?: UiInputAlign;
  paddingLeft?: number;
  paddingRight?: number;
  maxDecimals?: number;
  maximum?: number;
}

const props = withDefaults(defineProps<Props>(), {
  token: undefined,
  readonly: false,
  disabled: false,
  error: undefined,
  align: 'left',
  paddingLeft: 0,
  paddingRight: 0,
  maxDecimals: undefined,
  maximum: undefined
});

const emit = defineEmits<(e: 'update:modelValue', v: string) => void>();

const maskedValue = ref(props.modelValue);
const unmaskedValue = ref(props.modelValue);
const inputComponent = ref<typeof UiInput>();

watch(
  () => props.inputMode,
  () => (maskedValue.value = props.modelValue)
);

watch(
  () => props.modelValue,
  () => (unmaskedValue.value = props.modelValue)
);

const tokenDecimals = computed((): number =>
  Math.min(props.token?.decimals || 0, MAX_TOKEN_VISIBLE_DECIMALS)
);

const decimals = computed((): number => {
  if (props.maxDecimals) {
    return props.maxDecimals;
  }

  if (props.inputMode === 'FIAT') {
    return 2;
  }

  return tokenDecimals.value || 0;
});

const unmaskedModel = computed({
  get(): string {
    return unmaskedValue.value;
  },
  set(value: string) {
    if (props.maximum !== undefined && greaterThan(value, props.maximum)) {
      shake();
      nextTick(() => {
        //save old value
        maskedValue.value = unmaskedValue.value;
        emit('update:modelValue', unmaskedValue.value);
      });
      return;
    }
    unmaskedValue.value = value;

    if (
      new BigNumber(value).toNumber() !==
      new BigNumber(
        new BigNumber(props.modelValue).toFixed(decimals.value, BigNumber.ROUND_DOWN)
      ).toNumber()
    ) {
      emit('update:modelValue', unmaskedValue.value);
    }
  }
});

const mask = computed(() => ({
  mask: 'N',
  blocks: {
    N: {
      mask: Number,
      scale: decimals.value,
      thousandsSeparator: ',',
      normalizeZeros: false,
      min: 0,
      radix: '.',
      mapToRadix: [',', '.']
    }
  }
}));

const prefix = computed((): string | undefined => (props.inputMode === 'FIAT' ? '€' : undefined));

const postfix = computed((): string | undefined =>
  props.inputMode === 'TOKEN' ? props.token?.symbol : undefined
);

function focus(): void {
  inputComponent.value?.focus();
}

function shake(): void {
  inputComponent.value?.shake();
}

watch(
  () => props.modelValue,
  () => {
    unmaskedValue.value = props.modelValue;
  }
);

defineExpose({
  focus,
  shake
});
</script>

<template>
  <UiInput
    :key="props.inputMode"
    ref="inputComponent"
    v-model="maskedValue"
    v-model:unmasked="unmaskedModel"
    :mask="mask"
    :prefix="prefix"
    :prefix-margin="PREFIX_AND_POSTFIX_MARGIN"
    :postfix="postfix"
    :postfix-margin="PREFIX_AND_POSTFIX_MARGIN"
    :readonly="props.readonly"
    :disabled="props.disabled"
    :error="props.error"
    placeholder="0"
    inputmode="decimal"
    mod="transparent"
    :align="props.align"
    :padding-left="props.paddingLeft"
    :padding-right="props.paddingRight"
  />
</template>
