import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { BigNumber } from 'bignumber.js';
import type { Address, Hex } from 'viem';

import { isZero } from '@/helpers/bigmath';
import { addSentryBreadcrumb } from '@/logs/sentry';
import { createHHAxios } from '@/references/axios/axios';
import { HHAPIService } from '@/references/axios/base';
import type { ClientType } from '@/references/base';
import { getChainId, Network } from '@/references/network';
import { substituteAssetAddressIfNeeded } from '@/references/tokens';

import type { ClientEECode } from '../../../../composables/useErrorModal';
import { ExpectedError } from '../../../ExpectedError';
import { HHError } from '../../../HHError';
import { APIError } from '../../APIError';
import type { ISwapper } from '../ISwapper';
import type { SlippageGetter } from '../SlippageGetter';
import type { QuoteData, TransferData } from '../types';
import type {
  AssembleParams,
  AssembleResponse,
  BadRequestResponse,
  QuoteParams,
  QuoteResponse
} from './types';

type ConstructorArgs = {
  baseURL: string;
  network: Network;
  supportedNetworksOverride?: Network[];
  slippageGetter: SlippageGetter;
  clientType: ClientType;
};

// TODO: custom bad request errors validation
export class Swapper2APIService extends HHAPIService implements ISwapper {
  private readonly baseURL: string;
  protected readonly clientType: ClientType;
  protected readonly network: Network;
  private readonly slippageGetter: SlippageGetter;

  protected static allSupportedNetworks: Array<Network> = [
    Network.ethereum,
    Network.zksyncera,
    Network.base,
    Network.polygon,
    Network.optimism,
    Network.mode,
    Network.avalanche,
    Network.arbitrum,
    Network.bsc
  ];

  protected supportedNetworks: Array<Network>;

  constructor({
    baseURL,
    network,
    slippageGetter,
    supportedNetworksOverride,
    clientType
  }: ConstructorArgs) {
    super('swapper2.api.service');
    this.slippageGetter = slippageGetter;
    this.baseURL = baseURL;
    this.clientType = clientType;
    this.network = network;

    if (supportedNetworksOverride !== undefined) {
      this.supportedNetworks = Swapper2APIService.allSupportedNetworks.filter((n) =>
        supportedNetworksOverride.includes(n)
      );
    } else {
      this.supportedNetworks = Swapper2APIService.allSupportedNetworks;
    }
  }

  public canHandle(network: Network): boolean {
    return this.supportedNetworks.includes(network);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canHandleToken(network: Network, _tokenAddress: Address): boolean {
    return this.supportedNetworks.includes(network);
  }

  public async getTransferData(
    buyTokenAddress: Address,
    sellTokenAddress: Address,
    rawAmount: string,
    fromAddress: Address
  ): Promise<TransferData> {
    const slippage = await this.slippageGetter.getSlippage(
      this.network,
      sellTokenAddress,
      buyTokenAddress
    );

    const quoteData = await this.innerGetQuoteData({
      chainId: getChainId(this.network),
      compact: true,
      inputTokens: [
        {
          tokenAddress: sellTokenAddress,
          amount: rawAmount
        }
      ],
      outputTokens: [
        {
          tokenAddress: buyTokenAddress,
          proportion: 1
        }
      ],
      referralCode: 0,
      slippageLimitPercent: new BigNumber(slippage).toNumber(),
      userAddr: fromAddress
    });

    addSentryBreadcrumb({
      level: 'info',
      data: {
        quoteData
      },
      message: 'Swapper2 returned first step'
    });

    const assembleData = await this.getAssembleData({
      pathId: quoteData.pathId,
      simulate: false,
      userAddr: fromAddress,
      receiver: fromAddress
    });

    addSentryBreadcrumb({
      level: 'info',
      data: {
        assembleData
      },
      message: 'Swapper2 returned second step'
    });

    if (assembleData.outputTokens.length !== 1) {
      throw new HHError(
        `wrong length of output tokens arrays: ${assembleData.outputTokens.length}`
      );
    }

    if (assembleData.inputTokens.length !== 1) {
      throw new HHError(`wrong length of input tokens arrays: ${assembleData.inputTokens.length}`);
    }

    if (assembleData.transaction === null) {
      throw new HHError('transaction in assembly is null');
    }

    return {
      buyAmount: assembleData.outputTokens[0].amount,
      data: assembleData.transaction.data as Hex,
      value: assembleData.transaction.value as Hex,
      sellAmount: assembleData.inputTokens[0].amount,
      allowanceTarget: assembleData.transaction.to,
      to: assembleData.transaction.to,
      rawResponse: JSON.stringify({ ...assembleData, swapperName: this.getName() }),
      swappingVia: ''
    };
  }

  public async getQuoteData(
    buyTokenAddress: Address,
    sellTokenAddress: Address,
    rawAmount: string
  ): Promise<QuoteData> {
    const slippage = await this.slippageGetter.getSlippage(
      this.network,
      sellTokenAddress,
      buyTokenAddress
    );

    const quoteData = await this.innerGetQuoteData({
      chainId: getChainId(this.network),
      compact: true,
      inputTokens: [
        {
          tokenAddress: sellTokenAddress,
          amount: rawAmount
        }
      ],
      outputTokens: [
        {
          tokenAddress: buyTokenAddress,
          proportion: 1
        }
      ],
      referralCode: 0,
      slippageLimitPercent: new BigNumber(slippage).toNumber()
    });

    addSentryBreadcrumb({
      level: 'info',
      data: {
        quoteData
      },
      message: 'Swapper2 returned first step'
    });

    return {
      buyAmount: quoteData.outAmounts[0],
      sellAmount: quoteData.inAmounts[0],
      swappingVia: '',
      rawResponse: JSON.stringify(quoteData)
    };
  }

  private async innerGetQuoteData(params: QuoteParams): Promise<QuoteResponse> {
    if (!this.ensureNetworkIsSupported(this.network)) {
      throw new Error(`Swapper2 does not support network ${this.network}`);
    }

    if (params.inputTokens.length === 0) {
      throw new HHError('empty input tokens array');
    }

    if (params.inputTokens.length > 1) {
      throw new HHError('only single swap allowed');
    }

    if (isZero(params.inputTokens[0].amount) || params.inputTokens[0].amount === '') {
      throw new HHError('empty amount request prevented');
    }

    params.inputTokens[0].tokenAddress = substituteAssetAddressIfNeeded(
      params.inputTokens[0].tokenAddress,
      this.network,
      '0x0000000000000000000000000000000000000000'
    );
    params.outputTokens[0].tokenAddress = substituteAssetAddressIfNeeded(
      params.outputTokens[0].tokenAddress,
      this.network,
      '0x0000000000000000000000000000000000000000'
    );

    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType),
      onRejected: this.formatError.bind(this)
    });

    const response = await instance.requestRaw<QuoteResponse, QuoteParams>({
      url: '/sor/quote/v2',
      method: 'POST',
      data: params
    });

    return response.data;
  }

  public async getAssembleData(params: AssembleParams): Promise<AssembleResponse> {
    if (!this.ensureNetworkIsSupported(this.network)) {
      throw new Error(`Swapper2 does not support network ${this.network}`);
    }

    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType),
      onRejected: this.formatError.bind(this)
    });

    const response = await instance.requestRaw<AssembleResponse, AssembleParams>({
      url: '/sor/assemble',
      method: 'POST',
      data: params
    });

    return response.data;
  }

  protected ensureNetworkIsSupported(network?: Network): boolean {
    if (network === undefined) {
      return false;
    }

    return this.supportedNetworks.includes(network);
  }

  public getName(): string {
    return 'Swapper2APIService';
  }

  protected formatError(error: unknown): never {
    if (axios.isAxiosError(error)) {
      if (error.response !== undefined) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data === undefined) {
          const errorPayload = {
            method: error.config?.method,
            requestUri: axios.getUri(error.config),
            statusCode: error.status,
            statusMessage: error.message,
            axiosCode: error.code,
            responseCode: error.response.status
          };

          throw new APIError('Request failed: no data', error.message ?? 'no data', {
            payload: errorPayload
          });
        }

        if (error.response.status === 400) {
          // handle and log bad request responses differently
          throw this.formatBadRequestResponse(error.response as AxiosResponse<BadRequestResponse>);
        }

        throw new APIError(error.response.data.error, error.response.data.errorCode, {
          payload: {
            ...error.response.data,
            status: error.response.status,
            url: axios.getUri(error.response.config)
          },
          status: error.response.status
        });
      }

      if (error.request !== undefined) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest
        const errorData = {
          method: error.config?.method,
          requestUri: axios.getUri(error.config),
          statusCode: error.status,
          statusMessage: error.message,
          axiosCode: error.code
        };

        throw new APIError('The request has failed, no response', 'NO_RESPONSE', {
          payload: errorData
        });
      }
    }

    if (error instanceof Error) {
      // An error is JS-initiated error, just pass it through
      throw new Error('The request has failed', { cause: error });
    }

    throw new Error('The request has failed during setup / result handling', { cause: error });
  }

  protected formatBadRequestResponse(response: AxiosResponse<BadRequestResponse>): never {
    addSentryBreadcrumb({
      level: 'warning',
      category: this.sentryCategoryPrefix,
      message: `Request failed with code ${response.status} (${response.statusText}): ${response.data.detail}`,
      data: {
        method: response.config.method,
        requestUri: axios.getUri(response.config),
        statusCode: response.status,
        statusMessage: response.statusText,
        error: response.data.detail
      }
    });

    if (response.status === 400) {
      if (
        response.data.detail &&
        response.data.detail.startsWith('Routing unavailable for token')
      ) {
        throw new ExpectedError<ClientEECode>('swapUnsupportedToken');
      }

      throw new HHError(response.data.detail, { cause: response.data });
    }

    throw new HHError(response.data.detail, { cause: response.data });
  }
}
