<script setup lang="ts">
import { useI18n } from '@/references/i18n';

import UiText from '@/components/ui/UiText.vue';

interface Props {
  text?: string;
}

const props = withDefaults(defineProps<Props>(), {
  text: undefined
});

const { t } = useI18n();
</script>

<template>
  <UiText class="ui-not-found" size="md" weight="medium" tag="p">
    {{ props.text ?? `${t('forms.notFoundAnything')} :(` }}
  </UiText>
</template>

<style scoped>
.ui-not-found {
  padding: 16px;
  color: var(--color-main-text);
  background-color: var(--color-main-stroke);
  border-radius: 12px;
}
</style>
