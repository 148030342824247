import type { Address } from 'viem';

import { isZero } from '@/helpers/bigmath';
import { addSentryBreadcrumb } from '@/logs/sentry';
import type { ClientType } from '@/references/base';
import { Network } from '@/references/network';

import { HHError } from '../../HHError';
import type { ISwapper } from './ISwapper';
import { ParallelExecutor } from './ParallelExecutor';
import type { SlippageGetter } from './SlippageGetter';
import { Swapper0APIService } from './swapper0/Swapper0APIService';
import { Swapper1APIService } from './swapper1/Swapper1APIService';
import { Swapper2APIService } from './swapper2/Swapper2APIService';
import type { QuoteData, TransferData } from './types';

export type ConstructorArgs = {
  network: Network;
  baseURLSwapper0: string;
  baseURLSwapper1: string;
  baseURLSwapper2: string;
  slippageGetter: SlippageGetter;
  clientType: ClientType;
};

export class SwapAPIService {
  private sentryCategoryPrefix = 'swap.api.service';
  private readonly network: Network;
  private readonly swapExecutors: ISwapper[];
  private readonly quoteExecutors: ISwapper[];
  private readonly slippageGetter: SlippageGetter;

  constructor({
    network,
    baseURLSwapper0,
    baseURLSwapper1,
    baseURLSwapper2,
    slippageGetter,
    clientType
  }: ConstructorArgs) {
    this.network = network;
    this.slippageGetter = slippageGetter;

    // if main executors returns nothing (or error) we use fallback executors in parallel
    const fallbackExecutor1 = new Swapper0APIService({
      baseURL: baseURLSwapper0,
      network,
      clientType
    });
    // we use swapper 2 as fallback with all supported networks except mode (to avoid the duplication)
    const fallbackExecutor2 = new Swapper2APIService({
      baseURL: baseURLSwapper2,
      network,
      slippageGetter,
      supportedNetworksOverride: [
        Network.ethereum,
        Network.zksyncera,
        Network.base,
        Network.polygon,
        Network.optimism,
        Network.avalanche,
        Network.arbitrum,
        Network.bsc
      ],
      clientType
    });

    // we use swapper 1 as main swapper and swapper 2 only for mode
    // third option is the parallel executor
    this.swapExecutors = [
      new Swapper1APIService({ baseURL: baseURLSwapper1, network, clientType }),
      new Swapper2APIService({
        baseURL: baseURLSwapper2,
        network,
        slippageGetter,
        supportedNetworksOverride: [Network.mode],
        clientType
      }),
      new ParallelExecutor(network, [fallbackExecutor1, fallbackExecutor2])
    ];

    this.quoteExecutors = [
      new Swapper1APIService({ baseURL: baseURLSwapper1, network, clientType })
    ];
  }

  public getNetwork(): Network {
    return this.network;
  }

  public getSlippage(fromTokenAddress: Address, toTokenAddress: Address): Promise<string> {
    return this.slippageGetter.getSlippage(this.network, fromTokenAddress, toTokenAddress);
  }

  public async getTransferData(
    buyTokenAddress: Address,
    sellTokenAddress: Address,
    rawAmount: string,
    fromAddress: Address
  ): Promise<TransferData> {
    if (isZero(rawAmount) || rawAmount === '') {
      throw new HHError('swap zero amount request prevented');
    }

    let lastError: unknown = undefined;
    for (const executor of this.swapExecutors) {
      if (!executor.canHandleToken(this.network, sellTokenAddress)) {
        continue;
      }

      try {
        const data = await executor.getTransferData(
          buyTokenAddress,
          sellTokenAddress,
          rawAmount,
          fromAddress
        );

        addSentryBreadcrumb({
          level: 'info',
          message: 'Received transfer data',
          data: {
            swapExecutor: executor.getName(),
            buyTokenAddress,
            sellTokenAddress,
            rawAmount,
            data: data.data
          }
        });
        return data;
      } catch (error) {
        lastError = error;
        addSentryBreadcrumb({
          level: 'warning',
          message: 'Failed to get transfer data',
          data: {
            error,
            swapExecutor: executor.getName(),
            buyTokenAddress,
            sellTokenAddress,
            rawAmount
          }
        });
      }
    }

    if (lastError !== undefined) {
      addSentryBreadcrumb({
        level: 'error',
        category: this.sentryCategoryPrefix,
        message: 'Failed to execute swap, return last error',
        data: { network: this.network }
      });
      throw lastError;
    }

    addSentryBreadcrumb({
      level: 'error',
      category: this.sentryCategoryPrefix,
      message: 'Failed to find suitable swap executor',
      data: { network: this.network }
    });
    throw new Error(`No swap executor for network ${this.network}`);
  }

  public async getQuoteData(
    buyTokenAddress: Address,
    sellTokenAddress: Address,
    rawAmount: string
  ): Promise<QuoteData> {
    if (isZero(rawAmount) || rawAmount === '') {
      throw new HHError('swap zero amount request prevented');
    }

    let lastError: unknown = undefined;
    for (const executor of this.quoteExecutors) {
      if (!executor.canHandleToken(this.network, sellTokenAddress)) {
        continue;
      }

      try {
        const data = await executor.getQuoteData(buyTokenAddress, sellTokenAddress, rawAmount);

        addSentryBreadcrumb({
          level: 'info',
          message: 'Received quote data',
          data: {
            swapExecutor: executor.getName(),
            buyTokenAddress,
            sellTokenAddress,
            rawAmount,
            data
          }
        });
        return data;
      } catch (error) {
        lastError = error;
        addSentryBreadcrumb({
          level: 'warning',
          message: 'Failed to get quote data',
          data: {
            error,
            swapExecutor: executor.getName(),
            buyTokenAddress,
            sellTokenAddress,
            rawAmount
          }
        });
      }
    }

    if (lastError !== undefined) {
      addSentryBreadcrumb({
        level: 'error',
        category: this.sentryCategoryPrefix,
        message: 'Failed to execute quote, return last error',
        data: { network: this.network }
      });
      throw lastError;
    }

    addSentryBreadcrumb({
      level: 'error',
      category: this.sentryCategoryPrefix,
      message: 'Failed to find suitable quote executor',
      data: { network: this.network }
    });
    throw new Error(`No swap executor for network ${this.network}`);
  }
}
