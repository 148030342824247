import dayjs from 'dayjs';
import {
  type Address,
  type ContractFunctionParameters,
  formatUnits,
  type Hash,
  type Hex,
  parseEventLogs,
  type PublicClient
} from 'viem';

import type { ClientEECode } from '@/composables/useErrorModal';
import { assert } from '@/helpers/assert';
import { toWei } from '@/helpers/bigmath';
import { addSentryBreadcrumb } from '@/logs/sentry';
import { APIError } from '@/references/axios/APIError';
import type { HHAPIApprovalService } from '@/references/axios/approval/HHAPIApprovalService';
import type { GetApprovalReturn } from '@/references/axios/approval/types';
import type { TransferData } from '@/references/axios/swap/types';
import { getAvailableNetworks, Network } from '@/references/network';
import {
  getChainId,
  getNetworkAddress,
  isBaseAssetByNetwork,
  isDefaultAddress
} from '@/references/network';
import type { WalletInfoAdapter } from '@/references/onchain/adapters';
import { AllowanceMismatchError } from '@/references/onchain/holyheld/AllowanceMismatchError';
import { fixSignatureV } from '@/references/onchain/signature';
import type { Token } from '@/references/tokens';
import {
  getAdditionalGasBufferPercent,
  getBrrrToken,
  isBrrrSwapTarget,
  requiresZeroAllowanceBeforeApprove,
  substituteAssetAddressIfNeeded
} from '@/references/tokens';

import { ExpectedError } from '../../ExpectedError';
import { HHError } from '../../HHError';
import { Service } from '../../Service';
import { brrrProxyAbi } from '../abi/brrrProxy';
import { contractCallToPayload, Scope } from '../contractCallToPayload';
import {
  allowance,
  approve,
  estimateApprove,
  getMaxPermit2Allowance,
  isSufficientAllowance,
  isValidAllowanceWithBoundaries
} from '../erc20/approve';
import {
  assertNonNullish,
  extractEstimationError,
  rethrowIfChainMismatchError,
  rethrowIfUserRejectedRequest
} from '../errors';
import { estimateContractGasCompound } from '../gas';
import { shouldIncrementNonce } from '../getNonce';
import { parseBrrrPurchaseLogDataHex } from '../parseBrrrPurchaseLogDataHex';
import { isWalletSupportsPermit } from '../permit/walletSupportsPermit';
import { isRejectedRequestError } from '../ProviderRPCError';
import { callActionWithRetries, safeWatchTransactionReceipt } from '../retries';
import {
  mapTransferDataToExpectedMinimumAmountV2,
  mapTransferDataToHex,
  mapTransferDataToValue
} from '../transferData';
import type { TransferDataGetter } from '../transferDataGetter';
import type {
  BaseBRRRFlow,
  BRRRMeta,
  FormatBuyTokenWithApproveContractParametersFlowData,
  FormatBuyTokenWithPermit2ContractParametersFlowData,
  FormatBuyTokenWithPermitContractParametersFlowData,
  GetFinalAmountsByReceiptArgs,
  GetFinalAmountsByReceiptReturn,
  TryReuseOldPermitSigReturn
} from './BRRROnChainService.types';
import {
  AllowanceFlow,
  type EstimateApproveArgs,
  type EstimateWithApproveArgs,
  type EstimateWithPermit2Args,
  type EstimateWithPermitArgs,
  type ExecuteWithApproveArgs,
  type ExecuteWithApproveFlow,
  type ExecuteWithPermit2Args,
  type ExecuteWithPermit2Flow,
  type ExecuteWithPermitArgs,
  type ExecuteWithPermitFlow,
  type GetAllowanceFlowArgs,
  isFlowWithPermitData,
  type MakeApproveArgs,
  type MakeApproveFlow,
  type MakePermit2Args,
  type MakePermit2Flow,
  type MakePermitArgs,
  type MakePermitFlow
} from './flowTypes';
import { Permit2OnChainService, type PermitTransferFromData } from './Permit2OnChainService';
import { PermitOnChainService } from './PermitOnChainService';

type ConstructorArgs = {
  permitService: PermitOnChainService;
  permit2Service: Permit2OnChainService;
  walletInfo: WalletInfoAdapter;
  approvalService: HHAPIApprovalService;

  permitDeadlineSeconds?: number;
  permitBufferSizePercent?: bigint;
  approveMaxDeltaPercent?: bigint;
};

export class BRRROnChainService extends Service {
  private readonly permitService: PermitOnChainService;
  private readonly permit2Service: Permit2OnChainService;
  private readonly walletInfo: WalletInfoAdapter;
  private readonly approvalService: HHAPIApprovalService;
  private readonly permitDeadlineSeconds: number;
  private readonly permitBufferSizePercent: bigint;
  private readonly approveMaxDeltaPercent: bigint;
  protected readonly backendCheckTtl: number = 10 * 60; // 10 minutes
  protected readonly retryCount: number = 7;

  constructor({
    permitService,
    permit2Service,
    walletInfo,
    approvalService,
    permitDeadlineSeconds,
    permitBufferSizePercent,
    approveMaxDeltaPercent
  }: ConstructorArgs) {
    super('brrr.on-chain.service');

    this.permitService = permitService;
    this.permit2Service = permit2Service;
    this.walletInfo = walletInfo;
    this.approvalService = approvalService;

    this.permitDeadlineSeconds = permitDeadlineSeconds ?? 3600;
    this.permitBufferSizePercent = permitBufferSizePercent ?? 5n;
    this.approveMaxDeltaPercent = approveMaxDeltaPercent ?? 10n;
  }

  public async getTransferData(
    sellToken: Token,
    sellAmountToken: string,
    buyToken: Token,
    getTransferData: TransferDataGetter
  ): Promise<TransferData | undefined> {
    if (isBrrrSwapTarget(sellToken.address, sellToken.network)) {
      return undefined;
    }

    return await getTransferData(
      sellToken.network,
      substituteAssetAddressIfNeeded(buyToken.address, buyToken.network),
      substituteAssetAddressIfNeeded(sellToken.address, sellToken.network),
      toWei(sellAmountToken, sellToken.decimals),
      getNetworkAddress(sellToken.network, 'BRRR_EXCHANGE_PROXY_ADDRESS')
    );
  }

  public getAvailableNetworks(): Array<Network> {
    return getAvailableNetworks().filter((n) => {
      return !isDefaultAddress(getNetworkAddress(n, 'BRRR_PROXY_ADDRESS'));
    });
  }

  public async getAllowanceFlow({
    publicClient,
    senderAddress,
    flowData
  }: GetAllowanceFlowArgs<BaseBRRRFlow>): Promise<
    | MakeApproveFlow<BaseBRRRFlow>
    | MakePermitFlow<BaseBRRRFlow>
    | MakePermit2Flow<BaseBRRRFlow>
    | ExecuteWithApproveFlow<BaseBRRRFlow>
    | ExecuteWithPermitFlow<BaseBRRRFlow>
    | ExecuteWithPermit2Flow<BaseBRRRFlow>
  > {
    const isBase = isBaseAssetByNetwork(flowData.inputToken.address, flowData.inputToken.network);
    if (isBase) {
      // pseudo-permit1 flow
      return await this.getAllowanceFlowForBaseAsset(publicClient, senderAddress, flowData);
    }

    if (
      flowData.inputToken.hasPermit &&
      flowData.inputToken.permitType === 'erc2612' &&
      (await isWalletSupportsPermit(publicClient, senderAddress, this.walletInfo, {
        chainId: getChainId(flowData.inputToken.network)
      }))
    ) {
      if (isFlowWithPermitData<BaseBRRRFlow>(flowData)) {
        const reuseResult = await this.tryReuseOldPermitSig(flowData);
        if (reuseResult.ok) {
          return reuseResult.newFlow;
        }
      }

      return this.getAllowanceFlowForPermit(flowData);
    }

    if (
      this.permit2Service.isPermit2Allowed(flowData.inputToken) &&
      (await isWalletSupportsPermit(publicClient, senderAddress, this.walletInfo, {
        chainId: getChainId(flowData.inputToken.network)
      }))
    ) {
      return this.getAllowanceFlowForPermit2(publicClient, senderAddress, flowData);
    }

    return this.getAllowanceFlowForApprove(publicClient, senderAddress, flowData);
  }

  public async makePermit({
    publicClient,
    senderAddress,
    flowData,
    walletClientAdapter
  }: MakePermitArgs<BaseBRRRFlow>): Promise<ExecuteWithPermitFlow<BaseBRRRFlow>> {
    const deadline = dayjs().unix() + this.permitDeadlineSeconds;

    assert(
      flowData.inputToken.permitType === 'erc2612',
      `${flowData.inputToken.symbol} must support ERC-2612`
    );

    let addressCheckData: GetApprovalReturn;
    try {
      addressCheckData = await this.getAddressCheckData(
        senderAddress,
        flowData.inputToken,
        flowData.inputAmountInWei
      );
    } catch (error) {
      throw new ExpectedError<ClientEECode>('brrrAddressCheck', {
        cause: error,
        payload: { scope: 'makePermit' }
      });
    }

    let dataHex;
    try {
      dataHex = await this.permitService.buildPermitData(
        senderAddress,
        publicClient,
        walletClientAdapter,
        flowData.inputToken.permitType,
        flowData.inputToken.address,
        flowData.spenderAddress,
        getChainId(flowData.inputToken.network),
        flowData.allowanceAmount.toString(10),
        deadline,
        flowData.inputToken.permitVersion
      );
    } catch (error) {
      rethrowIfChainMismatchError(error);
      rethrowIfUserRejectedRequest(error, 'userRejectSign');
      throw new ExpectedError<ClientEECode>('swapPermitData', {
        cause: error,
        payload: { scope: 'permit1 signature' },
        sentryHandle: true
      });
    }

    return this.estimateBuyTokenWithPermit({
      publicClient,
      senderAddress,
      flowData: {
        addressCheckData,
        allowedAmount: flowData.allowanceAmount,
        inputAmountInWei: flowData.inputAmountInWei,
        callData: fixSignatureV(dataHex),
        contractAddress: getNetworkAddress(flowData.inputToken.network, 'BRRR_PROXY_ADDRESS'),
        eventConfig: flowData.eventConfig,
        expectedMinimumAmount: mapTransferDataToExpectedMinimumAmountV2(
          flowData.inputToken.priceUSD,
          formatUnits(flowData.inputAmountInWei, flowData.inputToken.decimals),
          flowData.swapTarget,
          flowData.swapTargetPriceUSD,
          flowData.transferData
        ),
        expiresAt: deadline,
        flow: AllowanceFlow.EstimateWithPermit,
        flowType: 'brrr',
        meta: flowData.meta,
        swapTarget: flowData.swapTarget,
        swapTargetPriceUSD: flowData.swapTargetPriceUSD,
        inputToken: flowData.inputToken,
        transferData: flowData.transferData,
        transferDataHex: mapTransferDataToHex(flowData.transferData),
        transferDataRaw: flowData.transferData?.rawResponse,
        transferDataValue: mapTransferDataToValue(flowData.transferData),
        tokensReceiver: flowData.tokensReceiver
      }
    });
  }

  public async makePermit2({
    publicClient,
    senderAddress,
    flowData,
    walletClientAdapter
  }: MakePermit2Args<BaseBRRRFlow>): Promise<ExecuteWithPermit2Flow<BaseBRRRFlow>> {
    let permitNonce;
    try {
      permitNonce = await this.permit2Service.getPermitNonce(publicClient, senderAddress);
    } catch (error) {
      throw new ExpectedError<ClientEECode>('swapPermitData', {
        cause: error,
        payload: { scope: 'permit2 nonce' },
        sentryHandle: true
      });
    }

    let addressCheckData: GetApprovalReturn;
    try {
      addressCheckData = await this.getAddressCheckData(
        senderAddress,
        flowData.inputToken,
        flowData.inputAmountInWei
      );
    } catch (error) {
      throw new ExpectedError<ClientEECode>('brrrAddressCheck', {
        cause: error,
        payload: { scope: 'makePermit' }
      });
    }

    const deadline = dayjs().unix() + this.permitDeadlineSeconds;

    let permitData: PermitTransferFromData;
    try {
      permitData = this.permit2Service.getPermitData(
        {
          deadline: BigInt(deadline),
          nonce: permitNonce,
          permitted: {
            token: flowData.inputToken.address,
            amount: flowData.inputAmountInWei
          },
          spender: getNetworkAddress(flowData.inputToken.network, 'BRRR_PROXY_ADDRESS')
        },
        this.permit2Service.getPermit2Address(flowData.inputToken.network),
        getChainId(flowData.inputToken.network)
      );
    } catch (error) {
      throw new ExpectedError<ClientEECode>('brrrPermitData', {
        cause: error,
        payload: { scope: 'permit2 data' },
        sentryHandle: true
      });
    }

    let permitSignature;
    try {
      permitSignature = fixSignatureV(
        await walletClientAdapter.useWalletClient({
          chainId: getChainId(flowData.inputToken.network)
        })((c) =>
          c.signTypedData({
            domain: permitData.domain,
            message: {
              permitted: {
                amount: permitData.values.permitted.amount,
                token: permitData.values.permitted.token
              },
              spender: permitData.values.spender,
              nonce: permitData.values.nonce,
              deadline: permitData.values.deadline,
              witness: permitData.values.witness
            },
            primaryType: permitData.primaryType,
            types: permitData.types
          })
        )
      );
    } catch (error) {
      rethrowIfChainMismatchError(error);
      rethrowIfUserRejectedRequest(error, 'userRejectSign');
      throw new ExpectedError<ClientEECode>('brrrPermitData', {
        cause: error,
        payload: { scope: 'permit2 signature' },
        sentryHandle: true
      });
    }

    return this.estimateBuyTokenWithPermit2({
      flowData: {
        addressCheckData,
        allowedAmount: flowData.allowanceAmount,
        inputAmountInWei: flowData.inputAmountInWei,
        contractAddress: getNetworkAddress(flowData.inputToken.network, 'BRRR_PROXY_ADDRESS'),
        eventConfig: flowData.eventConfig,
        expectedMinimumAmount: mapTransferDataToExpectedMinimumAmountV2(
          flowData.inputToken.priceUSD,
          formatUnits(flowData.inputAmountInWei, flowData.inputToken.decimals),
          flowData.swapTarget,
          flowData.swapTargetPriceUSD,
          flowData.transferData
        ),
        expiresAt: deadline,
        flow: AllowanceFlow.EstimateWithPermit2,
        flowType: 'brrr',
        meta: flowData.meta,
        permitData: {
          deadline: permitData.values.deadline,
          nonce: permitData.values.nonce,
          permitted: {
            token: permitData.values.permitted.token,
            amount: permitData.values.permitted.amount
          },
          spender: permitData.values.spender,
          witness: permitData.values.witness
        },
        permitSignature: fixSignatureV(permitSignature),
        swapTarget: flowData.swapTarget,
        swapTargetPriceUSD: flowData.swapTargetPriceUSD,
        inputToken: flowData.inputToken,
        transferData: flowData.transferData,
        transferDataHex: mapTransferDataToHex(flowData.transferData),
        transferDataRaw: flowData.transferData?.rawResponse,
        transferDataValue: mapTransferDataToValue(flowData.transferData),
        tokensReceiver: flowData.tokensReceiver
      },
      publicClient,
      senderAddress
    });
  }

  public async makeApprove({
    publicClient,
    senderAddress,
    flowData,
    walletClientAdapter
  }: MakeApproveArgs<BaseBRRRFlow>): Promise<
    | MakeApproveFlow<BaseBRRRFlow>
    | ExecuteWithApproveFlow<BaseBRRRFlow>
    | MakePermit2Flow<BaseBRRRFlow>
  > {
    try {
      const before = await allowance({
        network: flowData.inputToken.network,
        onCallData: flowData.eventConfig?.onCallData,
        owner: senderAddress,
        publicClient,
        spender: flowData.spenderAddress,
        tokenAddress: flowData.inputToken.address
      });

      addSentryBreadcrumb({
        level: 'debug',
        category: `${this.sentryCategoryPrefix}.makeApprove`,
        message: `Allowance before approve: ${before}`
      });
    } catch (error) {
      addSentryBreadcrumb({
        level: 'warning',
        category: `${this.sentryCategoryPrefix}.makeApprove`,
        message: 'Failed to check allowance before approve',
        data: { error }
      });
    }

    try {
      await approve({
        amountWei: flowData.allowanceAmount,
        estimation: flowData.estimation,
        network: flowData.inputToken.network,
        onBeforeApprove: flowData.eventConfig?.onBeforeApprove,
        onCallData: flowData.eventConfig?.onCallData,
        onTransactionHash: (hash) => {
          addSentryBreadcrumb({
            level: 'log',
            message: 'Received tx hash of approve tx',
            category: `${this.sentryCategoryPrefix}.makeApprove.onTransactionHash`,
            data: {
              hash,
              desiredApprove: flowData.allowanceAmount
            }
          });
          flowData.eventConfig?.onApproveTransactionHash?.(hash, flowData);
        },
        publicClient,
        spenderAddress: flowData.spenderAddress,
        tokenAddress: flowData.inputToken.address,
        walletClientAdapter
      });
    } catch (error) {
      rethrowIfChainMismatchError(error);
      rethrowIfUserRejectedRequest(error, 'userRejectTransaction');
      throw new ExpectedError<ClientEECode>('brrrApprove', {
        cause: error,
        payload: { scope: 'makeApprove' }
      });
    }

    try {
      await callActionWithRetries(
        () =>
          this.assertAllowanceValid(
            senderAddress,
            flowData.spenderAddress,
            publicClient,
            flowData.inputToken,
            flowData.allowanceAmount === 0n ? 0n : flowData.inputAmountInWei,
            !flowData.forPermit2
          ),
        5,
        0,
        undefined,
        (retryCount) => ~~(200 << retryCount)
      );
    } catch (error) {
      if (error instanceof ExpectedError) {
        throw error;
      }

      throw new ExpectedError<ClientEECode>('brrrApprove', {
        payload: { scope: 'makeApprove' },
        cause: error
      });
    }

    const flow = await this.getAllowanceFlow({
      flowData,
      publicClient,
      senderAddress
    });

    if (
      flow.flow === AllowanceFlow.MakeApprove ||
      flow.flow === AllowanceFlow.MakePermit2 ||
      flow.flow === AllowanceFlow.ExecuteWithApprove
    ) {
      return flow;
    }

    throw new ExpectedError<ClientEECode>('brrrApprove', {
      cause: new HHError(`Unexpected next step: got ${flow.flow}. Nodes out of sync?`, {
        payload: {
          expected: {
            oneOf: [
              AllowanceFlow.MakeApprove,
              AllowanceFlow.MakePermit2,
              AllowanceFlow.ExecuteWithApprove
            ]
          },
          got: flow.flow
        }
      })
    });
  }

  public async buyTokenPermit({
    publicClient,
    walletClientAdapter,
    flowData
  }: ExecuteWithPermitArgs<BaseBRRRFlow>): Promise<Hash> {
    this.assertPermitNotExpired(flowData.expiresAt);

    const network = flowData.inputToken.network;
    const params = this.formatBuyTokenWithPermitContractParameters(flowData);

    let hash: Hash;
    try {
      hash = await walletClientAdapter.useWalletClient({ chainId: getChainId(network) })((c) =>
        c.writeContract({
          ...params,
          gas: flowData.estimation.gasLimit,
          ...flowData.estimation.feeValues
        })
      );
      assertNonNullish(hash, 'userRejectTransaction');
    } catch (error) {
      flowData.eventConfig?.onCallData?.(
        contractCallToPayload({
          abi: params.abi,
          address: params.address,
          args: params.args,
          estimation: flowData.estimation,
          functionName: params.functionName,
          network,
          scope: Scope.ExecuteBroadcast,
          state: isRejectedRequestError(error) ? 'rejected' : 'error',
          transferDataRaw: flowData.transferDataRaw,
          value: params.value,
          meta: extractEstimationError(error)
        })
      );
      rethrowIfChainMismatchError(error);
      rethrowIfUserRejectedRequest(error, 'userRejectTransaction');
      throw new ExpectedError<ClientEECode>('brrrWithPermit', {
        cause: error,
        payload: { scope: 'broadcast' }
      });
    }

    flowData.eventConfig?.onCallData?.(
      contractCallToPayload({
        abi: params.abi,
        address: params.address,
        args: params.args,
        estimation: flowData.estimation,
        functionName: params.functionName,
        hash,
        network,
        scope: Scope.ExecuteBroadcast,
        state: 'success',
        transferDataRaw: flowData.transferDataRaw,
        value: params.value
      })
    );
    flowData.eventConfig?.onTransactionHash?.(hash, flowData);

    try {
      await safeWatchTransactionReceipt(
        publicClient,
        hash,
        network,
        flowData.eventConfig?.watchTxReceipt
      );
      flowData.eventConfig?.onCallData?.(
        contractCallToPayload({
          abi: params.abi,
          address: params.address,
          args: params.args,
          estimation: flowData.estimation,
          functionName: params.functionName,
          hash,
          network,
          scope: Scope.ExecuteReceipt,
          state: 'success',
          transferDataRaw: flowData.transferDataRaw,
          value: params.value
        })
      );
      flowData.eventConfig?.onTransactionExecuted?.(hash, flowData);
    } catch (error) {
      flowData.eventConfig?.onCallData?.(
        contractCallToPayload({
          abi: params.abi,
          address: params.address,
          args: params.args,
          estimation: flowData.estimation,
          functionName: params.functionName,
          hash,
          network,
          scope: Scope.ExecuteReceipt,
          state: isRejectedRequestError(error) ? 'rejected' : 'error',
          transferDataRaw: flowData.transferDataRaw,
          value: params.value,
          meta: extractEstimationError(error)
        })
      );
      rethrowIfChainMismatchError(error);
      rethrowIfUserRejectedRequest(error, 'userRejectTransaction');
      throw new ExpectedError<ClientEECode>('brrrWithPermit', {
        cause: error,
        payload: { scope: 'receipt' }
      });
    }

    return hash;
  }

  public async buyTokenPermit2({
    publicClient,
    walletClientAdapter,
    flowData,
    senderAddress
  }: ExecuteWithPermit2Args<BaseBRRRFlow>): Promise<Hash> {
    this.assertPermitNotExpired(flowData.expiresAt);

    const network = flowData.inputToken.network;
    const params = this.formatBuyTokenWithPermit2ContractParameters(flowData);

    let hash: Hash;
    try {
      hash = await walletClientAdapter.useWalletClient({ chainId: getChainId(network) })((c) =>
        c.writeContract({
          ...params,
          gas: flowData.estimation.gasLimit,
          ...flowData.estimation.feeValues
        })
      );
      assertNonNullish(hash, 'userRejectTransaction');
    } catch (error) {
      flowData.eventConfig?.onCallData?.(
        contractCallToPayload({
          abi: params.abi,
          address: params.address,
          args: params.args,
          estimation: flowData.estimation,
          functionName: params.functionName,
          network,
          scope: Scope.ExecuteBroadcast,
          state: isRejectedRequestError(error) ? 'rejected' : 'error',
          transferDataRaw: flowData.transferDataRaw,
          meta: extractEstimationError(error)
        })
      );
      rethrowIfChainMismatchError(error);
      rethrowIfUserRejectedRequest(error, 'userRejectTransaction');
      throw new ExpectedError<ClientEECode>('brrrWithPermit2', {
        cause: error,
        payload: { scope: 'broadcast' }
      });
    } finally {
      if (
        await shouldIncrementNonce(
          publicClient,
          senderAddress,
          this.walletInfo,
          getChainId(flowData.inputToken.network)
        )
      ) {
        await this.walletInfo.incrementPermit2Nonce({
          address: senderAddress,
          network: flowData.inputToken.network
        });
      }
    }

    flowData.eventConfig?.onCallData?.(
      contractCallToPayload({
        abi: params.abi,
        address: params.address,
        args: params.args,
        estimation: flowData.estimation,
        functionName: params.functionName,
        hash,
        network,
        scope: Scope.ExecuteBroadcast,
        state: 'success',
        transferDataRaw: flowData.transferDataRaw
      })
    );
    flowData.eventConfig?.onTransactionHash?.(hash, flowData);

    try {
      await safeWatchTransactionReceipt(
        publicClient,
        hash,
        network,
        flowData.eventConfig?.watchTxReceipt
      );
      flowData.eventConfig?.onCallData?.(
        contractCallToPayload({
          abi: params.abi,
          address: params.address,
          args: params.args,
          estimation: flowData.estimation,
          functionName: params.functionName,
          hash,
          network,
          scope: Scope.ExecuteReceipt,
          state: 'success',
          transferDataRaw: flowData.transferDataRaw
        })
      );
      flowData.eventConfig?.onTransactionExecuted?.(hash, flowData);
    } catch (error) {
      flowData.eventConfig?.onCallData?.(
        contractCallToPayload({
          abi: params.abi,
          address: params.address,
          args: params.args,
          estimation: flowData.estimation,
          functionName: params.functionName,
          hash,
          network,
          scope: Scope.ExecuteReceipt,
          state: isRejectedRequestError(error) ? 'rejected' : 'error',
          transferDataRaw: flowData.transferDataRaw,
          meta: extractEstimationError(error)
        })
      );
      rethrowIfUserRejectedRequest(error, 'userRejectTransaction');
      throw new ExpectedError<ClientEECode>('brrrWithPermit2', {
        cause: error,
        payload: { scope: 'receipt' }
      });
    }

    return hash;
  }

  public async buyTokenTrusted({
    publicClient,
    walletClientAdapter,
    flowData
  }: ExecuteWithApproveArgs<BaseBRRRFlow>): Promise<Hash> {
    this.assertApproveCheckNotExpired(flowData.approvalData.timestamp, flowData.inputToken.network);

    const network = flowData.inputToken.network;
    const params = this.formatBuyTokenTrustedContractParameters(flowData);

    let hash: Hash;
    try {
      hash = await walletClientAdapter.useWalletClient({ chainId: getChainId(network) })((c) =>
        c.writeContract({
          ...params,
          gas: flowData.estimation.gasLimit,
          ...flowData.estimation.feeValues
        })
      );
      assertNonNullish(hash, 'userRejectTransaction');
    } catch (error) {
      flowData.eventConfig?.onCallData?.(
        contractCallToPayload({
          abi: params.abi,
          address: params.address,
          args: params.args,
          estimation: flowData.estimation,
          functionName: params.functionName,
          network,
          scope: Scope.ExecuteBroadcast,
          state: isRejectedRequestError(error) ? 'rejected' : 'error',
          transferDataRaw: flowData.transferDataRaw,
          meta: extractEstimationError(error)
        })
      );
      rethrowIfChainMismatchError(error);
      rethrowIfUserRejectedRequest(error, 'userRejectTransaction');
      throw new ExpectedError<ClientEECode>('brrrWithApprove', {
        cause: error,
        payload: { scope: 'broadcast' }
      });
    }

    flowData.eventConfig?.onCallData?.(
      contractCallToPayload({
        abi: params.abi,
        address: params.address,
        args: params.args,
        estimation: flowData.estimation,
        functionName: params.functionName,
        hash,
        network,
        scope: Scope.ExecuteBroadcast,
        state: 'success',
        transferDataRaw: flowData.transferDataRaw,
        value: params.value
      })
    );
    flowData.eventConfig?.onTransactionHash?.(hash, flowData);

    try {
      await safeWatchTransactionReceipt(
        publicClient,
        hash,
        network,
        flowData.eventConfig?.watchTxReceipt
      );
      flowData.eventConfig?.onCallData?.(
        contractCallToPayload({
          abi: params.abi,
          address: params.address,
          args: params.args,
          estimation: flowData.estimation,
          functionName: params.functionName,
          hash,
          network,
          scope: Scope.ExecuteReceipt,
          state: 'success',
          transferDataRaw: flowData.transferDataRaw,
          value: params.value
        })
      );
      flowData.eventConfig?.onTransactionExecuted?.(hash, flowData);
    } catch (error) {
      flowData.eventConfig?.onCallData?.(
        contractCallToPayload({
          abi: params.abi,
          address: params.address,
          args: params.args,
          estimation: flowData.estimation,
          functionName: params.functionName,
          hash,
          network,
          scope: Scope.ExecuteReceipt,
          state: isRejectedRequestError(error) ? 'rejected' : 'error',
          transferDataRaw: flowData.transferDataRaw,
          value: params.value,
          meta: extractEstimationError(error)
        })
      );
      rethrowIfUserRejectedRequest(error, 'userRejectTransaction');
      throw new ExpectedError<ClientEECode>('brrrWithApprove', {
        cause: error,
        payload: { scope: 'receipt' }
      });
    }

    return hash;
  }

  /**
   * Replaces current transferData-bound values with received ones
   *
   * Caller must call {@link getAllowanceFlow} after receiving the result
   */
  public provideNewAmounts<
    F extends
      | ExecuteWithApproveFlow<BaseBRRRFlow>
      | ExecuteWithPermitFlow<BaseBRRRFlow>
      | ExecuteWithPermit2Flow<BaseBRRRFlow>
  >(v: F, amountInWei: bigint, transferData: TransferData | undefined, meta: BRRRMeta): F {
    return {
      ...v,
      amountInWei,
      expectedMinimumAmount: mapTransferDataToExpectedMinimumAmountV2(
        v.inputToken.priceUSD,
        formatUnits(v.inputAmountInWei, v.inputToken.decimals),
        v.swapTarget,
        v.swapTargetPriceUSD,
        transferData
      ),
      transferData,
      transferDataHex: mapTransferDataToHex(transferData),
      transferDataRaw: transferData?.rawResponse,
      transferDataValue:
        'transferDataValue' in v ? mapTransferDataToValue(transferData) : undefined,
      meta: meta ?? v.meta
    };
  }

  public async getFinalAmountsByReceipt({
    publicClient,
    hash,
    network,
    inputToken
  }: GetFinalAmountsByReceiptArgs): Promise<GetFinalAmountsByReceiptReturn> {
    const outputToken = getBrrrToken(network);

    const receipt = await publicClient.getTransactionReceipt({
      hash
    });

    const logs = parseEventLogs({
      abi: brrrProxyAbi,
      eventName: 'MintedForToken',
      logs: receipt.logs,
      strict: true
    });

    assert(
      logs.length > 0,
      `Transaction with hash ${hash} on ${network} is not a brrr purchase transaction`
    );

    const log = logs[0];

    const { amountToken, amountBrrr } = parseBrrrPurchaseLogDataHex(log.data);

    const amountFromParts = formatUnits(amountToken, inputToken.decimals);
    const amountToParts = formatUnits(amountBrrr, outputToken.decimals);

    return {
      inputAmount: amountFromParts,
      outputAmount: amountToParts
    };
  }

  protected async estimateBuyTokenWithApprove({
    publicClient,
    senderAddress,
    flowData
  }: EstimateWithApproveArgs<BaseBRRRFlow>): Promise<ExecuteWithApproveFlow<BaseBRRRFlow>> {
    this.assertApproveCheckNotExpired(flowData.approvalData.timestamp, flowData.inputToken.network);
    const network = flowData.inputToken.network;
    const params = this.formatBuyTokenTrustedContractParameters(flowData);

    flowData.eventConfig?.onCallData?.(
      contractCallToPayload({
        abi: params.abi,
        address: params.address,
        args: params.args,
        functionName: params.functionName,
        network,
        scope: Scope.BeforeEstimation,
        transferDataRaw: flowData.transferDataRaw,
        value: params.value
      })
    );

    let estimated;
    try {
      estimated = await callActionWithRetries(() =>
        estimateContractGasCompound(
          publicClient,
          senderAddress,
          getAdditionalGasBufferPercent(flowData.inputToken.address, flowData.inputToken.network)
        )({
          account: senderAddress,
          ...params
        })
      );
    } catch (error) {
      flowData.eventConfig?.onCallData?.(
        contractCallToPayload({
          abi: params.abi,
          address: params.address,
          args: params.args,
          functionName: params.functionName,
          network,
          scope: Scope.Estimation,
          state: 'error',
          transferDataRaw: flowData.transferDataRaw,
          value: params.value,
          meta: extractEstimationError(error)
        })
      );
      throw new ExpectedError<ClientEECode>('brrrWithApproveEstimation', { cause: error });
    }

    flowData.eventConfig?.onCallData?.(
      contractCallToPayload({
        abi: params.abi,
        address: params.address,
        args: params.args,
        functionName: params.functionName,
        network,
        scope: Scope.Estimation,
        state: 'success',
        transferDataRaw: flowData.transferDataRaw,
        value: params.value
      })
    );

    return {
      allowanceAmount: flowData.allowanceAmount,
      inputAmountInWei: flowData.inputAmountInWei,
      approvalData: flowData.approvalData,
      contractAddress: flowData.contractAddress,
      estimation: estimated,
      eventConfig: flowData.eventConfig,
      expectedMinimumAmount: flowData.expectedMinimumAmount,
      flow: AllowanceFlow.ExecuteWithApprove,
      flowType: 'brrr',
      meta: flowData.meta,
      spenderAddress: flowData.spenderAddress,
      swapTarget: flowData.swapTarget,
      swapTargetPriceUSD: flowData.swapTargetPriceUSD,
      inputToken: flowData.inputToken,
      transferData: flowData.transferData,
      transferDataHex: flowData.transferDataHex,
      transferDataRaw: flowData.transferDataRaw,
      tokensReceiver: flowData.tokensReceiver
    };
  }

  protected async estimateBuyTokenWithPermit({
    publicClient,
    senderAddress,
    flowData
  }: EstimateWithPermitArgs<BaseBRRRFlow>): Promise<ExecuteWithPermitFlow<BaseBRRRFlow>> {
    this.assertAddressCheckNotExpired(flowData.addressCheckData.timestamp);
    this.assertPermitNotExpired(flowData.expiresAt);

    const network = flowData.inputToken.network;
    const params = this.formatBuyTokenWithPermitContractParameters(flowData);

    flowData.eventConfig?.onCallData?.(
      contractCallToPayload({
        abi: params.abi,
        address: params.address,
        args: params.args,
        functionName: params.functionName,
        network,
        scope: Scope.BeforeEstimation,
        transferDataRaw: flowData.transferDataRaw,
        value: params.value
      })
    );

    let estimated;
    try {
      estimated = await callActionWithRetries(() =>
        estimateContractGasCompound(
          publicClient,
          senderAddress,
          getAdditionalGasBufferPercent(flowData.inputToken.address, flowData.inputToken.network)
        )({
          account: senderAddress,
          ...params
        })
      );
    } catch (error) {
      flowData.eventConfig?.onCallData?.(
        contractCallToPayload({
          abi: params.abi,
          address: params.address,
          args: params.args,
          functionName: params.functionName,
          network,
          scope: Scope.Estimation,
          state: 'error',
          transferDataRaw: flowData.transferDataRaw,
          value: params.value,
          meta: extractEstimationError(error)
        })
      );
      throw new ExpectedError<ClientEECode>('brrrWithPermitEstimation', { cause: error });
    }

    flowData.eventConfig?.onCallData?.(
      contractCallToPayload({
        abi: params.abi,
        address: params.address,
        args: params.args,
        functionName: params.functionName,
        network,
        scope: Scope.Estimation,
        state: 'success',
        transferDataRaw: flowData.transferDataRaw,
        value: params.value
      })
    );

    return {
      addressCheckData: flowData.addressCheckData,
      allowedAmount: flowData.allowedAmount,
      inputAmountInWei: flowData.inputAmountInWei,
      callData: flowData.callData,
      contractAddress: flowData.contractAddress,
      estimation: estimated,
      eventConfig: flowData.eventConfig,
      expectedMinimumAmount: flowData.expectedMinimumAmount,
      expiresAt: flowData.expiresAt,
      flow: AllowanceFlow.ExecuteWithPermit,
      flowType: 'brrr',
      meta: flowData.meta,
      swapTarget: flowData.swapTarget,
      swapTargetPriceUSD: flowData.swapTargetPriceUSD,
      inputToken: flowData.inputToken,
      transferData: flowData.transferData,
      transferDataHex: flowData.transferDataHex,
      transferDataRaw: flowData.transferDataRaw,
      transferDataValue: flowData.transferDataValue,
      tokensReceiver: flowData.tokensReceiver
    };
  }

  protected async estimateBuyTokenWithPermit2({
    publicClient,
    flowData,
    senderAddress
  }: EstimateWithPermit2Args<BaseBRRRFlow>): Promise<ExecuteWithPermit2Flow<BaseBRRRFlow>> {
    this.assertAddressCheckNotExpired(flowData.addressCheckData.timestamp);
    this.assertPermitNotExpired(flowData.expiresAt);

    const network = flowData.inputToken.network;
    const params = this.formatBuyTokenWithPermit2ContractParameters(flowData);

    flowData.eventConfig?.onCallData?.(
      contractCallToPayload({
        abi: params.abi,
        address: params.address,
        args: params.args,
        functionName: params.functionName,
        network,
        scope: Scope.BeforeEstimation,
        transferDataRaw: flowData.transferDataRaw,
        value: params.value
      })
    );

    let estimated;
    try {
      estimated = await callActionWithRetries(() =>
        estimateContractGasCompound(
          publicClient,
          senderAddress,
          getAdditionalGasBufferPercent(flowData.inputToken.address, flowData.inputToken.network)
        )({
          account: senderAddress,
          ...params
        })
      );
    } catch (error) {
      flowData.eventConfig?.onCallData?.(
        contractCallToPayload({
          abi: params.abi,
          address: params.address,
          args: params.args,
          functionName: params.functionName,
          network,
          scope: Scope.Estimation,
          state: 'error',
          transferDataRaw: flowData.transferDataRaw,
          value: params.value,
          meta: extractEstimationError(error)
        })
      );
      throw new ExpectedError<ClientEECode>('brrrWithPermit2Estimation', { cause: error });
    }

    flowData.eventConfig?.onCallData?.(
      contractCallToPayload({
        abi: params.abi,
        address: params.address,
        args: params.args,
        functionName: params.functionName,
        network,
        scope: Scope.Estimation,
        state: 'success',
        transferDataRaw: flowData.transferDataRaw,
        value: params.value
      })
    );

    return {
      addressCheckData: flowData.addressCheckData,
      allowedAmount: flowData.allowedAmount,
      inputAmountInWei: flowData.inputAmountInWei,
      contractAddress: flowData.contractAddress,
      estimation: estimated,
      eventConfig: flowData.eventConfig,
      expectedMinimumAmount: flowData.expectedMinimumAmount,
      expiresAt: flowData.expiresAt,
      flow: AllowanceFlow.ExecuteWithPermit2,
      flowType: 'brrr',
      meta: flowData.meta,
      permitData: flowData.permitData,
      permitSignature: flowData.permitSignature,
      swapTarget: flowData.swapTarget,
      swapTargetPriceUSD: flowData.swapTargetPriceUSD,
      inputToken: flowData.inputToken,
      transferData: flowData.transferData,
      transferDataHex: flowData.transferDataHex,
      transferDataRaw: flowData.transferDataRaw,
      transferDataValue: flowData.transferDataValue,
      tokensReceiver: flowData.tokensReceiver
    };
  }

  protected async estimateApprove({
    publicClient,
    senderAddress,
    flowData
  }: EstimateApproveArgs<BaseBRRRFlow>): Promise<MakeApproveFlow<BaseBRRRFlow>> {
    let estimated;
    try {
      estimated = await estimateApprove({
        amountWei: flowData.allowanceAmount,
        network: flowData.inputToken.network,
        onCallData: flowData.eventConfig?.onCallData,
        publicClient,
        senderAddress,
        spenderAddress: flowData.spenderAddress,
        tokenAddress: flowData.inputToken.address
      });
    } catch (error) {
      throw new ExpectedError<ClientEECode>('approveEstimation', { cause: error });
    }

    return {
      allowanceAmount: flowData.allowanceAmount,
      inputAmountInWei: flowData.inputAmountInWei,
      estimation: estimated,
      eventConfig: flowData.eventConfig,
      flow: AllowanceFlow.MakeApprove,
      flowType: 'brrr',
      forPermit2: flowData.forPermit2,
      meta: flowData.meta,
      spenderAddress: flowData.spenderAddress,
      swapTarget: flowData.swapTarget,
      swapTargetPriceUSD: flowData.swapTargetPriceUSD,
      inputToken: flowData.inputToken,
      transferData: flowData.transferData,
      tokensReceiver: flowData.tokensReceiver
    };
  }

  protected async getAllowanceFlowForBaseAsset(
    publicClient: PublicClient,
    senderAddress: Address,
    flowData: BaseBRRRFlow
  ): Promise<ExecuteWithPermitFlow<BaseBRRRFlow> | ExecuteWithApproveFlow<BaseBRRRFlow>> {
    let addressCheckData: GetApprovalReturn;
    try {
      addressCheckData = await this.getAddressCheckData(
        senderAddress,
        flowData.inputToken,
        flowData.inputAmountInWei
      );
    } catch (error) {
      throw new ExpectedError<ClientEECode>('brrrAddressCheck', {
        cause: error,
        payload: { scope: 'flowForBaseAsset' }
      });
    }

    return this.estimateBuyTokenWithPermit({
      flowData: {
        addressCheckData,
        allowedAmount: flowData.inputAmountInWei,
        inputAmountInWei: flowData.inputAmountInWei,
        callData: '0x0',
        contractAddress: getNetworkAddress(flowData.inputToken.network, 'BRRR_PROXY_ADDRESS'),
        eventConfig: flowData.eventConfig,
        expectedMinimumAmount: mapTransferDataToExpectedMinimumAmountV2(
          flowData.inputToken.priceUSD,
          formatUnits(flowData.inputAmountInWei, flowData.inputToken.decimals),
          flowData.swapTarget,
          flowData.swapTargetPriceUSD,
          flowData.transferData
        ),
        expiresAt: undefined,
        flow: AllowanceFlow.EstimateWithPermit,
        flowType: 'brrr',
        meta: flowData.meta,
        swapTarget: flowData.swapTarget,
        swapTargetPriceUSD: flowData.swapTargetPriceUSD,
        inputToken: flowData.inputToken,
        transferData: flowData.transferData,
        transferDataHex: mapTransferDataToHex(flowData.transferData),
        transferDataRaw: flowData.transferData?.rawResponse,
        transferDataValue: mapTransferDataToValue(flowData.transferData),
        tokensReceiver: flowData.tokensReceiver
      },
      publicClient,
      senderAddress
    });
  }

  protected getAllowanceFlowForPermit(flowData: BaseBRRRFlow): MakePermitFlow<BaseBRRRFlow> {
    const spenderAddress = getNetworkAddress(flowData.inputToken.network, 'BRRR_PROXY_ADDRESS');

    const allowanceAmountWithBuffer =
      (flowData.inputAmountInWei * (100n + this.permitBufferSizePercent)) / 100n;
    addSentryBreadcrumb({
      level: 'debug',
      category: this.sentryCategoryPrefix,
      message: `Add ${this.permitBufferSizePercent}% buffer to permit allowance amount. Was ${flowData.inputAmountInWei} now ${allowanceAmountWithBuffer}`,
      data: {
        allowanceAmount: flowData.inputAmountInWei,
        allowanceAmountWithBuffer
      }
    });

    return {
      allowanceAmount: allowanceAmountWithBuffer,
      inputAmountInWei: flowData.inputAmountInWei,
      eventConfig: flowData.eventConfig,
      flow: AllowanceFlow.MakePermit,
      flowType: 'brrr',
      meta: flowData.meta,
      spenderAddress,
      swapTarget: flowData.swapTarget,
      swapTargetPriceUSD: flowData.swapTargetPriceUSD,
      inputToken: flowData.inputToken,
      transferData: flowData.transferData,
      tokensReceiver: flowData.tokensReceiver
    };
  }

  protected async getAllowanceFlowForPermit2(
    publicClient: PublicClient,
    senderAddress: Address,
    flowData: BaseBRRRFlow
  ): Promise<
    | MakeApproveFlow<BaseBRRRFlow>
    | MakePermit2Flow<BaseBRRRFlow>
    | ExecuteWithPermit2Flow<BaseBRRRFlow>
  > {
    const spenderAddress =
      this.permit2Service.getPermit2Address(flowData.inputToken.network) ?? '0x0';
    const currentAllowance = await allowance({
      network: flowData.inputToken.network,
      onCallData: flowData.eventConfig?.onCallData,
      owner: senderAddress,
      publicClient,
      spender: spenderAddress,
      tokenAddress: flowData.inputToken.address
    });

    if (!isSufficientAllowance(currentAllowance, flowData.inputAmountInWei)) {
      addSentryBreadcrumb({
        level: 'warning',
        category: `${this.sentryCategoryPrefix}.getAllowanceFlowForPermit2`,
        message: `Current allowance is insufficient: expected at least ${flowData.inputAmountInWei} got ${currentAllowance}`
      });

      if (
        currentAllowance !== 0n &&
        requiresZeroAllowanceBeforeApprove(flowData.inputToken.address, flowData.inputToken.network)
      ) {
        addSentryBreadcrumb({
          level: 'info',
          category: `${this.sentryCategoryPrefix}.getAllowanceFlowForPermit2`,
          message: 'Token requires allowance reset. Estimate "reset" approve',
          data: {
            currentAllowance,
            token: flowData.inputToken
          }
        });

        return this.estimateApprove({
          publicClient,
          senderAddress,
          flowData: {
            allowanceAmount: 0n,
            inputAmountInWei: flowData.inputAmountInWei,
            eventConfig: flowData.eventConfig,
            flow: AllowanceFlow.EstimateApprove,
            flowType: 'brrr',
            forPermit2: true,
            meta: flowData.meta,
            spenderAddress,
            swapTarget: flowData.swapTarget,
            swapTargetPriceUSD: flowData.swapTargetPriceUSD,
            inputToken: flowData.inputToken,
            transferData: flowData.transferData,
            tokensReceiver: flowData.tokensReceiver
          }
        });
      }

      addSentryBreadcrumb({
        level: 'debug',
        category: `${this.sentryCategoryPrefix}.getAllowanceFlowForPermit2`,
        message: `Estimating approve for ${getMaxPermit2Allowance(flowData.inputToken)}`
      });

      return this.estimateApprove({
        flowData: {
          allowanceAmount: getMaxPermit2Allowance(flowData.inputToken),
          inputAmountInWei: flowData.inputAmountInWei,
          eventConfig: flowData.eventConfig,
          flow: AllowanceFlow.EstimateApprove,
          flowType: 'brrr',
          forPermit2: true,
          meta: flowData.meta,
          spenderAddress,
          swapTarget: flowData.swapTarget,
          swapTargetPriceUSD: flowData.swapTargetPriceUSD,
          inputToken: flowData.inputToken,
          transferData: flowData.transferData,
          tokensReceiver: flowData.tokensReceiver
        },
        publicClient,
        senderAddress
      });
    }

    return {
      allowanceAmount: flowData.inputAmountInWei,
      inputAmountInWei: flowData.inputAmountInWei,
      eventConfig: flowData.eventConfig,
      flow: AllowanceFlow.MakePermit2,
      flowType: 'brrr',
      meta: flowData.meta,
      spenderAddress,
      swapTarget: flowData.swapTarget,
      swapTargetPriceUSD: flowData.swapTargetPriceUSD,
      inputToken: flowData.inputToken,
      transferData: flowData.transferData,
      tokensReceiver: flowData.tokensReceiver
    };
  }

  protected async getAllowanceFlowForApprove<FD extends BaseBRRRFlow>(
    publicClient: PublicClient,
    senderAddress: Address,
    flowData: FD
  ): Promise<MakeApproveFlow<BaseBRRRFlow> | ExecuteWithApproveFlow<BaseBRRRFlow>> {
    const spenderAddress = getNetworkAddress(flowData.inputToken.network, 'BRRR_PROXY_ADDRESS');
    const currentAllowance = await allowance({
      network: flowData.inputToken.network,
      onCallData: flowData.eventConfig?.onCallData,
      owner: senderAddress,
      publicClient,
      spender: spenderAddress,
      tokenAddress: flowData.inputToken.address
    });
    addSentryBreadcrumb({
      level: 'debug',
      category: `${this.sentryCategoryPrefix}.getAllowanceFlowForApprove`,
      message: `Current allowance: ${currentAllowance}`
    });

    const maxValidAllowance =
      (flowData.inputAmountInWei * (100n + this.approveMaxDeltaPercent)) / 100n;
    // allowance to request in case of invalid / insufficient allowance
    //
    // request the following approval to be made with a safe margin (in case of failed estimation / expired checks)
    const amountInWeiWithThreshold = (flowData.inputAmountInWei + maxValidAllowance) / 2n;

    // allowance is insufficient
    if (!isSufficientAllowance(currentAllowance, flowData.inputAmountInWei)) {
      addSentryBreadcrumb({
        level: 'info',
        category: `${this.sentryCategoryPrefix}.getAllowanceFlowForApprove`,
        message: `Current allowance is insufficient: expected at least ${flowData.inputAmountInWei}, got ${currentAllowance}`,
        data: {
          currentAllowance
        }
      });

      if (
        currentAllowance !== 0n &&
        requiresZeroAllowanceBeforeApprove(flowData.inputToken.address, flowData.inputToken.network)
      ) {
        // token is USDT-like, requires reset. Estimate approve, execute one
        addSentryBreadcrumb({
          level: 'info',
          category: `${this.sentryCategoryPrefix}.getAllowanceFlowForApprove`,
          message: `Current allowance is not 0 (${currentAllowance}). Token requires allowance reset. Estimate "reset" approve`,
          data: {
            currentAllowance,
            network: flowData.inputToken.network,
            tokenAddress: flowData.inputToken.address
          }
        });

        return this.estimateApprove({
          flowData: {
            allowanceAmount: 0n,
            inputAmountInWei: flowData.inputAmountInWei,
            eventConfig: flowData.eventConfig,
            flow: AllowanceFlow.EstimateApprove,
            flowType: 'brrr',
            forPermit2: false,
            meta: flowData.meta,
            spenderAddress,
            swapTarget: flowData.swapTarget,
            swapTargetPriceUSD: flowData.swapTargetPriceUSD,
            inputToken: flowData.inputToken,
            transferData: flowData.transferData,
            tokensReceiver: flowData.tokensReceiver
          },
          publicClient,
          senderAddress
        });
      }

      // token does not require a reset or current allowance is 0. Estimate final approve, execute one
      return this.estimateApprove({
        flowData: {
          allowanceAmount: amountInWeiWithThreshold,
          inputAmountInWei: flowData.inputAmountInWei,
          eventConfig: flowData.eventConfig,
          flow: AllowanceFlow.EstimateApprove,
          flowType: 'brrr',
          forPermit2: false,
          meta: flowData.meta,
          spenderAddress,
          swapTarget: flowData.swapTarget,
          swapTargetPriceUSD: flowData.swapTargetPriceUSD,
          inputToken: flowData.inputToken,
          transferData: flowData.transferData,
          tokensReceiver: flowData.tokensReceiver
        },
        senderAddress,
        publicClient
      });
    }

    // allowance is invalid (greater than contract allows it to be)
    if (
      !isValidAllowanceWithBoundaries(
        currentAllowance,
        flowData.inputAmountInWei,
        this.approveMaxDeltaPercent
      )
    ) {
      addSentryBreadcrumb({
        level: 'info',
        category: `${this.sentryCategoryPrefix}.getAllowanceFlowForApprove`,
        message: `Current allowance is invalid: expected currentAllowance < ${
          (flowData.inputAmountInWei * (100n + this.approveMaxDeltaPercent)) / 100n
        }, got ${currentAllowance}`,
        data: {
          currentAllowance
        }
      });

      if (
        currentAllowance !== 0n &&
        requiresZeroAllowanceBeforeApprove(flowData.inputToken.address, flowData.inputToken.network)
      ) {
        // token is USDT-like, requires reset. Estimate approve, execute one
        addSentryBreadcrumb({
          level: 'info',
          category: `${this.sentryCategoryPrefix}.getAllowanceFlowForApprove`,
          message: `Current allowance is not 0 (${currentAllowance}). Token requires allowance reset. Estimate "reset" approve`,
          data: {
            currentAllowance,
            network: flowData.inputToken.network,
            tokenAddress: flowData.inputToken.address
          }
        });

        return this.estimateApprove({
          flowData: {
            allowanceAmount: 0n,
            inputAmountInWei: flowData.inputAmountInWei,
            eventConfig: flowData.eventConfig,
            flow: AllowanceFlow.EstimateApprove,
            flowType: 'brrr',
            forPermit2: false,
            meta: flowData.meta,
            spenderAddress,
            swapTarget: flowData.swapTarget,
            swapTargetPriceUSD: flowData.swapTargetPriceUSD,
            inputToken: flowData.inputToken,
            transferData: flowData.transferData,
            tokensReceiver: flowData.tokensReceiver
          },
          publicClient,
          senderAddress
        });
      }

      // token does not require a reset or current allowance is 0. Estimate final approve, execute one
      return this.estimateApprove({
        flowData: {
          allowanceAmount: amountInWeiWithThreshold,
          inputAmountInWei: flowData.inputAmountInWei,
          eventConfig: flowData.eventConfig,
          flow: AllowanceFlow.EstimateApprove,
          flowType: 'brrr',
          forPermit2: false,
          meta: flowData.meta,
          spenderAddress,
          swapTarget: flowData.swapTarget,
          swapTargetPriceUSD: flowData.swapTargetPriceUSD,
          inputToken: flowData.inputToken,
          transferData: flowData.transferData,
          tokensReceiver: flowData.tokensReceiver
        },
        senderAddress,
        publicClient
      });
    }

    // rather expensive check, therefore not triggered unless needed
    let approvalData;
    try {
      approvalData = await callActionWithRetries(
        () =>
          this.approvalService.getBuyBrrrSignature(
            flowData.inputAmountInWei,
            senderAddress,
            flowData.inputToken
          ),
        this.retryCount,
        undefined,
        (error) => !('flow' in flowData) && error instanceof APIError && error.code === 'NOT_FOUND',
        (retryCount) => ~~(200 << retryCount)
      );
    } catch (error) {
      if (!(error instanceof APIError && error.code === 'NOT_FOUND')) {
        addSentryBreadcrumb({
          level: 'warning',
          category: `${this.sentryCategoryPrefix}.getAllowanceFlowForApprove`,
          data: { error },
          message: 'Failed to get approval data from backend'
        });
        throw new ExpectedError<ClientEECode>('brrrApprove', {
          cause: error,
          payload: { scope: 'approvecheck' }
        });
      }

      addSentryBreadcrumb({
        level: 'debug',
        category: `${this.sentryCategoryPrefix}.getAllowanceFlowForApprove`,
        message: 'Approval not found. Estimate approve',
        data: { error }
      });

      if (
        currentAllowance !== 0n &&
        requiresZeroAllowanceBeforeApprove(flowData.inputToken.address, flowData.inputToken.network)
      ) {
        // token is USDT-like, requires reset. Estimate approve, execute one
        addSentryBreadcrumb({
          level: 'info',
          category: `${this.sentryCategoryPrefix}.getAllowanceFlowForApprove`,
          message: `Current allowance is not 0 (${currentAllowance}). Token requires allowance reset. Estimate "reset" approve`,
          data: {
            currentAllowance,
            network: flowData.inputToken.network,
            tokenAddress: flowData.inputToken.address
          }
        });

        return this.estimateApprove({
          flowData: {
            allowanceAmount: 0n,
            inputAmountInWei: flowData.inputAmountInWei,
            eventConfig: flowData.eventConfig,
            flow: AllowanceFlow.EstimateApprove,
            flowType: 'brrr',
            forPermit2: false,
            meta: flowData.meta,
            spenderAddress,
            swapTarget: flowData.swapTarget,
            swapTargetPriceUSD: flowData.swapTargetPriceUSD,
            inputToken: flowData.inputToken,
            transferData: flowData.transferData,
            tokensReceiver: flowData.tokensReceiver
          },
          publicClient,
          senderAddress
        });
      }

      // token does not require a reset or current allowance is 0. Estimate final approve, execute one
      return this.estimateApprove({
        flowData: {
          allowanceAmount: amountInWeiWithThreshold,
          inputAmountInWei: flowData.inputAmountInWei,
          eventConfig: flowData.eventConfig,
          flow: AllowanceFlow.EstimateApprove,
          flowType: 'brrr',
          forPermit2: false,
          meta: flowData.meta,
          spenderAddress,
          swapTarget: flowData.swapTarget,
          swapTargetPriceUSD: flowData.swapTargetPriceUSD,
          inputToken: flowData.inputToken,
          transferData: flowData.transferData,
          tokensReceiver: flowData.tokensReceiver
        },
        senderAddress,
        publicClient
      });
    }

    return this.estimateBuyTokenWithApprove({
      publicClient,
      senderAddress,
      flowData: {
        allowanceAmount: flowData.inputAmountInWei,
        inputAmountInWei: flowData.inputAmountInWei,
        approvalData,
        contractAddress: getNetworkAddress(flowData.inputToken.network, 'BRRR_PROXY_ADDRESS'),
        eventConfig: flowData.eventConfig,
        expectedMinimumAmount: mapTransferDataToExpectedMinimumAmountV2(
          flowData.inputToken.priceUSD,
          formatUnits(flowData.inputAmountInWei, flowData.inputToken.decimals),
          flowData.swapTarget,
          flowData.swapTargetPriceUSD,
          flowData.transferData
        ),
        flow: AllowanceFlow.EstimateWithApprove,
        flowType: 'brrr',
        meta: flowData.meta,
        spenderAddress,
        swapTarget: flowData.swapTarget,
        swapTargetPriceUSD: flowData.swapTargetPriceUSD,
        inputToken: flowData.inputToken,
        transferData: flowData.transferData,
        transferDataHex: mapTransferDataToHex(flowData.transferData),
        transferDataRaw: flowData.transferData?.rawResponse,
        tokensReceiver: flowData.tokensReceiver
      }
    });
  }

  protected async tryReuseOldPermitSig(
    flowData: ExecuteWithPermitFlow<BaseBRRRFlow>
  ): Promise<TryReuseOldPermitSigReturn> {
    if (flowData.inputAmountInWei > flowData.allowedAmount) {
      addSentryBreadcrumb({
        level: 'warning',
        category: this.sentryCategoryPrefix,
        message:
          'Received flow data with permit1 signature already present. Allowed amount is less than requested amount. Request new signature',
        data: {
          newAmount: flowData.inputAmountInWei,
          allowedAmount: flowData.allowedAmount
        }
      });

      return { ok: false };
    }

    addSentryBreadcrumb({
      level: 'info',
      category: this.sentryCategoryPrefix,
      message:
        'Received flow data with permit1 signature already present. Allowed amount is still greater than or equal to requested amount. Reuse signature',
      data: {
        newAmount: flowData.inputAmountInWei,
        allowedAmount: flowData.allowedAmount
      }
    });

    try {
      this.assertPermitNotExpired(flowData.expiresAt);
      addSentryBreadcrumb({
        level: 'debug',
        category: this.sentryCategoryPrefix,
        message: 'Permit signature is still valid'
      });
    } catch {
      addSentryBreadcrumb({
        level: 'warning',
        category: this.sentryCategoryPrefix,
        message: 'Present signature is expired. Request new signature'
      });
      return { ok: false };
    }

    return { ok: true, newFlow: flowData };
  }

  protected assertPermitNotExpired(expiresAt?: number): void {
    const now = dayjs().unix();
    if (expiresAt !== undefined && now > expiresAt) {
      addSentryBreadcrumb({
        level: 'warning',
        category: `${this.sentryCategoryPrefix}.assertPermitNotExpired`,
        message: 'Assertion failed (permit not expired): tx may fail',
        data: {
          expiresAt,
          now
        }
      });

      throw new ExpectedError<ClientEECode>('brrrPermitExpired', {
        payload: { expiresAt, now }
      });
    }
  }

  protected async assertAllowanceValid(
    senderAddress: Address,
    spenderAddress: Address,
    publicClient: PublicClient,
    token: Token,
    need: bigint,
    strictThreshold: boolean
  ): Promise<void> {
    let have;
    try {
      have = await allowance({
        network: token.network,
        owner: senderAddress,
        publicClient,
        spender: spenderAddress,
        tokenAddress: token.address
      });
    } catch (error) {
      throw new HHError('Failed to check current allowance after successful approve', {
        cause: error
      });
    }

    addSentryBreadcrumb({
      level: 'debug',
      category: `${this.sentryCategoryPrefix}.assertAllowanceValid`,
      message: `Allowance after approve: ${have}`
    });

    if (need === 0n) {
      // token with approve(n) -> approve(0) -> approve(m) requirement
      if (need !== have) {
        addSentryBreadcrumb({
          level: 'warning',
          category: `${this.sentryCategoryPrefix}.assertAllowanceValid`,
          message: `Required approve to 0, current allowance is ${have}`
        });

        throw new AllowanceMismatchError(`Expected ${need}, got ${have}`, 'requiresZeroAllowance', {
          payload: {
            expected: need,
            got: have
          }
        });
      }

      return;
    }

    if (!isSufficientAllowance(have, need)) {
      addSentryBreadcrumb({
        level: 'warning',
        category: `${this.sentryCategoryPrefix}.assertAllowanceValid`,
        message: `Required allowance to be at least ${need}, current allowance is ${have}`
      });

      throw new AllowanceMismatchError(
        `Expected at least ${need}, got ${have}`,
        'insufficientAllowance',
        {
          payload: {
            expected: need,
            got: have
          }
        }
      );
    }

    if (
      strictThreshold &&
      !isValidAllowanceWithBoundaries(have, need, this.approveMaxDeltaPercent)
    ) {
      const upperBoundary = (need * (100n + this.approveMaxDeltaPercent)) / 100n;

      addSentryBreadcrumb({
        level: 'warning',
        category: `${this.sentryCategoryPrefix}.assertAllowanceValid`,
        message: `Required allowance to be less than ${upperBoundary}, current allowance is ${have}`
      });

      throw new AllowanceMismatchError(
        `Allowance is higher than delta ${this.approveMaxDeltaPercent}% threshold: expected less than ${upperBoundary}, got ${have}`,
        'thresholdCheckFailed',
        {
          payload: {
            expected: need,
            got: have,
            thresholdPercent: this.approveMaxDeltaPercent
          }
        }
      );
    }
  }

  private assertAddressCheckNotExpired(performed: number): void {
    const ttl = this.backendCheckTtl;
    const now = dayjs().unix();

    if (now > performed + ttl) {
      addSentryBreadcrumb({
        level: 'warning',
        category: `${this.sentryCategoryPrefix}.assertAddressCheckNotExpired`,
        message: 'Assertion failed (address check): tx may fail',
        data: {
          expiresAt: performed + ttl,
          now,
          performed,
          ttl
        }
      });
      throw new ExpectedError<ClientEECode>('brrrBackendCheckExpired', {
        payload: { expiresAt: performed + ttl, now, scope: 'addresscheck' }
      });
    }
  }

  protected assertApproveCheckNotExpired(performed: number, network: Network): void {
    const ttl = this.backendCheckTtl;
    const now = dayjs().unix();

    let checkSource;
    if (network === Network.zksyncera) {
      // due to implementation of backend check on zkSync chain,
      //  it's required to add 1h to the "performed" date
      checkSource = performed + 3600;
    } else {
      checkSource = performed;
    }

    if (now > checkSource + ttl) {
      addSentryBreadcrumb({
        level: 'warning',
        category: `${this.sentryCategoryPrefix}.assertApproveCheckNotExpired`,
        message: 'Assertion failed (approve check): tx may fail',
        data: {
          expiresAt: performed + ttl,
          now,
          performed,
          ttl
        }
      });

      throw new ExpectedError<ClientEECode>('brrrBackendCheckExpired', {
        payload: { expiresAt: checkSource + ttl, now, scope: 'approvecheck' }
      });
    }
  }

  protected formatBuyTokenTrustedContractParameters(
    params: FormatBuyTokenWithApproveContractParametersFlowData
  ): ContractFunctionParameters<
    typeof brrrProxyAbi,
    'nonpayable',
    'mintWithSig',
    [Address, bigint, bigint, Hex, bigint, Hex, Hex]
  > {
    assert(params.approvalData.specialHash !== undefined, 'Missing mintHash');

    return {
      address: params.contractAddress,
      abi: brrrProxyAbi,
      functionName: 'mintWithSig',
      args: [
        substituteAssetAddressIfNeeded(params.inputToken.address, params.inputToken.network),
        params.inputAmountInWei,
        BigInt(params.approvalData.timestamp),
        params.approvalData.data,
        params.expectedMinimumAmount,
        params.transferDataHex,
        params.approvalData.specialHash
      ]
    } as const satisfies ContractFunctionParameters;
  }

  protected formatBuyTokenWithPermitContractParameters(
    params: FormatBuyTokenWithPermitContractParametersFlowData
  ): ContractFunctionParameters<
    typeof brrrProxyAbi,
    'payable',
    'mintWithSigPermit',
    [Address, bigint, bigint, Hex, Hex, bigint, Hex, Hex]
  > {
    assert(params.addressCheckData.specialHash !== undefined, 'Missing mintHash');

    return {
      address: params.contractAddress,
      abi: brrrProxyAbi,
      functionName: 'mintWithSigPermit',
      args: [
        substituteAssetAddressIfNeeded(params.inputToken.address, params.inputToken.network),
        params.inputAmountInWei,
        BigInt(params.addressCheckData.timestamp),
        params.addressCheckData.data,
        params.callData,
        params.expectedMinimumAmount,
        params.transferDataHex,
        params.addressCheckData.specialHash
      ],
      value: params.transferDataValue
    } as const satisfies ContractFunctionParameters;
  }

  protected formatBuyTokenWithPermit2ContractParameters(
    params: FormatBuyTokenWithPermit2ContractParametersFlowData
  ): ContractFunctionParameters<
    typeof brrrProxyAbi,
    'payable',
    'mintWithSigPermit2',
    [
      { permitted: { token: `0x${string}`; amount: bigint }; nonce: bigint; deadline: bigint },
      bigint,
      Hex,
      Hex,
      bigint,
      Hex,
      Hex
    ]
  > {
    assert(params.addressCheckData.specialHash !== undefined, 'Missing mintHash');

    return {
      address: params.contractAddress,
      abi: brrrProxyAbi,
      functionName: 'mintWithSigPermit2',
      args: [
        params.permitData,
        BigInt(params.addressCheckData.timestamp),
        params.addressCheckData.data,
        params.permitSignature,
        params.expectedMinimumAmount,
        params.transferDataHex,
        params.addressCheckData.specialHash
      ]
    } as const satisfies ContractFunctionParameters;
  }

  private async getAddressCheckData(
    senderAddress: Address,
    token: Token,
    amountInWei: bigint
  ): Promise<GetApprovalReturn> {
    return this.approvalService.getBuyBrrrSignature(amountInWei, senderAddress, token);
  }
}
