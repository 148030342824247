import { ref } from 'vue';

import {
  createFilterWrapper,
  throttleFilter,
  useEventListener,
  useThrottleFn,
  useTimeoutFn,
  type WindowEventName
} from '@vueuse/core';

import { useAuthAPIService } from '@/composables/services/useAuthAPIService';
import { useAccount } from '@/composables/useAccount';
import { useTimeUnix } from '@/composables/useTimeUnix';
import {
  HEARTBEAT_CHECK_INTERVAL,
  HEARTBEAT_SEND_THROTTLE_TIMEOUT,
  SESSION_LIVE_TIME
} from '@/references/constants';

export enum SessionState {
  Starting = 'STARTING',
  Active = 'ACTIVE',
  Stopping = 'STOPPING',
  Stopped = 'STOPPED'
}
const sessionState = ref<SessionState>(SessionState.Stopped);

const sendHeartbeatEvent = useThrottleFn(async () => {
  const { isAuthed } = useAccount();
  if (!isAuthed.value) {
    return;
  }

  const authAPIService = useAuthAPIService();
  await authAPIService.sendHeartbeat();
}, HEARTBEAT_SEND_THROTTLE_TIMEOUT);

const { tsNow: now } = useTimeUnix();
const previousLastActive = ref(now.value);
function checkSession(): Promise<void> {
  if (now.value - previousLastActive.value > SESSION_LIVE_TIME) {
    return stopSession();
  }

  previousLastActive.value = now.value;
  sessionLogoutTimeout.stop();
  sessionLogoutTimeout.start();
  return sendHeartbeatEvent();
}

const eventsToListen: Array<WindowEventName> = [
  'mousemove',
  'mousedown',
  'resize',
  'keydown',
  'touchstart',
  'wheel'
];
const onEvent = createFilterWrapper(throttleFilter(HEARTBEAT_CHECK_INTERVAL), checkSession);
eventsToListen.forEach((event) => useEventListener(window, event, onEvent, { passive: true }));
useEventListener(document, 'visibilitychange', () => {
  if (!document.hidden) {
    onEvent();
  }
});

const sessionLogoutTimeout = useTimeoutFn(() => stopSession(), SESSION_LIVE_TIME, {
  immediate: false
});

async function startSession() {
  if (sessionState.value !== SessionState.Stopped) {
    return;
  }

  sessionState.value = SessionState.Starting;
  previousLastActive.value = now.value;
  await checkSession();
  sessionLogoutTimeout.start();
  sessionState.value = SessionState.Active;
}

async function stopSession(): Promise<void> {
  if (sessionState.value !== SessionState.Active) {
    return;
  }

  sessionState.value = SessionState.Stopping;
  sessionLogoutTimeout.stop();
  const { logout, isAuthed } = useAccount();
  try {
    if (!isAuthed.value) {
      return;
    }

    await logout();
  } catch {
    // noop
  }
  sessionState.value = SessionState.Stopped;
}

export function useSessions() {
  return {
    previousLastActive,
    sessionState,
    startSession,
    stopSession
  };
}
