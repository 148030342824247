export type SolanaNetworkConfig = {
  networkInfo: SolanaNetworkInfo;
  addresses: SolanaNetworkAddresses;
  paymentToken: SolanaToken;
  brrrToken: SolanaToken;
};

export type SolanaNetworkInfo = {
  cluster: 'devnet' | 'testnet' | 'mainnet-beta';
  name: string;
  displayedName: string;
  httpRpcURL: string;
  wsRpcURL?: string;
  explorerURL: string;
  iconURL: string;
};

export type SolanaAddressMapKey = 'RECIPIENT_ADDRESS';

export type SolanaNetworkAddresses = Record<SolanaAddressMapKey, string>;

export type SolanaToken = {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  iconURL: string;
};

let config: SolanaNetworkConfig | undefined;

const setConfig = (cfg: SolanaNetworkConfig): void => {
  config = cfg;
};

const getConfig = (): SolanaNetworkConfig | undefined => {
  return config;
};

export { setConfig, getConfig };
