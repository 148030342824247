import {
  type Address,
  encodeAbiParameters,
  type Hex,
  keccak256,
  type PublicClient,
  toBytes
} from 'viem';

import { addSentryBreadcrumb } from '@/logs/sentry';
import type { WalletClientAdapter } from '@/references/onchain/adapters';
import { buildPermitCallData, getTokenNonce } from '@/references/onchain/permit/build';
import type { PermitParams } from '@/references/onchain/permit/types';

import { erc20ABI } from '../abi/erc20';
import { erc2612ABI } from '../abi/erc2612';

/**
 * A class representing basic functions for EIP-2612 Permit
 */
export class PermitOnChainService {
  protected readonly sentryCategoryPrefix = 'permit.on-chain-service';

  public async buildPermitData(
    senderAddress: Address,
    publicClient: PublicClient,
    walletClientAdapter: WalletClientAdapter,
    permitType: string,
    tokenAddress: Address,
    contractAddress: Address,
    chainId: number,
    value: string,
    deadline: number,
    version = '1'
  ): Promise<Hex> {
    const erc20Name = await this.getErc20Name(senderAddress, publicClient, tokenAddress);
    addSentryBreadcrumb({
      level: 'info',
      category: this.sentryCategoryPrefix,
      message: 'get erc20 name from token contract',
      data: {
        senderAddress,
        tokenAddress,
        erc20Name
      }
    });

    if (permitType !== 'erc2612') {
      throw new Error(`Unsupported permit type: ${permitType}`);
    }

    const domainSeparatorFromContract = await this.getDomainSeparator(
      senderAddress,
      publicClient,
      tokenAddress
    );

    const constructedDomainSeparatorWithoutVersion =
      await this.constructDomainSeparatorWithoutVersion(erc20Name, version, chainId, tokenAddress);

    const constructedDomainSeparatorWithSaltAndWithoutChainId =
      await this.constructDomainSeparatorSaltAndWithoutChainId(
        erc20Name,
        version,
        chainId,
        tokenAddress
      );

    addSentryBreadcrumb({
      level: 'info',
      category: this.sentryCategoryPrefix,
      message: 'domain separators',
      data: {
        domainSeparatorFromContract,
        constructedDomainSeparatorWithoutVersion,
        constructedDomainSeparatorWithSaltAndWithoutChainId
      }
    });

    const nonce = await getTokenNonce(senderAddress, publicClient, tokenAddress);
    addSentryBreadcrumb({
      level: 'info',
      category: this.sentryCategoryPrefix,
      message: 'get nonce from token contract',
      data: {
        senderAddress,
        tokenAddress,
        nonce
      }
    });

    const permitParams: PermitParams = {
      owner: senderAddress,
      spender: contractAddress,
      value,
      nonce,
      deadline
    };

    return buildPermitCallData(
      publicClient,
      walletClientAdapter,
      permitParams,
      chainId,
      erc20Name,
      tokenAddress,
      version
    );
  }

  async constructDomainSeparatorWithoutVersion(
    tokenName: string,
    version: string,
    chainId: number,
    tokenAddress: Address
  ): Promise<string> {
    return encodeAbiParameters(
      [
        { type: 'bytes32' },
        { type: 'bytes32' },
        { type: 'bytes32' },
        { type: 'uint256' },
        { type: 'address' }
      ],
      [
        keccak256(
          toBytes(
            'EIP712Domain(string name,string version,uint256 chainId,address verifyingContract)'
          )
        ),
        keccak256(toBytes(tokenName)),
        keccak256(toBytes(version)),
        BigInt(chainId),
        tokenAddress
      ]
    );
  }

  async constructDomainSeparatorSaltAndWithoutChainId(
    tokenName: string,
    version: string,
    chainId: number,
    tokenAddress: Address
  ): Promise<string> {
    return encodeAbiParameters(
      [
        { type: 'bytes32' },
        { type: 'bytes32' },
        { type: 'bytes32' },
        { type: 'address' },
        { type: 'bytes32' }
      ],
      [
        keccak256(
          toBytes('EIP712Domain(string name,string version,address verifyingContract,bytes32 salt)')
        ),
        keccak256(toBytes(tokenName)),
        keccak256(toBytes(version)),
        tokenAddress,
        encodeAbiParameters([{ type: 'uint256' }], [BigInt(chainId)])
      ]
    );
  }

  protected async getErc20Name(
    senderAddress: Address,
    publicClient: PublicClient,
    tokenAddress: Address
  ): Promise<string> {
    return publicClient.readContract({
      address: tokenAddress,
      account: senderAddress,
      functionName: 'name',
      abi: erc20ABI
    });
  }

  protected async getDomainSeparator(
    senderAddress: Address,
    publicClient: PublicClient,
    tokenAddress: Address
  ): Promise<Hex> {
    return publicClient.readContract({
      address: tokenAddress,
      account: senderAddress,
      functionName: 'DOMAIN_SEPARATOR',
      abi: erc2612ABI
    });
  }
}
