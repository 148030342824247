import { readonly, ref, watch } from 'vue';

import { useDocumentVisibility, useIntervalFn } from '@vueuse/core';
import dayjs from 'dayjs';

import { SECOND } from '@/references/constants';

const visibility = useDocumentVisibility();

const tsNow = ref(dayjs().unix());

const { pause, resume } = useIntervalFn(
  () => {
    tsNow.value = dayjs().unix();
  },
  SECOND,
  { immediate: true, immediateCallback: true }
);

watch(visibility, (nv) => {
  if (nv === 'hidden') {
    pause();
    return;
  }

  resume();
});

export const useTimeUnix = () => {
  return {
    tsNow: readonly(tsNow)
  };
};
