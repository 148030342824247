import type { Address, PublicClient } from 'viem';

import { LruMap } from '@/references/LruMap';
import { deriveChainId } from '@/references/onchain/adapters';

const cache = new LruMap<string, boolean>(16);

export const isContract = async (
  publicClient: PublicClient,
  address: Address
): Promise<boolean> => {
  const chainId = await deriveChainId(publicClient);
  const cacheKey = `${chainId}:${address}`;

  const cached = cache.get(cacheKey);
  if (cached !== undefined) {
    return cached;
  }

  const code = await publicClient.getBytecode({
    address
  });

  const res = code !== undefined && code !== '0x';
  if (chainId !== undefined) {
    cache.set(cacheKey, res);
  }
  return res;
};
