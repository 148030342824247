import { computed, ref } from 'vue';

import {
  createTransferCheckedInstruction,
  getAccount,
  getAssociatedTokenAddress
} from '@solana/spl-token';
import type { Adapter, WalletAdapterProps } from '@solana/wallet-adapter-base';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import { useIntervalFn } from '@vueuse/core';
import BigNumber from 'bignumber.js';
import { initWallet, useWallet } from 'solana-wallets-vue';
import { formatUnits, parseUnits } from 'viem';

import { filterDefined } from '@/helpers/arrays';
import { assert } from '@/helpers/assert';
import { createLogger } from '@/logs/datadog';
import { addSentryBreadcrumb, captureSentryException } from '@/logs/sentry';
import { SECOND } from '@/references/constants';
import { ExpectedError } from '@/references/ExpectedError';
import { getConfig, type SolanaNetworkInfo, type SolanaToken } from '@/references/solanaConfig';

import type { ClientEECode } from './useErrorModal';

export function init() {
  const solanaConfig = getConfig();
  assert(solanaConfig !== undefined, 'Config must be defined');

  initWallet({
    cluster: solanaConfig.networkInfo.cluster
  });
}

async function sendSPLToken(
  fromWallet: Adapter,
  connection: Connection,
  paymentToken: SolanaToken,
  amount: string,
  recipient: string,
  sendTransaction: WalletAdapterProps['sendTransaction']
): Promise<string> {
  const logger = createLogger('sender');

  const mintToken = new PublicKey(paymentToken.address);
  const recipientAddress = new PublicKey(recipient);

  logger.info(`current public key: ${fromWallet.publicKey}`);
  logger.info(`mintToken public key: ${mintToken}`);
  logger.info(`recipient public key: ${recipientAddress}`);

  const associatedTokenFrom = await getAssociatedTokenAddress(mintToken, fromWallet.publicKey!);

  logger.info(`associatedToken from ${associatedTokenFrom}`);

  const fromAccount = await getAccount(connection, associatedTokenFrom);

  logger.info(`fromAccount address: ${fromAccount.address}`);

  const associatedTokenTo = await getAssociatedTokenAddress(mintToken, recipientAddress);

  logger.info(`associatedToken to ${associatedTokenTo}`);

  const blockHash = await connection.getLatestBlockhash();

  const transaction = new Transaction({
    feePayer: fromWallet.publicKey,
    blockhash: blockHash.blockhash,
    lastValidBlockHeight: blockHash.lastValidBlockHeight
  }).add(
    createTransferCheckedInstruction(
      fromAccount.address,
      mintToken,
      associatedTokenTo,
      fromWallet.publicKey!,
      parseUnits(amount, paymentToken.decimals),
      paymentToken.decimals
    )
  );

  logger.info(`transaction`, transaction);

  try {
    const signature = await sendTransaction(transaction, connection);

    logger.info(`signature: ${signature}`);
    return signature;
  } catch (error) {
    if (error instanceof Error && /user rejected the request/i.test(error.message)) {
      throw new ExpectedError<ClientEECode>('userRejectTransaction', { cause: error });
    }

    throw error;
  }
}

async function getSplTokenBalance(
  connection: Connection,
  address: PublicKey,
  owner: PublicKey
): Promise<string> {
  const associatedTokenAddress = await getAssociatedTokenAddress(address, owner);
  try {
    const tokenAmount = await connection.getTokenAccountBalance(associatedTokenAddress);
    return formatUnits(BigInt(tokenAmount.value.amount), tokenAmount.value.decimals);
  } catch (error) {
    if (error instanceof Error && /could not find account/i.test(error.message)) {
      return '0';
    }

    throw error;
  }
}

const getUSDCToken = (): SolanaToken => {
  const config = getConfig();
  assert(config !== undefined, 'Config must be defined');

  return config.paymentToken;
};

const getBrrrToken = (): SolanaToken => {
  const config = getConfig();
  assert(config !== undefined, 'Config must be defined');

  return config.brrrToken;
};

const getSolanaNetworkInfo = (): SolanaNetworkInfo => {
  const config = getConfig();
  assert(config !== undefined, 'Config must be defined');

  return config.networkInfo;
};

const getUSDCBalance = (): Promise<string> => {
  const config = getConfig();
  assert(config !== undefined, 'Config must be defined');

  const { wallet } = useWallet();

  const connection = new Connection(config.networkInfo.httpRpcURL, {
    commitment: 'confirmed',
    wsEndpoint: config.networkInfo.wsRpcURL
  });

  assert(wallet.value?.adapter != null, 'Wallet must be connected');
  assert(
    wallet.value?.adapter.publicKey != null,
    'Public key must be available. Wallet is likely not connected'
  );

  return getSplTokenBalance(
    connection,
    new PublicKey(config.paymentToken.address),
    wallet.value.adapter.publicKey
  );
};

const getBlockTime = async (): Promise<number> => {
  const config = getConfig();
  if (config === undefined) {
    return 0;
  }

  const connection = new Connection(config.networkInfo.httpRpcURL, {
    commitment: 'confirmed',
    wsEndpoint: config.networkInfo.wsRpcURL
  });

  const latestSlot = await connection.getSlot();

  const blockPromises = Array(4)
    .fill(0)
    .map((_, index) => {
      return connection.getBlockTime(latestSlot - index);
    });

  const blockTimestamps: Array<number> = filterDefined(await Promise.all(blockPromises));

  const deltas: Array<number> = [];
  for (let i = 0; i < blockTimestamps.length; i++) {
    const currentBlock = blockTimestamps[i];
    const nextBlock = blockTimestamps[i + 1];
    if (nextBlock === undefined) {
      break;
    }
    deltas.push(Math.abs(currentBlock - nextBlock));
  }

  return Number(
    new BigNumber(
      deltas.reduce((acc, item) => {
        return acc + item;
      }, 0) / deltas.length
    ).toFixed(0, BigNumber.ROUND_UP)
  );
};

const usdcBalance = ref<string>('0');
const isUpdatingUSDCBalance = ref<boolean>(false);

const updateUsdcBalance = async () => {
  if (isUpdatingUSDCBalance.value) {
    return;
  }
  try {
    isUpdatingUSDCBalance.value = true;
    const { connected } = useWallet();
    if (!connected.value) {
      return;
    }
    usdcBalance.value = await getUSDCBalance();
  } catch (e) {
    addSentryBreadcrumb({
      level: 'error',
      message: 'fail to load usdc solana balance',
      data: {
        error: e
      }
    });
    captureSentryException(e);
  } finally {
    isUpdatingUSDCBalance.value = true;
  }
};

useIntervalFn(updateUsdcBalance, 30 * SECOND, { immediate: true });

export function useSolana() {
  const {
    connect,
    connecting,
    connected,
    ready,
    disconnect,
    disconnecting,
    publicKey,
    select,
    wallet,
    wallets,
    cluster,
    sendTransaction
  } = useWallet();

  return {
    connect: async () => {
      await connect();
      await updateUsdcBalance();
    },
    connecting,
    connected,
    ready,
    disconnect,
    disconnecting,
    publicKey,
    address: computed((): string => {
      if (!publicKey.value) {
        return '0x0';
      }
      return publicKey.value.toString();
    }),
    select,
    wallet,
    wallets,
    cluster,
    sendUSDC: (amount: string): Promise<string> => {
      const config = getConfig();
      assert(config !== undefined, 'Config must be defined');

      const connection = new Connection(config.networkInfo.httpRpcURL, {
        commitment: 'confirmed',
        wsEndpoint: config.networkInfo.wsRpcURL
      });

      assert(wallet.value?.adapter != null, 'Wallet must be connected');
      assert(
        wallet.value?.adapter.publicKey != null,
        'Public key must be available. Wallet is likely not connected'
      );

      return sendSPLToken(
        wallet.value.adapter,
        connection,
        config.paymentToken,
        amount,
        config.addresses.RECIPIENT_ADDRESS,
        sendTransaction
      );
    },
    usdcBalance,
    getBrrrToken,
    getUSDCToken,
    getUSDCBalance,
    getBlockTime,
    getSolanaNetworkInfo
  };
}
