import { computed, ref } from 'vue';

import { datadogLogs } from '@datadog/browser-logs';
import * as Sentry from '@sentry/vue';
import { useIdle, useIntervalFn, whenever } from '@vueuse/core';

import { useSettingsService } from '@/composables/services/useSettingsService';
import { useAccount } from '@/composables/useAccount';
import { useCurrency } from '@/composables/useCurrency';
import { useErrorModal } from '@/composables/useErrorModal';
import { useSessions } from '@/composables/useSessions';
import { queueImagePreload } from '@/helpers/preloadImages';
import { currentClientType } from '@/helpers/utils';
import { addSentryBreadcrumb, captureSentryException } from '@/logs/sentry';
import { setConfig } from '@/references/config';
import { GLOBAL_UPDATE_RATE, IDLE_TIMEOUT } from '@/references/constants';
import { HHError } from '@/references/HHError';
import { t } from '@/references/i18n';
import { setConfig as setSolanaConfig } from '@/references/solanaConfig';

const configVersion = ref(0);

const checkConfigVersion = async (): Promise<void> => {
  const service = useSettingsService();

  const version = await service.getApiVersion();

  if (configVersion.value === 0) {
    //prevent promise race on init flow
    configVersion.value = version.configVersion;
    return;
  }
  if (version.configVersion > configVersion.value) {
    //reload page
    addSentryBreadcrumb({
      level: 'warning',
      message: 'received new config version, reload page',
      data: {
        version
      }
    });
    await Sentry.flush(500);
    window.location.href = '/';
  }
};

const { idle } = useIdle(IDLE_TIMEOUT);
useIntervalFn(
  async () => {
    const account = useAccount();

    if (idle.value || !account.isAuthed.value) {
      return;
    }
    await Promise.allSettled([
      // useTokens().updateTokenList(),
      // useTransactions().load(),
      checkConfigVersion()
    ]);
  },
  GLOBAL_UPDATE_RATE,
  {
    immediate: true
  }
);

// use this composable to arrange data dependencies
export const useInitFlow = () => {
  const account = useAccount();
  const toast = useErrorModal();

  const initializing = ref(false);
  const globalErrorState = ref(false);
  const reportedGlobalErrorState = ref(false);

  const type = currentClientType();

  const init = async () => {
    initializing.value = true;
    try {
      const clientConfig = await useSettingsService().getClientConfig(type);

      datadogLogs.logger.info('init brrr-app with config', { data: clientConfig, type: type });

      configVersion.value = clientConfig.references.apiVersions.configVersion;

      setConfig(clientConfig.references.networks);
      setSolanaConfig(clientConfig.references.solanaNetwork);

      queueImagePreload(
        ...Object.values(clientConfig.references.networks).map((net) => net.networkInfo.iconURL),
        clientConfig.references.solanaNetwork.networkInfo.iconURL,
        clientConfig.references.solanaNetwork.paymentToken.iconURL,
        clientConfig.references.solanaNetwork.brrrToken.iconURL
      );

      const inited = await useCurrency().init();
      if (!inited) {
        throw new HHError('fail to init currency rate');
      }

      globalErrorState.value = false;
    } catch (error) {
      globalErrorState.value = true;
      initializing.value = false;

      if (reportedGlobalErrorState.value) {
        return;
      }

      captureSentryException(new HHError('Failed to init app', { cause: error }));
      toast.auto(t('errors.remoteConfigFailed'));
      reportedGlobalErrorState.value = true;
      return;
    }

    await account.tryConnectCached();
    initializing.value = false;
  };

  whenever(account.isAuthed, () => {
    useSessions().startSession();
  });

  return {
    uiUnlocked: computed(
      () => account.triedToConnect.value && !initializing.value && !globalErrorState.value
    ),
    init,
    initializing,
    globalErrorState
  };
};
