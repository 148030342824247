import { type Address } from 'viem';

import { createHHAxios } from '@/references/axios/axios';
import { HHAPIService } from '@/references/axios/base';
import type { ClientType } from '@/references/base';
import type { Network } from '@/references/network';

type ConstructorArgs = {
  baseURL: string;
  clientType: ClientType;
};

export class HHAPINonceService extends HHAPIService {
  private clientType: ClientType;
  private baseURL: string;

  constructor({ baseURL, clientType }: ConstructorArgs) {
    super('nonces.api.service');
    this.baseURL = baseURL;
    this.clientType = clientType;
  }

  public async getNonce(address: Address, network: Network): Promise<bigint> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    const resp = await instance.request<{ nonce: number }, { address: Address; network: Network }>({
      method: 'POST',
      url: '/v2/private/nonce',
      data: {
        address,
        network
      },
      withCredentials: true
    });

    return BigInt(resp.data.payload.nonce);
  }

  public async incrementNonce(address: Address, network: Network): Promise<void> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    await instance.request<unknown, { address: Address; network: Network }>({
      method: 'POST',
      url: '/v2/private/nonce/increment',
      data: {
        address,
        network
      },
      withCredentials: true
    });
  }
}
