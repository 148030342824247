export function walkError(error: unknown, predicate: (error: unknown) => boolean): boolean {
  if (predicate(error)) {
    return true;
  }

  if (typeof error === 'object' && error !== null && 'cause' in error) {
    return walkError(error.cause, predicate);
  }
  return false;
}

/**
 * Converts provided value to an Error instance
 *
 * Special cases:
 * - value is Error -- returns value
 * - value is string -- returns Error with message of value
 *
 * Otherwise, wraps the value, provides it in {@link Error.prototype.cause} field
 * @param value
 */
export function toError(value: unknown): Error {
  if (value instanceof Error) {
    return value;
  }

  if (typeof value === 'string') {
    return new Error(value);
  }

  return new Error('Error with wrong type', { cause: value });
}
