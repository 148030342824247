export const toArray = <T>(items: T | Array<T>): Array<T> => {
  return Array.isArray(items) ? items : [items];
};

export const chunkArray = <T>(items: Array<T>, chunkSize: number): Array<Array<T>> => {
  const totalChunks = Math.ceil(items.length / chunkSize);

  const chunks = new Array<Array<T>>(totalChunks);
  for (let i = 0; i < totalChunks; i += 1) {
    chunks[i] = items.slice(i * chunkSize, (i + 1) * chunkSize);
  }

  return chunks;
};

type Primitive = number | string | boolean | bigint | symbol | null | undefined;

export const deduplicate = <T extends Primitive>(items: Array<T>): Array<T> => {
  return [...new Set(items)];
};

export const deduplicateBy = <T, P extends Primitive>(
  items: Array<T>,
  predicate: (item: T) => P
): Array<T> => {
  const registry = new Map<P, T>();

  for (const item of items) {
    const key = predicate(item);
    if (registry.has(key)) {
      continue;
    }

    registry.set(key, item);
  }

  return Array.from(registry.values());
};

type ValueOrArray<T> = T | Array<ValueOrArray<T>>;
type RecursiveArray<T> = Array<ValueOrArray<T>>;
export const flattenDeep = <T>(array: RecursiveArray<T>): Array<T> =>
  array.flatMap((subArray) => (Array.isArray(subArray) ? flattenDeep(subArray) : subArray));

export const filterDefined = <T>(items: Array<T>): Array<NonNullable<T>> => {
  return items.filter((v) => v !== undefined && v !== null) as Array<NonNullable<T>>;
};

export const splitByPredicate = <T>(
  items: Array<T>,
  predicate: (item: T, idx: number, array: Array<T>) => boolean
): [Array<T>, Array<T>] => {
  const { found, others } = items.reduce(
    (acc, item, idx, array) => {
      if (predicate(item, idx, array)) {
        return {
          found: [...acc.found, item],
          others: acc.others
        };
      }

      return {
        found: acc.found,
        others: [...acc.others, item]
      };
    },
    {
      found: new Array<T>(),
      others: new Array<T>()
    }
  );

  return [found, others];
};

/**
 * Function that implements intersection on primitive type arrays
 *
 * Retains the `left` array ordering
 * @param left - array to intersect
 * @param right - another array to intersect
 * @returns A ∩ B
 */
export const intersect = <T extends Primitive>(left: Array<T>, right: Array<T>): Array<T> => {
  return left.filter((element) => right.includes(element));
};
