import axios, { type AxiosResponse } from 'axios';
import type { Address, Hex } from 'viem';

import { flattenDeep } from '@/helpers/arrays';
import { isZero } from '@/helpers/bigmath';
import { addSentryBreadcrumb } from '@/logs/sentry';
import { createHHAxios } from '@/references/axios/axios';
import { HHAPIService } from '@/references/axios/base';
import type { ISwapper } from '@/references/axios/swap/ISwapper';
import type {
  Protocol,
  QuoteParams,
  QuoteResponse,
  SwapParams,
  SwapResponse
} from '@/references/axios/swap/swapper1/types';
import type { QuoteData, TransferData } from '@/references/axios/swap/types';
import type { ClientType } from '@/references/base';
import { Network } from '@/references/network';
import { substituteAssetAddressIfNeeded } from '@/references/tokens';

import type { ClientEECode } from '../../../../composables/useErrorModal';
import { ExpectedError } from '../../../ExpectedError';
import { HHError } from '../../../HHError';
import { APIError } from '../../APIError';
import type { BadRequestResponse } from '../swapper1/types';

type ConstructorArgs = {
  baseURL: string;
  network: Network;
  clientType: ClientType;
};

export class Swapper1APIService extends HHAPIService implements ISwapper {
  private readonly baseURL: string;
  private readonly clientType: ClientType;
  private readonly network: Network;

  protected static supportedNetworks: Array<Network> = [
    Network.ethereum,
    Network.polygon,
    Network.avalanche,
    Network.arbitrum,
    Network.optimism,
    Network.gnosis,
    Network.zkevm,
    Network.base,
    Network.zksyncera,
    Network.bsc
  ];

  constructor({ baseURL, network, clientType }: ConstructorArgs) {
    super('swapper1.api.service');
    this.baseURL = baseURL;
    this.network = network;
    this.clientType = clientType;
  }

  public canHandle(network: Network): boolean {
    return Swapper1APIService.supportedNetworks.includes(network);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canHandleToken(network: Network, _tokenAddress: Address): boolean {
    return Swapper1APIService.supportedNetworks.includes(network);
  }

  public async getTransferData(
    buyTokenAddress: Address,
    sellTokenAddress: Address,
    rawAmount: string,
    fromAddress: Address
  ): Promise<TransferData> {
    const data = await this.getSwapData({
      toTokenAddress: buyTokenAddress,
      fromTokenAddress: sellTokenAddress,
      amount: rawAmount,
      fromAddress,
      destReceived: fromAddress,
      disableEstimate: true
    });

    let swappingVia = 'Swapper1';
    try {
      const protocolsFlattened = flattenDeep<Protocol>(data.protocols);
      const maxPartValue = Math.max(...protocolsFlattened.map((p) => p.part));
      const maxPartProtocol = protocolsFlattened.find((protocol) => protocol.part === maxPartValue);
      if (maxPartProtocol !== undefined) {
        swappingVia = maxPartProtocol.name.replaceAll('_', ' ');
      }
    } catch (error) {
      addSentryBreadcrumb({
        level: 'warning',
        category: this.sentryCategoryPrefix,
        message: 'Failed to format "swappingVia"',
        data: {
          error
        }
      });
    }

    addSentryBreadcrumb({
      level: 'info',
      data,
      message: 'Swapper1 returned'
    });

    addSentryBreadcrumb({
      level: 'info',
      data: {
        liquidityProviders: JSON.stringify(flattenDeep<Protocol>(data.protocols ?? []))
      },
      message: 'Protocols'
    });

    return {
      buyAmount: data.toTokenAmount,
      data: data.tx.data as Hex,
      swappingVia,
      value: data.tx.value as Hex,
      sellAmount: data.fromTokenAmount,
      allowanceTarget: data.tx.to as Address,
      to: data.tx.to as Address,
      rawResponse: JSON.stringify({ ...data, swapperName: this.getName() })
    };
  }

  public async getSwapData(params: SwapParams): Promise<SwapResponse> {
    if (!this.ensureNetworkIsSupported(this.network)) {
      throw new Error(`Swapper1 does not support network ${this.network}`);
    }

    if (isZero(params.amount) || params.amount === '') {
      throw new HHError('empty amount request prevented');
    }

    const fromTokenAddress = substituteAssetAddressIfNeeded(params.fromTokenAddress, this.network);
    const toTokenAddress = substituteAssetAddressIfNeeded(params.toTokenAddress, this.network);

    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType),
      onRejected: this.formatError.bind(this)
    });

    const response = await instance.requestRaw<SwapResponse>({
      url: `/swap/${this.network}`,
      method: 'GET',
      params: {
        ...params,
        fromTokenAddress,
        toTokenAddress
      }
    });

    return response.data;
  }

  public async getQuoteData(
    buyTokenAddress: Address,
    sellTokenAddress: Address,
    rawAmount: string
  ): Promise<QuoteData> {
    if (!this.ensureNetworkIsSupported(this.network)) {
      throw new Error(`Swapper1 does not support network ${this.network}`);
    }

    if (isZero(rawAmount) || rawAmount === '') {
      throw new HHError('empty amount request prevented');
    }

    const toTokenAddress = substituteAssetAddressIfNeeded(buyTokenAddress, this.network);
    const fromTokenAddress = substituteAssetAddressIfNeeded(sellTokenAddress, this.network);

    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType),
      onRejected: this.formatError.bind(this)
    });

    const response = await instance.requestRaw<QuoteResponse, QuoteParams>({
      url: `/swap/${this.network}`,
      method: 'GET',
      params: {
        src: fromTokenAddress,
        dst: toTokenAddress,
        amount: rawAmount,
        includeProtocols: true
      }
    });

    let swappingVia = 'Swapper1';
    try {
      const protocolsFlattened = flattenDeep<Protocol>(response.data.protocols);
      const maxPartValue = Math.max(...protocolsFlattened.map((p) => p.part));
      const maxPartProtocol = protocolsFlattened.find((protocol) => protocol.part === maxPartValue);
      if (maxPartProtocol !== undefined) {
        swappingVia = maxPartProtocol.name.replaceAll('_', ' ');
      }
    } catch (error) {
      addSentryBreadcrumb({
        level: 'warning',
        category: this.sentryCategoryPrefix,
        message: 'Failed to format "swappingVia"',
        data: {
          error
        }
      });
    }

    return {
      buyAmount: response.data.dstAmount,
      sellAmount: rawAmount,
      swappingVia,
      rawResponse: JSON.stringify(response)
    };
  }

  protected ensureNetworkIsSupported(network?: Network): boolean {
    if (network === undefined) {
      return false;
    }

    return Swapper1APIService.supportedNetworks.includes(network);
  }

  public getName(): string {
    return 'Swapper1APIService';
  }

  protected formatError(error: unknown): never {
    if (axios.isAxiosError(error)) {
      if (error.response !== undefined) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data === undefined) {
          const errorPayload = {
            method: error.config?.method,
            requestUri: axios.getUri(error.config),
            statusCode: error.status,
            statusMessage: error.message,
            axiosCode: error.code,
            responseCode: error.response.status
          };

          throw new APIError('Request failed: no data', error.message ?? 'no data', {
            payload: errorPayload
          });
        }

        if (error.response.status === 400) {
          // handle and log bad request responses differently
          throw this.formatBadRequestResponse(error.response as AxiosResponse<BadRequestResponse>);
        }

        throw new APIError(error.response.data.error, error.response.data.errorCode, {
          payload: {
            ...error.response.data,
            status: error.response.status,
            url: axios.getUri(error.response.config)
          },
          status: error.response.status
        });
      }

      if (error.request !== undefined) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest
        const errorData = {
          method: error.config?.method,
          requestUri: axios.getUri(error.config),
          statusCode: error.status,
          statusMessage: error.message,
          axiosCode: error.code
        };

        throw new APIError('The request has failed, no response', 'NO_RESPONSE', {
          payload: errorData
        });
      }
    }

    if (error instanceof Error) {
      // An error is JS-initiated error, just pass it through
      throw new Error('The request has failed', { cause: error });
    }

    throw new Error('The request has failed during setup / result handling', { cause: error });
  }

  protected formatBadRequestResponse(response: AxiosResponse<BadRequestResponse>): never {
    addSentryBreadcrumb({
      level: 'warning',
      category: this.sentryCategoryPrefix,
      message: `Request failed with code ${response.status} (${response.statusText}): ${response.data.error}`,
      data: {
        method: response.config.method,
        requestUri: axios.getUri(response.config),
        statusCode: response.status,
        statusMessage: response.statusText,
        error: response.data.error,
        description: response.data.description,
        meta: response.data.meta
      }
    });

    if (response.data.statusCode === 400) {
      if (/insufficient liquidity/i.test(response.data.description)) {
        throw new ExpectedError<ClientEECode>('swapInsufficientLiquidity');
      }

      if (/cannot sync/i.test(response.data.description)) {
        throw new ExpectedError<ClientEECode>('swapUnsupportedToken');
      }

      throw new HHError(response.data.description, { cause: response.data });
    }

    throw new HHError(response.data.description, { cause: response.data });
  }
}
