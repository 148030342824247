<script setup lang="ts">
import { reactive, watch } from 'vue';

import { type MotionVariants } from '@vueuse/motion';

import type { GetBrrrFlowReturn } from '@/composables/useBrrrTransaction';
import { type PrepareReturn, Type } from '@/helpers/flow/types';
import { addSentryBreadcrumb } from '@/logs/sentry';
import type { EstimateContractGasCompoundReturn } from '@/references/onchain/gas';
import type { BaseBRRRFlow } from '@/references/onchain/holyheld/BRRROnChainService.types';
import type {
  ExecuteWithApproveFlow,
  ExecuteWithPermit2Flow,
  ExecuteWithPermitFlow
} from '@/references/onchain/holyheld/flowTypes';
import type { TokenWithPriceAndBalance } from '@/references/tokens';
import { BrrrBuyViewStep } from '@/views/BrrrBuyView.types';

import PrepareFormBrrr from '@/components/PrepareFormBrrr.vue';
import TransactionReviewBrrr from '@/components/TransactionReviewBrrr.vue';

interface Props {
  step: BrrrBuyViewStep.Value | BrrrBuyViewStep.Review;
  token: TokenWithPriceAndBalance;
  motion: MotionVariants<never> | undefined;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'next'): void;
  (event: 'done', txHash: string): void;
}>();

const state = reactive<{
  amount: string;
  maxTokenAmountValue: string | null;
  maxEstimation: EstimateContractGasCompoundReturn | undefined;
  prepareObject: PrepareReturn<Type.Brrr> | undefined;
  flowData: GetBrrrFlowReturn | undefined;
  convertRate: string;
}>({
  amount: '',
  maxTokenAmountValue: null,
  maxEstimation: undefined,
  prepareObject: undefined,
  convertRate: '0',
  flowData: undefined
});

async function onEnterValue({
  prepare,
  flowData,
  processEnd
}: {
  processEnd: () => void;
  prepare: PrepareReturn<Type.Brrr>;
  flowData:
    | ExecuteWithApproveFlow<BaseBRRRFlow>
    | ExecuteWithPermitFlow<BaseBRRRFlow>
    | ExecuteWithPermit2Flow<BaseBRRRFlow>;
}): Promise<void> {
  addSentryBreadcrumb({
    level: 'debug',
    message: 'got prepare object',
    data: {
      prepare: prepare,
      flowData: flowData
    }
  });

  state.prepareObject = prepare;
  state.flowData = flowData;
  processEnd();
  emit('next');
}

watch(
  () => props.step,
  () => {
    window.scrollTo(0, 0);
  }
);
</script>

<template>
  <PrepareFormBrrr
    v-if="props.step === BrrrBuyViewStep.Value"
    v-model:amount="state.amount"
    v-model:max-token-amount-value="state.maxTokenAmountValue"
    v-model:max-estimation="state.maxEstimation"
    v-model:convert-rate="state.convertRate"
    v-motion="props.motion"
    :token="props.token"
    @submit="onEnterValue"
  />
  <TransactionReviewBrrr
    v-else-if="state.flowData !== undefined && state.prepareObject !== undefined"
    v-motion="props.motion"
    :prepare="state.prepareObject"
    :flow-data="state.flowData"
    @done="emit('done', $event)"
  />
</template>
