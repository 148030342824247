import { computed, reactive, readonly, shallowRef, toRefs } from 'vue';

import { datadogLogs } from '@datadog/browser-logs';
import { flush, setContext, setTag } from '@sentry/vue';
import { injected, walletConnect } from '@wagmi/connectors';
import {
  type Config,
  connect as connectWagmi,
  type Connection,
  type Connector,
  ConnectorAlreadyConnectedError,
  type ConnectorEventMap,
  type ConnectReturnType,
  createConfig,
  disconnect as disconnectWagmi,
  getChains,
  getConnectors,
  getPublicClient as getPublicClientWagmi,
  getWalletClient as getWalletClientWagmi,
  reconnect as reconnectWagmi,
  switchChain as switchChainWagmi
} from '@wagmi/core';
import type WalletConnectEthereumProvider from '@walletconnect/ethereum-provider';
import type { SessionTypes } from '@walletconnect/types';
import BigNumber from 'bignumber.js';
import type { Account, Address, Chain, Hex, PublicClient, Transport, WalletClient } from 'viem';
import { createClient, fallback, http, webSocket } from 'viem';
import { addChain as addChainViem } from 'viem/actions';
import {
  arbitrum,
  avalanche,
  base,
  blast,
  bsc,
  gnosis,
  mainnet,
  manta,
  mode,
  optimism,
  polygon,
  polygonZkEvm,
  zkSync
} from 'viem/chains';

import type { ClientEECode } from '@/composables/useErrorModal';
import { sameAddress } from '@/helpers/addresses';
import { filterDefined, intersect } from '@/helpers/arrays';
import { assert } from '@/helpers/assert';
import { toError, walkError } from '@/helpers/errors';
import { asyncSleep } from '@/helpers/promises';
import { cloneObject, isRecord } from '@/helpers/utils';
import { createAdapter, parseNamespaceSupportedChains } from '@/helpers/walletClientAdapter';
import { logger } from '@/logs/datadog';
import { addSentryBreadcrumb } from '@/logs/sentry';
import { WALLET_CONNECT_META, WALLET_CONNECT_PROJECT_ID } from '@/references/constants';
import { ExpectedError } from '@/references/ExpectedError';
import {
  AlephZero,
  getAvailableNetworks,
  getChainId,
  getNetwork,
  getNetworkByChainId,
  Network
} from '@/references/network';
import { rethrowIfUserRejectedRequest } from '@/references/onchain/errors';
import { isProviderRpcError, ProviderRpcErrorCode } from '@/references/onchain/ProviderRPCError';
import type { Token } from '@/references/tokens';

const state = reactive<{
  isConfigured: boolean;
  chainId: number | undefined;
  address: Address | undefined;
  walletConnectSession: SessionTypes.Struct | undefined;
  isConnected: boolean;
}>({
  isConfigured: false,
  chainId: undefined,
  address: undefined,
  walletConnectSession: undefined,
  isConnected: false
});

const config = shallowRef<Config>(
  createConfig({
    chains: [mainnet],
    client({ chain }) {
      return createClient({ chain, transport: http() });
    }
  })
);
let unwatchConfig: (() => void) | undefined;

function getCurrentConnection(config: Config): Connection | null {
  const currentConnectionId = config.state.current;
  if (currentConnectionId === null) {
    return null;
  }

  const connection = config.state.connections.get(currentConnectionId);
  if (connection == null) {
    return null;
  }

  return connection;
}

function getCurrentConnector<T extends Connector>(config: Config): T | null {
  const connection = getCurrentConnection(config);
  if (connection === null) {
    return null;
  }

  return connection.connector as T;
}

const supportedNetworks = computed((): Network[] => {
  const availableNetworks = getAvailableNetworks();

  if (!state.isConfigured) {
    return [];
  }

  const filteredAvailableNetworks = config.value.chains
    .map((chain) => {
      const netInf = getNetworkByChainId(chain.id);
      if (netInf === undefined) {
        return Network.unknown as Network;
      }
      return netInf.network;
    })
    .filter((item) => item !== Network.unknown)
    .sort((a, b) => availableNetworks.indexOf(a) - availableNetworks.indexOf(b)) as Network[];

  const currentConnector = getCurrentConnector(config.value);
  if (currentConnector == null) {
    return filteredAvailableNetworks;
  }

  if (currentConnector.type === walletConnect.type && state.walletConnectSession !== undefined) {
    if (state.walletConnectSession.peer.metadata.name === 'MetaMask Wallet') {
      return filteredAvailableNetworks;
    }

    return intersect(
      parseNamespaceSupportedChains(state.walletConnectSession.namespaces.eip155),
      filteredAvailableNetworks
    ).sort((a, b) => filteredAvailableNetworks.indexOf(a) - filteredAvailableNetworks.indexOf(b));
  }

  return filteredAvailableNetworks;
});

const currentNetwork = computed((): Network => {
  if (!state.chainId) {
    return Network.unknown;
  }

  return getNetworkByChainId(state.chainId)?.network ?? Network.unknown;
});

const supportsSignTypedDataV4 = computed((): boolean => {
  const currentConnector = getCurrentConnector(config.value);
  if (
    currentConnector === null ||
    currentConnector.type !== walletConnect.type ||
    state.walletConnectSession === undefined
  ) {
    return true;
  }

  return state.walletConnectSession.namespaces.eip155.methods.includes('eth_signTypedData_v4');
});

function onDisconnect(): void {
  logger.info('Received onDisconnect callback from wallet');
}

function onChangeAddress(addresses: Readonly<Address[]>): void {
  if (addresses.length === 0) {
    addSentryBreadcrumb({
      level: 'debug',
      message: 'No addresses as onChangeAddress arg',
      data: { addresses }
    });
    return;
  }

  const address = addresses[0];
  if (addresses.length > 1) {
    addSentryBreadcrumb({
      level: 'debug',
      message: 'Received multiple addresses',
      data: { addresses }
    });
  }

  if (sameAddress(address, state.address)) {
    return;
  }

  addSentryBreadcrumb({
    level: 'debug',
    category: 'onChangeAddress.useWagmi.hooks',
    message: 'Received changed accounts array. Page reload is needed',
    data: {
      old: state.address,
      new: address
    }
  });

  window.location.reload();
}

function onChangeChain(chainId: number): void {
  if (state.chainId === chainId) {
    return;
  }

  const oldChainId = state.chainId;
  addSentryBreadcrumb({
    level: 'debug',
    category: 'onChangeChain.useWagmi.hooks',
    message: 'Received changed chainId',
    data: {
      old: oldChainId,
      new: chainId
    }
  });

  state.chainId = chainId;
  const network = getNetworkByChainId(state.chainId)?.network;
  setContext('crypto_person', {
    address: state.address,
    network: network
  });
  setTag('crypto_person_network', network);
  datadogLogs.setUserProperty('network', network);
  logger.info('network changed', { old: oldChainId, new: state.chainId, network });
}

function onChange(value: ConnectorEventMap['change']): void {
  if (value?.accounts !== undefined && value.accounts.length > 0) {
    onChangeAddress(value.accounts);
  }

  if (value?.chainId !== undefined) {
    onChangeChain(value?.chainId);
  }
}

async function tryConnectCached(): Promise<boolean> {
  let recentConnectorId: string | null | undefined;
  try {
    recentConnectorId = await config.value.storage?.getItem('recentConnectorId');
  } catch {
    return false;
  }

  if (recentConnectorId === null || recentConnectorId === undefined) {
    return false;
  }

  const connectors = config.value.connectors;
  const connector = connectors.find((c) => c.id === recentConnectorId);
  if (connector === undefined) {
    return false;
  }

  connector.emitter.on('change', onChange);
  connector.emitter.on('disconnect', onDisconnect);

  let connection: Connection;
  logger.debug(`Reconnecting. Trying to reconnect using connector ${connector.id}`);
  try {
    const reconnected = await reconnectWagmi(config.value);
    if (reconnected.length === 0) {
      return false;
    }

    connection = reconnected[0];
  } catch (error) {
    logger.warn(
      'Failed to reconnect',
      {
        connector: {
          id: connector.id,
          name: connector.name,
          type: connector.type
        }
      },
      toError(error)
    );

    try {
      await config.value.storage?.removeItem('recentConnectorId');
    } catch {
      // ignore this error
    }

    return false;
  }

  try {
    await handleConnection(connector, connection);
  } catch (error) {
    logger.warn(
      'Failed to handle connection',
      {
        connector: {
          id: connector.id,
          name: connector.name,
          type: connector.type
        }
      },
      toError(error)
    );

    try {
      await config.value.storage?.removeItem('recentConnectorId');
    } catch {
      // ignore this error
    }

    return false;
  }

  return true;
}

async function connect(connector: Readonly<Connector>): Promise<void> {
  if (state.isConnected) {
    return;
  }

  addSentryBreadcrumb({
    level: 'debug',
    message: 'Connecting to provider',
    category: 'pureConnect.useWagmi.hooks',
    data: {
      id: connector.id,
      name: connector.name
    }
  });

  connector.emitter.on('change', onChange);
  connector.emitter.on('disconnect', onDisconnect);

  let connection: ConnectReturnType;
  try {
    connection = await connectWagmi(config.value, { connector });
  } catch (connectError) {
    rethrowIfUserRejectedRequest(connectError, 'userRejectAuth');
    rethrowPopupBlockedError(connectError);

    if (!(connectError instanceof ConnectorAlreadyConnectedError)) {
      throw new Error(`Failed to connect to wagmi using connector ${connector.id}`, {
        cause: connectError
      });
    }

    logger.info(
      'Connect failed with ConnectorAlreadyConnectedError. Try to recover',
      config.value.state,
      connectError
    );

    const currentConnection = config.value.state.connections.get(connector.id);
    assert(
      currentConnection != null,
      `Failed to connect to wagmi using connector ${connector.id} during ConnectorAlreadyConnectedError handling`
    );
    connection = currentConnection;
    logger.debug('Assigned the connection from recovery data', connection);
  }

  try {
    await handleConnection(connector, connection);
  } catch (error) {
    logger.warn(
      'Failed to handle connection',
      {
        connector: {
          id: connector.id,
          name: connector.name,
          type: connector.type
        }
      },
      toError(error)
    );
    throw error;
  }
}

async function handleConnection(
  connector: Readonly<Connector>,
  connection: ConnectReturnType
): Promise<void> {
  state.chainId = connection.chainId;

  assert(connection.accounts.length !== 0, 'Empty accounts array after connection to the provider');

  state.address = connection.accounts[0];

  if (connector.type === walletConnect.type) {
    const provider = (await connector.getProvider()) as WalletConnectEthereumProvider;
    if (provider.session !== undefined) {
      state.walletConnectSession = provider.session;
      addSentryBreadcrumb({
        level: 'debug',
        message: 'Connected with wallet',
        data: cloneObject(provider.session)
      });
      setContext('wc_peer_meta', provider.session.peer.metadata);
      datadogLogs.setUserProperty('walletConnectWalletMeta', provider.session.peer.metadata);
    }
  }

  setContext('crypto_person', {
    address: state.address,
    network: getNetworkByChainId(state.chainId)?.network
  });

  setTag('crypto_person_address', state.address);
  setTag('crypto_person_network', getNetworkByChainId(state.chainId)?.network);

  datadogLogs.setUserProperty('address', state.address);
  datadogLogs.setUserProperty('network', getNetworkByChainId(connection.chainId)?.network);
  logger.info(`connected to ${connector.id}`);

  state.isConnected = true;
}

async function disconnect(): Promise<void> {
  unwatchConfig?.();

  if (state.isConnected) {
    try {
      await disconnectWagmi(config.value);
    } catch (error) {
      addSentryBreadcrumb({
        level: 'warning',
        message: 'Received an error during provider disconnect',
        data: {
          error
        }
      });
    }
  }

  try {
    await config.value.storage?.removeItem('recentConnectorId');
  } catch {
    // ignore this error
  }

  state.chainId = undefined;
  state.address = undefined;
  state.walletConnectSession = undefined;
  state.isConnected = false;

  setContext('wc_peer_meta', null);
  setContext('connect_options', null);
  setContext('crypto_person', null);
  setContext('provider_info', null);
  setTag('crypto_person_address', null);
  setTag('crypto_person_network', null);

  logger.info('disconnect');
  datadogLogs.clearUser();

  await flush(500);
}

async function signMessage(messageOrData: string | Record<string, unknown>): Promise<Hex> {
  return createAdapter(getWalletClient).useWalletClient()((c) =>
    c.signMessage({
      message: typeof messageOrData === 'string' ? messageOrData : JSON.stringify(messageOrData)
    })
  );
}

async function changeNetwork(network: Network): Promise<void> {
  logger.info('Requested network change', {
    requestedNetwork: network,
    currentNetwork: currentNetwork.value
  });
  if (network === currentNetwork.value) {
    return;
  }

  assert(state.isConnected, 'Wallet is not connected');
  assert(state.address !== undefined, 'No active address');

  const availableNetworks = getAvailableNetworks();
  assert(
    availableNetworks.includes(network),
    `Network ${network} is not declared in configuration, not available`
  );
  assert(supportedNetworks.value.includes(network), `Network ${network} is not supported`);

  const networkInfo = getNetwork(network);
  assert(networkInfo !== undefined, `No network info is defined for ${network}`);

  try {
    await switchChainWagmi(config.value, {
      chainId: networkInfo.chainId,
      addEthereumChainParameter: {
        iconUrls: [networkInfo.iconURL],
        rpcUrls: networkInfo.rpcUrls
      }
    });
    logger.info('Completed network change');
  } catch (error) {
    addSentryBreadcrumb({
      level: 'error',
      category: 'changeNetwork.useWagmi.hooks',
      message: 'Failed to switch network',
      data: { error }
    });

    if (
      state.walletConnectSession !== undefined &&
      /rainbow/i.test(state.walletConnectSession.peer.metadata.name) &&
      isRecord(error) &&
      error.details === 'Chain Id not supported'
    ) {
      logger.info('Chain does not support inbox by rainbow, try add custom network');

      const chain = getChains(config.value).find((i) => i.id === networkInfo.chainId);
      if (chain !== undefined) {
        try {
          await addChainViem(await getWalletClient(), {
            chain: chain
          });
          await switchChainWagmi(config.value, { chainId: networkInfo.chainId });
          logger.info('Chain added and switched');
          return;
        } catch (e) {
          rethrowIfUserRejectedRequest(e, 'userRejectNetworkChange');
          rethrowPopupBlockedError(e);
          throw new ExpectedError<ClientEECode>('switchChainFailed', {
            cause: error,
            sentryHandle: true
          });
        }
      }
    }

    rethrowIfUserRejectedRequest(error, 'userRejectNetworkChange');
    rethrowPopupBlockedError(error);
    throw new ExpectedError<ClientEECode>('switchChainFailed', {
      cause: error,
      sentryHandle: true
    });
  }
}

export const changeNetworkByToken = async (token: Token): Promise<void> => {
  if (token.network === currentNetwork.value) {
    return;
  }

  addSentryBreadcrumb({
    level: 'info',
    message: 'need change network',
    data: {
      token: token,
      network: currentNetwork
    }
  });
  try {
    await changeNetwork(token.network);
    addSentryBreadcrumb({
      level: 'info',
      message: 'after call change network',
      data: {
        token: token,
        network: currentNetwork.value
      }
    });
    await asyncSleep(500);
  } catch (error) {
    if (error instanceof ExpectedError) {
      throw error;
    }

    throw new ExpectedError<ClientEECode>('switchChainFailed', {
      cause: error,
      sentryHandle: true
    });
  }
};

function getPublicClient(params?: { chainId?: number }): PublicClient<Transport, Chain> {
  return getPublicClientWagmi(config.value, params) as PublicClient<Transport, Chain>;
}

async function getWalletClient(params?: {
  chainId?: number;
}): Promise<WalletClient<Transport, Chain, Account>> {
  if (params?.chainId === undefined && !config.value.chains.some((c) => c.id === state.chainId)) {
    try {
      logger.warn(
        'Wallet is currently in unsupported chain. Try to require wallet to be in ethereum mainnet chain',
        { currentChain: state.chainId }
      );
      return await getWalletClientWagmi(config.value, { ...params, chainId: 1 });
    } catch (error) {
      if (error instanceof ExpectedError) {
        throw error;
      }

      logger.warn(
        'Failed to recover from unsupported chain. Wallet is still in the same chain. Try to require wallet to be in any known chain',
        undefined,
        new Error('Failed to get wallet client', { cause: error })
      );

      const networkToBeIn = supportedNetworks.value.find((n) => n !== Network.ethereum);
      if (networkToBeIn === undefined) {
        logger.error(
          'Cannot proceed anymore: no chains except ethereum mainnet are declared as supported. Let it fail',
          cloneObject(supportedNetworks.value)
        );
      } else {
        try {
          logger.debug(`Found any known network except ethereum mainnet: ${networkToBeIn}`);
          return await getWalletClientWagmi(config.value, {
            ...params,
            chainId: getNetwork(networkToBeIn)!.chainId
          });
        } catch (changeNetworkError) {
          if (changeNetworkError instanceof ExpectedError) {
            throw changeNetworkError;
          }

          logger.error(
            'Network switch failed. Let it fail with an error',
            undefined,
            new Error('Failed to get wallet client', { cause: changeNetworkError })
          );
          throw new ExpectedError<ClientEECode>('switchProviderNetwork', {
            cause: changeNetworkError,
            sentryHandle: true
          });
        }
      }
    }
  }

  return getWalletClientWagmi(config.value, params);
}

function init() {
  unwatchConfig?.();

  const knownViemChains = [
    avalanche,
    arbitrum,
    base,
    blast,
    bsc,
    gnosis,
    mainnet,
    manta,
    mode,
    optimism,
    polygon,
    polygonZkEvm,
    zkSync,
    AlephZero
  ];

  const availableNetworks = getAvailableNetworks();
  const viemChains = filterDefined(
    availableNetworks.map((network) => {
      const ni = getNetwork(network);
      if (ni === undefined) {
        return undefined;
      }

      const chain: Chain | undefined = knownViemChains.find((c) => c.id === ni.chainId);
      if (chain === undefined) {
        addSentryBreadcrumb({
          level: 'warning',
          message: `Chain ${ni.chainId} (${ni.displayedName}) is specified as available in remote config, yet viem does not include it`
        });
        return undefined;
      }

      return chain;
    })
  );

  config.value = createConfig({
    chains: viemChains as unknown as Readonly<[Chain, ...Chain[]]>,
    multiInjectedProviderDiscovery: true,
    connectors: [
      walletConnect({
        projectId: WALLET_CONNECT_PROJECT_ID,
        showQrModal: true,
        metadata: WALLET_CONNECT_META,
        isNewChainsStale: false
      }),
      injected() // default injected provider option (as a fallback)
    ],
    client({ chain }) {
      const ni = getNetworkByChainId(chain.id);
      if (ni === undefined) {
        addSentryBreadcrumb({
          level: 'warning',
          message: `Viem chain ${chain.id} (${chain.name}) is specified as available, yet available networks does not have the entry for it`
        });

        const transports = new Array<Transport>();
        if (chain.rpcUrls.default.webSocket !== undefined) {
          transports.push(...chain.rpcUrls.default.webSocket.map((url) => webSocket(url)));
        }

        transports.push(...chain.rpcUrls.default.http.map((url) => http(url)));

        return createClient({
          chain,
          transport: fallback(transports)
        }); // at least return something defined by viem
      }

      const transports = ni.rpcUrls.map((url) => http(url));

      return createClient({ chain, transport: fallback(transports) });
    }
  });

  logger.info(
    'Available connectors',
    config.value.connectors.map((c) => ({ id: c.id, name: c.name, type: c.type }))
  );

  unwatchConfig = config.value.subscribe(
    (st) => ({
      current: st.current,
      connections: st.connections,
      status: st.status
    }),
    (nv) => {
      if (nv.current === undefined || nv.current === null) {
        return;
      }

      const connection = nv.connections.get(nv.current);
      if (connection === undefined) {
        logger.info('Current connection is disposed or not initialized yet', nv);
        return;
      }

      logger.debug('Wagmi internal config state has changed', {
        connectorId: connection.connector.id,
        connectorName: connection.connector.name,
        connectorType: connection.connector.type,
        accounts: connection.accounts,
        chainId: connection.chainId,
        status: nv.status,
        currentConnectionId: nv.current
      });
    }
  );

  state.isConfigured = true;
}

const connectors = computed(() => {
  return getConnectors(config.value);
});

const getBlockTime = async (chain: Network): Promise<number> => {
  const chainId = getChainId(chain);
  const publicClient = getPublicClient({ chainId: chainId });

  const latestBlockNumber = await publicClient.getBlockNumber();
  const blockPromises = Array(4)
    .fill(0)
    .map((_, index) => {
      return publicClient.getBlock({ blockNumber: latestBlockNumber - BigInt(index) });
    });

  const blockTimestamps: Array<number> = (await Promise.all(blockPromises)).map((item) =>
    Number(item.timestamp)
  );

  const deltas: Array<number> = [];
  for (let i = 0; i < blockTimestamps.length; i++) {
    const currentBlock = blockTimestamps[i];
    const nextBlock = blockTimestamps[i + 1];
    if (nextBlock === undefined) {
      break;
    }
    deltas.push(Math.abs(currentBlock - nextBlock));
  }

  return Number(
    new BigNumber(
      deltas.reduce((acc, item) => {
        return acc + item;
      }, 0) / deltas.length
    ).toFixed(0, BigNumber.ROUND_UP)
  );
};

export function isPopupBlockedError(error: unknown): boolean {
  return walkError(
    error,
    (e) =>
      (e instanceof ExpectedError && e.getCode() === 'popupBlocked') ||
      (isProviderRpcError(e) &&
        e.code === ProviderRpcErrorCode.Internal &&
        e.message === 'Pop up window failed to open')
  );
}

export function rethrowPopupBlockedError(error: unknown): void {
  if (isPopupBlockedError(error)) {
    throw new ExpectedError<ClientEECode>('popupBlocked', { cause: error });
  }
}

export const useWagmi = () => {
  return {
    ...toRefs(readonly(state)),
    supportedNetworks,
    network: currentNetwork,
    supportsSignTypedDataV4,
    connect,
    getBlockTime,
    disconnect,
    signMessage,
    changeNetwork,
    getPublicClient,
    getWalletClient,
    init,
    connectors,
    changeNetworkByToken,
    tryConnectCached
  };
};
