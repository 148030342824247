import { reactive, toRefs } from 'vue';

import { useAssetsAPIService } from '@/composables/services/useAssetsAPIService';
import { useWagmi } from '@/composables/useWagmi';
import { assert } from '@/helpers/assert';
import { queueImagePreload } from '@/helpers/preloadImages';
import { captureSentryException } from '@/logs/sentry';
import { ExpectedError } from '@/references/ExpectedError';
import type { TokenWithPriceAndBalance } from '@/references/tokens';

const state = reactive<{
  assets: TokenWithPriceAndBalance[];
  loaded: boolean;
}>({
  assets: [],
  loaded: false
});

const updateTokenList = async () => {
  try {
    const { address } = useWagmi();

    assert(address.value !== undefined, new ExpectedError('notConnected'));

    const service = useAssetsAPIService();
    const resp = await service.getMultiChainWalletTokens(address.value);

    queueImagePreload(...resp.tokens.map((t) => t.iconURL));

    state.assets = resp.tokens;
    state.loaded = true;
  } catch (e) {
    captureSentryException(new Error('Failed to update token list', { cause: e }));
  }
};

export const useTokens = () => {
  return {
    ...toRefs(state),
    updateTokenList
  };
};
