//
// @example `${tokenAddress}:${chainId}.toLowerCase()`
// @warning Only toLowerCase string
//
import type { AbiFunction } from 'abitype';

export const TOKEN_ADDRESSES_WITH_SALT = [
  '0x2791bca1f2de4661ed88a30c99a7a9449aa84174:137', // USDC Proxy Polygon
  '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063:137' // DAI Proxy Polygon
];

export const DOMAINS_WITHOUT_VERSION = [
  /** @signature 'EIP712Domain(string name,uint chainId,address verifyingContract)' */
  '0x797cfab58fcb15f590eb8e4252d5c228ff88f94f907e119e80c4393a946e8f35',
  /** @signature 'EIP712Domain(string name,uint256 chainId,address verifyingContract)' */
  '0x8cad95687ba82c2ce50e74f7b754645e5117c3a5bec8151c0726d5857980a866'
];

export const EIP_2612_PERMIT_SELECTOR = '0xd505accf';

export const ERC_20_NONCES_ABI: readonly AbiFunction[] = [
  {
    constant: true,
    inputs: [
      {
        name: '',
        type: 'address'
      }
    ],
    name: 'nonces',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: '',
        type: 'address'
      }
    ],
    name: '_nonces',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: '',
        type: 'address'
      }
    ],
    name: 'nonce',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: '',
        type: 'address'
      }
    ],
    name: 'getNonce', // dai polygon
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  }
];

export const EIP_2612_PERMIT_ABI: readonly AbiFunction[] = [
  {
    constant: false,
    inputs: [
      {
        name: 'owner',
        type: 'address'
      },
      {
        name: 'spender',
        type: 'address'
      },
      {
        name: 'value',
        type: 'uint256'
      },
      {
        name: 'deadline',
        type: 'uint256'
      },
      {
        name: 'v',
        type: 'uint8'
      },
      {
        name: 'r',
        type: 'bytes32'
      },
      {
        name: 's',
        type: 'bytes32'
      }
    ],
    name: 'permit',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  }
];

export const DOMAIN_TYPEHASH_ABI: readonly AbiFunction[] = [
  {
    constant: true,
    inputs: [],
    name: 'DOMAIN_TYPEHASH',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  }
];
