<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useBrrrOnChainService } from '@/composables/services/useBrrrOnChainService';
import { useSolana } from '@/composables/useSolana';
import { getNetwork, type Network } from '@/references/network';

import UiIcon from '@/components/ui/UiIcon.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

type OptionItem = {
  text: string;
  image: string;
  value: Network;
  handler: () => void;
};

const emit = defineEmits<{
  (e: 'selected', value: Network | 'solana'): void;
}>();

const { t } = useI18n();

const availableNetworks = computed((): Network[] => {
  return useBrrrOnChainService().getAvailableNetworks();
});

const solanaNetworkInfo = useSolana().getSolanaNetworkInfo();

const options = computed((): OptionItem[] => {
  return availableNetworks.value.map((item) => ({
    value: item,
    text: getNetwork(item)?.displayedName ?? '',
    image: getNetwork(item)?.iconURL ?? '',
    handler() {
      emit('selected', item);
    }
  }));
});
</script>

<template>
  <div class="flow-network-selector">
    <UiTitle class="flow-network-selector__title" :level="1">
      {{ t('chooseNetwork') }}
    </UiTitle>
    <UiText class="flow-network-selector__description" tag="p" size="md" muted>
      {{ t('chooseNetworkWhereYouWillBuy') }}
    </UiText>
    <div class="flow-network-selector__list-wrapper">
      <UiText class="flow-network-selector__list-label" muted tag="p">
        {{ t('availableNetworks') }}
      </UiText>
      <ul class="flow-network-selector__list">
        <li v-for="option in options" :key="`${option.value}`" class="flow-network-selector__item">
          <button class="flow-network-selector__button" type="button" @click="option.handler">
            <img
              v-if="option.image"
              class="flow-network-selector__image"
              :src="option.image"
              alt=""
            />
            <UiText class="flow-network-selector__label" size="md" weight="semibold">
              {{ option.text }}
            </UiText>
            <UiIcon class="flow-network-selector__icon" name="success" />
          </button>
        </li>
        <li class="flow-network-selector__item">
          <button
            class="flow-network-selector__button"
            type="button"
            @click="emit('selected', 'solana')"
          >
            <img class="flow-network-selector__image" :src="solanaNetworkInfo.iconURL" alt="" />
            <UiText class="flow-network-selector__label" size="md" weight="semibold">
              {{ solanaNetworkInfo.displayedName }}
            </UiText>
            <UiIcon class="flow-network-selector__icon" name="success" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.flow-network-selector__title {
  margin: 0 0 8px;
}

.flow-network-selector__description {
  margin: 0 0 40px;
}

.flow-network-selector__list-wrapper {
  padding: 16px;
  background-color: var(--color-main-underlay);
  border-radius: 12px;
}

.flow-network-selector__list-label {
  margin: 0 0 16px;
}

.flow-network-selector__list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.flow-network-selector__item:not(:last-child) {
  margin: 0 0 24px;
}

.flow-network-selector__button {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% + 16px);
  margin: -8px;
  padding: 8px 40px 8px 8px;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  text-align: left;
  word-break: break-word;
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: opacity var(--animation-micro) var(--animation-effect);
  appearance: none;
}

.flow-network-selector__button:not(.flow-network-selector__button_selected):disabled {
  cursor: not-allowed;
  opacity: 0.25;
}

.flow-network-selector__button_selected {
  cursor: default;
}

.flow-network-selector__image {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  margin: 0 16px 0 0;
}

.flow-network-selector__icon {
  position: absolute;
  top: calc(50% - 12px);
  right: 8px;
  opacity: 0;
  transition: opacity var(--animation-micro) var(--animation-effect);
  fill: var(--color-green);
}

.flow-network-selector__button_selected .flow-network-selector__icon {
  opacity: 1;
}
</style>
