import type { Address, Hex, PublicClient } from 'viem';

import type { GetApprovalReturn } from '@/references/axios/approval/types';
import type { WalletClientAdapter } from '@/references/onchain/adapters';
import type { EstimateContractGasCompoundReturn } from '@/references/onchain/gas';

import type { PermitTransferFrom } from './Permit2OnChainService';

export enum AllowanceFlow {
  EstimateApprove = 'estimateApprove',
  MakeApprove = 'makeApprove',
  MakePermit = 'makePermit',
  MakePermit2 = 'makePermit2',
  EstimateWithApprove = 'estimateWithApprove',
  EstimateWithPermit = 'estimateWithPermit',
  EstimateWithPermit2 = 'estimateWithPermit2',
  ExecuteWithApprove = 'executeWithApprove',
  ExecuteWithPermit = 'executeWithPermit',
  ExecuteWithPermit2 = 'executeWithPermit2'
}

export type OmitFlow<F> = Omit<F, 'flow'>;
export type BaseFlow = {
  flowType: string;
};
export type EstimateApproveFlow<BF> = BF & {
  allowanceAmount: bigint;
  flow: AllowanceFlow.EstimateApprove;
  spenderAddress: Address;
  forPermit2: boolean;
};
export type EstimateApproveArgs<BF extends BaseFlow> = {
  flowData: EstimateApproveFlow<BF>;
  publicClient: PublicClient;
  senderAddress: Address;
};
export type MakeApproveFlow<BF extends BaseFlow> = OmitFlow<
  ExecuteFlow<EstimateApproveFlow<BF>>
> & {
  flow: AllowanceFlow.MakeApprove;
  forPermit2: boolean;
};
export type MakePermitFlow<BF> = OmitFlow<BF> & {
  allowanceAmount: bigint;
  flow: AllowanceFlow.MakePermit;
  spenderAddress: Address;
};
export type MakePermit2Flow<BF> = OmitFlow<BF> & {
  allowanceAmount: bigint;
  flow: AllowanceFlow.MakePermit2;
  spenderAddress: Address;
};
export type FlowWithApprovalData<BF> = BF & {
  approvalData: GetApprovalReturn;
};
export type FlowWithAddressCheckData<BF> = BF & {
  addressCheckData: GetApprovalReturn;
};
export type EstimateWithApproveFlow<BF> = OmitFlow<FlowWithApprovalData<BF>> & {
  allowanceAmount: bigint;
  contractAddress: Address;
  expectedMinimumAmount: bigint;
  flow: AllowanceFlow.EstimateWithApprove;
  spenderAddress: Address;
  transferDataHex: Hex;
  transferDataRaw: string | undefined;
};
export type EstimateWithPermitFlow<BF> = OmitFlow<FlowWithAddressCheckData<BF>> & {
  allowedAmount: bigint;
  callData: Hex;
  contractAddress: Address;
  expectedMinimumAmount: bigint;
  expiresAt: number | undefined;
  flow: AllowanceFlow.EstimateWithPermit;
  transferDataHex: Hex;
  transferDataRaw: string | undefined;
  transferDataValue: bigint;
};
export type EstimateWithPermit2Flow<BF> = OmitFlow<FlowWithAddressCheckData<BF>> & {
  allowedAmount: bigint;
  contractAddress: Address;
  expectedMinimumAmount: bigint;
  expiresAt: number | undefined;
  flow: AllowanceFlow.EstimateWithPermit2;
  permitData: PermitTransferFrom;
  permitSignature: Hex;
  transferDataHex: Hex;
  transferDataRaw: string | undefined;
  transferDataValue: bigint;
};

type ExecuteFlow<BF> = BF & {
  estimation: EstimateContractGasCompoundReturn;
};
export type ExecuteWithApproveFlow<BF extends BaseFlow> = OmitFlow<
  ExecuteFlow<EstimateWithApproveFlow<BF>>
> & {
  flow: AllowanceFlow.ExecuteWithApprove;
};
export type ExecuteWithPermitFlow<BF extends BaseFlow> = OmitFlow<
  ExecuteFlow<EstimateWithPermitFlow<BF>>
> & {
  flow: AllowanceFlow.ExecuteWithPermit;
};
export type ExecuteWithPermit2Flow<BF extends BaseFlow> = OmitFlow<
  ExecuteFlow<EstimateWithPermit2Flow<BF>>
> & {
  flow: AllowanceFlow.ExecuteWithPermit2;
};

export type GetAllowanceFlowArgs<BF extends BaseFlow> = {
  flowData: BF;
  publicClient: PublicClient;
  senderAddress: Address;
};
export type MakeApproveArgs<BF extends BaseFlow> = {
  flowData: MakeApproveFlow<BF>;
  publicClient: PublicClient;
  senderAddress: Address;
  walletClientAdapter: WalletClientAdapter;
};
export type MakePermitArgs<BF extends BaseFlow> = {
  flowData: MakePermitFlow<BF>;
  publicClient: PublicClient;
  senderAddress: Address;
  walletClientAdapter: WalletClientAdapter;
};
export type MakePermit2Args<BF extends BaseFlow> = {
  flowData: MakePermit2Flow<BF>;
  publicClient: PublicClient;
  senderAddress: Address;
  walletClientAdapter: WalletClientAdapter;
};
export type EstimateWithApproveArgs<BF extends BaseFlow> = {
  flowData: EstimateWithApproveFlow<BF>;
  publicClient: PublicClient;
  senderAddress: Address;
};
export type EstimateWithPermitArgs<BF extends BaseFlow> = {
  flowData: EstimateWithPermitFlow<BF>;
  publicClient: PublicClient;
  senderAddress: Address;
};
export type EstimateWithPermit2Args<BF extends BaseFlow> = {
  flowData: EstimateWithPermit2Flow<BF>;
  publicClient: PublicClient;
  senderAddress: Address;
};
export type ExecuteWithApproveArgs<BF extends BaseFlow> = {
  flowData: ExecuteWithApproveFlow<BF>;
  publicClient: PublicClient;
  walletClientAdapter: WalletClientAdapter;
};
export type ExecuteWithPermitArgs<BF extends BaseFlow> = {
  flowData: ExecuteWithPermitFlow<BF>;
  publicClient: PublicClient;
  walletClientAdapter: WalletClientAdapter;
};
export type ExecuteWithPermit2Args<BF extends BaseFlow> = {
  flowData: ExecuteWithPermit2Flow<BF>;
  publicClient: PublicClient;
  senderAddress: Address;
  walletClientAdapter: WalletClientAdapter;
};

export function isFlowWithPermitData<BF extends BaseFlow>(
  v: unknown
): v is ExecuteWithPermitFlow<BF> {
  return (
    typeof v === 'object' && v !== null && 'flow' in v && v.flow === AllowanceFlow.ExecuteWithPermit
  );
}

export function isFlowWithPermit2Data<BF extends BaseFlow>(
  v: unknown
): v is ExecuteWithPermit2Flow<BF> {
  return (
    typeof v === 'object' &&
    v !== null &&
    'flow' in v &&
    v.flow === AllowanceFlow.ExecuteWithPermit2
  );
}
