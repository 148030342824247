import qs from 'qs';

import { createHHAxios } from '@/references/axios/axios';
import { HHAPIService } from '@/references/axios/base';
import type { ClientType } from '@/references/base';
import { CurrencyCode } from '@/references/currency';

import { type Rates } from './types';

export class HHAPIRatesService extends HHAPIService {
  constructor(
    private readonly baseURL: string,
    private readonly clientType: ClientType
  ) {
    super('rates.api.service');
  }

  public async getLastEURRates(): Promise<Rates> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType),
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    });

    const res = await instance.request<Rates>({
      method: 'GET',
      url: '/rates/last',
      params: {
        code: [CurrencyCode.EUR]
      }
    });

    return res.data.payload;
  }
}
