<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import type { Token } from '@/references/tokens';

import TokenImage from '@/components/TokenImage.vue';
import UiBlinker from '@/components/ui/UiBlinker.vue';
import UiButtonIcon from '@/components/ui/UiButtonIcon.vue';
import UiText from '@/components/ui/UiText.vue';

interface Props {
  value?: string | null;
  token?: Token;
  time?: number | null;
  blinking?: boolean;
  feeBlinking?: boolean;
  timeBlinking?: boolean;
  error?: boolean;
  isTipVisible?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  value: null,
  token: undefined,
  time: null,
  blinking: false,
  feeBlinking: false,
  timeBlinking: false,
  error: false,
  isTipVisible: false
});

const emit = defineEmits<{
  (event: 'click:tip'): void;
}>();

const { t } = useI18n();

const value = computed((): string => {
  if (props.value === null) {
    return t('free');
  }

  return props.value;
});

const time = computed((): string => {
  if (props.time === null) {
    return t('instant');
  }

  return t('transactionFlow.titles.remain', { time: props.time });
});
</script>

<template>
  <ul class="fee-info">
    <li class="fee-info__left">
      <UiText
        class="fee-info__value"
        :class="{ 'fee-info__value_error': props.error }"
        size="sm"
        weight="medium"
        tag="p"
      >
        <TokenImage v-if="props.token" class="fee-info__token" :token="props.token" size="sm" />
        <UiBlinker :blinking="props.blinking || props.feeBlinking">
          {{ value }}
        </UiBlinker>
      </UiText>
      <UiText class="fee-info__label" size="xs" weight="medium" tag="p" muted>
        {{ t('estimatedFee') }}
        <UiButtonIcon
          v-if="isTipVisible"
          class="fee-info__tip"
          icon="tip"
          size="sm"
          :icon-size="16"
          mod="transparent-muted"
          @click="emit('click:tip')"
        />
      </UiText>
    </li>
    <li class="fee-info__right">
      <UiText class="fee-info__value" size="sm" weight="medium" tag="p">
        <UiBlinker :blinking="props.blinking || props.timeBlinking">
          {{ time }}
        </UiBlinker>
      </UiText>
      <UiText class="fee-info__label" size="xs" weight="medium" tag="p" muted>
        {{ t('time') }}
      </UiText>
    </li>
  </ul>
</template>

<style scoped>
.fee-info {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.fee-info__left {
  margin: 0 16px 0 0;
}

.fee-info__right {
  position: relative;
  margin: 0 0 0 auto;
  text-align: right;
}

.fee-info__value {
  display: flex;
  align-items: center;
  min-height: 22px;
  overflow-wrap: break-word;
  transition: color var(--animation-micro) var(--animation-effect);
}

.fee-info__value_error {
  color: var(--color-red);
}

.fee-info__token {
  margin: 0 4px 0 0;
}

.fee-info__label {
  display: flex;
  align-items: center;
}

.fee-info__right .fee-info__label,
.fee-info__right .fee-info__value {
  justify-content: flex-end;
}

.fee-info__tip {
  margin: -4px 0;
}
</style>
