import { BigNumber } from 'bignumber.js';
import { concat, fromBytes, fromHex, type Hex, pad, parseUnits, toBytes, toHex } from 'viem';

import { divide, greaterThan, multiply, toWei } from '@/helpers/bigmath';
import { addSentryBreadcrumb } from '@/logs/sentry';
import type { TransferData } from '@/references/axios/swap/types';
import type { Token } from '@/references/tokens';

export const mapTransferDataToExpectedMinimumAmountV2 = (
  inputAssetPrice: string,
  inputAmount: string,
  swapTarget: Token,
  swapTargetPrice: string,
  data?: TransferData
): bigint => {
  if (data === undefined) {
    return 0n;
  }

  if (data.buyAmount === undefined || data.sellAmount === undefined) {
    throw new Error('buy amount or sell amount is undefined!');
  }

  const sellSum = multiply(inputAmount, inputAssetPrice);
  addSentryBreadcrumb({
    level: 'info',
    message: 'Sell price by DEFI price',
    data: {
      sellSum
    }
  });

  const expectedAmountByDEFI = divide(sellSum, swapTargetPrice);
  addSentryBreadcrumb({
    level: 'info',
    message: 'Expected USDC by DEFI',
    data: {
      expectedAmountByDEFI
    }
  });
  const expectedAmountByDEFIInWEI = new BigNumber(
    toWei(expectedAmountByDEFI, swapTarget.decimals)
  ).toFixed(0);
  addSentryBreadcrumb({
    level: 'info',
    message: 'Expected USDC by DEFI and SWAP in WEI:',
    data: {
      expectedAmountByDEFIInWEI,
      swapBuyAmount: data.buyAmount
    }
  });

  const multiplier = '0.85';
  if (greaterThan(expectedAmountByDEFIInWEI, data.buyAmount)) {
    const res = new BigNumber(multiply(expectedAmountByDEFIInWEI, multiplier)).toFixed(0);
    addSentryBreadcrumb({
      level: 'info',
      message: 'Min expected amount (by DEFI)',
      data: {
        res: res.toString()
      }
    });
    return parseUnits(res, 0);
  }

  const res = new BigNumber(multiply(data.buyAmount, multiplier)).toFixed(0);
  addSentryBreadcrumb({
    level: 'info',
    message: 'Min expected amount (by swapper)',
    data: {
      res: res.toString()
    }
  });
  return parseUnits(res, 0);
};

export const mapTransferDataToHex = (data?: TransferData): Hex => {
  if (data === undefined) {
    return fromBytes(new Uint8Array(), 'hex');
  }

  return fromBytes(
    concat([
      fromHex(data.to, 'bytes'),
      fromHex(data.allowanceTarget, 'bytes'),
      fromHex(pad(toHex(BigInt(data.value)), { dir: 'left', size: 32 }), 'bytes'),
      toBytes(data.data)
    ]),
    'hex'
  );
};

export const mapTransferDataToValue = (data?: TransferData): bigint => {
  if (data === undefined || data.value === undefined) {
    return 0n;
  }

  return BigInt(data?.value);
};
