<script setup lang="ts">
function afterEnter(element: Element): void {
  (element as HTMLElement).style.height = 'auto';
}

function enter(element: Element): void {
  (element as HTMLElement).style.height = 'auto';
  const { height } = getComputedStyle(element);
  (element as HTMLElement).style.height = '0px';
  requestAnimationFrame(() => {
    (element as HTMLElement).style.height = height;
  });
}

function leave(element: Element): void {
  (element as HTMLElement).style.height = getComputedStyle(element).height;
  requestAnimationFrame(() => {
    (element as HTMLElement).style.height = '0px';
  });
}
</script>

<template>
  <Transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
    <slot />
  </Transition>
</template>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  overflow: hidden;
  transition:
    height var(--animation-medium) var(--animation-effect),
    opacity var(--animation-medium) var(--animation-effect);
}

.expand-enter-from,
.expand-leave-to {
  height: 0;
  opacity: 0;
}
</style>
