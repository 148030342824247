<script setup lang="ts">
import { computed, onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useKYC } from '@/composables/useKYC';
import { useSolana } from '@/composables/useSolana';
import { useViewBreakpoints } from '@/composables/useViewBreakpoints';
import { useWagmi } from '@/composables/useWagmi';
import { fade, type MotionVariants } from '@/helpers/motion';
import { KYCStatus } from '@/references/axios/kyc/types';
import { useI18n } from '@/references/i18n';
import { RouteNames } from '@/router';

import HomeInfoDetailsWrapper from '@/components/HomeInfoDetailsWrapper.vue';
import type { InfoBlockButton } from '@/components/InfoBlock.types';
import InfoBlock from '@/components/InfoBlock.vue';
import type { UiButtonMod } from '@/components/ui/UiButton.types';
import UiButton from '@/components/ui/UiButton.vue';
import UiButtonIcon from '@/components/ui/UiButtonIcon.vue';
import UiContainer from '@/components/ui/UiContainer.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

const state = reactive<{
  isMounted: boolean;
}>({
  isMounted: false
});

const { t } = useI18n();
const { KYCstatus } = useKYC();
const route = useRoute();
const { push, replace } = useRouter();
const { lessThanXL } = useViewBreakpoints();
const {
  isConnected: isConnectedWagmi,
  address: evmAddress,
  disconnect: disconnectWagmi
} = useWagmi();
const {
  connected: isConnectedSolana,
  address: solanaAddress,
  disconnect: disconnectSolana
} = useSolana();

const kycButtons = computed((): InfoBlockButton[] => {
  return [
    {
      text: t('kyc.getStart'),
      mod: 'primary' as UiButtonMod,
      handler: () => {
        push({ name: RouteNames.KYC });
      }
    }
  ];
});

const kycTitle = computed(() => {
  switch (KYCstatus.value) {
    case KYCStatus.SUCCESS:
      return t('kyc.success');
    case KYCStatus.FAILED:
      return t('kyc.failed');
    case KYCStatus.NOT_STARTED:
      return t('kyc.waitForYou');
    default:
      return t('kyc.inProgress');
  }
});

const kycDescription = computed(() => {
  return t('kyc.description');
});

function onBrrrButtonClick(): void {
  push({ name: RouteNames.Brrr });
}

const isKYCSuccess = computed(() => {
  return KYCstatus.value === KYCStatus.SUCCESS;
});

const showKYCModal = computed(() => {
  return [KYCStatus.STARTED, KYCStatus.NOT_STARTED].includes(KYCstatus.value);
});

const motion = computed((): MotionVariants<never> | undefined => {
  if (state.isMounted) {
    return fade;
  }

  return undefined;
});

const isDetailsBlockOpened = computed((): boolean => {
  if ([RouteNames.Brrr].includes(route.name as RouteNames)) {
    return true;
  }

  return false;
});

const detailsTitle = computed((): string => {
  switch (route.name) {
    case RouteNames.Brrr:
      return t('brrr');
    default:
      return '';
  }
});

onMounted(() => {
  state.isMounted = true;
});
</script>

<template>
  <section class="home-info">
    <UiContainer class="home-info__container">
      <div v-if="!isDetailsBlockOpened || !lessThanXL" v-motion="motion" class="home-info__body">
        <UiTitle :level="1" class="home-info__title">
          {{ t('home.title') }}
        </UiTitle>
        <section v-if="isConnectedWagmi" class="home-info__section">
          <UiText class="home-info__label" tag="p" size="md" weight="regular" muted>
            {{ t('wallet.connectedTo', { address: evmAddress }) }}
          </UiText>
          <UiButton
            :title="t('disconnectWalletWithAddress', { address: evmAddress })"
            @click="disconnectWagmi"
          />
        </section>
        <section v-if="isConnectedSolana" class="home-info__section">
          <UiText class="home-info__label" tag="p" size="md" weight="regular" muted>
            {{ t('wallet.connectedTo', { address: solanaAddress }) }}
          </UiText>
          <UiButton
            :title="t('disconnectWalletWithAddress', { address: solanaAddress })"
            @click="disconnectSolana"
          />
        </section>
        <section v-if="showKYCModal" class="home-info__section">
          <InfoBlock :title="kycTitle" :description="kycDescription" :buttons="kycButtons" />
        </section>
        <section v-if="KYCstatus === KYCStatus.REVIEW" class="home-info__section">
          <InfoBlock :title="t('KYC_under_review')" :description="kycDescription" :buttons="[]" />
        </section>
        <section v-if="isKYCSuccess" class="home-info__section">
          <UiText class="home-info__label" tag="p" size="md" weight="regular" muted>
            {{ t('account') }}
          </UiText>
          <ul class="home-info__account-list">
            <li class="home-info__account-item">
              <button class="home-info__account-button" type="button" @click="onBrrrButtonClick">
                <img
                  class="home-info__account-image"
                  src="@/assets/images/brrr-preview@3x.png"
                  alt=""
                />
                <span class="home-info__account-info">
                  <UiText size="md" weight="semibold">
                    {{ t('brrr') }}
                  </UiText>
                </span>
              </button>
            </li>
          </ul>
        </section>
      </div>
      <div
        v-else-if="isDetailsBlockOpened && lessThanXL"
        v-motion="motion"
        class="card-info__details"
      >
        <div class="card-info__details-header">
          <UiButtonIcon
            class="card-info__close-button"
            icon="cross-large"
            mod="transparent"
            @click="replace({ name: RouteNames.Home })"
          />
          <UiTitle class="card-info__details-title" :level="2" align="center" size="md">
            {{ detailsTitle }}
          </UiTitle>
        </div>
        <RouterView />
      </div>
      <Transition name="card-info-details">
        <div v-if="isDetailsBlockOpened && !lessThanXL" class="card-info__details">
          <Transition name="card-info-details-item" mode="out-in">
            <HomeInfoDetailsWrapper
              :key="route.name || 0"
              @close="replace({ name: RouteNames.Home })"
            >
              <RouterView />
            </HomeInfoDetailsWrapper>
          </Transition>
        </div>
      </Transition>
    </UiContainer>
  </section>
</template>

<style scoped>
.home-info {
  position: relative;
  margin: -40px 0;
}

@media (max-width: 1279px) {
  .home-info {
    margin: 0;
  }
}

.home-info__container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  transition: max-width var(--animation-micro) var(--animation-micro) var(--animation-effect);
}

@media (min-width: 1280px) {
  .home-info_opened .home-info__container {
    max-width: 1080px;
    transition: max-width var(--animation-micro) var(--animation-effect);
  }
}

.home-info__body {
  min-width: 560px;
  max-width: 560px;
  padding: 40px 0;
  transition:
    max-width var(--animation-micro) var(--animation-micro) var(--animation-effect),
    min-width var(--animation-micro) var(--animation-micro) var(--animation-effect),
    opacity var(--animation-micro) var(--animation-effect);
}

@media (max-width: 1319px) {
  .home-info_opened .home-info__body {
    min-width: 500px;
    max-width: 500px;
    transition:
      max-width var(--animation-micro) var(--animation-effect),
      min-width var(--animation-micro) var(--animation-effect);
  }
}

@media (max-width: 1279px) {
  .home-info .home-info__body {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 0;
    transition: none;
  }
}

.home-info__body:not(:last-child) {
  margin: 0 40px 0 0;
}

.home-info__title {
  margin: 0 0 24px;
}

@media (max-width: 919px) {
  .home-info__title {
    display: none;
  }
}

.home-info__section {
  padding: 16px;
  background-color: var(--color-main-underlay);
  border-radius: 12px;
}

.home-info__section:not(:last-child) {
  margin: 0 0 32px;
}

.home-info__label:not(:last-child) {
  margin: 0 0 16px;
}

.home-info__account-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.home-info__account-item {
  position: relative;
  width: 100%;
}

.home-info__account-item:not(:last-child) {
  margin: 0 0 24px;
}

.home-info__account-button {
  display: flex;
  align-items: center;
  width: calc(100% + 16px);
  min-height: 60px;
  margin: -8px;
  padding: 8px;
  color: inherit;
  font-weight: inherit;
  font-family: inherit;
  line-height: inherit;
  text-align: left;
  text-decoration: none;
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  appearance: none;
}

.home-info__account-image {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 32px;
  margin: 0 16px 0 0;
  color: var(--color-white);
  border-radius: 6px;
}
</style>
