import type { Address } from 'viem';

import { createHHAxios } from '@/references/axios/axios';
import { HHAPIService } from '@/references/axios/base';
import type { ClientType } from '@/references/base';
import { getChainId } from '@/references/network';
import type { Token } from '@/references/tokens';
import { substituteAssetAddressIfNeeded } from '@/references/tokens';

import type { BuyBrrrSignaturePayload, BuyBrrrSignatureResponse, GetApprovalReturn } from './types';

export class HHAPIApprovalService extends HHAPIService {
  protected readonly sentryCategoryPrefix = 'approval.api.service';

  protected readonly baseURL: string;
  protected readonly clientType: ClientType;

  constructor(baseURL: string, clientType: ClientType) {
    super('approval.api.service');
    this.baseURL = baseURL;
    this.clientType = clientType;
  }

  public async getBuyBrrrSignature(
    amountWei: bigint,
    senderAddress: Address,
    token: Token
  ): Promise<GetApprovalReturn> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    const response = await instance.request<BuyBrrrSignatureResponse, BuyBrrrSignaturePayload>({
      method: 'POST',
      url: '/v1/private/brrr/signature',
      withCredentials: true,
      data: {
        address: senderAddress,
        token: substituteAssetAddressIfNeeded(token.address, token.network),
        amount: amountWei.toString(10),
        chainID: getChainId(token.network)
      }
    });

    return {
      amount: response.data.payload.amount,
      approval: 'ok',
      data: response.data.payload.sig,
      specialHash: response.data.payload.mintHash,
      timestamp: response.data.payload.timestamp
    };
  }
}
