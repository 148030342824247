import type { FeeValues } from 'viem';

import { isEqual, multiply } from '@/helpers/bigmath';
import { cloneObject, roundToPrecision } from '@/helpers/utils';
import { addSentryBreadcrumb } from '@/logs/sentry';
import { getGasEstimationMultiplier } from '@/references/config';
import type { Network } from '@/references/network';
import type { EstimateContractGasCompoundReturn } from '@/references/onchain/gas';

/**
 * get cloned estimation, reload func replaced with stub. Stub return same result without reload,
 * @return [clonedEstimationWithMultiplierApplied, originalTotalFee]
 * @param net
 * @param estimation
 * @param usePresentedGasData
 */
export async function getClonedGasEstimationByNetwork(
  net: Network,
  estimation: EstimateContractGasCompoundReturn,
  usePresentedGasData = false
): Promise<[EstimateContractGasCompoundReturn, bigint]> {
  const est = usePresentedGasData ? estimation : await estimation.reload();

  const originalTotalFee = cloneObject(est.totalFee);
  const result: EstimateContractGasCompoundReturn = {
    ...cloneEstimation(est),
    reload: () => Promise.resolve(result)
  };

  const multiplier = getGasEstimationMultiplier(net);

  if (isEqual(multiplier, '1')) {
    return [result, originalTotalFee];
  }

  //EIP-1559
  if (est.feeValues.maxFeePerGas !== undefined) {
    const baseFee = est.feeValues.maxFeePerGas - est.feeValues.maxPriorityFeePerGas;
    const newBaseFee = BigInt(roundToPrecision(multiply(baseFee.toString(10), multiplier), 0));
    const newMaxPriorityFeePerGas = BigInt(
      roundToPrecision(multiply(est.feeValues.maxPriorityFeePerGas.toString(10), multiplier), 0)
    );

    addSentryBreadcrumb({
      level: 'debug',
      message: `multiply maxFeePerGas x ${multiplier} for ${net}`,
      data: {
        baseFee,
        newBaseFee,
        newMaxPriorityFeePerGas
      }
    });

    result.feeValues.maxPriorityFeePerGas = newMaxPriorityFeePerGas;
    result.feeValues.maxFeePerGas = newBaseFee + newMaxPriorityFeePerGas;
    result.totalFee = est.gasLimit * est.feeValues.maxFeePerGas + est.syncFee;
  } else if (est.feeValues.gasPrice !== undefined) {
    //Legacy
    addSentryBreadcrumb({
      level: 'debug',
      message: `multiply gasPrice x ${multiplier} for ${net}`
    });
    result.feeValues.gasPrice = BigInt(
      roundToPrecision(multiply(est.feeValues.gasPrice.toString(10), multiplier), 0)
    );
    result.totalFee = est.gasLimit * est.feeValues.gasPrice + est.syncFee;
  }
  addSentryBreadcrumb({
    level: 'debug',
    message: 'getGasEstimationByNetwork result',
    data: {
      feeValues: result.feeValues,
      estimation: result
    }
  });
  return [result, originalTotalFee];
}

export const cloneEstimation = (
  est: EstimateContractGasCompoundReturn
): EstimateContractGasCompoundReturn => {
  let feeValues: FeeValues;

  //EIP-1559
  if (est.feeValues.maxFeePerGas !== undefined) {
    feeValues = {
      maxFeePerGas: est.feeValues.maxFeePerGas,
      maxPriorityFeePerGas: est.feeValues.maxPriorityFeePerGas
    };
  } else {
    //Legacy
    feeValues = {
      gasPrice: est.feeValues.gasPrice
    };
  }

  const a: EstimateContractGasCompoundReturn = {
    gasLimit: est.gasLimit,
    totalFee: est.totalFee,
    feeValues: feeValues,
    syncFee: est.syncFee,
    reload: est.reload
  };

  return a;
};
