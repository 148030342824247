import BigNumber from 'bignumber.js';

import { MoneyFormatter } from '@/helpers/formatters/money';
import { AmountMode } from '@/references/axios/transactions/types';
import type { CurrencyCode } from '@/references/currency';
import { i18n } from '@/references/i18n';

import { formatTokenAmount } from './amount';

/**
 * Formats rate with provided decimals and input/output currencies using current locale settings
 * @example
 * // returns "1 USD = 0.918 EUR"
 * formatRate('0.9181', 3, CurrencyCode.USD, CurrencyCode.EUR);
 * @param amount - an amount to format
 * @param decimals - decimals
 * @param inputCurrency - input currency code
 * @param outputCurrency - output currency code
 */
export function formatRate(
  amount: BigNumber.Value,
  decimals: number,
  inputCurrency: CurrencyCode,
  outputCurrency: CurrencyCode
): string {
  const amountAsBigNumber = new BigNumber(
    new BigNumber(amount).toFixed(decimals, BigNumber.ROUND_DOWN)
  );
  if (amountAsBigNumber.isNaN()) {
    return Number.NaN.toString(10);
  }

  const rightFormatter = new MoneyFormatter(i18n.global.locale, outputCurrency);

  const left = `1 ${inputCurrency}`;
  const right = rightFormatter.formatCurrency(amountAsBigNumber.toNumber(), 'short-explicit', {
    maximumFractionDigits: decimals,
    minimumFractionDigits: 0
  });

  return `${left} = ${right}`;
}

/**
 * Formats token to fiat rate
 * @example
 * // returns "1 AAB = 0.918 USD"
 * formatTokenRate('0.9181', 3, 'AAB', CurrencyCode.USD);
 * @param amount - an amount to format
 * @param decimals - decimals
 * @param inputCurrency - input token symbol
 * @param outputCurrency - output currency code
 */
export function formatTokenRate(
  amount: BigNumber.Value,
  decimals: number,
  inputCurrency: string,
  outputCurrency: CurrencyCode
): string {
  const amountAsBigNumber = new BigNumber(
    new BigNumber(amount).toFixed(decimals, BigNumber.ROUND_DOWN)
  );
  if (amountAsBigNumber.isNaN()) {
    return Number.NaN.toString(10);
  }

  const rightFormatter = new MoneyFormatter(i18n.global.locale, outputCurrency);

  const left = `1 ${inputCurrency}`;
  const right = rightFormatter.formatCurrency(amountAsBigNumber.toNumber(), 'short-explicit', {
    maximumFractionDigits: decimals,
    minimumFractionDigits: amountAsBigNumber.isInteger() ? 0 : decimals
  });

  return `${left} = ${right}`;
}

/**
 * Formats token to token rate
 * @example
 * // returns "1 AAB = 0.918 POL"
 * formatTokensRate('0.9181', 3, 'AAB', 'POL');
 * @param amount - an amount to format
 * @param decimals - decimals
 * @param inputCurrency - input token symbol
 * @param outputCurrency - output token symbol
 */
export function formatTokensRate(
  amount: BigNumber.Value,
  decimals: number,
  inputCurrency: string,
  outputCurrency: string
): string {
  const amountAsBigNumber = new BigNumber(
    new BigNumber(amount).toFixed(decimals, BigNumber.ROUND_DOWN)
  );
  if (amountAsBigNumber.isNaN()) {
    return Number.NaN.toString(10);
  }

  const left = `1 ${inputCurrency}`;
  const right = formatTokenAmount(amount, decimals, outputCurrency, AmountMode.CodeLast, false);

  return `${left} = ${right}`;
}

export function formatReversedTokenRate(
  amount: BigNumber.Value,
  decimals: number,
  inputCurrency: CurrencyCode,
  outputCurrency: string
): string {
  const amountAsBigNumber = new BigNumber(
    new BigNumber(amount).toFixed(decimals, BigNumber.ROUND_DOWN)
  );

  const left = `1 ${inputCurrency}`;
  if (amountAsBigNumber.isNaN()) {
    return `${left} = 0 ${outputCurrency}`;
  }

  const right = formatTokenAmount(amount, decimals, outputCurrency, AmountMode.CodeLast, false);

  return `${left} = ${right}`;
}
