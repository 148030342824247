<script setup lang="ts">
import { computed } from 'vue';

import NumberFlow from '@number-flow/vue';

import { formatAmount } from '@/helpers/formatters';
import { AmountMode } from '@/references/axios/transactions/types';
import { CurrencyCode } from '@/references/currency';
import { useI18n } from '@/references/i18n';

const MAXIMUM_FRACTION_DIGITS = 99;

type UiAnimatedNumberParts = {
  prefix: string;
  number: string;
  postfix: string;
};

interface Props {
  value?: string;
}

const props = withDefaults(defineProps<Props>(), {
  value: ''
});

const { locale } = useI18n();

const parts = computed((): UiAnimatedNumberParts => {
  const regex =
    /^(?<prefix>\s*[^0-9.,]+\s*)?(?<number>[0-9]+(?:[\s,][0-9]+)*(?:\.\d+)?)(?<postfix>\s*[^0-9.,]+\s*)?$/;
  const match = props.value.match(regex);

  if (!match || !match.groups) {
    return {
      prefix: '',
      number: '',
      postfix: ''
    };
  }

  return {
    prefix: match.groups.prefix ? match.groups.prefix : '',
    number: match.groups.number ? match.groups.number.replace(/[\s]+/g, '') : '',
    postfix: match.groups.postfix ? match.groups.postfix : ''
  };
});

const prefix = computed((): string => parts.value.prefix);

const formattedNumber = computed((): string => parts.value.number);

const postfix = computed((): string => parts.value.postfix);

const normalizedNumber = computed((): string => {
  const testString = formatAmount(1234.56, 2, CurrencyCode.EUR, AmountMode.ValueOnly);

  const groupSeparator = testString[1];
  const decimalSeparator = testString[testString.length - 3];

  const groupSeparatorRegex = new RegExp(`[${groupSeparator}]`, 'g');

  return formattedNumber.value.replace(groupSeparatorRegex, '').replace(decimalSeparator, '.');
});

const number = computed((): number => parseFloat(normalizedNumber.value));

const minimumFractionDigits = computed((): number => {
  if (!normalizedNumber.value.includes('.')) {
    return 0;
  }

  const [, decimalPart] = normalizedNumber.value.split('.');

  return decimalPart.length;
});
</script>

<template>
  <NumberFlow
    :value="number"
    :prefix="prefix"
    :suffix="postfix"
    :locales="locale"
    :format="{ maximumFractionDigits: MAXIMUM_FRACTION_DIGITS, minimumFractionDigits }"
  />
</template>
