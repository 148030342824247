<script setup lang="ts">
import { computed, nextTick, onBeforeUnmount, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { useIntervalFn } from '@vueuse/core';
import { formatUnits } from 'viem';

import { useBrrr } from '@/composables/useBrrr';
import { type GetBrrrFlowReturn, useBrrrTransaction } from '@/composables/useBrrrTransaction';
import { useCurrency } from '@/composables/useCurrency';
import { type ClientEECode, useErrorModal } from '@/composables/useErrorModal';
import { useTokens } from '@/composables/useTokens';
import { useWagmi } from '@/composables/useWagmi';
import { assert } from '@/helpers/assert';
import {
  add,
  divide,
  greaterThan,
  isZero,
  lessThan,
  lessThanOrEqual,
  multiply,
  roundDown
} from '@/helpers/bigmath';
import { walkError } from '@/helpers/errors';
import { GasWatcher, type NetworkInfoEvent, WatchEvent } from '@/helpers/flow/gas/watchGas';
import {
  BadSwapPriceError,
  type InputTokenOrFiatMode,
  type PrepareReturn,
  Type
} from '@/helpers/flow/types';
import {
  calcLocalInsufficientToken,
  isNeedApproveOrPermit,
  isNeedTransaction
} from '@/helpers/flow/utils';
import { formatAmount, formatTokenAmount } from '@/helpers/formatters';
import { cloneEstimation } from '@/helpers/gas';
import { asyncSleep, createPromise } from '@/helpers/promises';
import { getMaxUsableBalance } from '@/helpers/tokens';
import { cloneObject, roundToPrecision } from '@/helpers/utils';
import { addSentryBreadcrumb, captureSentryException } from '@/logs/sentry';
import { AmountMode } from '@/references/axios/transactions/types';
import {
  FORM_SET_AMOUNT_DEBOUNCE,
  MAX_TOKEN_VISIBLE_DECIMALS,
  SECOND
} from '@/references/constants';
import { CurrencyCode } from '@/references/currency';
import { ExpectedError } from '@/references/ExpectedError';
import { HHError } from '@/references/HHError';
import { isBaseAssetByNetwork } from '@/references/network';
import type { EstimateContractGasCompoundReturn } from '@/references/onchain/gas';
import { AllowanceMismatchError } from '@/references/onchain/holyheld/AllowanceMismatchError';
import type { BaseBRRRFlow } from '@/references/onchain/holyheld/BRRROnChainService.types';
import {
  AllowanceFlow,
  type ExecuteWithApproveFlow,
  type ExecuteWithPermit2Flow,
  type ExecuteWithPermitFlow
} from '@/references/onchain/holyheld/flowTypes';
import { settings } from '@/references/settings/globals';
import { getBrrrToken, type TokenWithPriceAndBalance } from '@/references/tokens';
import { RouteNames } from '@/router';

import { ApproveOrPermitModalType } from '@/components/ApproveOrPermitModal.types';
import ApproveOrPermitModal from '@/components/ApproveOrPermitModal.vue';
import type { PrepareFormAmountOption } from '@/components/PrepareForm.types';
import PrepareForm from '@/components/PrepareForm.vue';

type Amounts = { tokenAmount: string; brrrAmount: string };

const { t } = useI18n();
const toast = useErrorModal();
const {
  getBrrrConversionHelper,
  applyFlowData,
  getBrrrFlow,
  prepareBrrr,
  makeGetFlowParamsFromPrepareReturn
} = useBrrrTransaction();
const { available, minBrrrAmount } = useBrrr();
const { fromUSD } = useCurrency();
const { replace } = useRouter();

interface Props {
  token: TokenWithPriceAndBalance;
  maxTokenAmountValue: string | null;
  maxEstimation: EstimateContractGasCompoundReturn | undefined;
  convertRate: string;
  amount: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:inputMode', value: InputTokenOrFiatMode): void;
  (event: 'update:amount', value: string): void;
  (event: 'update:maxTokenAmountValue', value: string): void;
  (event: 'update:maxEstimation', value: EstimateContractGasCompoundReturn | undefined): void;
  (event: 'update:convertRate', value: string): void;
  (event: 'clear'): void;
  (
    event: 'submit',
    value: {
      processEnd: () => void;
      prepare: PrepareReturn<Type.Brrr>;
      flowData:
        | ExecuteWithApproveFlow<BaseBRRRFlow>
        | ExecuteWithPermitFlow<BaseBRRRFlow>
        | ExecuteWithPermit2Flow<BaseBRRRFlow>;
      estimation?: EstimateContractGasCompoundReturn;
    }
  ): void;
}>();

const state = reactive<{
  flowData: GetBrrrFlowReturn | undefined;
  prepare: PrepareReturn<Type.Brrr> | undefined;
  approveNetworkInfo: NetworkInfoEvent | null;
  calledError: string;
  isGasPriceCalculating: boolean;
  isExecuting: boolean;
  isLoading: boolean;
  isMaxCalculating: boolean;
  isCalculatingNetworkInfo: boolean;
  isConvertRateCalculating: boolean;
  calculatingConvertRateId: number;
  isApproveOrPermitModalOpened: boolean;
  isAllowanceMismatchError: boolean;
  isExpiredApproveOrPermit: boolean;
  isFailToCheckAllowance: boolean;
  isAvailableValuePulsing: boolean;
  isSecondValuePulsing: boolean;
  isPulsingNetworkInfo: boolean;
  isCalculating: boolean;
  secondsCounter: number;
  isSwapInsufficientLiquidity: boolean;
  txApproveHash: string;
}>({
  flowData: undefined,
  prepare: undefined,
  approveNetworkInfo: null,
  calledError: '',
  isGasPriceCalculating: false,
  isExecuting: false,
  isLoading: false,
  isMaxCalculating: false,
  isConvertRateCalculating: false,
  isCalculatingNetworkInfo: false,
  calculatingConvertRateId: 0,
  isApproveOrPermitModalOpened: false,
  isAllowanceMismatchError: false,
  isExpiredApproveOrPermit: false,
  isFailToCheckAllowance: false,
  isAvailableValuePulsing: false,
  isSecondValuePulsing: false,
  isPulsingNetworkInfo: false,
  isCalculating: false,
  secondsCounter: 0,
  isSwapInsufficientLiquidity: false,
  txApproveHash: ''
});

let maxCalculatingPromise: ReturnType<typeof createPromise<void>> | undefined = undefined;
let rateCalculatingPromise: ReturnType<typeof createPromise<void>> | undefined = undefined;
let gasWatcher: GasWatcher | undefined;
let approveActionAbortSignal: AbortController = new AbortController();

const prepareFormComponent = ref<typeof PrepareForm>();

const minBrrrAmountText = computed((): string => {
  return formatAmount(minBrrrAmount.value, 2, CurrencyCode.EUR, AmountMode.CodeLast);
});

const maxBrrrAmountText = computed((): string => {
  return formatTokenAmount(
    available.value,
    brrrToken.value.decimals,
    brrrToken.value.symbol,
    AmountMode.CodeLast
  );
});

const maxAmountToken = computed((): string => {
  if (props.convertRate === '0' || props.maxTokenAmountValue === null) {
    return '0';
  }
  return roundToPrecision(props.maxTokenAmountValue, Math.min(props.token.decimals, 6));
});

const isUsingMax = computed((): boolean => {
  return props.amount === maxAmountToken.value && props.maxTokenAmountValue !== null;
});

const secondInputValue = computed((): string => {
  if (isZero(props.convertRate) || props.amount === '' || isZero(props.amount)) {
    return '0';
  }

  return amountToBrrr(props.amount);
});

const tokenAmountInEUR = computed((): string => {
  if (isZero(props.amount) || props.amount === '') {
    return '0';
  }

  return roundDown(fromUSD(multiply(props.amount, props.token.priceUSD)), 2);
});

const brrrToken = computed(() => {
  return getBrrrToken(props.token.network);
});

const secondAmountText = computed((): string => {
  return formatTokenAmount(
    secondInputValue.value,
    Math.min(MAX_TOKEN_VISIBLE_DECIMALS, brrrToken.value.decimals),
    brrrToken.value.symbol,
    AmountMode.CodeLast
  );
});

const maxAmount = computed((): string => {
  if (props.maxTokenAmountValue === null || props.convertRate === '0') {
    return '0';
  }

  return maxAmountToken.value;
});

const forceAvailableText = computed((): string | undefined => {
  if (state.isApproveOrPermitModalOpened) {
    return t('forms.usingToken', { token: props.token.symbol });
  }
  return undefined;
});

const maxAmountText = computed((): string => {
  return formatTokenAmount(
    maxAmount.value,
    Math.min(MAX_TOKEN_VISIBLE_DECIMALS, props.token.decimals),
    props.token.symbol,
    AmountMode.CodeLast
  );
});

const isFormReadyForContinue = computed((): boolean => {
  if (state.isApproveOrPermitModalOpened) {
    return false;
  }

  if (state.isLoading) {
    return false;
  }

  if (state.isMaxCalculating) {
    return false;
  }

  return true;
});

const hideSecondAmountText = computed((): boolean => {
  if (props.amount === '' || isZero(props.amount)) {
    return false;
  }
  if (state.isConvertRateCalculating || isZero(props.convertRate)) {
    return true;
  }

  return false;
});

const options = computed((): PrepareFormAmountOption[] => {
  return [
    {
      label: isUsingMax.value ? t('forms.usingMax') : t('forms.useMax'),
      value: maxAmount.value,
      disabled: isUsingMax.value || isZero(maxAmount.value),
      handler() {
        useMax();
      }
    }
  ];
});

const modalType = computed((): ApproveOrPermitModalType => {
  if (state.flowData === undefined) {
    return ApproveOrPermitModalType.Permit;
  }
  if (state.flowData.flow === AllowanceFlow.MakeApprove && state.flowData.allowanceAmount === 0n) {
    return ApproveOrPermitModalType.Reset;
  }
  if (state.flowData.flow === AllowanceFlow.MakeApprove) {
    return ApproveOrPermitModalType.Universal;
  }
  return ApproveOrPermitModalType.Permit;
});

const computedError = computed((): string => {
  if (props.amount.includes('.')) {
    if (!settings.isLimitsDisabled && lessThan(tokenAmountInEUR.value, minBrrrAmount.value)) {
      return t('forms.minimumPurchase', {
        min: minBrrrAmountText.value
      });
    }
  }

  if (props.amount === '' || isZero(props.amount)) {
    return '';
  }

  if (state.isConvertRateCalculating || state.isMaxCalculating) {
    return '';
  }

  if (greaterThan(props.amount, maxAmountToken.value)) {
    return t('forms.insufficientBalance');
  }

  return '';
});

const error = computed((): string => {
  if (state.isSwapInsufficientLiquidity) {
    return t('forms.swapInsufficientLiquidity');
  }
  return computedError.value || state.calledError;
});

const formattedGasPrice = computed((): string => {
  if (state.approveNetworkInfo === null) {
    return '';
  }

  if (state.approveNetworkInfo.networkInfo.baseAsset === undefined) {
    return '';
  }

  const baseToken = state.approveNetworkInfo.networkInfo.baseAsset;

  return formatTokenAmount(
    state.approveNetworkInfo.networkInfo.baseAssetAmount,
    Math.min(MAX_TOKEN_VISIBLE_DECIMALS, baseToken.decimals),
    baseToken.symbol,
    AmountMode.ValueOnly
  );
});

const formattedGasPriceEur = computed((): string => {
  if (state.approveNetworkInfo === null) {
    return '';
  }

  if (state.approveNetworkInfo.networkInfo.baseAsset === undefined) {
    return '';
  }

  const eurGasPrice = fromUSD(state.approveNetworkInfo.networkInfo.txGasPriceUSD);

  if (lessThan(eurGasPrice, 0.01)) {
    const formatted = formatAmount(0.01, 2, CurrencyCode.EUR, AmountMode.SymbolFirst);
    return `< ${formatted}`;
  } else {
    return formatAmount(eurGasPrice, 2, CurrencyCode.EUR, AmountMode.SymbolFirst);
  }
});

const isGasPriceCalculating = computed((): boolean => {
  if (state.flowData !== undefined) {
    if (!isNeedTransaction(state.flowData)) {
      return false;
    }
  }
  if (state.approveNetworkInfo === null) {
    return true;
  }
  if (state.approveNetworkInfo.networkInfo.baseAsset === undefined) {
    return true;
  }

  return state.isCalculatingNetworkInfo;
});

const blockTime = computed((): number => {
  if (state.approveNetworkInfo === null) {
    return 0;
  }

  return state.approveNetworkInfo.networkInfo.blockTime;
});

const isInsufficientGas = computed((): boolean => {
  if (state.approveNetworkInfo === null) {
    return true;
  }

  if (state.flowData === undefined) {
    return true;
  }

  if (state.approveNetworkInfo.networkInfo.baseAsset === undefined) {
    return true;
  }

  const flowAmount = isBaseAssetByNetwork(
    state.flowData.inputToken.address,
    state.flowData.inputToken.network
  )
    ? formatUnits(state.flowData.inputAmountInWei, state.flowData.inputToken.decimals)
    : '0';

  const amount = add(flowAmount, state.approveNetworkInfo.networkInfo.baseAssetAmount);

  return calcLocalInsufficientToken(
    state.approveNetworkInfo.networkInfo.baseAsset,
    amount,
    useTokens().assets.value
  );
});

async function calculateConvertRate(silent = false): Promise<void> {
  const token = props.token;
  state.calculatingConvertRateId += 1;
  const calcId = state.calculatingConvertRateId;

  if (!silent) {
    state.isConvertRateCalculating = true;
    state.isMaxCalculating = true;
  }
  try {
    const maxUsableEstimation = await getMaxUsableBalance(token);
    emit('update:maxTokenAmountValue', maxUsableEstimation.max);
    emit('update:maxEstimation', maxUsableEstimation.estimation);

    maxCalculatingPromise?.resolve();
    state.isMaxCalculating = false;

    const helper = getBrrrConversionHelper();

    let rateAmount = token.balance;
    if (lessThanOrEqual(rateAmount, 0)) {
      rateAmount = '1';
    }
    const brrrAmount = (await helper.convertTokenToBRRR(token, rateAmount)).brrrAmount;

    const rate = divide(rateAmount, brrrAmount);
    if (calcId < state.calculatingConvertRateId) {
      return;
    }

    rateCalculatingPromise?.resolve();
    emit('update:convertRate', rate);
    state.isConvertRateCalculating = false;
    state.isSwapInsufficientLiquidity = false;
    await nextTick();
  } catch (err) {
    if (
      walkError(
        err,
        (e) => e instanceof ExpectedError && e.getCode() === 'swapInsufficientLiquidity'
      )
    ) {
      state.isSwapInsufficientLiquidity = true;
    }
    addSentryBreadcrumb({
      level: 'error',
      message: 'fail to calculate rate',
      data: {
        error: err,
        token: token
      }
    });
    captureSentryException(err);
  }
}

let debounceTimeoutId: ReturnType<typeof setTimeout> | undefined = undefined;

function updateAmount(amount: string): void {
  emit('update:amount', amount);
  state.calledError = '';
  clearTimeout(debounceTimeoutId);
  debounceTimeoutId = setTimeout(() => {
    debouncedValidate();
  }, FORM_SET_AMOUNT_DEBOUNCE);
}

function debouncedValidate(validEmptyString = true): void {
  if (state.isApproveOrPermitModalOpened) {
    return;
  }

  if (error.value) {
    return;
  }

  if (props.amount === '' || isZero(props.amount) || props.amount.length === 0) {
    if (!validEmptyString) {
      state.calledError = t('forms.minimumPurchase', { min: minBrrrAmountText.value });
    }

    return;
  }

  if (!settings.isLimitsDisabled && lessThan(tokenAmountInEUR.value, minBrrrAmount.value)) {
    state.calledError = t('forms.minimumPurchase', {
      min: minBrrrAmountText.value
    });
    return;
  }
}

function validateWithMaxAmount(amounts: Amounts): string | null {
  if (isZero(amounts.tokenAmount) || isZero(amounts.brrrAmount)) {
    state.calledError = t('forms.minimumPurchase', { min: minBrrrAmountText.value });
    return null;
  }

  if (greaterThan(amounts.tokenAmount, props.maxTokenAmountValue ?? 0)) {
    if (isZero(props.maxTokenAmountValue ?? 0)) {
      state.calledError = t('forms.insufficientBalance');
      return null;
    }

    return cloneObject(props.maxTokenAmountValue);
  }

  if (!settings.isLimitsDisabled && lessThan(amounts.brrrAmount, minBrrrAmount.value)) {
    state.calledError = t('forms.minimumPurchase', { min: minBrrrAmountText.value });
    return null;
  }

  if (!settings.isLimitsDisabled && greaterThan(secondInputValue.value, available.value)) {
    state.calledError = t('forms.maximumPurchase', { max: maxBrrrAmountText.value });
    return null;
  }

  state.calledError = '';

  return null;
}

function amountToBrrr(tokenAmount: string): string {
  if (lessThanOrEqual(tokenAmount, '0')) {
    return '0';
  }

  return roundToPrecision(divide(tokenAmount, props.convertRate), 2);
}

async function useMax(): Promise<void> {
  if (state.isMaxCalculating) {
    return;
  }

  emit('update:amount', maxAmount.value);
}

async function reloadFlowData(prepare: PrepareReturn<Type.Brrr>): Promise<void> {
  try {
    addSentryBreadcrumb({
      level: 'info',
      message: 'reloading flow data'
    });

    const getFlowParams = await makeGetFlowParamsFromPrepareReturn(prepare, async (preResult) => {
      const { address } = useWagmi();
      assert(address.value !== undefined, new ExpectedError('notConnected'));
      preResult.tokensReceiver = address.value;
      return preResult;
    });

    const flowData = await getBrrrFlow(getFlowParams);

    state.flowData = flowData;
    addSentryBreadcrumb({
      level: 'info',
      message: 'reloaded flow data',
      data: {
        flowType: flowData.flow
      }
    });

    gasWatcher?.destroy();
    gasWatcher = new GasWatcher(flowData);
    subscribeGasWatcher(gasWatcher);
    gasWatcher.start();
  } catch (err) {
    if (err instanceof ExpectedError) {
      toast.auto(err);
      return;
    }

    toast.auto(
      new ExpectedError<ClientEECode>('generateBrrrFlowFailed', {
        cause: err,
        sentryHandle: true
      })
    );
    return;
  }
}

async function onApproveAction(): Promise<void> {
  if (state.isExecuting) {
    return;
  }
  gasWatcher?.destroy();
  gasWatcher = undefined;

  const flowData = state.flowData;
  const prepare = state.prepare;

  if (flowData === undefined || prepare === undefined) {
    return;
  }

  addSentryBreadcrumb({
    level: 'info',
    message: 'start approve (or permit(or permit v2)) with prepare data',
    data: {
      prepare: prepare
    }
  });

  state.isExecuting = true;

  let estimation: EstimateContractGasCompoundReturn | undefined = undefined;

  if (state.approveNetworkInfo !== null && state.approveNetworkInfo.estimation !== undefined) {
    estimation = state.approveNetworkInfo.estimation;
  }

  approveActionAbortSignal.abort();
  approveActionAbortSignal = new AbortController();

  try {
    const result = await applyFlowData(flowData, estimation, approveActionAbortSignal.signal);
    if (result === true) {
      //thinking, this is never
      return;
    }

    addSentryBreadcrumb({
      level: 'info',
      message: 'received new flow data after approve or permit (maybe v2)',
      data: {
        flowData: result
      }
    });

    state.flowData = result;

    if (isNeedApproveOrPermit(result)) {
      state.isApproveOrPermitModalOpened = true;
      state.approveNetworkInfo = null;
      state.isCalculatingNetworkInfo = true;
      state.isPulsingNetworkInfo = false;

      if (isNeedTransaction(result)) {
        gasWatcher = new GasWatcher(result);
        subscribeGasWatcher(gasWatcher);
        gasWatcher.start();
      }
      state.isExecuting = false;
      return;
    }
    const { resolve, wait } = createPromise<void>();
    emit('submit', {
      processEnd: () => {
        addSentryBreadcrumb({
          level: 'debug',
          message: 'received process emited flow data ended'
        });
        resolve();
      },
      prepare: prepare,
      flowData: result,
      estimation: props.maxEstimation ? cloneEstimation(props.maxEstimation) : undefined
    });

    await wait();

    state.isExecuting = false;
    state.isApproveOrPermitModalOpened = false;
    state.approveNetworkInfo = null;
    state.isPulsingNetworkInfo = false;
    state.isCalculatingNetworkInfo = false;
    return;
  } catch (err) {
    if (err === 'aborted') {
      //user close modal, that trigger signal, resolved as "aborted" ඞ
      toast.auto(new ExpectedError<ClientEECode>('executeAbortedByUser'));
      return;
    }

    if (err instanceof ExpectedError && (err.getCode() as ClientEECode) === 'brrrUnavailable') {
      toast.auto(err);
      replace({ name: RouteNames.Brrr });
      return;
    }

    if (
      walkError(
        err,
        (e) =>
          e instanceof ExpectedError &&
          ((e as ExpectedError<ClientEECode>).getCode() === 'brrrBackendCheckExpired' ||
            (e as ExpectedError<ClientEECode>).getCode() === 'brrrPermitExpired')
      )
    ) {
      addSentryBreadcrumb({
        level: 'warning',
        message: 'permit (maybe v2) expired error',
        data: {
          error: err
        }
      });

      await reloadFlowData(prepare);
      state.isExpiredApproveOrPermit = true;
      return;
    }

    if (walkError(err, (e) => e instanceof AllowanceMismatchError)) {
      addSentryBreadcrumb({
        level: 'warning',
        message: 'allowance mismatch error',
        data: {
          error: err
        }
      });
      captureSentryException(err);

      await reloadFlowData(prepare);
      state.isAllowanceMismatchError = true;
      return;
    }
    if (
      walkError(
        err,
        (e) =>
          e instanceof HHError &&
          e.message === 'Failed to check current allowance after successful approve'
      )
    ) {
      addSentryBreadcrumb({
        level: 'warning',
        message: 'fail to check allowance after successful approve',
        data: {
          error: err
        }
      });
      captureSentryException(err);

      await reloadFlowData(prepare);
      state.isFailToCheckAllowance = true;
      return;
    }
    if (err instanceof ExpectedError) {
      toast.auto(err);
      return;
    }

    toast.auto(
      new ExpectedError<ClientEECode>('generateBrrrFlowFailed', {
        cause: err,
        sentryHandle: true
      })
    );
    return;
  } finally {
    state.isExecuting = false;
  }
}

function closeApproveOrPermitModal(): void {
  addSentryBreadcrumb({
    level: 'debug',
    message: '[BRRR] close approve ore permit modal'
  });
  approveActionAbortSignal.abort();
  debouncedValidate();
  gasWatcher?.destroy();
  gasWatcher = undefined;
  state.isApproveOrPermitModalOpened = false;
  state.approveNetworkInfo = null;
  state.isPulsingNetworkInfo = false;
  state.isCalculatingNetworkInfo = false;
  state.isAllowanceMismatchError = false;
  state.isExecuting = false;
  state.flowData = undefined;
  state.prepare = undefined;

  addSentryBreadcrumb({
    level: 'debug',
    message: '[BRRR] close approve ore permit modal done'
  });
}

async function onClickContinueButton(): Promise<void> {
  addSentryBreadcrumb({
    level: 'debug',
    message: '[BRRR] click next from prepare'
  });

  debouncedValidate(false);

  if (error.value) {
    prepareFormComponent.value?.shake();
    prepareFormComponent.value?.focus();
    return;
  }

  if (!isFormReadyForContinue.value) {
    return;
  }

  state.isLoading = true;

  //wait for numpad send all data
  await asyncSleep(FORM_SET_AMOUNT_DEBOUNCE);

  await rateCalculatingPromise?.wait();
  await maxCalculatingPromise?.wait();

  if (error.value) {
    prepareFormComponent.value?.shake();
    prepareFormComponent.value?.focus();
    state.isLoading = false;
    return;
  }

  try {
    addSentryBreadcrumb({
      level: 'debug',
      message: 'cleanup some state field'
    });
    state.prepare = undefined;
    state.flowData = undefined;

    let amount = props.amount;

    if (props.amount === maxAmountToken.value && props.maxTokenAmountValue !== null) {
      addSentryBreadcrumb({
        level: 'debug',
        message: 'detected use max for token value, use max token amount, and use TOKEN input mode',
        data: {
          amount: props.maxTokenAmountValue
        }
      });
      await calculateConvertRate(true);
      amount = props.maxTokenAmountValue;
      emit('update:amount', maxAmountToken.value);
    }

    let prepare = await prepareBrrr(cloneObject(props.token), cloneObject(amount));

    const validateWithMaxResult = validateWithMaxAmount({
      tokenAmount: prepare.tokenAmount,
      brrrAmount: prepare.totalSent
    });

    //check only that field, computed validate too fast
    if (state.calledError) {
      state.isLoading = false;
      prepareFormComponent.value?.shake();
      prepareFormComponent.value?.focus();
      return;
    }

    if (validateWithMaxResult !== null) {
      addSentryBreadcrumb({
        level: 'debug',
        message:
          'validate prepare with max amount detect incorrect value, recalc prepare with new amount and TOKEN input mode',
        data: {
          amount: validateWithMaxResult,
          invalidePrepare: prepare
        }
      });
      prepare = await prepareBrrr(cloneObject(props.token), validateWithMaxResult);
    }

    addSentryBreadcrumb({
      level: 'debug',
      message: 'received prepare object',
      data: {
        prepare: prepare
      }
    });

    const currentAmountInEUR = prepare.totalSent;

    if (!settings.isLimitsDisabled && lessThan(currentAmountInEUR, minBrrrAmount.value)) {
      state.calledError = t('forms.minimumPurchase', { min: minBrrrAmountText.value });
      state.isLoading = false;
      prepareFormComponent.value?.shake();
      prepareFormComponent.value?.focus();
      return;
    }

    const getFlowParams = await makeGetFlowParamsFromPrepareReturn(prepare, async (preResult) => {
      const { address } = useWagmi();
      assert(address.value !== undefined, new ExpectedError('notConnected'));
      preResult.tokensReceiver = address.value;
      return preResult;
    });

    addSentryBreadcrumb({
      level: 'debug',
      message: 'received params for flow getter',
      data: { params: getFlowParams }
    });

    const flowData = await getBrrrFlow(getFlowParams);

    addSentryBreadcrumb({
      level: 'debug',
      message: 'received flowdata object with flow',
      data: { flow: flowData.flow }
    });

    if (!isNeedApproveOrPermit(flowData)) {
      //go to next step - review tx
      const { resolve, wait } = createPromise<void>();
      emit('submit', {
        processEnd: () => {
          addSentryBreadcrumb({
            level: 'debug',
            message: 'received process emited flow data ended'
          });
          resolve();
        },
        prepare: prepare,
        flowData: flowData,
        estimation: props.maxEstimation ? cloneEstimation(props.maxEstimation) : undefined
      });
      await wait();
      state.isApproveOrPermitModalOpened = false;
      return;
    }

    state.prepare = prepare;
    state.flowData = flowData;
    state.isApproveOrPermitModalOpened = true;

    if (isNeedTransaction(flowData)) {
      gasWatcher = new GasWatcher(flowData);
      subscribeGasWatcher(gasWatcher);
      gasWatcher.start();
    }

    return;
  } catch (err) {
    if (
      walkError(
        err,
        (e) => e instanceof ExpectedError && e.getCode() === 'swapInsufficientLiquidity'
      )
    ) {
      state.isSwapInsufficientLiquidity = true;
      prepareFormComponent.value?.shake();
      prepareFormComponent.value?.focus();
      return;
    }
    if (err instanceof BadSwapPriceError) {
      captureSentryException(err);
      toast.auto(t('toasts.badPrices'));
      return;
    }
    if (err instanceof ExpectedError) {
      toast.auto(err);
    } else {
      toast.auto(
        new ExpectedError<ClientEECode>('brrrDataFetchFailed', {
          cause: err,
          sentryHandle: true
        })
      );
    }
  } finally {
    addSentryBreadcrumb({
      level: 'debug',
      message: '[BRRR] click next from prepare done'
    });
    state.isLoading = false;
  }
}

function subscribeGasWatcher(gw: GasWatcher): void {
  gw.addEventListener('reloadingNetworkInfo', onReloadingNetworkInfo);
  gw.addEventListener('networkInfo', onNewNetworkInfo);
  gw.addEventListener('error', onWatchGasError);
  gw.addEventListener('updateTick', updateTick);
}

function onWatchGasError(e: WatchEvent<'error'>): void {
  captureSentryException(e.options.error);
}

function onReloadingNetworkInfo(): void {
  state.isCalculatingNetworkInfo = true;
}

function updateTick(e: WatchEvent<'updateTick'>): void {
  if (e.options.total - e.options.now === 2) {
    state.isPulsingNetworkInfo = true;
  }
}

function onNewNetworkInfo(e: WatchEvent<'networkInfo'>): void {
  addSentryBreadcrumb({
    level: 'info',
    message: 'received new network info',
    data: e.options
  });
  state.approveNetworkInfo = e.options;
  state.isPulsingNetworkInfo = false;
  state.isCalculatingNetworkInfo = false;
}

useIntervalFn(
  () => {
    if (state.isConvertRateCalculating) {
      return;
    }
    if (state.isCalculating) {
      return;
    }
    if (state.isApproveOrPermitModalOpened) {
      return;
    }
    if (state.isLoading) {
      return;
    }
    switch (state.secondsCounter) {
      case 13:
        state.isAvailableValuePulsing = true;
        if (!hideSecondAmountText.value) {
          state.isSecondValuePulsing = true;
        }
        state.secondsCounter += 1;
        prepareFormComponent.value?.updateTokenPriceData();
        return;
      case 15:
        state.isCalculating = true;
        calculateConvertRate(true).finally(() => {
          state.isCalculating = false;
          state.isAvailableValuePulsing = false;
          state.isSecondValuePulsing = false;
        });
        state.secondsCounter = 0;
        return;
      default:
        state.secondsCounter += 1;
        return;
    }
  },
  SECOND,
  { immediate: true }
);

watch(hideSecondAmountText, () => {
  if (hideSecondAmountText.value) {
    state.isSecondValuePulsing = true;
  } else {
    state.isSecondValuePulsing = false;
  }
});

maxCalculatingPromise = createPromise();
rateCalculatingPromise = createPromise();
calculateConvertRate();

state.isAvailableValuePulsing = true;
Promise.allSettled([maxCalculatingPromise.wait(), rateCalculatingPromise.wait()]).then(() => {
  state.isAvailableValuePulsing = false;
});

onBeforeUnmount(() => {
  gasWatcher?.destroy();
});
</script>

<template>
  <section class="prepare-form-brrr">
    <PrepareForm
      ref="prepareFormComponent"
      :token="props.token"
      :secondary-token="brrrToken"
      input-mode="TOKEN"
      :amount="props.amount"
      :options="options"
      :available-text="forceAvailableText"
      :max-amount-text="maxAmountText"
      :second-amount-text="secondAmountText"
      :is-max-amount-pulsing="state.isAvailableValuePulsing"
      :is-second-amount-pulsing="state.isSecondValuePulsing"
      :error="error"
      :is-submit-button-disabled="!isFormReadyForContinue"
      :is-submit-button-loading="state.isLoading"
      is-toggle-button-readonly
      @update:input-mode="emit('update:inputMode', $event)"
      @update:amount="updateAmount"
      @submit="onClickContinueButton"
    />
    <ApproveOrPermitModal
      :model-value="state.isApproveOrPermitModalOpened && !!state.flowData"
      :is-need-transaction="isNeedTransaction(state.flowData)"
      :formatted-gas-price="formattedGasPrice"
      :formatted-gas-price-eur="formattedGasPriceEur"
      :calculating-gas-price="isGasPriceCalculating"
      :insufficient-gas="isInsufficientGas"
      :token="state.flowData?.inputToken"
      :block-time="blockTime"
      :token-amount="state.prepare?.tokenAmount"
      :executing="state.isExecuting"
      :has-expired-prefix="state.isExpiredApproveOrPermit"
      :modal-type="modalType"
      :is-user-modified-allowance="state.isAllowanceMismatchError"
      :is-pulsing="state.isPulsingNetworkInfo"
      :approve-hash="state.txApproveHash"
      @approve="onApproveAction"
      @decline="closeApproveOrPermitModal"
    />
  </section>
</template>

<style scoped>
.prepare-form-brrr {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.prepare-form-brrr__toggle-icon {
  margin: 0 4px 0 0;
}
</style>
