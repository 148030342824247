<script setup lang="ts">
import type { StyleValue } from 'vue';
import { computed } from 'vue';

interface Props {
  width: string;
  height: string;
  borderRadius?: string;
  tag?: string;
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'div',
  borderRadius: '999px'
});

const styleObject = computed((): StyleValue => {
  const { width, height, borderRadius } = props;
  return { width, height, borderRadius, maxWidth: width, maxHeight: height };
});
</script>

<template>
  <component :is="props.tag" class="ui-skeleton" :style="styleObject" />
</template>

<style scoped>
.ui-skeleton {
  position: relative;
  width: initial;
  max-width: none;
  height: initial;
  max-height: none;
  overflow: hidden;
  background-color: var(--color-skeleton-start);
  border-radius: 20px;
  transform: translateZ(0);
  cursor: wait;
}

.ui-skeleton::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    var(--color-skeleton-start),
    var(--color-skeleton-end),
    var(--color-skeleton-start)
  );
  animation: ui-skeleton 1500ms linear infinite;
  content: '';
}

@keyframes ui-skeleton {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
</style>
