import {
  type Address,
  ChainDoesNotSupportContract,
  ClientChainNotConfiguredError,
  type EstimateContractGasParameters,
  type FeeValuesEIP1559,
  type FeeValuesLegacy,
  getChainContractAddress,
  type PublicClient
} from 'viem';
import {
  type EstimateContractL1FeeParameters,
  type PublicActionsL2,
  publicActionsL2
} from 'viem/op-stack';

import { assert } from '@/helpers/assert';
import { add, multiply, type NumericValue, toBigInt } from '@/helpers/bigmath';
import { addSentryBreadcrumb } from '@/logs/sentry';
import { deriveChainId } from '@/references/onchain/adapters';

const gasPriceOracleAddressesOverrides: Record<number, Address | undefined> = {
  // mantle
  169: '0x420000000000000000000000000000000000000F',
  // blast
  81457: '0x420000000000000000000000000000000000000F',
  // mode
  34443: '0x420000000000000000000000000000000000000F'
};

/**
 *  Adds `bufferSize`% buffer to the value
 * @param gas - a value to add a buffer to
 * @param bufferSize - buffer size in percents
 */
export const addGasBuffer = (gas: bigint, bufferSize: bigint): bigint => {
  return (gas * (100n + bufferSize)) / 100n;
};

const extendPublicClient = (publicClient: PublicClient): PublicClient & PublicActionsL2 => {
  const possibleClient = publicClient as PublicClient & PublicActionsL2;
  if (
    possibleClient.estimateContractL1Fee !== undefined &&
    typeof possibleClient.estimateContractL1Fee === 'function'
  ) {
    return possibleClient;
  }

  return publicClient.extend(publicActionsL2());
};

export type EstimateContractGasCompoundReturn = {
  /**
   * Gas limit (in current chain)
   */
  gasLimit: bigint;
  /**
   * Total fee of tx (gasPrice or baseFeePerGas + maxPriorityFeePerGas) * gasLimit + syncFee
   */
  totalFee: bigint;
  /**
   * Fee values object
   */
  feeValues: FeeValuesLegacy | FeeValuesEIP1559;
  /**
   * L1 sync fee
   */
  syncFee: bigint;
  /**
   * A handle to reload fresh estimation upon already provided params
   * @param usePreviousGasLimit - skip gas limit estimation, reload syncFee, gasPrice and totalFee
   */
  reload: (usePreviousGasLimit?: boolean) => Promise<EstimateContractGasCompoundReturn>;
};

type LoadFeesReturn = {
  totalFee: bigint;
  syncFee: bigint;
  feeValues: FeeValuesLegacy | FeeValuesEIP1559;
};

/**
 * Estimates contract gas limit, returns gasPrice, sync fee (if present), and total fee
 * @param publicClient - viem's public client
 * @param senderAddress - client's connected wallet address
 * @param gasBuffer - a gas buffer to apply to estimated value (in percents)
 */
export const estimateContractGasCompound = (
  publicClient: PublicClient,
  senderAddress: Address,
  gasBuffer: bigint
): ((
  args: EstimateContractGasParameters & {
    account: Required<EstimateContractGasParameters>['account'];
  },
  estimatedGasLimit?: bigint
) => Promise<EstimateContractGasCompoundReturn>) => {
  if (publicClient.chain == null) {
    throw new Error('Failed to extend public client', {
      cause: new ClientChainNotConfiguredError()
    });
  }

  const publicClient_ = extendPublicClient(publicClient);

  return async (params, estimatedGasLimit) => {
    const loadFeeType = async (chainId: number): Promise<'eip1559' | 'legacy'> => {
      try {
        if (chainId === 324) {
          return 'legacy';
        }
        const block = await publicClient_.getBlock({
          blockTag: 'latest',
          includeTransactions: false
        });
        return block.baseFeePerGas !== undefined &&
          block.baseFeePerGas !== null &&
          typeof block.baseFeePerGas === 'bigint'
          ? 'eip1559'
          : 'legacy';
      } catch (error) {
        throw new Error('Failed to get block to find feeType', {
          cause: error
        });
      }
    };

    const loadGasLimit = async (): Promise<bigint> => {
      if (estimatedGasLimit) {
        addSentryBreadcrumb({
          level: 'debug',
          message: 'gasLimit was previously estimated, reusing the old value',
          data: { estimatedGasLimit }
        });
        return estimatedGasLimit;
      }

      try {
        const gasLimit = await publicClient_.estimateContractGas(params);
        const withBuffer = addGasBuffer(gasLimit, gasBuffer);
        if (gasBuffer !== undefined) {
          addSentryBreadcrumb({
            level: 'debug',
            message: `Added gas buffer of ${gasBuffer}%. Estimated ${gasLimit}, with buffer ${withBuffer}`,
            data: {
              gasLimit,
              withBuffer,
              gasBuffer
            }
          });
        }
        return withBuffer;
      } catch (error) {
        throw new Error('Failed to estimate current chain transaction', {
          cause: error
        });
      }
    };

    const loadFees = async (
      gasLimit: bigint,
      feeType: 'eip1559' | 'legacy',
      chainId: number
    ): Promise<LoadFeesReturn> => {
      let estimateL1Fees = false;
      let gasPriceOracleAddress: Address | undefined;

      try {
        assert(publicClient_.chain !== undefined, 'Public client does not have chain set up');
        getChainContractAddress({
          chain: publicClient_.chain,
          contract: 'gasPriceOracle'
        });
        estimateL1Fees = true;
      } catch (error) {
        if (error instanceof ChainDoesNotSupportContract) {
          gasPriceOracleAddress = gasPriceOracleAddressesOverrides[chainId];
          if (gasPriceOracleAddress === undefined) {
            addSentryBreadcrumb({
              level: 'info',
              message: `Chain ${chainId} does not support gasPriceOracle contract, assume the chain is no OP stack chain`,
              data: { error }
            });
            estimateL1Fees = false;
          }
        }
      }

      let feeValues;
      try {
        feeValues = await publicClient_.estimateFeesPerGas({
          chain: publicClient_.chain,
          type: feeType
        });
        addSentryBreadcrumb({
          level: 'info',
          message: 'Estimated fees per gas',
          data: {
            feeValues,
            gasLimit
          }
        });
      } catch (error) {
        throw new Error('Failed to estimate fees per gas', { cause: error });
      }

      if (!estimateL1Fees || feeValues.gasPrice !== undefined) {
        addSentryBreadcrumb({
          level: 'info',
          message: 'Estimating fees per gas for legacy / eip-1559 without OP stack tweaks',
          data: { estimateL1Fees }
        });

        if (feeValues.gasPrice !== undefined) {
          const totalFee = gasLimit * feeValues.gasPrice;
          return {
            syncFee: 0n,
            totalFee,
            feeValues
          };
        }

        const totalFee = gasLimit * feeValues.maxFeePerGas;
        return {
          syncFee: 0n,
          totalFee,
          feeValues
        };
      }

      const p: EstimateContractL1FeeParameters = {
        abi: params.abi,
        account: params.account,
        address: params.address,
        functionName: params.functionName,
        chain: publicClient_.chain,
        args: params.args,
        gas: gasLimit,
        value: params.value,
        gasPriceOracleAddress,
        ...feeValues
      } as const;

      let l1Fee;
      try {
        l1Fee = await publicClient_.estimateContractL1Fee(p);
        addSentryBreadcrumb({
          level: 'info',
          message: 'Estimated L1 fee',
          data: {
            l1Fee,
            gasLimit
          }
        });
      } catch (error) {
        throw new Error('Failed to estimate L1 sync fee', {
          cause: error
        });
      }

      const totalFee = l1Fee + gasLimit * feeValues.maxFeePerGas;
      return {
        syncFee: l1Fee,
        feeValues,
        totalFee
      };
    };

    const chainId = await deriveChainId(publicClient_);
    const [gasLimit, feeType] = await Promise.all([loadGasLimit(), loadFeeType(chainId)]);

    const reload = (usePreviousGasLimit = false): Promise<EstimateContractGasCompoundReturn> =>
      estimateContractGasCompound(
        publicClient_,
        senderAddress,
        gasBuffer
      )(params, usePreviousGasLimit ? gasLimit : undefined);

    const fees = await loadFees(gasLimit, feeType, chainId);

    addSentryBreadcrumb({
      level: 'info',
      message: 'Estimated contract gas compound',
      data: {
        gasLimit,
        fees
      }
    });

    return {
      gasLimit,
      totalFee: fees.totalFee,
      feeValues: fees.feeValues,
      syncFee: fees.syncFee,
      reload
    };
  };
};
export const applyMultiplier = <T extends FeeValuesEIP1559 | FeeValuesLegacy>(
  feeValues: T,
  multiplier: NumericValue
): T => {
  if (feeValues.gasPrice !== undefined) {
    return {
      ...feeValues,
      gasPrice: toBigInt(multiply(feeValues.gasPrice, multiplier))
    };
  }

  const multipliedBaseFee = multiply(
    feeValues.maxFeePerGas - feeValues.maxPriorityFeePerGas,
    multiplier
  );
  const multipliedMaxPriorityFee = multiply(feeValues.maxPriorityFeePerGas, multiplier);

  return {
    ...feeValues,
    maxFeePerGas: toBigInt(add(multipliedBaseFee, multipliedMaxPriorityFee)),
    maxPriorityFeePerGas: toBigInt(multipliedMaxPriorityFee)
  };
};
