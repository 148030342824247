import { formatUnits } from 'viem';

import type { GetBrrrFlowReturn } from '@/composables/useBrrrTransaction';
import { useTokenPrice } from '@/composables/useTokenPrice';
import { useWagmi } from '@/composables/useWagmi';
import { greaterThan, lessThanOrEqual, multiply } from '@/helpers/bigmath';
import { isNeedTransaction } from '@/helpers/flow/utils';
import { cloneEstimation, getClonedGasEstimationByNetwork } from '@/helpers/gas';
import { addSentryBreadcrumb } from '@/logs/sentry';
import { SECOND } from '@/references/constants';
import { HHError } from '@/references/HHError';
import type { Network } from '@/references/network';
import { getBaseAssetData } from '@/references/network';
import type { EstimateContractGasCompoundReturn } from '@/references/onchain/gas';
import type { Token, TokenWithPrice } from '@/references/tokens';

const UPDATE_INTERVAL = 15;

type BaseNetworkInfo = {
  network: Network;
  blockTime: number;
};

export type NetworkInfoWithGasInfo = BaseNetworkInfo & {
  baseAsset: TokenWithPrice;
  baseAssetAmount: string;
  txGasPriceUSD: string;
};

export type NetworkInfoWithoutGasInfo = BaseNetworkInfo & {
  baseAsset: undefined;
};

export type NetworkInfo = NetworkInfoWithGasInfo | NetworkInfoWithoutGasInfo;

export type NetworkInfoEvent =
  | {
      networkInfo: NetworkInfoWithGasInfo;
      estimation: EstimateContractGasCompoundReturn;
    }
  | {
      networkInfo: NetworkInfoWithoutGasInfo;
      estimation: undefined;
    };

type WatchEvents = {
  started: undefined;

  error: { error: HHError };

  reloadingNetworkInfo: undefined;
  networkInfo: NetworkInfoEvent;

  updateTick: {
    now: number;
    total: number;
  };
};

type WatchEventListener<T extends keyof WatchEvents> = (e: WatchEvent<T>) => void;
type WatchEventListenerObject<T extends keyof WatchEvents> = {
  handleEvent(e: WatchEvent<T>): void;
};
type WatchEventListenerOrEventListenerObject<T extends keyof WatchEvents> =
  | EventListenerOrEventListenerObject
  | WatchEventListener<T>
  | WatchEventListenerObject<T>;

export type CalcGasPriceReturn = {
  estimation: EstimateContractGasCompoundReturn;
  priceUSD: string;
  baseToken: Token;
  baseTokenPrice: string;
  baseTokenAmount: string;
  originalBaseTokenAmount: string;
};

export class GasWatcher extends EventTarget {
  private interval: ReturnType<typeof setInterval> | undefined;
  private updateIntervalCounter = 0;
  private lastEstimateResult: CalcGasPriceReturn | undefined = undefined;
  private calculating: boolean = false;

  private listeners: Array<{
    type: keyof WatchEvents;
    callback: WatchEventListenerOrEventListenerObject<keyof WatchEvents>;
  }> = [];

  constructor(
    private flowData: GetBrrrFlowReturn,
    private blockTime: number = 0
  ) {
    super();
    this.reloadBlockTime();
  }

  addEventListener<T extends keyof WatchEvents>(
    type: T,
    callback: WatchEventListenerOrEventListenerObject<T> | null,
    options?: AddEventListenerOptions | boolean
  ) {
    if (callback !== null) {
      this.listeners.push({
        type,
        callback: callback as WatchEventListenerOrEventListenerObject<keyof WatchEvents>
      });
    }
    super.addEventListener(type, callback as EventListenerOrEventListenerObject, options);
  }

  removeEventListener<T extends keyof WatchEvents>(
    type: T,
    callback: WatchEventListenerOrEventListenerObject<T> | null,
    options?: EventListenerOptions | boolean
  ) {
    super.removeEventListener(type, callback as EventListenerOrEventListenerObject, options);
  }

  stop() {
    this.updateIntervalCounter = 0;
    clearInterval(this.interval);
  }

  destroy() {
    this.stop();
    this.lastEstimateResult = undefined;
    this.listeners.forEach((e) => {
      this.removeEventListener(e.type, e.callback);
    });
  }

  private calcGasPrice = async (
    network: Network,
    estimation: EstimateContractGasCompoundReturn,
    useCurrentFlowDataEstimation = false
  ): Promise<CalcGasPriceReturn> => {
    addSentryBreadcrumb({
      level: 'debug',
      message: 'calculate GasPrice',
      data: {
        network,
        estimation,
        useCurrentFlowDataEstimation
      }
    });

    try {
      const baseToken = getBaseAssetData(network);

      const [est, originalTotalFee] = await getClonedGasEstimationByNetwork(
        network,
        estimation,
        useCurrentFlowDataEstimation
      );

      const baseTokenAmount = formatUnits(est.totalFee, baseToken.decimals);
      const originalBaseTokenAmount = formatUnits(originalTotalFee, baseToken.decimals);

      const baseTokenPrice = await useTokenPrice().getTokenPrice(
        baseToken.address,
        baseToken.network
      );
      const price = multiply(baseTokenAmount, baseTokenPrice);

      addSentryBreadcrumb({
        level: 'debug',
        message: 'use estimation for display',
        data: {
          est: est,
          baseToken,
          baseTokenAmount,
          baseTokenPrice,
          price
        }
      });

      return {
        estimation: est,
        priceUSD: price,
        baseToken,
        baseTokenAmount,
        originalBaseTokenAmount,
        baseTokenPrice
      };
    } catch (e) {
      addSentryBreadcrumb({
        level: 'debug',
        message: 'fail to calc network fee',
        data: {
          estimation: estimation,
          error: e
        }
      });
      throw new HHError('fail to calc network fee', { cause: e });
    }
  };

  public start(): void {
    if (this.interval !== undefined) {
      return;
    }

    this.interval = setInterval(() => this.tick(), SECOND);
    this.dispatchEvent(new WatchEvent('started', undefined, undefined));
    this.tickWork(true, true);
  }

  private async tick(): Promise<void> {
    if (this.updateIntervalCounter < UPDATE_INTERVAL) {
      this.updateIntervalCounter++;
      this.dispatchEvent(
        new WatchEvent('updateTick', undefined, {
          now: this.updateIntervalCounter,
          total: UPDATE_INTERVAL
        })
      );
      return;
    } else {
      this.updateIntervalCounter = 0;
      this.dispatchEvent(
        new WatchEvent('updateTick', undefined, {
          now: this.updateIntervalCounter,
          total: UPDATE_INTERVAL
        })
      );
    }

    this.tickWork(false);
  }

  private async tickWork(
    useCurrentEstimation = false,
    separateNetworkEvent = false
  ): Promise<void> {
    this.dispatchEvent(new WatchEvent('reloadingNetworkInfo', undefined, undefined));

    if (separateNetworkEvent) {
      this.reloadGas(useCurrentEstimation).then(() => {
        this.generateNetworkInfo();
      });
      this.reloadBlockTime().then(() => {
        this.generateNetworkInfo();
      });
      return;
    }
    await Promise.allSettled([this.reloadGas(useCurrentEstimation), this.reloadBlockTime()]);

    this.generateNetworkInfo();
  }

  generateNetworkInfo(): void {
    let event: NetworkInfoEvent;
    let baseAsset: TokenWithPrice | undefined;
    if (this.lastEstimateResult !== undefined) {
      baseAsset = {
        ...this.lastEstimateResult.baseToken,
        priceUSD: this.lastEstimateResult.baseTokenPrice
      };
      event = {
        networkInfo: {
          network: this.flowData.inputToken.network,
          blockTime: this.blockTime,
          baseAsset: baseAsset,
          baseAssetAmount: this.lastEstimateResult.baseTokenAmount,
          txGasPriceUSD: this.lastEstimateResult.priceUSD
        },
        estimation: cloneEstimation(this.lastEstimateResult.estimation)
      };
    } else {
      event = {
        networkInfo: {
          network: this.flowData.inputToken.network,
          blockTime: this.blockTime,
          baseAsset: undefined
        },
        estimation: undefined
      };
    }

    addSentryBreadcrumb({
      level: 'debug',
      message: 'generateNetworkInfo',
      data: event
    });

    this.dispatchEvent(new WatchEvent('networkInfo', undefined, event));
  }

  async reloadBlockTime(): Promise<void> {
    this.blockTime = await useWagmi().getBlockTime(this.flowData.inputToken.network);
  }

  async reloadGas(useCurrent = false): Promise<void> {
    addSentryBreadcrumb({
      level: 'debug',
      message: 'calculateGasPrice'
    });
    if (this.calculating) {
      return;
    }

    this.calculating = true;

    try {
      if (!isNeedTransaction(this.flowData)) {
        this.calculating = false;
        return;
      }

      const calcResult = await this.calcGasPrice(
        this.flowData.inputToken.network,
        this.flowData.estimation,
        useCurrent
      );
      //if on new estimation originalTotalFee > (baseTokenAmount === prevOriginalTotalFee * Multiplier)
      //save new estimation
      //else -> skip estimation
      if (this.lastEstimateResult === undefined) {
        //save estimation and calc ins. gas
        this.lastEstimateResult = calcResult;
      } else if (
        lessThanOrEqual(
          calcResult.originalBaseTokenAmount,
          this.lastEstimateResult.originalBaseTokenAmount
        )
      ) {
        //save estimation and calc ins. gas
        this.lastEstimateResult = calcResult;
      } else if (
        greaterThan(calcResult.originalBaseTokenAmount, this.lastEstimateResult.baseTokenAmount)
      ) {
        //save estimation and calc ins. gas
        this.lastEstimateResult = calcResult;
      } else {
        //skip that estimation
        return;
      }
    } catch (e) {
      addSentryBreadcrumb({
        level: 'debug',
        message: 'fail to calc network fee',
        data: {
          flowData: this.flowData,
          error: e
        }
      });
      this.dispatchEvent(new WatchEvent('error', undefined, { error: e as HHError }));
    } finally {
      this.calculating = false;
    }
  }
}

export class WatchEvent<T extends keyof WatchEvents> extends Event {
  constructor(
    type: T,
    eventInit: EventInit | undefined,
    public options: WatchEvents[T]
  ) {
    super(type, eventInit);
  }
}
