<script setup lang="ts">
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

import { useIntervalFn } from '@vueuse/core';

import { useBrrrOnChainService } from '@/composables/services/useBrrrOnChainService';
import { useTokens } from '@/composables/useTokens';
import { formatTokenAmount } from '@/helpers/formatters';
import { AmountMode } from '@/references/axios/transactions/types';
import { SECOND } from '@/references/constants';
import type { Network } from '@/references/network';
import type { TokenWithPriceAndBalance } from '@/references/tokens';

import ListSkeleton from '@/components/ListSkeleton.vue';
import TokenInfo from '@/components/TokenInfo.vue';
import TokenSelectorHeader from '@/components/TokenSelectorHeader.vue';
import UiNotFound from '@/components/ui/UiNotFound.vue';
import UiText from '@/components/ui/UiText.vue';

interface Props {
  network: Network;
  networks: Network[];
  forceNetworks?: Network[];
  isNetworkSelectorReadonly?: boolean;
  localStorageTipKey?: string;
}

const props = withDefaults(defineProps<Props>(), {
  isNetworkSelectorReadonly: false,
  localStorageTipKey: undefined,
  forceNetworks: undefined
});

const emit = defineEmits<{
  (event: 'clear'): void;
  (event: 'select', value: TokenWithPriceAndBalance): void;
  (event: 'update:network', value: Network): void;
}>();

const state = reactive<{
  updating: boolean;
  searchValue: string;
}>({
  updating: false,
  searchValue: ''
});

const { t } = useI18n();
const { assets, loaded: loadedTokens, updateTokenList } = useTokens();

const networks = computed((): Network[] => {
  if (props.forceNetworks) {
    return props.forceNetworks;
  }
  return useBrrrOnChainService().getAvailableNetworks();
});

const isSkeletonVisible = computed((): boolean => !loadedTokens.value);

const availableTokens = computed((): TokenWithPriceAndBalance[] => {
  let items = assets.value.slice();

  items = items.filter(
    (token) => props.networks.includes(token.network) && token.network === props.network
  );

  return items;
});

const searchedTokens = computed((): TokenWithPriceAndBalance[] => {
  if (state.searchValue !== '') {
    const search = state.searchValue.toLowerCase();
    return availableTokens.value.filter(
      (token) =>
        token.address.toLowerCase().includes(search) ||
        token.symbol.toLowerCase().includes(search) ||
        token.name.toLowerCase().includes(search)
    );
  }

  return availableTokens.value;
});

function selectToken(token: TokenWithPriceAndBalance): void {
  emit('select', token);
}

function updateSearchValue(value: string): void {
  state.searchValue = value.trim().toLowerCase();
}

function updateNetworkFilter(network: Network | null): void {
  if (network) {
    emit('update:network', network);
  }
}

useIntervalFn(
  async () => {
    if (state.updating) {
      return;
    }
    try {
      state.updating = true;
      await updateTokenList();
    } finally {
      state.updating = false;
    }
  },
  30 * SECOND,
  { immediate: true, immediateCallback: true }
);
</script>

<template>
  <div class="token-selector">
    <TokenSelectorHeader
      class="token-selector__header"
      :placeholder="t('forms.searchAnyToken')"
      :model-value="state.searchValue"
      :network="props.network"
      :networks="networks"
      :use-unset="false"
      :local-storage-tip-key="props.localStorageTipKey"
      :is-network-selector-readonly="props.isNetworkSelectorReadonly"
      @update:model-value="updateSearchValue"
      @update:network="updateNetworkFilter"
    />
    <ListSkeleton v-if="isSkeletonVisible" />
    <div v-else-if="searchedTokens.length" class="token-selector__list-wrapper">
      <UiText class="token-selector__label" muted tag="p">
        {{ t('forms.tokenSelector.popular') }}
      </UiText>
      <ul class="token-selector__token-list">
        <li
          v-for="token in searchedTokens"
          :key="`${token.network}${token.address}`"
          class="token-selector__token-item"
        >
          <TokenInfo
            :token="token"
            :description="token.symbol"
            :value="
              formatTokenAmount(token.balance, token.decimals, token.symbol, AmountMode.ValueOnly)
            "
            @click="selectToken(token)"
          />
        </li>
      </ul>
    </div>
    <UiNotFound v-else />
  </div>
</template>

<style scoped>
.token-selector__header {
  position: sticky;
  top: 96px;
  z-index: var(--z-index-header);
  margin: 0 0 40px;
}

@media (max-width: 919px) {
  .token-selector__header {
    top: 80px;
  }
}

.token-selector__list-wrapper {
  padding: 4px 16px;
  background-color: var(--color-main-underlay);
  border-radius: 12px;
}

.token-selector__label {
  margin: 8px 0 16px;
}

.token-selector__token-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.token-selector__token-item {
  padding: 0 16px 16px 0;
  background-color: var(--color-main-underlay);
  border-radius: 12px;
}

.token-selector__token-item:not(:last-child) {
  margin: 0 0 8px;
}
</style>
