import { urlJoin } from '@/helpers/url';
import { currentClientType } from '@/helpers/utils';
import type { MinimalStorage } from '@/references/axios/slippage/HHAPISlippageGetterService';
import { HHAPISlippageGetterService } from '@/references/axios/slippage/HHAPISlippageGetterService';
import { getEndpoint } from '@/references/endpoints';

let service: HHAPISlippageGetterService | undefined;

const storageData = new Map<string, string>();
const storage: MinimalStorage = {
  getItem(key: string): string | null {
    return storageData.get(key) ?? null;
  },
  setItem(key: string, value: string): void {
    storageData.set(key, value);
  }
};

export const useSlippageGetterAPIService = (): HHAPISlippageGetterService => {
  if (service === undefined) {
    service = new HHAPISlippageGetterService({
      baseURL: urlJoin(getEndpoint('apiAssets'), '/swap'),
      clientType: currentClientType(),
      storage
    });
  }

  return service;
};
