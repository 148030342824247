import type {
  Abi,
  AbiStateMutability,
  Address,
  ContractFunctionArgs,
  ContractFunctionName,
  GetValue,
  Narrow
} from 'viem';
import { encodeFunctionData, getAbiItem } from 'viem';
import { formatAbiItemWithArgs } from 'viem/utils';

import type { Network } from '@/references/network';

import type { ExtractEstimationErrorReturn } from './errors';
import type { EstimateContractGasCompoundReturn } from './gas';

export enum Scope {
  BeforeEstimation = 'before-estimation',
  Estimation = 'estimation',
  ExecuteBroadcast = 'execute-broadcast',
  ExecuteReceipt = 'execute-receipt',
  Call = 'call'
}

export type OnCalldataPayload = {
  scope: Scope;
  network: Network;
  contractAddress: Address;
  functionName: string;
  callData: string;
  prettyCallData?: string;
  hash?: string;
  value?: string;
  estimation?: Omit<EstimateContractGasCompoundReturn, 'reload'>;
  transferDataRaw?: string;
  state?: string;
  meta?: Record<string, unknown>;
};

export type ContractFunctionConfig<
  TAbi extends Abi,
  TFunctionName extends ContractFunctionName<TAbi, TAbiStateMutability>,
  TAbiStateMutability extends AbiStateMutability = AbiStateMutability
> = {
  /** Contract ABI */
  abi: Narrow<TAbi>;
  /** Contract address */
  address: Address;
  /** Function to invoke on the contract */
  functionName: TFunctionName;
  args: ContractFunctionArgs<TAbi, TAbiStateMutability, TFunctionName>;
};

type ContractCallToPayloadParams<
  TAbi extends Abi,
  TFunctionName extends ContractFunctionName<TAbi, TAbiStateMutability>,
  TAbiStateMutability extends AbiStateMutability = AbiStateMutability
> = ContractFunctionConfig<TAbi, TFunctionName> &
  GetValue<TAbi, TFunctionName> &
  (
    | {
        estimation: EstimateContractGasCompoundReturn;
        hash?: string;
        network: Network;
        scope: Scope.ExecuteBroadcast;
        transferDataRaw?: string;
        state: 'success' | 'error' | 'rejected';
        meta?: ExtractEstimationErrorReturn;
      }
    | {
        estimation: EstimateContractGasCompoundReturn;
        hash: string;
        network: Network;
        scope: Scope.ExecuteReceipt;
        transferDataRaw?: string;
        state: 'success' | 'error' | 'rejected';
        meta?: ExtractEstimationErrorReturn;
      }
    | {
        network: Network;
        scope: Scope.Estimation;
        transferDataRaw?: string;
        state: 'success' | 'error';
        meta?: ExtractEstimationErrorReturn;
      }
    | {
        network: Network;
        scope: Scope.BeforeEstimation;
        transferDataRaw?: string;
      }
    | {
        network: Network;
        scope: Scope.Call;
        state: 'success' | 'error';
      }
  );

export const contractCallToPayload = <
  TAbi extends Abi,
  TAbiStateMutability extends AbiStateMutability = AbiStateMutability,
  TFunctionName extends ContractFunctionName<TAbi, TAbiStateMutability> = ContractFunctionName<
    TAbi,
    TAbiStateMutability
  >
>(
  params: ContractCallToPayloadParams<TAbi, TFunctionName>
): OnCalldataPayload => {
  const calldata = encodeFunctionData({
    abi: params.abi as Abi,
    functionName: params.functionName as string,
    args: params.args as unknown[]
  });
  const abiItem = getAbiItem({
    abi: params.abi as Abi,
    args: params.args as unknown[],
    name: params.functionName as string
  });

  let estimationObj: Omit<EstimateContractGasCompoundReturn, 'reload'> | undefined = undefined;
  if ('estimation' in params && params.estimation !== undefined) {
    const copy: Record<string, unknown> = params.estimation;
    delete copy['reload'];
    estimationObj = copy as Omit<EstimateContractGasCompoundReturn, 'reload'>;
  }

  if (abiItem === undefined) {
    return {
      scope: params.scope,
      state: 'state' in params ? params.state : undefined,
      network: params.network,
      contractAddress: params.address,
      functionName: params.functionName,
      callData: calldata,
      prettyCallData: 'failed to decode function',
      hash: 'hash' in params ? params.hash : undefined,
      value: 'value' in params ? params.value?.toString(10) : undefined,
      estimation: estimationObj,
      transferDataRaw: 'transferDataRaw' in params ? params.transferDataRaw : undefined,
      meta: 'meta' in params ? params.meta : undefined
    };
  }

  const prettyCallData = formatAbiItemWithArgs({
    abiItem,
    args: params.args as unknown[],
    includeFunctionName: true,
    includeName: true
  });

  return {
    scope: params.scope,
    state: 'state' in params ? params.state : undefined,
    network: params.network,
    contractAddress: params.address,
    functionName: params.functionName,
    callData: calldata,
    prettyCallData,
    hash: 'hash' in params ? params.hash : undefined,
    value: 'value' in params ? params.value?.toString(10) : undefined,
    estimation: estimationObj,
    transferDataRaw: 'transferDataRaw' in params ? params.transferDataRaw : undefined,
    meta: 'meta' in params ? params.meta : undefined
  };
};
