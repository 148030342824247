<script setup lang="ts">
import { computed, onMounted, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useLocalStorage } from '@vueuse/core';

import { getNetwork, type Network } from '@/references/network';

import type { UiDropdownItem } from '@/components/ui/UiDropdown.types';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiIcon from '@/components/ui/UiIcon.vue';

interface Props {
  modelValue: Network | null;
  networks: Network[];
  useUnset?: boolean;
  readonly?: boolean;
  localStorageNetworkKey?: string;
}

const props = withDefaults(defineProps<Props>(), {
  useUnset: true,
  readonly: false,
  localStorageNetworkKey: undefined
});

const emit = defineEmits<{
  (e: 'update:modelValue', v: Network | null): void;
}>();

const state = reactive<{
  isOtherVisible: boolean;
}>({
  isOtherVisible: false
});

const { t } = useI18n();

const lastSelectedNetwork = useLocalStorage<Network | null>(
  props.localStorageNetworkKey || 'lastSelectedNetwork',
  null
);

const options = computed((): UiDropdownItem[] => {
  let items: UiDropdownItem[] = [];

  items = props.networks.map((item) => ({
    value: item,
    text: getNetwork(item)?.displayedName ?? '',
    image: getNetwork(item)?.iconURL ?? '',
    handler() {
      lastSelectedNetwork.value = item;
      emit('update:modelValue', item);
    }
  }));

  const popular = items.filter((item) => getNetwork(item.value as Network)?.isPopular);
  const other = items.filter((item) => !getNetwork(item.value as Network)?.isPopular);

  if (popular.length && other.length) {
    items = state.isOtherVisible ? other : popular;

    items.push({
      value: null,
      text: state.isOtherVisible ? t('popular') : t('other'),
      icon: state.isOtherVisible ? 'star' : 'box',
      isNotClosing: true,
      separated: true,
      handler() {
        setTimeout(() => {
          state.isOtherVisible = !state.isOtherVisible;
        });
      }
    });
  }

  if (props.useUnset) {
    items.push({
      value: null,
      text: t('all'),
      icon: 'filter',
      separated: true,
      handler() {
        lastSelectedNetwork.value = null;
        emit('update:modelValue', null);
      }
    });
  }

  return items;
});

const selectedNetworkImage = computed((): string => {
  if (!props.modelValue) {
    return '';
  }

  const selectedNetworkInfo = getNetwork(props.modelValue);

  if (!selectedNetworkInfo) {
    return '';
  }

  return selectedNetworkInfo.iconURL;
});

function checkNetwork(networks: Network[]): void {
  if (networks && !networks.some((item) => props.modelValue === item)) {
    if (props.useUnset) {
      emit('update:modelValue', null);
    } else {
      emit('update:modelValue', networks[0]);
    }
  }

  const selectedOption = options.value.find((item) => item.value === props.modelValue);

  if (props.modelValue && !selectedOption) {
    state.isOtherVisible = !state.isOtherVisible;
  }
}

onMounted(() => {
  if (props.modelValue === null && lastSelectedNetwork.value !== null) {
    if (props.networks.some((item) => lastSelectedNetwork.value === item)) {
      emit('update:modelValue', lastSelectedNetwork.value);
    }
  }

  if (props.modelValue !== null && lastSelectedNetwork.value !== null) {
    lastSelectedNetwork.value = props.modelValue;
  }
});

watch(
  [() => props.networks, () => props.modelValue],
  ([networks]) => {
    checkNetwork(networks);
  },
  { immediate: true }
);
</script>

<template>
  <div class="network-selector">
    <UiDropdown
      class="network-selector__dropdown"
      position="bottom-end"
      :items="options"
      max-height="calc(100vh - 192px)"
      muted-icons
    >
      <button class="network-selector__button" type="button" :disabled="props.readonly">
        <img
          v-if="selectedNetworkImage"
          class="network-selector__button-image"
          :src="selectedNetworkImage"
          alt=""
        />
        <UiIcon v-else name="filter" :size="24" muted />
      </button>
    </UiDropdown>
  </div>
</template>

<style scoped>
.network-selector {
  display: flex;
  align-items: center;
  justify-content: center;
}

.network-selector__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 48px;
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: inherit;
  font-family: inherit;
  line-height: inherit;
  text-align: left;
  text-decoration: none;
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  appearance: none;
}

.network-selector__button:disabled {
  cursor: default;
}

.network-selector__button-image {
  width: 24px;
  height: 24px;
}
</style>
