import { useAssetsAPIService } from '@/composables/services/useAssetsAPIService';
import { addSentryBreadcrumb, captureSentryException } from '@/logs/sentry';
import { isTokenHasPermitData, type PermitData, type Token } from '@/references/tokens';

export const getTokenWithPermit = async <T extends Token>(token: T): Promise<T & PermitData> => {
  let tokenWithPermit: T & PermitData;
  if (isTokenHasPermitData(token)) {
    tokenWithPermit = token;
  } else {
    try {
      const permitData = await useAssetsAPIService().getPermitData(token.address, token.network);
      tokenWithPermit = {
        ...token,
        hasPermit: permitData.hasPermit,
        permitVersion: permitData.permitVersion,
        permitType: permitData.permitType
      };
    } catch (e) {
      addSentryBreadcrumb({
        type: 'error',
        message: 'cant get permit data for token',
        data: {
          tokenAddress: token.address,
          network: token.network
        }
      });
      captureSentryException(e);
      tokenWithPermit = {
        ...token,
        hasPermit: false
      };
    }
  }

  return tokenWithPermit;
};

export const getPermitData = async <T extends Token>(
  token: T | (T & PermitData)
): Promise<PermitData> => {
  if (isTokenHasPermitData(token)) {
    return {
      hasPermit: token.hasPermit,
      permitVersion: token.permitVersion,
      permitType: token.permitType
    };
  }

  try {
    return await useAssetsAPIService().getPermitData(token.address, token.network);
  } catch (e) {
    addSentryBreadcrumb({
      type: 'error',
      message: 'cant get permit data for token',
      data: {
        tokenAddress: token.address,
        network: token.network
      }
    });
    captureSentryException(e);
    return {
      hasPermit: false
    };
  }
};
