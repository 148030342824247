import { currentClientType } from '@/helpers/utils';
import { HHAPIRatesService } from '@/references/axios/rates/HHAPIRatesService';
import { getEndpoint } from '@/references/endpoints';

let service: HHAPIRatesService | undefined;
export const useRatesAPIService = (): HHAPIRatesService => {
  if (service === undefined) {
    service = new HHAPIRatesService(getEndpoint('apiAssets'), currentClientType());
  }

  return service;
};
