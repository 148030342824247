<script setup lang="ts">
import { computed } from 'vue';

import { marked } from 'marked';
import sanitize from 'sanitize-html';

import UiText from '@/components/ui/UiText.vue';

interface Props {
  string?: string;
}

const props = withDefaults(defineProps<Props>(), {
  string: ''
});

const renderer = new marked.Renderer();
const linkRenderer = renderer.link;

renderer.link = (options) => {
  const localLink = options.href.startsWith(`${location.protocol}//${location.hostname}`);
  const html = linkRenderer.call(renderer, options);
  return localLink ? html : html.replace(/^<a /, `<a target="_blank" rel="noopener noreferrer" `);
};

const sanitizedTerms = computed((): string => {
  const parsedString = marked.parse(props.string, { renderer, breaks: true });

  return sanitize(parsedString as string, {
    allowedAttributes: { a: ['href', 'target', 'rel'] }
  });
});
</script>

<template>
  <UiText class="ui-markdown" tag="div" size="md" weight="regular">
    <div v-html="sanitizedTerms" />
  </UiText>
</template>

<style scoped>
.ui-markdown {
  position: relative;
}

.ui-markdown :deep(h1),
.ui-markdown :deep(h2),
.ui-markdown :deep(h3),
.ui-markdown :deep(h4),
.ui-markdown :deep(h5),
.ui-markdown :deep(h6) {
  font-weight: var(--font-weight-bold);
}

.ui-markdown :deep(h1:not(:first-child)),
.ui-markdown :deep(h2:not(:first-child)),
.ui-markdown :deep(h3:not(:first-child)),
.ui-markdown :deep(h4:not(:first-child)),
.ui-markdown :deep(h5:not(:first-child)),
.ui-markdown :deep(h6:not(:first-child)) {
  margin-top: 40px;
}

.ui-markdown :deep(h1:not(:last-child)),
.ui-markdown :deep(h2:not(:last-child)),
.ui-markdown :deep(h3:not(:last-child)),
.ui-markdown :deep(h4:not(:last-child)),
.ui-markdown :deep(h5:not(:last-child)),
.ui-markdown :deep(h6:not(:last-child)) {
  margin-bottom: 24px;
}

.ui-markdown :deep(h1) {
  font-size: var(--font-size-h1);
  line-height: var(--line-height-h1);
}

.ui-markdown :deep(h2) {
  font-size: var(--font-size-h2);
  line-height: var(--line-height-h2);
}

.ui-markdown :deep(h3) {
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
}

.ui-markdown :deep(h4) {
  font-size: var(--font-size-h4);
  line-height: var(--line-height-h4);
}

.ui-markdown :deep(h5) {
  font-size: var(--font-size-h5);
  line-height: var(--line-height-h5);
}

.ui-markdown :deep(h6) {
  font-size: var(--font-size-h6);
  line-height: var(--line-height-h6);
}

.ui-markdown :deep(a) {
  color: inherit;
  font-weight: var(--font-weight-bold);
  text-decoration: none;
}
</style>
