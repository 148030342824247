import type { Address, PublicClient } from 'viem';

import { createLogger } from '@/logs/datadog';
import type { SupportsPermitParams, WalletInfoAdapter } from '@/references/onchain/adapters';

/**
 * Returns if the wallet supports permit
 *
 * If no custom "supportsPermit" function is provided, then the default {@link supportsPermitStd} function is used
 *
 * @param _publicClient - public client in desired chain
 * @param address - wallet address
 * @param walletInfoAdapter - wallet info adapter
 * @param params - query params
 */
export const isWalletSupportsPermit = async (
  _publicClient: PublicClient,
  address: Address,
  walletInfoAdapter: WalletInfoAdapter,
  params: SupportsPermitParams
): Promise<boolean> => {
  const logger = createLogger('isWalletSupportsPermit');

  if (walletInfoAdapter.supportsPermit !== undefined) {
    logger.info('Custom "supportsPermit" function passed. Calling it');
    return walletInfoAdapter.supportsPermit(params);
  }

  return supportsPermitStd(_publicClient, address, walletInfoAdapter, params);
};

/**
 * Standard implementation of "supportsPermit" function. Used if no custom function is provided in walletInfoAdapter
 *
 * Returns if the wallet supports permit
 *
 * @param _publicClient - public client in desired chain
 * @param address - wallet address
 * @param walletInfoAdapter - wallet info adapter
 * @param params - query params
 */
export const supportsPermitStd = async (
  _publicClient: PublicClient,
  address: Address,
  walletInfoAdapter: WalletInfoAdapter,
  params: SupportsPermitParams
): Promise<boolean> => {
  const logger = createLogger('supportsPermitStd');

  const [isErc1271Signer, supportsSignTypedDataV4] = await Promise.all([
    walletInfoAdapter.isErc1271Signer({ chainId: params.chainId }),
    walletInfoAdapter.supportsSignTypedDataV4()
  ]);

  if (!supportsSignTypedDataV4) {
    logger.info(
      `${address} in chain ${params.chainId} does not support signTypedDataV4, unable to perform Permit`
    );
    return false;
  }

  if (isErc1271Signer) {
    logger.info(
      `${address} in chain ${params.chainId} is EIP-1271 (or EIP-4337) CA. SCA are unable to produce secp256k1 signatures. Unable to perform Permit`
    );
    return false;
  }

  return true;
};
