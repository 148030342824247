import type { Address, PublicClient } from 'viem';

import { createLogger } from '@/logs/datadog';
import { addSentryBreadcrumb } from '@/logs/sentry';
import type { WalletInfoAdapter } from '@/references/onchain/adapters';
import { deriveChainId } from '@/references/onchain/adapters';

import { getAmbireNonce } from './account-abstraction-wallets/getAmbireNonce';
import { getArgentNonce } from './account-abstraction-wallets/getArgentNonce';
import { getEIP4337Nonce } from './account-abstraction-wallets/getEIP4337Nonce';
import { isAmbireWallet } from './account-abstraction-wallets/isAmbireWallet';
import { isArgentWallet } from './account-abstraction-wallets/isArgentWallet';
import { isContract } from './account-abstraction-wallets/isContract';
import { isSemiAbstractedNonceSupported } from './account-abstraction-wallets/isSemiAbstractedNonceSupported';
import { extractEstimationError } from './errors';
import { getEOANonce } from './getEOANonce';

/**
 * Returns current address nonce
 *
 * If no custom "getNonce" function is provided, then the default {@link getNonceStd} function is used
 *
 * @param publicClient - a public address in desired chain
 * @param address - address to get nonce for
 * @param walletInfoAdapter - wallet info adapter
 */
export const getNonce = async (
  publicClient: PublicClient,
  address: Address,
  walletInfoAdapter: WalletInfoAdapter
): Promise<bigint> => {
  const logger = createLogger('getNonce');
  if (walletInfoAdapter.getNonce !== undefined) {
    logger.info('Custom "getNonce" function passed. Calling it');

    const chainId = await deriveChainId(publicClient);
    return await walletInfoAdapter.getNonce({ chainId });
  }

  return getNonceStd(publicClient, address, walletInfoAdapter);
};

/**
 * Standard implementation of "getNonce" function. Used if no custom function is provided in walletInfoAdapter
 *
 * @param publicClient - a public address in desired chain
 * @param address - address to get nonce for
 * @param walletInfoAdapter - wallet info adapter
 */
export const getNonceStd = async (
  publicClient: PublicClient,
  address: Address,
  walletInfoAdapter: WalletInfoAdapter
): Promise<bigint> => {
  const logger = createLogger('getNonceStd');
  const chainId = await deriveChainId(publicClient);

  const isErc1271Signer = await walletInfoAdapter.isErc1271Signer({
    chainId
  });

  if (!isErc1271Signer) {
    logger.info(
      `${address} in chain ${chainId} is not a ERC-1271 (or ERC-6492) signer. Getting EOA nonce`
    );
    return getEOANonce(address, publicClient);
  }

  const isAlreadyDeployed = await isContract(publicClient, address);
  if (!isAlreadyDeployed) {
    logger.info(`${address} in chain ${chainId} is not deployed yet. Getting EOA nonce`);

    // we are able to use EOA nonce here, as it will certainly change after
    //  deployment at least by 1 (create2/initialize call)
    return getEOANonce(address, publicClient);
  }

  const [ambireWallet, argentWallet, supportsSemiAbstractedNonce] = await Promise.all([
    isAmbireWallet(publicClient, address),
    isArgentWallet(publicClient, address),
    isSemiAbstractedNonceSupported(publicClient, address)
  ]);

  if (ambireWallet) {
    logger.info(`${address} in chain ${chainId} appears to be an Ambire wallet. Getting nonce`);

    return getAmbireNonce(publicClient, address);
  }

  if (argentWallet) {
    logger.info(`${address} in chain ${chainId} appears to be an Argent wallet. Getting nonce`);

    return getArgentNonce(publicClient, address);
  }

  if (supportsSemiAbstractedNonce) {
    logger.info(
      `${address} in chain ${chainId} supports EIP-4337 semi-abstracted nonce. Getting nonce`
    );

    return getEIP4337Nonce(publicClient, address);
  }

  logger.warn(
    `${address} in chain ${chainId} does not support externally obtainable nonce. Unable to reliably get one`
  );

  throw new Error(
    `Address ${address} in chain ${chainId} is a contract, yet it does support externally obtainable nonce. Unable to reliably get one`
  );
};

export async function shouldIncrementNonceByError(
  publicClient: PublicClient,
  address: Address,
  walletInfoAdapter: WalletInfoAdapter,
  chainId: number,
  error: unknown
): Promise<boolean> {
  const isErc1271Signer = await walletInfoAdapter.isErc1271Signer({ chainId });

  if (!isErc1271Signer) {
    return false;
  }

  const [ambireWallet, argentWallet, supportsSemiAbstractedNonce] = await Promise.all([
    isAmbireWallet(publicClient, address),
    isArgentWallet(publicClient, address),
    isSemiAbstractedNonceSupported(publicClient, address)
  ]);

  if (!ambireWallet && !argentWallet && !supportsSemiAbstractedNonce) {
    return false;
  }

  const invalidNonceSignature = '0x756688fe';

  const estimationError = extractEstimationError(error);

  if (estimationError !== undefined) {
    addSentryBreadcrumb({
      level: 'warning',
      message: 'Detected estimationError',
      data: { estimationError }
    });

    if (
      estimationError.signature === invalidNonceSignature ||
      estimationError?.reason?.includes(invalidNonceSignature) ||
      /nonce/i.test(estimationError?.reason ?? '')
    ) {
      addSentryBreadcrumb({
        level: 'info',
        message: 'Resolved estimation error contains invalid nonce signature or message'
      });
      return true;
    }
  }

  if (
    error instanceof Error &&
    (error.message.includes(invalidNonceSignature) || /nonce/i.test(error.message))
  ) {
    addSentryBreadcrumb({
      level: 'info',
      message: 'Error itself contains invalid nonce signature or message',
      data: { message: error.message }
    });

    return true;
  }

  return false;
}

export async function shouldIncrementNonce(
  publicClient: PublicClient,
  address: Address,
  walletInfoAdapter: WalletInfoAdapter,
  chainId: number
): Promise<boolean> {
  const isErc1271Signer = await walletInfoAdapter.isErc1271Signer({ chainId });

  if (!isErc1271Signer) {
    return false;
  }

  const [ambireWallet, argentWallet, supportsSemiAbstractedNonce] = await Promise.all([
    isAmbireWallet(publicClient, address),
    isArgentWallet(publicClient, address),
    isSemiAbstractedNonceSupported(publicClient, address)
  ]);

  return ambireWallet || argentWallet || supportsSemiAbstractedNonce;
}
