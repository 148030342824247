import axios, { type AxiosResponse } from 'axios';
import type { Address, Hex } from 'viem';

import { greaterThan } from '@/helpers/bigmath';
import { createHHAxios } from '@/references/axios/axios';
import { HHAPIService } from '@/references/axios/base';
import type { ISwapper } from '@/references/axios/swap/ISwapper';
import {
  type BadRequestResponse,
  GeneralErrorCode,
  type SwapQuoteParams,
  type SwapQuoteResponse,
  ValidationErrorReason
} from '@/references/axios/swap/swapper0/types';
import type { QuoteData, TransferData } from '@/references/axios/swap/types';
import type { ClientType } from '@/references/base';
import { Network } from '@/references/network';
import { substituteAssetAddressIfNeeded } from '@/references/tokens';

import type { ClientEECode } from '../../../../composables/useErrorModal';
import { ExpectedError } from '../../../ExpectedError';
import { APIError } from '../../APIError';

type ConstructorArgs = {
  baseURL: string;
  network: Network;
  clientType: ClientType;
};

export class Swapper0APIService extends HHAPIService implements ISwapper {
  protected readonly baseURL: string;
  protected readonly clientType: ClientType;
  protected readonly network: Network;
  protected static readonly validNetworks: Array<Network> = [
    Network.ethereum,
    Network.polygon,
    Network.avalanche,
    Network.arbitrum,
    Network.optimism,
    Network.bsc
  ];

  constructor({ baseURL, network, clientType }: ConstructorArgs) {
    super('swapper0.api.service');
    this.network = network;
    this.clientType = clientType;
    this.baseURL = baseURL;
  }

  public canHandle(network: Network): boolean {
    return Swapper0APIService.validNetworks.includes(network);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canHandleToken(network: Network, _tokenAddress: Address): boolean {
    return Swapper0APIService.validNetworks.includes(network);
  }

  public async getTransferData(
    buyTokenAddress: Address,
    sellTokenAddress: Address,
    rawAmount: string
  ): Promise<TransferData> {
    const resp = await this.getSwapQuote({
      buyToken: substituteAssetAddressIfNeeded(buyTokenAddress, this.network),
      sellToken: substituteAssetAddressIfNeeded(sellTokenAddress, this.network),
      sellAmount: rawAmount
    });

    return this.mapSwapQuote(resp);
  }

  public async getQuoteData(
    buyTokenAddress: Address,
    sellTokenAddress: Address,
    rawAmount: string
  ): Promise<QuoteData> {
    return this.getTransferData(buyTokenAddress, sellTokenAddress, rawAmount);
  }

  /**
   * Requests swap quote with all the estimations needed
   * @param params - quote params
   */
  public async getSwapQuote(params: SwapQuoteParams): Promise<SwapQuoteResponse> {
    if (!Swapper0APIService.ensureNetworkIsSupported(this.network)) {
      throw new Error(`Swapper 0 does not support network ${this.network}`);
    }

    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType),
      onRejected: this.formatError.bind(this)
    });

    const response = await instance.requestRaw<SwapQuoteResponse>({
      method: 'GET',
      url: `/swap/${this.network}`,
      params
    });

    return response.data;
  }

  protected mapSwapQuote(data: SwapQuoteResponse): TransferData {
    const via = data.sources.find((s) => greaterThan(s.proportion, 0)) ?? {
      name: ''
    };

    return {
      allowanceTarget: data.allowanceTarget as Address,
      buyAmount: data.buyAmount,
      data: data.data as Hex,
      sellAmount: data.sellAmount,
      to: data.to as Address,
      value: data.value as Hex,
      swappingVia: via.name,
      rawResponse: JSON.stringify({ ...data, swapperName: this.getName() })
    };
  }

  protected static ensureNetworkIsSupported(network?: Network): boolean {
    if (network === undefined) {
      return false;
    }

    return Swapper0APIService.validNetworks.includes(network);
  }

  public getName(): string {
    return 'Swapper0APIService';
  }

  protected formatError(error: unknown): never {
    if (axios.isAxiosError(error)) {
      if (error.response !== undefined) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data === undefined) {
          const errorPayload = {
            method: error.config?.method,
            requestUri: axios.getUri(error.config),
            statusCode: error.status,
            statusMessage: error.message,
            axiosCode: error.code,
            responseCode: error.response.status
          };

          throw new APIError('Request failed: no data', error.message ?? 'no data', {
            payload: errorPayload
          });
        }

        if (error.response.status === 400) {
          // handle and log bad request responses differently
          throw this.formatBadRequestResponse(error.response as AxiosResponse<BadRequestResponse>);
        }

        throw new APIError(error.response.data.error, error.response.data.errorCode, {
          payload: {
            ...error.response.data,
            status: error.response.status,
            url: axios.getUri(error.response.config)
          },
          status: error.response.status
        });
      }

      if (error.request !== undefined) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest
        const errorData = {
          method: error.config?.method,
          requestUri: axios.getUri(error.config),
          statusCode: error.status,
          statusMessage: error.message,
          axiosCode: error.code
        };

        throw new APIError('The request has failed, no response', 'NO_RESPONSE', {
          payload: errorData
        });
      }
    }

    if (error instanceof Error) {
      // An error is JS-initiated error, just pass it through
      throw new Error('The request has failed', { cause: error });
    }

    throw new Error('The request has failed during setup / result handling', { cause: error });
  }

  protected formatBadRequestResponse(response: AxiosResponse<BadRequestResponse>): never {
    if (response.data.code === GeneralErrorCode.ValidationFailed) {
      if (
        response.data.validationErrors?.some(
          (item) => item.reason === ValidationErrorReason.InsufficientAssetLiquidity
        )
      ) {
        throw new ExpectedError<ClientEECode>('swapInsufficientLiquidity');
      }

      const validationErrorString =
        response.data.validationErrors?.[0].reason ?? response.data.reason;
      throw new Error(validationErrorString, { cause: response.data });
    }

    throw new Error(response.data.reason, { cause: response.data });
  }
}
