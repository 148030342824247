import type { TransferData } from '@/references/axios/swap/types';
import { HHError } from '@/references/HHError';
import type { Token, TokenWithPriceAndBalance } from '@/references/tokens';

export enum Type {
  Brrr
}

export type InputTokenOrFiatMode = 'TOKEN' | 'FIAT';

type BasePrepareReturn<Type extends Type.Brrr> = {
  type: Type;
  input: InputTokenOrFiatMode;
  token: TokenWithPriceAndBalance;
  tokenAmount: string;
  feeAmount: string;
  feeToken: Token;
  //alias for fiatAmount
  totalSent: string;
  swapTarget: Token;
  swapTargetPrice: string;
};

type PrepareReturnWithoutSwap<Type extends Type.Brrr> = BasePrepareReturn<Type> & {
  willSwap: false;
};

type PrepareReturnWithSwap<Type extends Type.Brrr> = BasePrepareReturn<Type> & {
  willSwap: true;
  transferData: TransferData;
};

export type PrepareReturn<Type extends Type.Brrr> =
  | PrepareReturnWithoutSwap<Type>
  | PrepareReturnWithSwap<Type>;

export type SettlementData = {
  settlementToken: Token;
  settlementTokenAmount: string;
  settlementTokenFeeAmount: string;
  settlementTokenRate: string;
};

export class BadSwapPriceError extends HHError {
  constructor(token: Token, opt?: { cause?: unknown; payload?: Record<string, unknown> }) {
    super('Bad swap price detected', { ...opt, payload: { ...opt?.payload, token: token } });
  }
}
