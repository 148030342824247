<script setup lang="ts">
import type { PropType } from 'vue';

import UiIcon from '@/components/ui/UiIcon.vue';
import type { UiListItem } from '@/components/ui/UiList.types';
import UiText from '@/components/ui/UiText.vue';

const props = defineProps({
  items: {
    type: Array as PropType<UiListItem[]>,
    default: () => []
  }
});
</script>

<template>
  <div class="ui-list">
    <ul class="ui-list__list">
      <li v-for="item in props.items" :key="item.title" class="ui-list__item">
        <component
          :is="item.handler ? 'button' : 'div'"
          class="ui-list__body"
          :type="item.handler ? 'button' : undefined"
          @click="item.handler ? item.handler() : () => {}"
        >
          <UiIcon v-if="item.icon" class="ui-list__icon" :name="item.icon" muted />
          <span class="ui-list__info">
            <UiText class="ui-list__title" size="md" weight="bold">{{ item.title }}</UiText>
            <UiText class="ui-list__description" size="sm" weight="medium" muted>
              {{ item.description }}
            </UiText>
          </span>
        </component>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.ui-list__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.ui-list__body {
  display: flex;
  width: 100%;
  padding: 16px 24px;
}

button.ui-list__body {
  margin: 0;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  appearance: none;
  user-select: none;
}

.ui-list__item:nth-child(even) .ui-list__body {
  background-color: var(--color-main-underlay);
  border-radius: 20px;
}

.ui-list__icon {
  flex-shrink: 0;
  margin: 8px 16px 8px 0;
}

.ui-list__title,
.ui-list__description {
  display: block;
}
</style>
