import { ContractFunctionRevertedError, ExecutionRevertedError, type Hash, type Hex } from 'viem';

import type { ClientEECode } from '@/composables/useErrorModal';
import { assert } from '@/helpers/assert';
import { ExpectedError } from '@/references/ExpectedError';
import {
  isChainMismatchError,
  isMethodNotSupportedError,
  isRejectedRequestError
} from '@/references/onchain/ProviderRPCError';

export const rethrowIfUserRejectedRequest = (
  error: unknown,
  errorCode: ClientEECode = 'userRejectTransaction'
): void | never => {
  if (isRejectedRequestError(error)) {
    throw new ExpectedError<ClientEECode>(errorCode, { cause: error });
  }
};

export const assertNonNullish = <T extends Hex | Hash | 'null' | null>(
  value: T,
  errorCode: ClientEECode = 'userRejectTransaction'
): void => {
  assert(
    value !== 'null' && value !== null,
    new ExpectedError(errorCode, {
      cause: new Error(`Assertion failed: received nullish value: ${value}`)
    })
  );
};

export const rethrowIfChainMismatchError = (error: unknown): void | never => {
  if (isChainMismatchError(error)) {
    throw new ExpectedError<ClientEECode>('wrongWalletChain', { cause: error });
  }
};

export const rethrowIfMethodNotSupportedError = (error: unknown): void | never => {
  if (isMethodNotSupportedError(error)) {
    throw new ExpectedError<ClientEECode>('methodNotSupported', { cause: error });
  }
};

export type ExtractEstimationErrorReturn = {
  signature?: Hex;
  reason?: string;
};
export function extractEstimationError(error: unknown): ExtractEstimationErrorReturn | undefined {
  // during estimation
  if (error instanceof ContractFunctionRevertedError) {
    return {
      reason: error.reason ?? 'unknown reason',
      signature: error.signature
    };
  }

  // during execution (broadcast -- from node / watch receipt -- from explain function via eth call)
  if (error instanceof ExecutionRevertedError) {
    return {
      reason: error.message
    };
  }

  if (!(error instanceof Error)) {
    return undefined;
  }

  if (error.cause !== undefined) {
    return extractEstimationError(error.cause);
  }

  return undefined;
}
