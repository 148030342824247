import { type Address, parseUnits } from 'viem';

import { useBrrrAPIService } from '@/composables/services/useBrrrAPIService';
import { useBrrrOnChainService } from '@/composables/services/useBrrrOnChainService';
import { useSwapAPIService } from '@/composables/services/useSwapAPIService';
import { useAudit } from '@/composables/useAudit';
import { useBrrr } from '@/composables/useBrrr';
import type { ClientEECode } from '@/composables/useErrorModal';
import { useTokenPrice } from '@/composables/useTokenPrice';
import { useWagmi } from '@/composables/useWagmi';
import { filterDefined } from '@/helpers/arrays';
import { assert } from '@/helpers/assert';
import { HashEvent } from '@/helpers/events';
import { type PrepareReturn, Type } from '@/helpers/flow/types';
import { isNeedApproveOrPermit, throwBadPrice } from '@/helpers/flow/utils';
import { getClonedGasEstimationByNetwork } from '@/helpers/gas';
import { generateOperationId } from '@/helpers/operationId';
import { getTokenWithPermit } from '@/helpers/permit';
import { createPromiseWithAbortSignal, type MaybePromise } from '@/helpers/promises';
import { createAdapter } from '@/helpers/walletClientAdapter';
import { addSentryBreadcrumb } from '@/logs/sentry';
import type { TransferData } from '@/references/axios/swap/types';
import { MIN_DEFI_SWAP_DIFF_UPPER_BORDER } from '@/references/constants';
import {
  BrrrConversionHelper,
  type TransferDataGetterArgs
} from '@/references/ConversionHelperBrrr';
import { ExpectedError } from '@/references/ExpectedError';
import { HHError } from '@/references/HHError';
import { getChainId, getNetwork, isBaseAssetByNetwork } from '@/references/network';
import type { EstimateContractGasCompoundReturn } from '@/references/onchain/gas';
import type { BRRROnChainService } from '@/references/onchain/holyheld/BRRROnChainService';
import type {
  BaseBRRRFlow,
  BRRRMeta
} from '@/references/onchain/holyheld/BRRROnChainService.types';
import type {
  ExecuteWithApproveFlow,
  ExecuteWithPermit2Flow,
  ExecuteWithPermitFlow,
  MakeApproveFlow,
  MakePermit2Flow,
  MakePermitFlow
} from '@/references/onchain/holyheld/flowTypes';
import { AllowanceFlow } from '@/references/onchain/holyheld/flowTypes';
import {
  type PermitData,
  type Token,
  type TokenWithPrice,
  type TokenWithPriceAndBalance
} from '@/references/tokens';
import { getBrrrSwapTarget } from '@/references/tokens';

export type GetBrrrFlowReturn =
  | MakeApproveFlow<BaseBRRRFlow>
  | MakePermitFlow<BaseBRRRFlow>
  | MakePermit2Flow<BaseBRRRFlow>
  | ExecuteWithApproveFlow<BaseBRRRFlow>
  | ExecuteWithPermitFlow<BaseBRRRFlow>
  | ExecuteWithPermit2Flow<BaseBRRRFlow>;

export type GetFlowBrrrParams = {
  type: Type.Brrr;
  token: TokenWithPrice & PermitData;
  tokensReceiver: Address;
  amountInWei: bigint;
  transferData: TransferData | undefined;
  swapTarget: Token;
  swapTargetTokenPrice: string;
  meta: BRRRMeta;
};

async function prepareBrrr(
  token: TokenWithPriceAndBalance,
  amount: string
): Promise<PrepareReturn<Type.Brrr>> {
  const helper = getBrrrConversionHelper();
  const converted = await helper.convertTokenToBRRR(token, amount);

  if (converted.willSwap) {
    if (converted.badPriceDetected) {
      return throwBadPrice(token, amount, 'FIAT');
    }

    return {
      feeAmount: '0',
      feeToken: converted.swapTarget,
      input: 'FIAT',
      swapTarget: converted.swapTarget,
      swapTargetPrice: converted.swapTargetPrice,
      token: token,
      tokenAmount: amount,
      totalSent: converted.brrrAmount,
      transferData: converted.transferData,
      type: Type.Brrr,
      willSwap: true
    };
  }

  return {
    feeAmount: '0',
    feeToken: token,
    input: 'FIAT',
    swapTarget: token,
    swapTargetPrice: token.priceUSD,
    token: token,
    tokenAmount: amount,
    totalSent: converted.brrrAmount,
    type: Type.Brrr,
    willSwap: false
  };
}

async function makeGetFlowParamsFromPrepareReturn(
  prepareReturn: PrepareReturn<Type.Brrr>,
  enrichFunc: (preReturn: GetFlowBrrrParams) => MaybePromise<GetFlowBrrrParams>
): Promise<GetFlowBrrrParams> {
  const tokenWithPermit = await getTokenWithPermit(prepareReturn.token);

  return enrichFunc({
    type: Type.Brrr,
    token: tokenWithPermit,
    amountInWei: parseUnits(prepareReturn.tokenAmount, tokenWithPermit.decimals),
    transferData: prepareReturn.willSwap ? prepareReturn.transferData : undefined,
    swapTarget: prepareReturn.swapTarget,
    swapTargetTokenPrice: prepareReturn.swapTargetPrice,
    // must be enriched by enrichFunc
    tokensReceiver: '0x0',
    meta: {
      expectedBRRRAmount: prepareReturn.totalSent
    }
  });
}

const eventTarget = new EventTarget();

function subscribeOnReceiveHash(onTransactionHashReceived: (hash: string) => void) {
  const dispose = (): void => {
    eventTarget.removeEventListener('hash_received', fn as EventListener);
  };

  const fn = (e: HashEvent) => {
    onTransactionHashReceived(e.hash);
    dispose();
  };

  eventTarget.addEventListener('hash_received', fn as EventListener);
  return dispose;
}

async function getBrrrFlow(
  params: GetFlowBrrrParams,
  onApproveTransactionHash?: (hash: string) => void
): Promise<GetBrrrFlowReturn> {
  const { isConnected, address, supportsSignTypedDataV4, getPublicClient } = useWagmi();

  assert(isConnected.value && address.value !== undefined, new ExpectedError('notConnected'));

  addSentryBreadcrumb({
    level: 'info',
    message: 'call getBrrrFlow with arg',
    data: {
      senderAddress: address.value,
      params: params,
      loadedInfo: {
        supportsSignTypedDataV4: supportsSignTypedDataV4.value
      }
    }
  });

  const uuidBrrr = generateOperationId('brrr');

  // const brrrToken = getBrrrToken(params.token.network);
  const networkInfo = getNetwork(params.token.network);
  assert(networkInfo !== undefined, `No network info defined for network ${params.token.network}`);

  const service = useBrrrOnChainService();
  const publicClient = getPublicClient({ chainId: getChainId(params.token.network) });

  return await service.getAllowanceFlow({
    senderAddress: address.value,
    publicClient,
    flowData: {
      flowType: 'brrr',
      inputToken: params.token,
      inputAmountInWei: params.amountInWei,
      transferData: params.transferData,
      swapTarget: params.swapTarget,
      swapTargetPriceUSD: params.swapTargetTokenPrice,
      tokensReceiver: params.tokensReceiver,
      meta: params.meta,
      eventConfig: {
        onCallData: (payload) => useAudit().sendTxCallAuditEvent(uuidBrrr, payload),
        onApproveTransactionHash,
        onTransactionHash: (hash) => {
          eventTarget.dispatchEvent(new HashEvent(hash));
        },
        onTransactionExecuted: () => {},
        watchTxReceipt: () => new Promise<void>(() => undefined)
      }
    }
  });
}

async function reloadBrrrWithNewAmount(
  params: GetFlowBrrrParams,
  flowData: GetBrrrFlowReturn,
  onApproveTransactionHash?: (hash: string) => void
): Promise<GetBrrrFlowReturn> {
  const { isConnected, address } = useWagmi();

  assert(isConnected.value && address.value !== undefined, new ExpectedError('notConnected'));

  addSentryBreadcrumb({
    level: 'info',
    message: 'call reloadFlowWithNewAmount',
    data: {
      address: address.value,
      params: params,
      oldFlowData: flowData
    }
  });

  if (isNeedApproveOrPermit(flowData)) {
    return getBrrrFlow(params, onApproveTransactionHash);
  }

  const { getPublicClient } = useWagmi();

  const service = useBrrrOnChainService();

  const fd = service.provideNewAmounts(
    flowData,
    params.amountInWei,
    params.transferData,
    params.meta
  );

  const publicClient = getPublicClient({ chainId: getChainId(flowData.inputToken.network) });

  addSentryBreadcrumb({
    level: 'info',
    message: 'call getAllowanceFlow with provided new amounts',
    data: {
      senderAddress: address.value,
      flowData: fd
    }
  });

  return service.getAllowanceFlow({
    senderAddress: address.value,
    publicClient: publicClient,
    flowData: fd
  });
}

async function approve(
  flowData: MakeApproveFlow<BaseBRRRFlow>,
  estimation: EstimateContractGasCompoundReturn | undefined
): Promise<
  | MakeApproveFlow<BaseBRRRFlow>
  | MakePermit2Flow<BaseBRRRFlow>
  | ExecuteWithApproveFlow<BaseBRRRFlow>
> {
  const { isConnected, address, changeNetwork, getPublicClient, getWalletClient } = useWagmi();

  assert(isConnected.value && address.value !== undefined, new ExpectedError('notConnected'));

  await changeNetwork(flowData.inputToken.network);

  addSentryBreadcrumb({
    level: 'info',
    message: 'call approve with data',
    data: {
      flowData: flowData,
      senderAddress: address.value
    }
  });

  let est = estimation;

  if (est === undefined) {
    addSentryBreadcrumb({
      level: 'info',
      message: 'estimation not presented - reloading'
    });

    est = (
      await getClonedGasEstimationByNetwork(flowData.inputToken.network, flowData.estimation, false)
    )[0];

    addSentryBreadcrumb({
      level: 'info',
      message: 'estimation reloaded',
      data: {
        estimation: est
      }
    });
  }

  const executeFlowData = { ...flowData, estimation: est };

  const service = useBrrrOnChainService();

  const publicClient = getPublicClient({ chainId: getChainId(flowData.inputToken.network) });

  return await service.makeApprove({
    flowData: executeFlowData,
    publicClient,
    senderAddress: address.value,
    walletClientAdapter: createAdapter(getWalletClient)
  });
}

async function makePermit(
  flowData: MakePermitFlow<BaseBRRRFlow>
): Promise<ExecuteWithPermitFlow<BaseBRRRFlow>> {
  const { isConnected, address, changeNetwork, getPublicClient, getWalletClient } = useWagmi();

  assert(isConnected.value && address.value !== undefined, new ExpectedError('notConnected'));

  await changeNetwork(flowData.inputToken.network);

  const service = useBrrrOnChainService();

  const publicClient = getPublicClient({ chainId: getChainId(flowData.inputToken.network) });

  addSentryBreadcrumb({
    level: 'info',
    message: 'call permit with data',
    data: {
      flowData: flowData,
      senderAddress: address.value
    }
  });

  return await service.makePermit({
    flowData: flowData,
    publicClient: publicClient,
    senderAddress: address.value,
    walletClientAdapter: createAdapter(getWalletClient)
  });
}

async function makePermit2(
  flowData: MakePermit2Flow<BaseBRRRFlow>
): Promise<ExecuteWithPermit2Flow<BaseBRRRFlow>> {
  const { isConnected, address, changeNetwork, getPublicClient, getWalletClient } = useWagmi();

  assert(isConnected.value && address.value !== undefined, new ExpectedError('notConnected'));

  await changeNetwork(flowData.inputToken.network);

  const service = useBrrrOnChainService();
  const publicClient = getPublicClient({ chainId: getChainId(flowData.inputToken.network) });

  addSentryBreadcrumb({
    level: 'info',
    message: 'call permit2 with data',
    data: {
      flowData: flowData,
      senderAddress: address.value
    }
  });

  return await service.makePermit2({
    flowData: flowData,
    senderAddress: address.value,
    publicClient: publicClient,
    walletClientAdapter: createAdapter(getWalletClient)
  });
}

async function buyTokenWithApprove(flowData: ExecuteWithApproveFlow<BaseBRRRFlow>): Promise<void> {
  const { isConnected, address, getPublicClient, getWalletClient } = useWagmi();

  assert(isConnected.value && address.value !== undefined, new ExpectedError('notConnected'));

  const service = useBrrrOnChainService();

  const publicClient = getPublicClient({ chainId: getChainId(flowData.inputToken.network) });

  addSentryBreadcrumb({
    level: 'info',
    message: 'call execute with approve',
    data: {
      flowData: flowData
    }
  });

  try {
    await service.buyTokenTrusted({
      flowData: flowData,
      publicClient: publicClient,
      walletClientAdapter: createAdapter(getWalletClient)
    });
  } catch (error) {
    // if (error instanceof TransactionRevertedError) {
    //   useTransactionsV3().handleRemoveMempoolTransaction(error.hash);
    // }
    throw error;
  } finally {
    // useWallet().updateTokens();
  }
}

async function buyTokenWithPermit(flowData: ExecuteWithPermitFlow<BaseBRRRFlow>): Promise<void> {
  const { isConnected, address, getPublicClient, getWalletClient } = useWagmi();

  assert(isConnected.value && address.value !== undefined, new ExpectedError('notConnected'));

  const service = useBrrrOnChainService();

  const publicClient = getPublicClient({ chainId: getChainId(flowData.inputToken.network) });

  addSentryBreadcrumb({
    level: 'info',
    message: 'call execute with permit',
    data: {
      flowData: flowData
    }
  });

  try {
    await service.buyTokenPermit({
      flowData: flowData,
      publicClient: publicClient,
      walletClientAdapter: createAdapter(getWalletClient)
    });
  } catch (error) {
    // if (error instanceof TransactionRevertedError) {
    //   useTransactionsV3().handleRemoveMempoolTransaction(error.hash);
    // }
    throw error;
  } finally {
    // useWallet().updateTokens();
  }
}

async function buyTokenWithPermit2(flowData: ExecuteWithPermit2Flow<BaseBRRRFlow>): Promise<void> {
  const service = useBrrrOnChainService();
  const { isConnected, address, getPublicClient, getWalletClient } = useWagmi();

  assert(isConnected.value && address.value !== undefined, new ExpectedError('notConnected'));

  addSentryBreadcrumb({
    level: 'info',
    message: 'call execute with permit2',
    data: {
      flowData: flowData
    }
  });

  const publicClient = getPublicClient({ chainId: getChainId(flowData.inputToken.network) });

  try {
    await service.buyTokenPermit2({
      flowData: flowData,
      publicClient: publicClient,
      walletClientAdapter: createAdapter(getWalletClient),
      senderAddress: address.value
    });
  } catch (error) {
    // if (error instanceof TransactionRevertedError) {
    //   useTransactionsV3().handleRemoveMempoolTransaction(error.hash);
    // }
    throw error;
  } finally {
    // useWallet().updateTokens();
  }
}

async function buyToken(
  flowData:
    | ExecuteWithPermitFlow<BaseBRRRFlow>
    | ExecuteWithPermit2Flow<BaseBRRRFlow>
    | ExecuteWithApproveFlow<BaseBRRRFlow>,
  estimation: EstimateContractGasCompoundReturn
): Promise<void> {
  const { isConnected, address, changeNetwork } = useWagmi();

  assert(isConnected.value && address.value !== undefined, new ExpectedError('notConnected'));

  await changeNetwork(flowData.inputToken.network);

  switch (flowData.flow) {
    case AllowanceFlow.ExecuteWithApprove:
      return buyTokenWithApprove({ ...flowData, estimation: estimation });
    case AllowanceFlow.ExecuteWithPermit2:
      return buyTokenWithPermit2({ ...flowData, estimation: estimation });
    case AllowanceFlow.ExecuteWithPermit:
      return buyTokenWithPermit({ ...flowData, estimation: estimation });
  }
}

async function getBaseAssetEstimation(
  token: TokenWithPriceAndBalance
): Promise<EstimateContractGasCompoundReturn> {
  assert(
    isBaseAssetByNetwork(token.address, token.network),
    new HHError('Provided token is not a base asset', { payload: { token: token } })
  );

  const { isConnected, address, getPublicClient } = useWagmi();

  assert(isConnected.value && address.value !== undefined, new ExpectedError('notConnected'));

  const publicClient = getPublicClient({ chainId: getChainId(token.network) });
  const service = useBrrrOnChainService();

  const swapTarget = getBrrrSwapTarget(token.network);

  let transferData: TransferData | undefined;
  try {
    transferData = await getTransferDataForBrrr(service)({
      buyToken: swapTarget,
      sellToken: token,
      sellAmount: token.balance
    });
  } catch (error) {
    throw new HHError('Failed to get transfer data', { cause: error });
  }

  let swapTargetPrice;
  try {
    swapTargetPrice = await useTokenPrice().getTokenPrice(swapTarget.address, swapTarget.network);
  } catch (error) {
    throw new HHError('Failed to get swap target token price', {
      cause: error,
      payload: { swapTarget: swapTarget }
    });
  }

  const tokenWithPermit = await getTokenWithPermit(token);

  const allowanceFlow = await service.getAllowanceFlow({
    publicClient,
    senderAddress: address.value,
    flowData: {
      flowType: 'brrr',
      inputAmountInWei: parseUnits(token.balance, token.decimals),
      inputToken: tokenWithPermit,
      transferData,
      swapTargetPriceUSD: swapTargetPrice,
      tokensReceiver: address.value,
      meta: { expectedBRRRAmount: '0' },
      swapTarget
    }
  });

  assert(
    allowanceFlow.flow === AllowanceFlow.ExecuteWithPermit,
    new HHError(
      `Unexpected flow outcome: want ${AllowanceFlow.ExecuteWithPermit}, got ${allowanceFlow.flow}`
    )
  );

  return allowanceFlow.estimation;
}

let conversionHelperInstance: BrrrConversionHelper | null = null;
function getBrrrConversionHelper(): BrrrConversionHelper {
  if (conversionHelperInstance === null) {
    const service = useBrrrOnChainService();

    conversionHelperInstance = new BrrrConversionHelper({
      apiService: useBrrrAPIService(),
      swapTargets: filterDefined(
        service.getAvailableNetworks().map((n) => {
          try {
            return getBrrrSwapTarget(n);
          } catch (error) {
            addSentryBreadcrumb({
              level: 'warning',
              message: `No BRRR swap target for network ${n}`,
              data: {
                error
              }
            });
            // TODO: do something better...
            return undefined;
          }
        })
      ),
      tokenPriceGetter: (token) => useTokenPrice().getTokenPrice(token.address, token.network),
      transferDataGetter: getTransferDataForBrrr(service),
      minDEFIMaxSwapBorder: MIN_DEFI_SWAP_DIFF_UPPER_BORDER
    });
  }

  return conversionHelperInstance;
}

function getTransferDataForBrrr(service: BRRROnChainService) {
  return (params: TransferDataGetterArgs) =>
    service.getTransferData(
      params.sellToken,
      params.sellAmount,
      params.buyToken,
      (network, buyTokenAddress, sellTokenAddress, rawAmount, fromAddress) =>
        useSwapAPIService(network).getTransferData(
          buyTokenAddress,
          sellTokenAddress,
          rawAmount,
          fromAddress
        )
    );
}

async function internalApplyFlowData(
  flowData: GetBrrrFlowReturn,
  estimation: EstimateContractGasCompoundReturn | undefined
): Promise<true | GetBrrrFlowReturn> {
  switch (flowData.flow) {
    case AllowanceFlow.MakeApprove:
      if (estimation === undefined) {
        //FALLBACK if custom estimation not presented
        estimation = (
          await getClonedGasEstimationByNetwork(
            flowData.inputToken.network,
            flowData.estimation,
            false
          )
        )[0];
      }

      return await approve(flowData, estimation);
    case AllowanceFlow.MakePermit:
      return await makePermit(flowData);
    case AllowanceFlow.MakePermit2:
      return await makePermit2(flowData);
    case AllowanceFlow.ExecuteWithApprove:
    case AllowanceFlow.ExecuteWithPermit:
    case AllowanceFlow.ExecuteWithPermit2:
      if (estimation === undefined) {
        //FALLBACK if custom estimation not presented
        estimation = (
          await getClonedGasEstimationByNetwork(
            flowData.inputToken.network,
            flowData.estimation,
            false
          )
        )[0];
      }

      await buyToken(flowData, estimation);
      return true;
  }
}

async function applyFlowData(
  flowData: GetBrrrFlowReturn,
  estimation: EstimateContractGasCompoundReturn | undefined,
  signal: AbortSignal
): Promise<true | GetBrrrFlowReturn> {
  const { resolve, reject, wait } = createPromiseWithAbortSignal<true | GetBrrrFlowReturn, unknown>(
    signal
  );

  addSentryBreadcrumb({
    level: 'info',
    message: 'apply flow data',
    data: {
      flowData,
      estimation
    }
  });

  const { checkIfStillAvailable } = useBrrr();
  if (!(await checkIfStillAvailable())) {
    throw new ExpectedError<ClientEECode>('brrrUnavailable', {
      cause: new Error('Brrr is not available (reported by settings)')
    });
  }

  internalApplyFlowData(flowData, estimation).then(resolve).catch(reject);
  return wait();
}

export function useBrrrTransaction() {
  return {
    prepareBrrr,
    getBrrrFlow,
    makeGetFlowParamsFromPrepareReturn,
    applyFlowData,
    reloadBrrrWithNewAmount,
    subscribeOnReceiveHash,
    getBrrrConversionHelper,
    getBaseAssetEstimation
  };
}
