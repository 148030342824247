import axios, {
  type AxiosError,
  type AxiosResponse,
  type Cancel,
  type CreateAxiosDefaults,
  type Method as AxiosMethod
} from 'axios';

import { useAccount } from '@/composables/useAccount';

import { APIError } from './APIError';
import {
  type HHAPIErrorResponse,
  type HHAPIResponse,
  type HHAPISuccessfulResponse,
  isErrorResponse,
  type RequestOptions
} from './types';

type Method = Uppercase<AxiosMethod>;
type MethodsWithBody = Extract<Method, 'POST' | 'PUT' | 'PATCH'>;
type MethodsWithoutBody = Exclude<Method, MethodsWithBody>;

type HHRequestConfig<Data> = {
  url: string;
  headers?: Record<string, string>;
  opts?: RequestOptions;
  withCredentials?: boolean;
  skipSessionErrorHandling?: boolean;
} & (
  | {
      method: MethodsWithBody;
      params?: Record<string, unknown>;
      data: Data;
      onUploadProgress?: CreateAxiosDefaults['onUploadProgress'];
    }
  | {
      method: MethodsWithoutBody;
      params?: Record<string, unknown>;
      data?: never;
    }
);

interface HHAxiosInstance {
  request<Payload, Data = void>(
    params: HHRequestConfig<Data>
  ): Promise<AxiosResponse<HHAPISuccessfulResponse<Payload>> | never>;

  requestRaw<Payload, Data = void>(
    params: HHRequestConfig<Data>
  ): Promise<AxiosResponse<Payload> | never>;
}

type CreateHHAxiosDefaults = CreateAxiosDefaults & {
  onFulfilled?: (<T>(value: T) => T | Promise<T>) | null;
  onRejected?: ((error: unknown) => never) | null;
};

export const createHHAxios = (defaults?: CreateHHAxiosDefaults): HHAxiosInstance => {
  const instance = axios.create(defaults);

  if (defaults?.onFulfilled !== undefined || defaults?.onRejected !== undefined) {
    instance.interceptors.response.use(defaults.onFulfilled, defaults.onRejected);
  } else {
    instance.interceptors.response.use(
      <Payload, ErrorPayload>(
        response: AxiosResponse<HHAPIResponse<Payload, ErrorPayload> | undefined>
      ): AxiosResponse<HHAPISuccessfulResponse<Payload>> | never => {
        if (response.data === undefined) {
          throw new APIError('Empty response', 'empty response code', { payload: { response } });
        }

        // if response.data.status === 'error' then API returned malformed
        // response and/or the response should be treated as an error
        if (isErrorResponse(response.data)) {
          const wrappedError = APIError.fromErrorResponse<ErrorPayload>(
            response as AxiosResponse<HHAPIErrorResponse<ErrorPayload>>
          );

          if (wrappedError.code === 'INVALID_SESSION') {
            useAccount().handleSessionError(wrappedError);
          }

          throw wrappedError;
        }

        // otherwise, don't process the response
        // anyway, we have to cast response (originally HHAPIResponse<T, E>) to
        // the HHAPISuccessfulResponse<T> given that response.data.status is not of
        // error type
        return response as AxiosResponse<HHAPISuccessfulResponse<Payload>>;
      },
      (error) => {
        throw formatError(error);
      }
    );
  }

  function formatError(error: unknown): Error | Cancel {
    if (axios.isCancel(error)) {
      // short-circuit to just throw, as we need to catch / handle it at caller's level
      return error;
    }

    if (error instanceof APIError) {
      return error;
    }

    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<HHAPIErrorResponse<unknown>>;
      if (axiosError.response !== undefined) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (axiosError.response.data === undefined) {
          const errorPayload = {
            method: axiosError.config?.method,
            requestUri: axios.getUri(axiosError.config),
            statusCode: axiosError.status,
            statusMessage: axiosError.message,
            axiosCode: axiosError.code,
            responseCode: axiosError.response.status
          };

          return new APIError('Request failed: no data', axiosError.message ?? 'no data', {
            payload: errorPayload
          });
        }

        return APIError.fromErrorResponse(axiosError.response);
      }

      if (axiosError.request !== undefined) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest

        const errorData = {
          method: axiosError.config?.method,
          requestUri: axios.getUri(axiosError.config),
          statusCode: axiosError.status,
          statusMessage: axiosError.message,
          axiosCode: axiosError.code
        };

        return new APIError('The request has failed, no response', 'NO_RESPONSE', {
          payload: errorData
        });
      }
    }

    if (error instanceof Error) {
      // An error is JS-initiated error, just pass it through
      return new Error('The request has failed', { cause: error });
    }

    return new Error('The request has failed during setup / result handling', { cause: error });
  }

  return {
    async request<Payload, Data>(
      req: HHRequestConfig<Data>
    ): Promise<AxiosResponse<HHAPISuccessfulResponse<Payload>> | never> {
      try {
        return await instance.request({
          ...req,
          signal: req.opts?.signal,
          params: {
            ...(req.opts?.paginate !== undefined ? req.opts.paginate : {}),
            ...req.params
          }
        });
      } catch (error) {
        const formattedError = formatError(error);
        if (
          !req.skipSessionErrorHandling &&
          formattedError instanceof APIError &&
          formattedError.code === 'INVALID_SESSION'
        ) {
          useAccount().handleSessionError(formattedError);
        }

        throw error;
      }
    },
    async requestRaw<Payload, Data>(
      req: HHRequestConfig<Data>
    ): Promise<AxiosResponse<Payload> | never> {
      try {
        return await instance.request({
          ...req,
          signal: req.opts?.signal,
          params: {
            ...(req.opts?.paginate !== undefined ? req.opts.paginate : {}),
            ...req.params
          }
        });
      } catch (error) {
        const formattedError = formatError(error);
        if (
          !req.skipSessionErrorHandling &&
          formattedError instanceof APIError &&
          formattedError.code === 'INVALID_SESSION'
        ) {
          useAccount().handleSessionError(formattedError);
        }

        throw error;
      }
    }
  };
};
