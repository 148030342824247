import { type Address, ContractFunctionZeroDataError, type PublicClient } from 'viem';

import { walkError } from '@/helpers/errors';
import { createLogger } from '@/logs/datadog';
import { LruMap } from '@/references/LruMap';
import { deriveChainId } from '@/references/onchain/adapters';

import { getAmbireNonce, MissingReferenceError } from './getAmbireNonce';

const cache = new LruMap<string, boolean>(16);
const logger = createLogger('isAmbireWallet');

export const isAmbireWallet = async (
  publicClient: PublicClient,
  address: Address
): Promise<boolean> => {
  const chainId = await deriveChainId(publicClient);
  const cacheKey = `${chainId}:${address}`;
  const cached = cache.get(cacheKey);
  if (cached !== undefined) {
    return cached;
  }

  try {
    const nonce = await getAmbireNonce(publicClient, address);
    // an assumption is made that AA wallet nonce could not be 0,
    //  as the first tx ever made from that address becomes its first used nonce (0)
    const res = nonce !== 0n;
    cache.set(cacheKey, res);
    return res;
  } catch (error) {
    if (walkError(error, (e) => e instanceof ContractFunctionZeroDataError)) {
      logger.debug(
        `The function call "getAmbireNonce" for ${address} in ${chainId} reverted. Either the address is not initialized or EOA`
      );
      cache.set(cacheKey, false);
      return false;
    }

    if (error instanceof MissingReferenceError) {
      cache.set(cacheKey, false);
      return false;
    }

    throw error;
  }
};
