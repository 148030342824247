<script setup lang="ts">
import { onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';

import { Synaps } from '@synaps-io/verify-sdk';
import { useIntervalFn } from '@vueuse/core';

import { useKYC } from '@/composables/useKYC';
import { addSentryBreadcrumb, captureSentryException } from '@/logs/sentry';
import { KYCStatus } from '@/references/axios/kyc/types';
import { SECOND } from '@/references/constants';
import { useI18n } from '@/references/i18n';
import { RouteNames } from '@/router';

import { InnerLayoutButtonType } from '@/components/layouts/InnerLayout.types';
import InnerLayout from '@/components/layouts/InnerLayout.vue';

const { t } = useI18n();
const { push } = useRouter();
const { synapsSessionId, KYCstatus, updateKYCStatus } = useKYC();

const goBack = () => {
  push({ name: RouteNames.Home });
};

onMounted(() => {
  if (KYCstatus.value === KYCStatus.SUCCESS || KYCstatus.value === KYCStatus.FAILED) {
    push({ name: RouteNames.Home });
    return;
  }
  Synaps.init({
    sessionId: synapsSessionId.value,
    mode: 'embed',
    onFinish: () => {
      updateKYCStatus().then(() => push({ name: RouteNames.Home }));
    }
  });
});

watch(KYCstatus, () => {
  if (KYCstatus.value === KYCStatus.SUCCESS || KYCstatus.value === KYCStatus.FAILED) {
    push({ name: RouteNames.Home });
  }
});

useIntervalFn(async () => {
  try {
    await updateKYCStatus();
  } catch (e) {
    addSentryBreadcrumb({
      level: 'error',
      message: 'fail to update KYC status on background',
      data: {
        error: e
      }
    });
    captureSentryException(e);
  }
}, 5 * SECOND);
</script>

<template>
  <section class="kyc-view">
    <InnerLayout :title="t('kyc.title')" :button-type="InnerLayoutButtonType.Back" @back="goBack">
      <div id="synaps-wrapper" style="height: 600px"></div>
    </InnerLayout>
  </section>
</template>

<style scoped>
.kyc-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.kyc-view__header {
  position: sticky;
  top: 96px;
  z-index: var(--z-index-header);
  margin: 0 0 40px;
}

@media (max-width: 919px) {
  .kyc-view__header {
    top: 80px;
    margin: 0 0 24px;
  }
}
</style>
