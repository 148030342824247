import { BigNumber } from 'bignumber.js';

import { sameAddress } from '@/helpers/addresses';
import { assert } from '@/helpers/assert';
import { divide, lessThan, multiply, toWei } from '@/helpers/bigmath';
import { addSentryBreadcrumb } from '@/logs/sentry';
import type { HHAPIBrrrService } from '@/references/axios/brrr/HHAPIBrrrService';
import type { TransferData } from '@/references/axios/swap/types';
import type { Network } from '@/references/network';
import { getConfig as getSolanaConfig, type SolanaToken } from '@/references/solanaConfig';
import type { Token, TokenWithBalance } from '@/references/tokens';
import { getBrrrToken } from '@/references/tokens';

export type TokenPriceGetter = <T extends Token>(token: T) => Promise<string>;
export type TransferDataGetterArgs = {
  buyToken: Token;
  sellAmount: string;
  sellToken: Token;
};
export type TransferDataGetterReturn = TransferData | undefined;
export type TransferDataGetter = (
  params: TransferDataGetterArgs
) => Promise<TransferDataGetterReturn>;

type ConstructorArgs = {
  apiService: HHAPIBrrrService;
  swapTargets: Array<Token>;
  transferDataGetter: TransferDataGetter;
  tokenPriceGetter: TokenPriceGetter;
  minDEFIMaxSwapBorder: string;
};

export type ConvertTokenToBRRRReturn = {
  inputTokenAmount: string;
  brrrAmount: string;
  brrrToken: Token;
  rate: string;
} & (
    | {
      willSwap: true;
      swapTarget: Token;
      swapTargetPrice: string;
      transferData: TransferData;
      badPriceDetected: boolean;
    }
    | {
      willSwap: false;
      transferData: undefined;
    }
  );

export type ConvertSolanaTokenToBRRRReturn = {
  inputTokenAmount: string;
  brrrAmount: string;
  brrrToken: SolanaToken;
  rate: string;
};

export class BrrrConversionHelper {
  private readonly apiService: HHAPIBrrrService;
  private readonly swapTargets: Array<Token>;
  private readonly getTransferData: TransferDataGetter;
  private readonly getTokenPrice: TokenPriceGetter;
  private readonly minDEFIMaxSwapBorder: string;

  constructor({
    apiService,
    swapTargets,
    transferDataGetter,
    tokenPriceGetter,
    minDEFIMaxSwapBorder
  }: ConstructorArgs) {
    this.apiService = apiService;
    this.swapTargets = swapTargets;
    this.getTransferData = transferDataGetter;
    this.getTokenPrice = tokenPriceGetter;
    this.minDEFIMaxSwapBorder = minDEFIMaxSwapBorder;
  }

  public async convertTokenToBRRR(
    inputToken: TokenWithBalance,
    inputTokenAmount: string,
    signal?: AbortSignal
  ): Promise<ConvertTokenToBRRRReturn> {
    addSentryBreadcrumb({
      level: 'debug',
      message: 'Converting token to BRRR',
      data: {
        inputToken,
        inputTokenAmount
      }
    });

    const brrrToken = getBrrrToken(inputToken.network);

    if (this.isSwapTarget(inputToken)) {
      addSentryBreadcrumb({
        level: 'debug',
        message: `${inputToken.symbol} is a swap target. Operation involves no swap, no need to generate transfer data`
      });

      const result = await this.apiService.getBrrrAmountByToken(
        {
          inputToken,
          transferData: undefined,
          outputToken: inputToken,
          tokenAmount: inputTokenAmount
        },
        signal
      );

      return {
        brrrAmount: result.brrrAmount,
        brrrToken,
        rate: result.rate,
        inputTokenAmount,
        transferData: undefined,
        willSwap: false
      };
    }

    addSentryBreadcrumb({
      level: 'debug',
      message: `${inputToken.symbol} is a common token. Operation involves a swap`
    });

    const swapTarget = this.getSwapTarget(inputToken.network);
    const [inputTokenPrice, swapTargetPrice, transferData] = await Promise.all([
      this.getTokenPrice(inputToken),
      this.getTokenPrice(swapTarget),
      this.getTransferData({
        buyToken: swapTarget,
        sellAmount: inputTokenAmount,
        sellToken: inputToken
      })
    ]);

    assert(
      transferData !== undefined,
      `Missing transfer data on non-primitive token ${inputToken.symbol}`
    );

    const result = await this.apiService.getBrrrAmountByToken(
      {
        inputToken,
        transferData,
        outputToken: swapTarget,
        tokenAmount: inputTokenAmount
      },
      signal
    );

    const badPriceDetected = this.isBadPrice(
      inputTokenPrice,
      inputTokenAmount,
      swapTarget,
      swapTargetPrice,
      transferData
    );

    return {
      brrrAmount: result.brrrAmount,
      brrrToken,
      rate: result.rate,
      inputTokenAmount,
      transferData,
      willSwap: true,
      swapTarget,
      swapTargetPrice,
      badPriceDetected
    };
  }

  public async convertSolanaTokenToBRRR(
    inputToken: SolanaToken,
    inputTokenAmount: string,
    signal?: AbortSignal
  ): Promise<ConvertSolanaTokenToBRRRReturn> {
    const config = getSolanaConfig();
    assert(config !== undefined, 'Config must be defined');

    const brrrToken = config.brrrToken;

    const result = await this.apiService.getBrrrAmountBySolanaToken(
      {
        inputToken,
        tokenAmount: inputTokenAmount,
        brrrToken
      },
      signal
    );

    return {
      inputTokenAmount: result.inputTokenAmount,
      brrrAmount: result.brrrAmount,
      brrrToken: result.outputToken,
      rate: result.rate
    };
  }

  private isSwapTarget(token: Token): boolean {
    return this.swapTargets.some(
      (st) => sameAddress(st.address, token.address) && st.network == token.network
    );
  }

  private getSwapTarget(network: Network): Token {
    const target = this.swapTargets.find((st) => st.network === network);
    if (target === undefined) {
      throw new Error(`No swap target defined for ${network}`);
    }

    return target;
  }

  public isBadPrice(
    tokenPrice: string,
    tokenAmount: string,
    swapTarget: Token,
    swapTargetPrice: string,
    transferData: TransferData
  ): boolean {
    const sumByDefi = multiply(tokenAmount, tokenPrice);

    const expectedSwapTargetAmountByDEFI = divide(sumByDefi, swapTargetPrice);

    const expectedUSDCAmountByDEFIInWei = new BigNumber(
      toWei(expectedSwapTargetAmountByDEFI, swapTarget.decimals)
    ).toFixed(0);

    const diffDEFIvsSwapper = divide(transferData.buyAmount, expectedUSDCAmountByDEFIInWei);
    const badPrice = lessThan(diffDEFIvsSwapper, this.minDEFIMaxSwapBorder);
    if (badPrice) {
      addSentryBreadcrumb({
        level: 'debug',
        message: 'Detected bad price',
        data: {
          minDEFIMaxSwapBorder: this.minDEFIMaxSwapBorder,
          swapBuyAmount: transferData.buyAmount.toString(),
          DEFIBuyAmount: expectedUSDCAmountByDEFIInWei.toString(),
          tokenAmount,
          diffDEFIvsSwapper: diffDEFIvsSwapper.toString(),
          raw: transferData.rawResponse
        }
      });
    }

    return badPrice;
  }
}
