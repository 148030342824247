<script setup lang="ts">
import { getNetwork, type Network } from '@/references/network';
import type { Token } from '@/references/tokens';

import TokenImage from '@/components/TokenImage.vue';
import UiText from '@/components/ui/UiText.vue';

interface Props {
  token: Token;
  description: string;
  value: string;
  networks?: Network[];
  readonly?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  networks: undefined,
  readonly: false
});
</script>

<template>
  <component
    :is="props.readonly ? 'div' : 'button'"
    class="token-info"
    :type="props.readonly ? undefined : 'button'"
  >
    <TokenImage
      class="token-info__image"
      :token="props.token"
      :hide-network-icon="props.networks && props.networks.length > 1"
    />
    <span class="token-info__body">
      <UiText class="token-info__title" size="md" weight="medium">
        {{ props.token.name }}
      </UiText>
      <span class="token-info__footer">
        <ul v-if="props.networks && props.networks.length > 1" class="token-info__network-list">
          <li v-for="network in props.networks" :key="network" class="token-info__network-item">
            <img
              class="token-info__network-image"
              :src="getNetwork(network)?.iconURL"
              :alt="getNetwork(network)?.displayedName"
              :title="getNetwork(network)?.displayedName"
            />
          </li>
        </ul>
        <UiText class="token-info__description" size="sm" weight="medium" muted>
          {{ props.description }}
        </UiText>
      </span>
    </span>
    <UiText class="token-info__value" tag="p" weight="medium" size="lg">
      {{ props.value }}
    </UiText>
  </component>
</template>

<style scoped>
.token-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

button.token-info {
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  appearance: none;
  user-select: none;
}

.token-info__image {
  flex-shrink: 0;
  margin: 0 16px 0 0;
}

.token-info__body {
  margin: 0 16px 0 0;
}

.token-info__title {
  display: block;
  word-break: break-word;
}

.token-info__footer {
  display: flex;
  align-items: center;
}

.token-info__network-list {
  display: flex;
  align-items: center;
  margin: 0 8px 0 0;
  padding: 0;
  list-style-type: none;
}

.token-info__network-item:not(:first-child) {
  margin: 0 0 0 -8px;
}

.token-info__network-image {
  display: block;
  width: 20px;
  height: 20px;
  margin: -2px;
  border: 2px solid var(--color-white);
  border-radius: 50%;
}

.token-info__description {
  display: block;
  text-transform: uppercase;
  word-break: break-word;
}

.token-info__value {
  margin: 0 0 0 auto;
  text-align: right;
}

@media (max-width: 919px) {
  .token-info__value {
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
  }
}
</style>
