import { v4 } from 'uuid';

/**
 * Returns random operation id with provided prefix
 * @param prefix - a string to precede operation id with
 * @example
 * generateOperationId('payment'); // output: payment_8d6a4c75-b556-4efa-95f8-b13d1098a1db
 */
export function generateOperationId(prefix: string): string {
  return `${prefix}_${v4()}`;
}
