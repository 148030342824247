import type { GetBrrrFlowReturn } from '@/composables/useBrrrTransaction';
import { greaterThanOrEqual } from '@/helpers/bigmath';
import type { InputTokenOrFiatMode } from '@/helpers/flow/types';
import { BadSwapPriceError } from '@/helpers/flow/types';
import { sameToken } from '@/helpers/tokens';
import type { BaseBRRRFlow } from '@/references/onchain/holyheld/BRRROnChainService.types';
import type {
  ExecuteWithApproveFlow,
  ExecuteWithPermit2Flow,
  ExecuteWithPermitFlow,
  MakeApproveFlow,
  MakePermit2Flow,
  MakePermitFlow
} from '@/references/onchain/holyheld/flowTypes';
import { AllowanceFlow } from '@/references/onchain/holyheld/flowTypes';
import type { Token, TokenWithPriceAndBalance } from '@/references/tokens';

export const isNeedApproveOrPermit = (
  flowData: GetBrrrFlowReturn
): flowData is
  | MakeApproveFlow<BaseBRRRFlow>
  | MakePermitFlow<BaseBRRRFlow>
  | MakePermit2Flow<BaseBRRRFlow> => {
  switch (flowData.flow) {
    case AllowanceFlow.MakeApprove:
    case AllowanceFlow.MakePermit:
    case AllowanceFlow.MakePermit2:
      return true;
    default:
      return false;
  }
};

export const isNeedTransaction = (
  flowData?: GetBrrrFlowReturn
): flowData is
  | ExecuteWithApproveFlow<BaseBRRRFlow>
  | ExecuteWithPermitFlow<BaseBRRRFlow>
  | ExecuteWithPermit2Flow<BaseBRRRFlow> => {
  switch (flowData?.flow) {
    case AllowanceFlow.MakeApprove:
    case AllowanceFlow.ExecuteWithPermit:
    case AllowanceFlow.ExecuteWithPermit2:
    case AllowanceFlow.ExecuteWithApprove:
      return true;
    default:
      return false;
  }
};

export const throwBadPrice = (
  token: Token,
  amount: string,
  inputMode: InputTokenOrFiatMode
): never => {
  throw new BadSwapPriceError(token, {
    payload: {
      amount: amount,
      inputMode: inputMode
    }
  });
};

export const calcLocalInsufficientToken = (
  token: Token,
  tokenAmount: string,
  walletAssets: TokenWithPriceAndBalance[] | Readonly<TokenWithPriceAndBalance[]>
): boolean => {
  const walletToken = walletAssets.find((item) => sameToken(token, item));
  if (walletToken === undefined) {
    return true;
  }

  return !greaterThanOrEqual(walletToken.balance, tokenAmount);
};
