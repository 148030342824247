import type { Abi, Address, Hex, PublicClient } from 'viem';
import { encodeAbiParameters, encodeFunctionData } from 'viem';

import type { WalletClientAdapter } from '@/references/onchain/adapters';
import { prefixHexIfNeeded } from '@/references/onchain/hex';
import { inputIsNotNullOrUndefined } from '@/references/onchain/nullish';
import { uintBufferToHex } from '@/references/onchain/parsing';
import { fixSignatureV, hexToSignature } from '@/references/onchain/signature';

import {
  DOMAIN_TYPEHASH_ABI,
  DOMAINS_WITHOUT_VERSION,
  EIP_2612_PERMIT_ABI,
  EIP_2612_PERMIT_SELECTOR,
  ERC_20_NONCES_ABI,
  TOKEN_ADDRESSES_WITH_SALT
} from './const';
import {
  type EIP712Object,
  type EIP712Parameter,
  type EIP712TypedData,
  eip2612PermitModelFields,
  type PermitParams,
  type PermitTypedDataParamsModel
} from './types';

const domainTypeHashStorage = new Map<string, string>();

export async function getTokenNonce(
  walletAddress: Address,
  publicClient: PublicClient,
  tokenAddress: Address,
  nonceMethodNameIndex = 0
): Promise<number> {
  const methodName = ERC_20_NONCES_ABI[nonceMethodNameIndex]?.name;
  if (!methodName) {
    throw new Error('nonce not supported');
  }

  const callData = contractEncodeABI(ERC_20_NONCES_ABI, methodName, [walletAddress]);

  let data: Hex;
  try {
    const r = await publicClient.call({
      to: tokenAddress as Address,
      data: callData
    });
    data = r.data ?? '0x';
  } catch {
    console.info('ethCall error, try other nonce function name');
    return getTokenNonce(walletAddress, publicClient, tokenAddress, nonceMethodNameIndex + 1);
  }

  if (data === '0x' || Number.isNaN(Number(data))) {
    throw new Error('nonce is NaN');
  }

  return Number(data);
}

export async function buildPermitCallData(
  publicClient: PublicClient,
  walletClientAdapter: WalletClientAdapter,
  permitParams: PermitParams,
  chainId: number,
  tokenName: string,
  tokenAddress: Address,
  version?: string
): Promise<Hex> {
  const permitSignature = await buildPermitSignature(
    publicClient,
    walletClientAdapter,
    permitParams,
    chainId,
    tokenName,
    tokenAddress,
    version
  );
  const permitCallData = contractEncodeABI(
    EIP_2612_PERMIT_ABI,
    'permit',
    getPermitContractCallParams(permitParams, permitSignature)
  );

  return permitCallData.replace(EIP_2612_PERMIT_SELECTOR, '0x') as Hex;
}

export const buildPermitSignature = async (
  publicClient: PublicClient,
  walletClientAdapter: WalletClientAdapter,
  permitParams: PermitParams,
  chainId: number,
  tokenName: string,
  tokenAddress: Address,
  version?: string
): Promise<Hex> => {
  const permitData = buildPermitTypedData({
    chainId,
    tokenName,
    tokenAddress,
    params: permitParams,
    isSaltInsteadOfChainId: isSaltInsteadOfChainId(tokenAddress, chainId),
    isDomainWithoutVersion: await isDomainWithoutVersion(publicClient, tokenAddress),
    version
  });

  const signature = await walletClientAdapter.useWalletClient({ chainId })((c) =>
    c.signTypedData({
      domain: permitData.domain,
      types: permitData.types,
      primaryType: permitData.primaryType,
      message: permitData.message
    })
  );

  return fixSignatureV(signature);
};

export function buildPermitTypedData(data: PermitTypedDataParamsModel): EIP712TypedData {
  const {
    chainId,
    tokenName,
    tokenAddress: verifyingContract,
    params,
    isDomainWithoutVersion = false,
    isSaltInsteadOfChainId = false,
    version = '1',
    permitModelFields = eip2612PermitModelFields
  } = data;
  const domain: EIP712Object = { name: tokenName, verifyingContract };

  if (isSaltInsteadOfChainId) domain.salt = getSalt(data);
  if (!isSaltInsteadOfChainId) domain.chainId = chainId;
  if (!isDomainWithoutVersion) domain.version = version;

  return {
    types: {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        isDomainWithoutVersion ? null : { name: 'version', type: 'string' },
        isSaltInsteadOfChainId ? null : { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' },
        !isSaltInsteadOfChainId ? null : { name: 'salt', type: 'bytes32' }
      ].filter<EIP712Parameter>(inputIsNotNullOrUndefined),
      Permit: permitModelFields
    },
    primaryType: 'Permit',
    domain,
    message: params
  };
}

function getPermitContractCallParams(
  permitParams: PermitParams,
  permitSignature: Hex
): (string | number | bigint)[] {
  const { v, r, s } = hexToSignature(permitSignature);

  return [
    permitParams.owner,
    permitParams.spender,
    permitParams.value,
    permitParams.deadline,
    v,
    r,
    s
  ];
}

const isSaltInsteadOfChainId = (tokenAddress: Address, chainId: number): boolean => {
  const identifier = buildTokenIdentifier(tokenAddress, chainId);
  return TOKEN_ADDRESSES_WITH_SALT.includes(identifier);
};

const getDomainTypeHash = async (
  publicClient: PublicClient,
  tokenAddress: Address
): Promise<string | null> => {
  if (domainTypeHashStorage.has(tokenAddress)) {
    const r = domainTypeHashStorage.get(tokenAddress);
    return r ?? null; // make TS happy
  }
  try {
    const r = await publicClient.call({
      to: tokenAddress as Address,
      data: contractEncodeABI(DOMAIN_TYPEHASH_ABI, 'DOMAIN_TYPEHASH', [])
    });
    const domainTypeHash = r.data ?? '0x';

    domainTypeHashStorage.set(tokenAddress, domainTypeHash);

    return domainTypeHash;
  } catch {
    return Promise.resolve(null);
  }
};

function contractEncodeABI(abi: Abi, methodName: string, methodParams: unknown[]): Hex {
  return encodeFunctionData({
    abi,
    args: methodParams.map((param) =>
      param instanceof Uint8Array ? prefixHexIfNeeded(uintBufferToHex(param)) : param
    ),
    functionName: methodName
  });
}

const isDomainWithoutVersion = async (
  publicClient: PublicClient,
  tokenAddress: Address
): Promise<boolean> => {
  const domainTypeHash = await getDomainTypeHash(publicClient, tokenAddress);

  return !!domainTypeHash && DOMAINS_WITHOUT_VERSION.includes(domainTypeHash.toLowerCase());
};

function buildTokenIdentifier(tokenAddress: Address, chainId: number): string {
  return `${tokenAddress}:${chainId}`.toLowerCase();
}

function getSalt(data: PermitTypedDataParamsModel): string {
  const { chainId, tokenAddress } = data;
  const identifier = buildTokenIdentifier(tokenAddress, chainId);
  if (TOKEN_ADDRESSES_WITH_SALT.includes(identifier)) {
    return encodeAbiParameters([{ type: 'uint256' }], [BigInt(chainId)]);
  }
  return encodeAbiParameters([{ type: 'uint256' }], [BigInt(0)]);
}
