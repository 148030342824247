import { currentClientType } from '@/helpers/utils';
import { HHAPINonceService } from '@/references/axios/nonce/HHAPINonceService';
import { getEndpoint } from '@/references/endpoints';

let instance: HHAPINonceService | null = null;
export function useNonceAPIService(): HHAPINonceService {
  if (instance === null) {
    instance = new HHAPINonceService({
      baseURL: getEndpoint('apiBrrr'),
      clientType: currentClientType()
    });
  }

  return instance;
}
