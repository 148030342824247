<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { useElementVisibility } from '@vueuse/core';

import helpLottie from '@/assets/lottie/help.json';
import { useBrrr } from '@/composables/useBrrr';
import { type ClientEECode, useErrorModal } from '@/composables/useErrorModal';
import { ExpectedError } from '@/references/ExpectedError';
import { RouteNames } from '@/router';

import { InnerLayoutButtonType } from '@/components/layouts/InnerLayout.types';
import InnerLayout from '@/components/layouts/InnerLayout.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiLottie from '@/components/ui/UiLottie.vue';
import UiMarkdown from '@/components/ui/UiMarkdown.vue';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

const { t } = useI18n();
const { back, push } = useRouter();
const { auto } = useErrorModal();

const { termsLoading, termsLoaded, terms, fetchTerms, acceptTerms } = useBrrr();

const isLoading = ref(false);

async function onButtonClick(): Promise<void> {
  try {
    isLoading.value = true;
    await acceptTerms();
    await push({ name: RouteNames.BrrrBuyToken });
  } catch (error) {
    auto(
      new ExpectedError<ClientEECode>('brrrTermsAndConditionsAccept', {
        cause: error,
        sentryHandle: true
      })
    );
  } finally {
    isLoading.value = false;
  }
}

const targetElement = ref<HTMLElement>();
const targetElementIsVisible = useElementVisibility(targetElement);

const isButtonDisabled = computed((): boolean => !targetElementIsVisible.value);

const buttonText = computed((): string =>
  isButtonDisabled.value
    ? t('brrrTerms.scrollToContinue')
    : t('brrrTerms.agreeAndContinueToPayment')
);

onMounted(fetchTerms);
</script>

<template>
  <InnerLayout
    :button-type="InnerLayoutButtonType.Back"
    :tip-title="t('brrrTerms.infoModal.title')"
    :tip-description="t('brrrTerms.infoModal.description')"
    @back="back"
  >
    <div class="brrr-accept-terms">
      <template v-if="termsLoaded">
        <UiTitle class="brrr-accept-terms__title" :level="1">
          {{ t('brrrTerms.title') }}
        </UiTitle>
        <UiText class="brrr-accept-terms__description" tag="p" size="md" muted>
          {{ t('brrrTerms.description') }}
        </UiText>

        <UiMarkdown class="brrr-accept-terms__content" :string="terms" />

        <div class="brrr-accept-terms__button-wrapper">
          <UiButton
            class="brrr-accept-terms__button"
            :loading="isLoading"
            :mod="isButtonDisabled ? 'muted' : 'primary'"
            :disabled="isButtonDisabled"
            @click="onButtonClick"
          >
            {{ buttonText }}
          </UiButton>
        </div>

        <div ref="targetElement" class="brrr-accept-terms__target"></div>
      </template>

      <template v-else-if="termsLoading">
        <UiSpinner class="brrr-accept-terms__spinner" />
      </template>

      <div v-else class="brrr-accept-terms__offline">
        <UiLottie
          class="brrr-accept-terms__offline-lottie"
          :data="helpLottie"
          :size="180"
          autoplay
          loop
        />
        <UiText
          class="brrr-accept-terms__offline-text"
          tag="p"
          weight="medium"
          size="md"
          muted
          align="center"
        >
          {{ t('itLooksLikeYouAreOffline') }}
        </UiText>
        <UiButton class="brrr-accept-terms__offline-button" mod="secondary" @click="fetchTerms">
          {{ t('reconnect') }}
        </UiButton>
      </div>
    </div>
  </InnerLayout>
</template>

<style scoped>
.brrr-accept-terms {
  position: relative;
  flex-grow: 1;
}

.brrr-accept-terms__title {
  margin: 0 0 8px;
}

.brrr-accept-terms__description {
  margin: 0 0 24px;
}

.brrr-accept-terms__content {
  margin: 0 0 40px;
}

.brrr-accept-terms__button-wrapper {
  position: sticky;
  bottom: 40px;
  margin: auto 0 0;
  background-color: var(--color-background);
  box-shadow: var(--shadow-background-spread-lg);
}

.brrr-accept-terms__target {
  position: absolute;
  bottom: -32px;
  left: 0;
  width: 100%;
  height: 1px;
}

.brrr-accept-terms__spinner {
  margin: 40px auto;
}

.brrr-accept-terms__offline {
  padding: 40px 0;
}

.brrr-accept-terms__offline-lottie {
  margin: 0 auto 4px;
}

.brrr-accept-terms__offline-text {
  margin: 0 auto 40px;
  white-space: pre-wrap;
}

.brrr-accept-terms__offline-button {
  max-width: 334px;
  margin: 0 auto;
}
</style>
