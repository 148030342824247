import { useSlippageGetterAPIService } from '@/composables/services/useSlippageGetterAPIService';
import { urlJoin } from '@/helpers/url';
import { currentClientType } from '@/helpers/utils';
import { SwapAPIService } from '@/references/axios/swap/SwapAPIService';
import { getEndpoint } from '@/references/endpoints';
import type { Network } from '@/references/network';

const registry = new Map<Network, SwapAPIService>();

export const useSwapAPIService = (network: Network) => {
  const storedService = registry.get(network);

  if (storedService === undefined) {
    const service = new SwapAPIService({
      network,
      baseURLSwapper0: urlJoin(getEndpoint('apiAssets'), '/0x'),
      baseURLSwapper1: urlJoin(getEndpoint('apiAssets'), '/1inch'),
      baseURLSwapper2: 'https://api.odos.xyz',
      slippageGetter: {
        getSlippage: (a, b, c) => useSlippageGetterAPIService().getSlippage(a, b, c)
      },
      clientType: currentClientType()
    });
    registry.set(network, service);
    return service;
  }

  return storedService;
};
