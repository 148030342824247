<script setup lang="ts">
import type { Connector } from '@wagmi/core';

import { useErrorModal } from '@/composables/useErrorModal';
import { useWagmi } from '@/composables/useWagmi';
import { addSentryBreadcrumb } from '@/logs/sentry';

import UiButton from '@/components/ui/UiButton.vue';
import UiModal from '@/components/ui/UiModal.vue';

interface Props {
  isOpened: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'connected'): void;
  (e: 'close'): void;
}>();

const { isConnected, connect, disconnect, connectors } = useWagmi();

const connectByConnector = async (connector: Connector): Promise<void> => {
  try {
    await connect(connector);
    emit('connected');
  } catch (e) {
    addSentryBreadcrumb({
      level: 'error',
      message: 'fail to connect'
    });
    useErrorModal().auto(e);
  }
};
</script>

<template>
  <UiModal :model-value="props.isOpened" @update:model-value="emit('close')">
    <div>
      <UiButton v-if="isConnected" @click="disconnect">Disconnect</UiButton>
      <div v-else>
        <UiButton
          v-for="connector in connectors"
          :key="connector.id"
          @click="connectByConnector(connector)"
        >
          <img width="16" height="16" :src="connector.icon" />{{ connector.name }}
        </UiButton>
      </div>
    </div>
  </UiModal>
</template>
