export class LruMap<key = string, value = unknown> extends Map<key, value> {
  maxSize: number;

  constructor(size: number) {
    super();
    this.maxSize = size;
  }

  override set(key: key, value: value): this {
    super.set(key, value);
    if (this.maxSize && this.size > this.maxSize) {
      const nextKey = this.keys().next().value;
      if (nextKey === undefined || nextKey === null) {
        return this;
      }

      this.delete(nextKey);
    }

    return this;
  }
}
