<script setup lang="ts">
import { reactive, useSlots } from 'vue';
import { useI18n } from 'vue-i18n';

import UiButton from '@/components/ui/UiButton.vue';
import UiDetailsButton from '@/components/ui/UiDetailsButton.vue';
import UiExpandable from '@/components/ui/UiExpandable.vue';
import UiInputAlert from '@/components/ui/UiInputAlert.vue';
import type { UiListPairGroup, UiListPairItem } from '@/components/ui/UiListPair.types';
import UiListPair from '@/components/ui/UiListPair.vue';
import type { UiLottieData } from '@/components/ui/UiLottie.types';
import UiLottie from '@/components/ui/UiLottie.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

interface Props {
  title: string;
  lottie?: UiLottieData;
  details: UiListPairGroup[];
  additionalDetails?: UiListPairGroup[];
  buttonText: string;
  loading: boolean;
  disabled?: boolean;
  error?: string;
  note?: string;
}

const { t } = useI18n();

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'submit'): void;
  (event: 'click:item', value: UiListPairItem): void;
}>();

const state = reactive<{
  isDetailsOpened: boolean;
}>({
  isDetailsOpened: false
});

const slots = useSlots();

function toggleDetails(): void {
  state.isDetailsOpened = !state.isDetailsOpened;
}
</script>

<template>
  <div class="transaction-review">
    <div class="transaction-review__body">
      <div class="transaction-review__icon-wrapper">
        <UiLottie
          v-if="props.lottie"
          class="transaction-review__icon"
          :data="props.lottie"
          :size="48"
          autoplay
          loop
        />
        <slot v-else name="icon" />
      </div>
      <UiTitle class="transaction-review__title" :level="1">
        {{ props.title }}
      </UiTitle>
      <UiListPair
        class="transaction-review__list"
        :details="props.details"
        @click:item="emit('click:item', $event)"
      />
      <div v-if="props.additionalDetails" class="transaction-review__additional-details">
        <UiExpandable>
          <div v-if="state.isDetailsOpened">
            <UiListPair
              class="transaction-review__list transaction-review__list_additional"
              :details="props.additionalDetails"
              @click:item="emit('click:item', $event)"
            />
          </div>
        </UiExpandable>
        <UiDetailsButton
          class="transaction-review__additional-details-button"
          :opened="state.isDetailsOpened"
          @click="toggleDetails"
        />
      </div>
      <div v-if="slots.default" class="transaction-review__slot">
        <slot />
      </div>
    </div>
    <div class="transaction-review__footer">
      <Transition name="transaction-review-error">
        <UiInputAlert v-if="props.error" class="transaction-review__error" size="sm">
          {{ props.error }}
        </UiInputAlert>
        <UiText v-else class="transaction-review__note" size="xs" align="center" tag="p" muted>
          {{ props.note ?? t('transactionFlow.hints.review') }}
        </UiText>
      </Transition>
      <UiButton
        class="transaction-review__button"
        :disabled="!!props.disabled"
        :loading="props.loading"
        @click="emit('submit')"
      >
        {{ props.buttonText }}
      </UiButton>
    </div>
  </div>
</template>

<style scoped>
.transaction-review {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 64px 0 0;
}

@media (max-width: 919px) {
  .transaction-review {
    padding: 24px 0 0;
  }
}

.transaction-review__body {
  margin: 0 0 40px;
}

.transaction-review__icon-wrapper,
.transaction-review__title {
  margin: 0 0 24px;
}

.transaction-review__title {
  word-break: break-word;
}

.transaction-review__additional-details {
  margin: 12px 0 0;
}

.transaction-review__list_additional {
  margin: 0 0 12px;
}

.transaction-review__additional-details-button {
  margin: -8px 0;
}

.transaction-review__slot {
  margin: 12px 0 0;
}

.transaction-review__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0 0;
}

.transaction-review__error,
.transaction-review__note {
  margin: 0 auto 16px;
}

.transaction-review__note {
  white-space: pre-wrap;
}

.transaction-review__button {
  width: 100%;
}

.transaction-review-error-enter-from,
.transaction-review-error-leave-to {
  opacity: 0;
}

.transaction-review-error-enter-active,
.transaction-review-error-leave-active {
  transition: opacity var(--animation-micro) var(--animation-effect);
}
</style>
