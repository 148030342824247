<template>
  <span class="ui-dots">
    <span v-for="dot in 3" :key="dot">.</span>
  </span>
</template>

<style scoped>
.ui-dots span:nth-child(1) {
  animation: ui-dots-1 1.5s linear infinite;
}

.ui-dots span:nth-child(2) {
  animation: ui-dots-2 1.5s linear infinite;
}

.ui-dots span:nth-child(3) {
  animation: ui-dots-3 1.5s linear infinite;
}

@keyframes ui-dots-1 {
  0% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ui-dots-2 {
  0% {
    opacity: 0;
  }

  21% {
    opacity: 0;
  }

  22% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ui-dots-3 {
  0% {
    opacity: 0;
  }

  43% {
    opacity: 0;
  }

  44% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
</style>
