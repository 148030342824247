<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import eurIcon from '@/assets/icons/eur.svg';
import swapIcon from '@/assets/icons/swap.svg';
import swapReverseIcon from '@/assets/icons/swap-reverse.svg';
import { useViewBreakpoints } from '@/composables/useViewBreakpoints';
import { isTouchDevice } from '@/helpers/device';
import { MAX_FIAT_AMOUNT, MAX_TOKEN_VISIBLE_DECIMALS } from '@/references/constants';
import type { Token } from '@/references/tokens';

import type { PrepareFormAmountOption } from '@/components/PrepareForm.types';
import TokenImage from '@/components/TokenImage.vue';
import UiBlinker from '@/components/ui/UiBlinker.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiInputAlert from '@/components/ui/UiInputAlert.vue';
import UiText from '@/components/ui/UiText.vue';
import type { InputTokenOrFiatMode } from '@/components/ui/UiTokenInput.types';
import UiTokenInput from '@/components/ui/UiTokenInput.vue';

interface Props {
  amount: string;
  inputMode?: InputTokenOrFiatMode;
  token?: Token;
  options?: PrepareFormAmountOption[];
  availableText?: string;
  error?: string;
  maxAmountText?: string;
  secondAmountText?: string;
  isAmountPulsing?: boolean;
  isMaxAmountPulsing?: boolean;
  isSecondAmountPulsing?: boolean;
  isToggleButtonDisabled?: boolean;
  hasToggleButton?: boolean;
  isSubmitButtonDisabled?: boolean;
  isSubmitButtonVisible?: boolean;
  isSubmitButtonLoading?: boolean;
  isSkipMaxFiatAmount?: boolean;
  isInfoClickable?: boolean;
  readonly?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  inputMode: 'FIAT',
  token: undefined,
  options: () => [],
  availableText: '',
  error: '',
  maxAmountText: '',
  secondAmountText: '',
  isAmountPulsing: false,
  isMaxAmountPulsing: false,
  isSecondAmountPulsing: false,
  isToggleButtonDisabled: false,
  hasToggleButton: true,
  isSubmitButtonDisabled: false,
  isSubmitButtonVisible: true,
  isSubmitButtonLoading: false,
  isSkipMaxFiatAmount: false,
  isInfoClickable: false,
  readonly: false
});

const emit = defineEmits<{
  (e: 'submit'): void;
  (e: 'toggle'): void;
  (e: 'click:info'): void;
  (e: 'update:inputMode', v: InputTokenOrFiatMode): void;
  (e: 'update:amount', v: string): void;
}>();

const inputComponent = ref<typeof UiTokenInput>();

const { isMobile } = useViewBreakpoints();
const { t } = useI18n();

const classList = computed(
  (): Record<string, boolean> => ({
    'prepare-form_readonly': props.readonly
  })
);

const maxDecimalCount = computed((): number => {
  if (!props.inputMode || props.inputMode !== 'TOKEN') {
    return 2;
  }

  return Math.min(props.token?.decimals ?? MAX_TOKEN_VISIBLE_DECIMALS, MAX_TOKEN_VISIBLE_DECIMALS);
});

function focus(): void {
  inputComponent.value?.focus();
}

function shake(): void {
  inputComponent.value?.shake();
}

function onBodyClick(event: Event): void {
  if (props.readonly) {
    return;
  }

  const target = event.target as HTMLElement;

  if (target.tagName === 'BUTTON' || target.closest('button')) {
    return;
  }

  focus();
}

function onInfoClick(): void {
  if (props.isInfoClickable) {
    emit('click:info');
  }
}

onMounted(() => {
  if (!isTouchDevice()) {
    focus();
  }
});

defineExpose({
  focus,
  shake
});
</script>

<template>
  <div class="prepare-form" :class="classList">
    <form class="prepare-form__form" @submit.prevent="emit('submit')">
      <div class="prepare-form__body" @click="onBodyClick">
        <div class="prepare-form__top">
          <div class="prepare-form__info">
            <component
              :is="props.isInfoClickable ? 'button' : 'div'"
              class="prepare-form__info-button"
              :type="props.isInfoClickable ? 'button' : undefined"
              @click="onInfoClick"
            >
              <TokenImage v-if="props.token" class="prepare-form__image" :token="props.token" />
              <span v-else class="prepare-form__eur">
                <UiIcon class="prepare-form__eur-icon" :name="eurIcon" :size="40" />
              </span>
              <span class="prepare-form__description">
                <UiText class="prepare-form__name" size="md" weight="medium">
                  {{ props.token ? props.token.name : 'Holyheld' }}
                </UiText>
                <UiText
                  v-if="props.availableText"
                  class="prepare-form__max-amount"
                  size="sm"
                  weight="medium"
                  muted
                >
                  {{ props.availableText }}
                </UiText>
                <UiText v-else class="prepare-form__max-amount" size="sm" weight="medium" muted>
                  <i18n-t keypath="forms.available" scope="global">
                    <template #value>
                      <UiBlinker :blinking="props.isMaxAmountPulsing">
                        {{ props.maxAmountText }}
                      </UiBlinker>
                    </template>
                  </i18n-t>
                </UiText>
              </span>
            </component>
          </div>
          <div class="prepare-form__amount">
            <UiBlinker class="prepare-form__input-blinker" :blinking="props.isAmountPulsing">
              <UiTokenInput
                ref="inputComponent"
                :input-mode="props.inputMode"
                :model-value="props.amount"
                class="prepare-form__input"
                :token="props.token"
                :align="isMobile ? 'left' : 'right'"
                :padding-left="isMobile ? 16 : 0"
                :padding-right="isMobile ? 0 : 16"
                :max-decimals="maxDecimalCount"
                :maximum="
                  props.inputMode === 'TOKEN' || props.isSkipMaxFiatAmount
                    ? undefined
                    : MAX_FIAT_AMOUNT
                "
                :readonly="props.readonly"
                @update:model-value="emit('update:amount', $event)"
                @update:input-mode="emit('update:inputMode', $event)"
              />
            </UiBlinker>
            <Transition mode="out-in">
              <UiInputAlert v-if="props.error" class="prepare-form__note" size="sm">
                {{ props.error }}
              </UiInputAlert>
              <button
                v-else-if="props.token && props.secondAmountText"
                class="prepare-form__toggle"
                type="button"
                :disabled="props.isToggleButtonDisabled"
                @click="emit('toggle')"
              >
                <UiIcon
                  v-if="props.inputMode === 'TOKEN'"
                  class="prepare-form__toggle-eur-icon"
                  :name="eurIcon"
                  :size="16"
                />
                <TokenImage
                  v-else
                  :token="props.token"
                  class="prepare-form__toggle-token"
                  size="sm"
                />
                <UiText class="prepare-form__toggle-text">
                  <UiBlinker :blinking="props.isSecondAmountPulsing">
                    {{ props.secondAmountText }}
                  </UiBlinker>
                </UiText>
                <UiIcon
                  v-if="props.hasToggleButton"
                  class="prepare-form__toggle-swap-icon"
                  :name="props.inputMode === 'FIAT' ? swapIcon : swapReverseIcon"
                  :size="16"
                />
              </button>
            </Transition>
          </div>
        </div>
        <ul v-if="props.options.length" class="prepare-form__options">
          <li v-for="option in props.options" :key="option.label" class="prepare-form__option">
            <UiButton mod="secondary" :disabled="option.disabled" @click="option.handler">
              {{ option.label }}
            </UiButton>
          </li>
        </ul>
      </div>
      <UiButton
        v-if="props.isSubmitButtonVisible"
        class="prepare-form__button"
        :disabled="props.isSubmitButtonDisabled"
        :loading="props.isSubmitButtonLoading"
        type="submit"
      >
        {{ t('forms.continue') }}
      </UiButton>
    </form>
  </div>
</template>

<style scoped>
.prepare-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.prepare-form__form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.prepare-form__body {
  padding: 16px;
  background-color: var(--color-main-underlay);
  border-radius: 12px;
}

.prepare-form:not(.prepare-form_readonly) .prepare-form__body {
  cursor: text;
}

.prepare-form__body:not(:last-child) {
  margin: 0 0 40px;
}

.prepare-form__top {
  display: flex;
}

@media (max-width: 919px) {
  .prepare-form__top {
    display: block;
  }
}

.prepare-form__info {
  display: flex;
  width: 50%;
  padding: 0 8px 0 0;
}

@media (max-width: 919px) {
  .prepare-form__info {
    width: 100%;
    margin: 0 0 16px;
    padding: 0;
  }
}

.prepare-form__info-button {
  display: flex;
}

button.prepare-form__info-button {
  width: auto;
  max-width: 100%;
  margin: 0;
  padding: 0;
  color: var(--color-main-text);
  text-align: left;
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  appearance: none;
  user-select: none;
}

.prepare-form__name {
  display: block;
}

.prepare-form__max-amount {
  display: block;
  transition: opacity var(--animation-micro) var(--animation-effect);
}

.prepare-form__amount {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
  padding: 0 0 0 8px;
}

@media (max-width: 919px) {
  .prepare-form__amount {
    align-items: flex-start;
    width: 100%;
    padding: 0;
  }
}

.prepare-form__input-blinker {
  display: block;
  width: calc(100% + 16px);
  min-width: calc(100% + 16px);
  margin: 0 -16px 8px 0;
}

@media (max-width: 919px) {
  .prepare-form__input-blinker {
    margin: 0 0 8px -16px;
  }
}

.prepare-form__input-blinker:last-child {
  margin-bottom: 0;
}

.prepare-form__input {
  max-width: 100%;
}

.prepare-form__note {
  min-height: 22px;
  text-align: right;
}

@media (max-width: 919px) {
  .prepare-form__note {
    text-align: left;
  }
}

.prepare-form__toggle {
  display: flex;
  align-items: center;
  max-width: 100%;
  height: auto;
  padding: 0;
  color: var(--color-main-text);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-sm);
  font-family: inherit;
  line-height: var(--line-height-sm);
  white-space: nowrap;
  text-align: right;
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  appearance: none;
  user-select: none;
}

.prepare-form__toggle:disabled {
  cursor: not-allowed;
}

.prepare-form__toggle-eur-icon {
  margin: 0 4px 0 0;
  color: var(--color-white);
  background-color: var(--color-grey);
  border-radius: 50%;
}

.prepare-form__toggle-token {
  margin: 0 4px 0 0;
}

.prepare-form__toggle-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.prepare-form__toggle-swap-icon {
  margin: 0 0 0 4px;
}

.prepare-form__image,
.prepare-form__eur {
  flex-shrink: 0;
  margin: 0 16px 0 0;
}

.prepare-form__eur {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background-color: var(--color-grey);
  border-radius: 50%;
}

.prepare-form__eur-icon {
  color: var(--color-white);
}

.prepare-form__options {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 16px -16px -16px 0;
  padding: 0;
  list-style-type: none;
}

.prepare-form__option {
  max-width: 100%;
  margin: 0 16px 16px 0;
}

.prepare-form__button {
  margin: auto 0 0;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity var(--animation-micro) var(--animation-effect);
  will-change: opacity;
}
</style>
