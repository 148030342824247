import { currentClientType } from '@/helpers/utils';
import { HHAPIApprovalService } from '@/references/axios/approval/HHAPIApprovalService';
import { getEndpoint } from '@/references/endpoints';

let service: HHAPIApprovalService | undefined;
export const useApprovalAPIService = (): HHAPIApprovalService => {
  if (service === undefined) {
    service = new HHAPIApprovalService(getEndpoint('apiBrrr'), currentClientType());
  }

  return service;
};
