import {
  type Address,
  type Hex,
  maxUint256,
  type PublicClient,
  type TypedDataDomain,
  type TypedDataParameter
} from 'viem';

import {
  getNetworkAddress,
  getNetworkByChainId,
  isBaseAssetByNetwork,
  isDefaultAddress,
  Network
} from '@/references/network';
import type { WalletInfoAdapter } from '@/references/onchain/adapters';
import { deriveChainId } from '@/references/onchain/adapters';
import { getNonce } from '@/references/onchain/getNonce';
import type { Token } from '@/references/tokens';

export interface PermitTransferFrom {
  permitted: TokenPermissions;
  spender: Address;
  nonce: bigint;
  deadline: bigint;
  witness?: Hex;
}

export interface TokenPermissions {
  token: Address;
  amount: bigint;
}

export interface Witness {
  witness: Hex;
  witnessTypeName: string;
  witnessType: Record<string, TypedDataParameter[]>;
}

export type PermitTransferFromData = {
  domain: TypedDataDomain;
  types: Record<string, TypedDataParameter[]>;
  values: PermitTransferFrom;
  primaryType: string;
};

const TOKEN_PERMISSIONS: TypedDataParameter[] = [
  { name: 'token', type: 'address' },
  { name: 'amount', type: 'uint256' }
];

const PERMIT_TRANSFER_FROM_TYPES = {
  PermitTransferFrom: [
    { name: 'permitted', type: 'TokenPermissions' },
    { name: 'spender', type: 'address' },
    { name: 'nonce', type: 'uint256' },
    { name: 'deadline', type: 'uint256' }
  ],
  TokenPermissions: TOKEN_PERMISSIONS
} as Record<string, TypedDataParameter[]>;

/**
 * A class representing basic functions for permit2
 */
export class Permit2OnChainService {
  protected readonly sentryCategoryPrefix = 'permit2.on-chain-service';

  public readonly baseNonce: bigint = 104111108121104101108100n;
  public readonly maxSigDeadline = maxUint256;
  public readonly maxUnorderedNonce = maxUint256;
  public readonly maxSignatureTransferAmount = maxUint256;
  public readonly PERMIT2_DOMAIN_NAME = 'Permit2';
  protected readonly walletInfo: WalletInfoAdapter;

  constructor(walletInfo: WalletInfoAdapter) {
    this.walletInfo = walletInfo;
  }

  public async getPermitNonce(publicClient: PublicClient, senderAddress: Address): Promise<bigint> {
    const [onChainNonce, offChainNonce] = await Promise.all([
      getNonce(publicClient, senderAddress, this.walletInfo),
      this.walletInfo.getOffchainPermit2Nonce?.({
        address: senderAddress,
        network: getNetworkByChainId(await deriveChainId(publicClient))?.network ?? Network.unknown
      }) ?? 0n
    ]);

    return this.baseNonce + onChainNonce + offChainNonce;
  }

  public isPermit2Allowed(token: Token): boolean {
    if (isBaseAssetByNetwork(token.address, token.network)) {
      return false;
    }

    // permit2 contract exists
    return !isDefaultAddress(getNetworkAddress(token.network, 'PERMIT2_CONTRACT_ADDRESS'));
  }

  public getPermit2Address(network: Network): Address {
    return getNetworkAddress(network, 'PERMIT2_CONTRACT_ADDRESS');
  }

  protected permit2Domain(permit2Address: Address, chainId: number): TypedDataDomain {
    return {
      name: this.PERMIT2_DOMAIN_NAME,
      chainId,
      verifyingContract: permit2Address
    };
  }

  protected validateTokenPermissions(permissions: TokenPermissions): never | void {
    if (this.maxSignatureTransferAmount <= permissions.amount) {
      throw new Error(`AMOUNT_OUT_OF_RANGE: ${permissions.amount}`);
    }
  }

  protected permitTransferFromWithWitnessType(
    witness: Witness
  ): Record<string, TypedDataParameter[]> {
    return {
      PermitWitnessTransferFrom: [
        { name: 'permitted', type: 'TokenPermissions' },
        { name: 'spender', type: 'address' },
        { name: 'nonce', type: 'uint256' },
        { name: 'deadline', type: 'uint256' },
        { name: 'witness', type: witness.witnessTypeName }
      ],
      TokenPermissions: TOKEN_PERMISSIONS,
      ...witness.witnessType
    };
  }

  public getPermitData(
    permit: PermitTransferFrom,
    permit2Address: Address,
    chainId: number,
    witness?: Witness
  ): PermitTransferFromData {
    if (this.maxSigDeadline <= permit.deadline) {
      throw new Error(`SIG_DEADLINE_OUT_OF_RANGE: ${permit.deadline}`);
    }
    if (this.maxUnorderedNonce <= permit.nonce) {
      throw new Error(`SIG_NONCE_OUT_OF_RANGE: ${permit.nonce}`);
    }

    const domain = this.permit2Domain(permit2Address, chainId);
    this.validateTokenPermissions(permit.permitted);
    const types = witness
      ? this.permitTransferFromWithWitnessType(witness)
      : PERMIT_TRANSFER_FROM_TYPES;
    const values = witness ? Object.assign(permit, { witness: witness.witness }) : permit;
    return {
      domain,
      types,
      values,
      primaryType: witness ? 'PermitWitnessTransferFrom' : 'PermitTransferFrom'
    };
  }
}
