import { HHError } from '@/references/HHError';

type Reason = 'thresholdCheckFailed' | 'insufficientAllowance' | 'requiresZeroAllowance';

export class AllowanceMismatchError extends HHError {
  override name = 'AllowanceMismatchError';
  constructor(
    description: string,
    reason: Reason,
    { cause, payload }: { cause?: unknown; payload?: Record<string, unknown> } = {}
  ) {
    super(`Allowance mismatch (${reason}): ${description}`, { cause, payload });
  }
}
