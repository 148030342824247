<script setup lang="ts">
import { computed, nextTick, onBeforeUnmount, onMounted, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { type WalletAdapter, WalletReadyState } from '@solana/wallet-adapter-base';
import { useStepper } from '@vueuse/core';

import topUpLottieData from '@/assets/lottie/top-up.json';
import { useBrrr } from '@/composables/useBrrr';
import { type ClientEECode, useErrorModal } from '@/composables/useErrorModal';
import { useSolana } from '@/composables/useSolana';
import { isZero } from '@/helpers/bigmath';
import { fade, type MotionVariants, slideLeft, slideRight } from '@/helpers/motion';
import { addSentryBreadcrumb } from '@/logs/sentry';
import { PROGRESS_VIEW_VISIBILITY_DURATION } from '@/references/constants';
import { ExpectedError } from '@/references/ExpectedError';
import { Network } from '@/references/network';
import { RouteNames } from '@/router';
import { BrrrBuySolanaViewStep } from '@/views/BrrrBuySolanaView.types';

import BrrrSolanaCrypto from '@/components/BrrrSolanaCrypto.vue';
import { InnerLayoutButtonType } from '@/components/layouts/InnerLayout.types';
import InnerLayout from '@/components/layouts/InnerLayout.vue';
import ProgressLayout from '@/components/layouts/ProgressLayout.vue';
import UiButton from '@/components/ui/UiButton.vue';

const { t } = useI18n();
const { replace, push } = useRouter();
const { isBrrrAvailable, available, termsAccepted, infoAccepted } = useBrrr();
const { connect, wallets, select, connected } = useSolana();

const state = reactive<{
  isMounted: boolean;
  previousStep: BrrrBuySolanaViewStep | null;
  network: Network;
  isProgressState: boolean;
  txHash: string;
}>({
  isMounted: false,
  previousStep: null,
  network: Network.unknown,
  isProgressState: false,
  txHash: ''
});

const stepper = useStepper([
  BrrrBuySolanaViewStep.Connect,
  BrrrBuySolanaViewStep.Value,
  BrrrBuySolanaViewStep.Review
]);

let progressTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

const tipDescription = computed((): string | undefined => {
  if (stepper.current.value === BrrrBuySolanaViewStep.Review) {
    // if (state.token === null) {
    //   return t('forms.bankTransfer.tips.youAreAboutToMakeATransaction');
    // }
    return t('youAreAboutToMakeATransaction');
  }

  return undefined;
});

const internalMotion = computed((): MotionVariants<never> | undefined => {
  if (!state.isMounted) {
    return fade;
  }

  if (!state.previousStep || stepper.isAfter(state.previousStep)) {
    return slideRight;
  }

  return slideLeft;
});

function goBack(): void {
  if (stepper.current.value === BrrrBuySolanaViewStep.Connect) {
    replace({ name: RouteNames.Brrr });
    return;
  }

  state.previousStep = stepper.current.value;
  stepper.goToPrevious();
}

function goNext(): void {
  state.previousStep = stepper.current.value;
  stepper.goToNext();
}

async function handleConnectToWallet(wallet: WalletAdapter) {
  select(wallet.name);
  await nextTick();
  try {
    await connect();
    goNext();
  } catch (error) {
    if (error instanceof Error && /user rejected the request/i.test(error.message)) {
      useErrorModal().auto(new ExpectedError<ClientEECode>('userRejectAuth', { cause: error }));
    }
  }
}

function onDone(txHash: string): void {
  state.txHash = txHash;
  state.isProgressState = true;

  progressTimeout = setTimeout(() => {
    push({ name: RouteNames.Home });
  }, PROGRESS_VIEW_VISIBILITY_DURATION);
}

onMounted(() => {
  state.isMounted = true;

  if (
    !isBrrrAvailable.value ||
    !termsAccepted.value ||
    (!infoAccepted.value && isZero(available.value))
  ) {
    addSentryBreadcrumb({
      level: 'debug',
      message: 'BRRR buy feature is not currently not available',
      data: {
        featureAvailable: isBrrrAvailable.value,
        termsAccepted: termsAccepted.value,
        infoAccepted: infoAccepted.value
      }
    });

    useErrorModal().auto(
      new ExpectedError<ClientEECode>('brrrUnavailable', {
        sentryHandle: false,
        payload: {
          featureAvailable: isBrrrAvailable.value,
          termsAccepted: termsAccepted.value,
          infoAccepted: infoAccepted.value
        }
      })
    );

    replace({ name: RouteNames.Home });
  }
});

onMounted(() => {
  if (connected.value) {
    goNext();
  }
});

onBeforeUnmount(() => {
  clearTimeout(progressTimeout);
});
</script>

<template>
  <section class="brrr-buy-view">
    <ProgressLayout
      v-if="state.isProgressState"
      v-motion="slideRight"
      :title="t('brrr')"
      :lottie="topUpLottieData"
    />
    <InnerLayout
      v-else
      :title="t('brrrBuy.title')"
      :button-type="InnerLayoutButtonType.Back"
      :tip-title="t('whatDoesItMean')"
      :tip-description="tipDescription"
      @back="goBack"
    >
      <ul v-if="stepper.current.value === BrrrBuySolanaViewStep.Connect">
        <template v-if="wallets.length > 0">
          <li v-for="w in wallets" :key="w.adapter.name">
            <UiButton
              :loading="w.adapter.connecting"
              :disabled="
                w.readyState === WalletReadyState.NotDetected ||
                w.readyState === WalletReadyState.Unsupported
              "
              @click="handleConnectToWallet(w.adapter)"
            >
              <img width="16" height="16" :src="w.adapter.icon" :alt="w.adapter.name" />
              {{ w.adapter.name }}
            </UiButton>
          </li>
        </template>
        <li v-else>{{ t('no solana wallet detected') }}</li>
      </ul>
      <BrrrSolanaCrypto
        v-else
        :step="stepper.current.value"
        :motion="internalMotion"
        @next="goNext"
        @done="onDone"
      />
    </InnerLayout>
  </section>
</template>

<style scoped>
.brrr-buy-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
