<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import UiContainer from '@/components/ui/UiContainer.vue';
import type { UiLottieData } from '@/components/ui/UiLottie.types';
import UiLottie from '@/components/ui/UiLottie.vue';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

interface Props {
  lottie: UiLottieData;
  title: string;
}

const props = defineProps<Props>();

const { t } = useI18n();
</script>

<template>
  <main class="progress-layout">
    <UiContainer class="progress-layout__container" size="sm">
      <div class="progress-layout__body">
        <UiLottie class="progress-layout__lottie" :data="props.lottie" :size="80" loop autoplay />
        <UiTitle class="progress-layout__title" :level="1" size="md" align="center">
          {{ props.title }}
        </UiTitle>
        <UiText
          class="progress-layout__text"
          tag="p"
          weight="regular"
          size="md"
          muted
          align="center"
        >
          {{ t('inProgress') }}
        </UiText>
      </div>
      <UiSpinner class="progress-layout__spinner" :size="40" />
    </UiContainer>
  </main>
</template>

<style scoped>
.progress-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}

.progress-layout__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.progress-layout__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin: 0 0 40px;
}

.progress-layout__lottie {
  display: flex;
  justify-content: center;
  margin: 0 0 24px;
}

.progress-layout__title {
  margin: 0 0 8px;
}

.progress-layout__spinner {
  margin: auto auto 0;
}
</style>
