import type { Account, Address, Chain, PublicClient, Transport, WalletClient } from 'viem';

import type { AwaitedNestedPromise, MaybePromise } from '@/helpers/promises';
import type { Network } from '@/references/network';

export type WalletClientWithHoistedAccount = WalletClient<Transport, Chain, Account>;

export type GetWalletClientArgs = {
  chainId?: number;
};

export type WalletClientGetter = (
  args?: GetWalletClientArgs
) => Promise<WalletClientWithHoistedAccount | null>;

/**
 * A client-shaped proxy with minimal, yet required method set for client to implement
 *
 * Integrator may to use this proxy object to inject per-request logs / analytics / error handling
 */
export interface WalletClientProxy {
  signMessage: WalletClientWithHoistedAccount['signMessage'];
  signTypedData: WalletClientWithHoistedAccount['signTypedData'];
  writeContract: WalletClientWithHoistedAccount['writeContract'];
}

export type WalletClientExecutor = <T>(
  executor: (client: WalletClientProxy) => Promise<AwaitedNestedPromise<T>>
) => Promise<AwaitedNestedPromise<T>>;

export interface WalletClientAdapter {
  useWalletClient(args?: GetWalletClientArgs): WalletClientExecutor;
}

export type PublicClientWithHoistedChain = PublicClient<Transport, Chain>;

/**
 * Extracts chainId from current state of publicClient (synchronously) or gets one via
 *  inner call to the client (or transport, if needed)
 *
 * @param publicClient - a client to derive chainId from
 */
export const deriveChainId = async (publicClient: PublicClient): Promise<number> => {
  if (publicClient.chain !== undefined) {
    return publicClient.chain.id;
  }

  return publicClient.getChainId();
};

export interface WalletInfoAdapter {
  /**
   * Returns if the connected wallet is ERC-1271 signer
   *
   * Such wallets prevent some allowance variants from being used
   * @param params - predicate params
   */
  isErc1271Signer(params: IsErc1271SignerParams): MaybePromise<boolean>;

  /**
   * Returns if the wallet supports eth_signTypedData_v4
   *
   * Inability to perform such request prevents some allowance variants from being used
   */
  supportsSignTypedDataV4(): MaybePromise<boolean>;

  /**
   * Returns nonce
   *
   * Custom function that may be defined by the client to bypass standard
   *
   * @param params - nonce params
   */
  getNonce?(params: GetNonceParams): Promise<bigint>;

  /**
   * Returns if the wallet supports permit operations
   *
   * @param params - predicate params
   */
  supportsPermit?(params: SupportsPermitParams): MaybePromise<boolean>;

  /**
   * Returns if the wallet supports permit2 operations
   *
   * @param params - predicate params
   */
  supportsPermit2?(params: SupportsPermit2Params): MaybePromise<boolean>;

  /**
   * Returns if the wallet supports raw transactions signing via "eth_sign" or "eth_signTransaction"
   */
  supportsRawTransactionsSigning(): MaybePromise<boolean>;

  /**
   * Returns offchain permit2 nonce
   *
   * Defaults to 0
   */
  getOffchainPermit2Nonce(params: GetOffchainPermit2NonceParams): Promise<bigint>;

  /**
   * Increments offchain permit2 nonce
   */
  incrementPermit2Nonce(params: IncrementPermit2NonceParams): Promise<void>;
}

export type IsErc1271SignerParams = { chainId: number };
export type GetNonceParams = { chainId: number };
export type SupportsPermitParams = { chainId: number };
export type SupportsPermit2Params = { chainId: number };
export type GetOffchainPermit2NonceParams = { address: Address; network: Network };
export type IncrementPermit2NonceParams = { address: Address; network: Network };
