import { type Address, ContractFunctionZeroDataError, isAddress, type PublicClient } from 'viem';

import { walkError } from '@/helpers/errors';
import { createLogger } from '@/logs/datadog';
import { LruMap } from '@/references/LruMap';
import { deriveChainId } from '@/references/onchain/adapters';

import { erc4337Abi, erc4337EntryPointAbi } from '../abi/erc4337';
import { isContract } from './isContract';

const cache = new LruMap<string, boolean>(16);

/**
 * Returns if the address is EIP-4337-compatible CA that supports semi-abstracted nonce
 *
 * @see https://eips.ethereum.org/EIPS/eip-4337#semi-abstracted-nonce-support
 * @param publicClient - public address in desired chain
 * @param address - address to check
 */
export const isSemiAbstractedNonceSupported = async (
  publicClient: PublicClient,
  address: Address
): Promise<boolean> => {
  const logger = createLogger('isSemiAbstractedNonceSupported');
  const chainId = await deriveChainId(publicClient);

  const cacheKey = `${chainId}:${address}`;
  const cached = cache.get(cacheKey);
  if (cached !== undefined) {
    return cached;
  }

  let entryPoint: Address;
  try {
    entryPoint = await publicClient.readContract({
      address,
      abi: erc4337Abi,
      functionName: 'entryPoint'
    });
  } catch (error) {
    if (walkError(error, (e) => e instanceof ContractFunctionZeroDataError)) {
      logger.debug(
        `The function call "entryPoint" on ${address} in chain ${chainId} reverted. Either such function is not implemented, the address is EOA or the contract is not initialized yet`,
        { error }
      );

      return false;
    }

    throw error;
  }

  logger.debug(
    `Entrypoint function call on ${address} in chain ${chainId} returned ${entryPoint}. Checking if it's the address`,
    { entryPoint }
  );

  const hasEntryPoint = isAddress(entryPoint) && entryPoint !== '0x0';
  if (!hasEntryPoint) {
    logger.info(
      `${entryPoint} is not a valid address. The address is likely non-EIP-4337-compatible CA or the contract is not initialized yet`
    );
    cache.set(cacheKey, false);
    return false;
  }

  const isEntryPointCA = await isContract(publicClient, entryPoint);
  if (!isEntryPointCA) {
    logger.warn(
      `An entryPoint is not a CA. Assume the AA wallet ${address} does not support semi-abstracted nonce`
    );
    cache.set(cacheKey, false);
    return false;
  }

  logger.debug(
    `${entryPoint} is a valid CA address. Try to call "getNonce" to check if it's implemented`
  );

  try {
    const nonce = await publicClient.readContract({
      address: entryPoint,
      abi: erc4337EntryPointAbi,
      functionName: 'getNonce',
      args: [address, 0n]
    });

    logger.info(
      `Entrypoint responded with the nonce of ${nonce}. Assume it supports semi-abstracted nonce`
    );
    cache.set(cacheKey, true);
    return true;
  } catch (error) {
    if (walkError(error, (e) => e instanceof ContractFunctionZeroDataError)) {
      logger.debug(
        `The function call "getNonce" on ${entryPoint} in chain ${chainId} reverted. Either such function is not implemented or the entrypoint is EOA`,
        {
          error
        }
      );
      cache.set(cacheKey, false);
      return false;
    }

    throw error;
  }
};
