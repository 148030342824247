export const ROOT_DOMAIN = 'holyheld.com';
export const MILLISECOND = 1;
export const SECOND = 1000 * MILLISECOND;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const IDLE_TIMEOUT = 5 * MINUTE; // 5 min
export const GLOBAL_UPDATE_RATE = 30 * SECOND; // 1 min
export const TOASTS_TIMEOUT = 3 * SECOND; // 3s
export const KB = 1024;
export const MB = 1024 * KB;
export const TOKEN_INFO_MAX_VISIBLE_VALUE = 1000000;
export const MAX_FIAT_AMOUNT = 100000;
export const NATIVE_OPTIONS = [10000, 50000];

export const HEARTBEAT_CHECK_INTERVAL = 5 * SECOND;
export const HEARTBEAT_SEND_THROTTLE_TIMEOUT = MINUTE;
export const SESSION_LIVE_TIME = 5 * MINUTE;
export const FORM_SET_AMOUNT_DEBOUNCE = 400 * MILLISECOND;

export const MAX_TOKEN_VISIBLE_DECIMALS = 6;

export const DEFAULT_GET_DURATION = 500 * MILLISECOND;
export const MIN_DEFI_SWAP_DIFF_UPPER_BORDER = '0.95';
export const PROGRESS_VIEW_VISIBILITY_DURATION = 5 * SECOND;
export const STILL_PENDING_STATUS_RESERVE_TIME = 2 * SECOND;

export const WALLET_CONNECT_PROJECT_ID =
  import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID ?? 'not set';

export const WALLET_CONNECT_META = {
  name: 'Holyheld BRRR',
  description: 'BRRR Purchase app',
  url: 'https://brrr.holyheld.com',
  icons: ['https://brrr.holyheld.com/favicon.ico']
};
