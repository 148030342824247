import {
  ChainMismatchError,
  MethodNotFoundRpcError,
  MethodNotSupportedRpcError,
  UserRejectedRequestError
} from 'viem';

import type { ClientEECode } from '@/composables/useErrorModal';
import { walkError } from '@/helpers/errors';

import { ExpectedError } from '../ExpectedError';

export interface ProviderRpcError {
  code: number;
  message: string;
  data?: unknown;
}

export const isProviderRpcError = (error: unknown): error is ProviderRpcError => {
  if (!(error instanceof Object)) {
    return false;
  }

  const candidate = error as Partial<ProviderRpcError>;
  return candidate.message !== undefined && candidate.code !== undefined;
};

export const isUnrecognizedChainError = (error: unknown): boolean => {
  if (
    isProviderRpcError(error) &&
    (error.code === ProviderRpcErrorCode.Internal ||
      error.code === ProviderRpcErrorCode.UnrecognizedChain)
  ) {
    return true;
  }

  return error instanceof Error && error.message.startsWith('Unrecognized chain ID');
};

export const unwrapWalletConnectError = (error: unknown): ProviderRpcError | undefined => {
  if (!isProviderRpcError(error)) {
    return undefined;
  }

  try {
    const parsed = JSON.parse(error.message);
    if (isProviderRpcError(parsed)) {
      return parsed;
    }

    return undefined;
  } catch {
    return undefined;
  }
};

const isRejectedRequestErrorMessage = (error: unknown): boolean => {
  let target: string;
  if (typeof error === 'string') {
    target = error;
  } else if (error instanceof Error) {
    target = error.message;
  } else {
    try {
      target = JSON.stringify(error);
    } catch {
      target = String(error);
    }
  }

  if (/user (?:disapproved|rejected)/i.test(target)) {
    return true;
  }

  if (/reject/i.test(target)) {
    return true;
  }

  if (/cancelled/i.test(target)) {
    return true;
  }

  if (/requested resource not available/i.test(target)) {
    return true;
  }

  switch (target) {
    case WalletConnectErrorSignature.FailedOrRejectedRequest:
    case WalletConnectErrorSignature.RejectedByTheUser:
    case WalletConnectErrorSignature.RejectedByUser:
    case WalletConnectErrorSignature.SignatureDenied:
    case WalletConnectErrorSignature.RejectedByUser2:
    case WalletConnectErrorSignature.AmbireUserRejectedRequest:
    case WalletConnectErrorSignature.RequestRejected:
    case WalletConnectErrorSignature.RequestRejected2:
    case WalletConnectErrorSignature.UserCancelledTheRequest:
    case WalletConnectErrorSignature.UserAction:
    case MetamaskErrorSignature.UserDeniedMessageSignature:
    case TrustWalletErrorSignature.UserCanceled:
    case WalletConnectErrorSignature.UserSignatureFailure:
    case WalletConnectErrorSignature.ConnectionRequestReset:
      return true;
  }

  return false;
};

export const isRejectedRequestError = (error: unknown): boolean => {
  if (error instanceof ExpectedError) {
    switch (error.getCode()) {
      case 'userRejectAuth':
      case 'userRejectTransaction':
      case 'userRejectNetworkChange':
      case 'userRejectOwnershipSignature':
      case 'userRejectSign':
        return true;
    }
  }

  if (error instanceof UserRejectedRequestError) {
    return true;
  }

  if (isProviderRpcError(error)) {
    switch (error.code) {
      case ProviderRpcErrorCode.UserRejectedRequest:
      case ProviderRpcErrorCode.TransactionRejected:
      case ProviderRpcErrorCode.Unauthorized:
      case WalletConnectErrorCode.UserRejected:
      case WalletConnectErrorCode.UserRejectedChains:
      case WalletConnectErrorCode.UserRejectedMethods:
      case WalletConnectErrorCode.UserRejectedEvents:
        return true;
    }

    if (isRejectedRequestErrorMessage(error.message)) {
      return true;
    }

    const unwrappedError = unwrapWalletConnectError(error);
    if (unwrappedError !== undefined && isRejectedRequestError(unwrappedError)) {
      return true;
    }

    // Zerion over WC2 error rejection signature
    if (error.code === 0 && error.message.trim() === '') {
      return true;
    }
  }

  return (
    (error instanceof Error && isRejectedRequestError(error?.cause)) ||
    isRejectedRequestErrorMessage(error)
  );
};

export enum ProviderRpcErrorCode {
  InvalidInput = -32000,
  ResourceNotFound = -32001,
  ResourceUnavailable = -32002,
  TransactionRejected = -32003,
  MethodNotSupported = -32004,
  LimitExceeded = -32005,
  Parse = -32700,
  InvalidRequest = -32600,
  MethodNotFound = -32601,
  InvalidParams = -32602,
  Internal = -32603,
  UserRejectedRequest = 4001,
  Unauthorized = 4100,
  UnsupportedMethod = 4200,
  Disconnected = 4900,
  ChainDisconnected = 4901,
  UnrecognizedChain = 4902
}

export enum WalletConnectErrorCode {
  InvalidMethod = 1001,
  InvalidEvent = 1002,
  InvalidUpdateRequest = 1003,
  InvalidExtendRequest = 1004,
  InvalidSessionSettleRequest = 1005,
  UnauthorizedMethod = 3001,
  UnauthorizedEvent = 3002,
  UnauthorizedUpdateRequest = 3003,
  UnauthorizedExtendRequest = 3004,
  UnauthorizedChain = 3005,
  UserRejectedRequest = 4001,
  UserRejected = 5000,
  UserRejectedChains = 5001,
  UserRejectedMethods = 5002,
  UserRejectedEvents = 5003,
  UnsupportedChains = 5100,
  UnsupportedMethods = 5101,
  UnsupportedEvents = 5102,
  UnsupportedAccounts = 5103,
  UnsupportedNamespaceKey = 5104,
  UserDisconnected = 6000,
  SessionSettlementFailed = 7000,
  NoSessionForTopic = 7001,
  SessionRequestExpired = 8000
}

export enum WalletConnectErrorSignature {
  FailedOrRejectedRequest = 'Failed or Rejected Request',
  RejectedByTheUser = 'Reject by the user',
  RejectedByUser = 'Rejected by User',
  SignatureDenied = 'signature denied', // Ambire
  RejectedByUser2 = 'Rejected by user', // Argent
  AmbireUserRejectedRequest = 'Ambire user rejected the request',
  RequestRejected = 'Request rejected',
  RequestRejected2 = 'Request Rejected', // Bitcoin.com
  UserCancelledTheRequest = 'User cancelled the request', // Rainbow
  UserAction = 'User action', // STASIS
  UserSignatureFailure = 'User signature failure', // Binance
  ConnectionRequestReset = 'Connection request reset. Please try again.'
}

export enum TrustWalletErrorSignature {
  UserCanceled = 'User canceled'
}

export enum MetamaskErrorSignature {
  UserDeniedMessageSignature = 'MetaMask Personal Message Signature: User denied message signature.'
}

export const isChainMismatchError = (error: unknown): boolean => {
  return walkError(error, (e) => {
    if (e instanceof ChainMismatchError) {
      return true;
    }

    const re =
      /the current chain of the wallet \(.*?\) does not match the target chain for the transaction/i;

    if (typeof e === 'string' && re.test(e)) {
      return true;
    }

    if (!(isProviderRpcError(e) || e instanceof Error)) {
      return false;
    }

    return re.test(e.message);
  });
};

export const isMethodNotSupportedError = (error: unknown): boolean => {
  return walkError(
    error,
    (e) =>
      e instanceof MethodNotSupportedRpcError ||
      e instanceof MethodNotFoundRpcError ||
      (e instanceof ExpectedError && (e.getCode() as ClientEECode) === 'methodNotSupported')
  );
};
