import { type Address } from 'viem';

import type { MaybePromise } from '@/helpers/promises';
import { createHHAxios } from '@/references/axios/axios';
import { HHAPIService } from '@/references/axios/base';
import type { SlippageGetter } from '@/references/axios/swap/SlippageGetter';
import type { ClientType } from '@/references/base';
import type { Network } from '@/references/network';
import { substituteAssetAddressIfNeeded } from '@/references/tokens';

export interface MinimalStorage {
  getItem(key: string): MaybePromise<string | null>;

  setItem(key: string, value: string): MaybePromise<void>;
}

type ConstructorArgs = {
  baseURL: string;
  clientType: ClientType;
  storage?: MinimalStorage;
};

export class HHAPISlippageGetterService extends HHAPIService implements SlippageGetter {
  private readonly baseURL: string;
  private readonly clientType: ClientType;
  private readonly storage?: MinimalStorage;

  constructor({ baseURL, storage, clientType }: ConstructorArgs) {
    super('slippage-getter.api.service');
    this.storage = storage;
    this.baseURL = baseURL;
    this.clientType = clientType;
  }

  async getSlippage(
    network: Network,
    fromTokenAddress: Address,
    toTokenAddress: Address
  ): Promise<string> {
    const sanitizedFrom = substituteAssetAddressIfNeeded(fromTokenAddress, network);
    const sanitizedTo = substituteAssetAddressIfNeeded(toTokenAddress, network);

    if (this.storage !== undefined) {
      const stored = await this.storage.getItem(
        this.getStorageKey(network, sanitizedFrom, sanitizedTo)
      );
      if (stored !== null) {
        return stored;
      }
    }

    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    const resp = await instance.request<
      {
        slippage: string;
      },
      {
        network: Network;
        fromTokenAddress: Address;
        toTokenAddress: Address;
      }
    >({
      method: 'POST',
      url: '/slippage',
      data: {
        network,
        fromTokenAddress: sanitizedFrom,
        toTokenAddress: sanitizedTo
      }
    });

    if (this.storage !== undefined) {
      await this.storage.setItem(
        this.getStorageKey(network, sanitizedFrom, sanitizedTo),
        resp.data.payload.slippage
      );
    }

    return resp.data.payload.slippage;
  }

  public getStorageKey(
    network: Network,
    fromTokenAddress: Address,
    toTokenAddress: Address
  ): string {
    const sanitizedFrom = substituteAssetAddressIfNeeded(fromTokenAddress, network);
    const sanitizedTo = substituteAssetAddressIfNeeded(toTokenAddress, network);
    return `${network}:${sanitizedFrom}:${sanitizedTo}`;
  }
}
